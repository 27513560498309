import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Select} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog, confirmDeleteDialog } from "../../../functions/alert";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import FormStatus from "../component/form-status";

const { Option } = Select;
const { Content } = Layout;
const { Title, Text } = Typography;

const AdminStatus = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('ks.kategori_status,asc');
  const [filter, setFilter] = useState('');
  const [dataList, setDataList] = useState(null);
  const [dataLov, setDataLov] = useState([]);
  const [dataLovAddable, setDataLovAddable] = useState([]);
  const [isShow, setShow] = useState({show: false, record:null});

  useEffect(()=>{
    getDataLov()
  }, []);

  useEffect(()=>{
    console.log(pagination)
  }, [pagination])

  const getDataLov = () => {
    setLoading(true)
    LoyolaServices.getDataLovStatusAdmin().then(res => {
      if(res.data){
        var data = res.data.data
        console.log(data)
        setDataLov(data)
        getDataLovAddable()
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        // setDataList([])
        setLoading(false)
      }
    })
  }

  const getDataLovAddable = () => {
    setLoading(true)
    LoyolaServices.getDataLovStatusAdminAddable().then(res => {
      if(res.data){
        var data = res.data.data
        console.log(data)
        setDataLovAddable(data)
        getData(pagination,sort,filter)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        // setDataList([])
        setLoading(false)
      }
    })
  }

  const getData = (page,sort,filter) => {
    setLoading(true)
    LoyolaServices.getDataStatusAdmin(page.current - 1,page.pageSize,sort,filter).then(res => {
      if(res.data){
        var data = res.data.data
        console.log(data)
        setDataList(data)
        setPagination({
          ...pagination,
          current: page.current,
          total: res.data.total
        })
        setLoading(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        setLoading(false)
      }
    })
  }

  const handleFilter = (param) => {
    setFilter(param)
    getData({...pagination, current: 1}, sort, param)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'ks.kategori_status,asc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,filter)
  };

  const onRefresh = () => {
    console.log('data onrefresh : ')
      setShow({show: false, record: null})
      getData(pagination,sort,filter)
  }

  const deleteData = (record) => {
    setLoading(true)
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    let body = {
      userId: userId,
      statusId: record.statusId
    }
    LoyolaServices.deleteStatusAdmin(body).then(res => {
      if(res.data){
        onRefresh()
        dialog({icon: 'success', text: res.data.message})
      }
    }).catch(err => {
      if(err){
        setLoading(false)
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  const columns = [
    {
        title: 'Kategori Status',
        dataIndex: 'statusKategori',
        key: 'ks.kategori_status',
        sorter: true,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
    },
    {
        title: 'Deskripsi',
        dataIndex: 'deskripsiStatus',
        key: 'deskripsi',
        sorter: true,
    },
    {
        title: 'Isi Pengumuman',
        dataIndex: 'pengumuman',
        key: 'pengumuman',
        sorter: true,
    },
    {
        width: '25%',
        title: 'Aksi',
        render: (record) => {
          return (
            <Row gutter={[20,10]}>
              <Col>
                <Button className="app-btn" style={{backgroundColor: '#EF712B', color: '#fff'}} onClick={() => setShow({show: true, record: record})}>
                  Ubah
                </Button>
              </Col>
              {
                record.isStatusAddable &&
                <Col>
                  <Button className="app-btn" type='primary' danger onClick={() => {
                    confirmDeleteDialog({text: 'Apakah Anda yakin akan menghapus data ini ?'}).then(() => deleteData(record))
                    .catch(err => {});
                  }}>
                    Hapus
                  </Button>
                </Col>
              }
            </Row>
          );
        },
      },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}}>
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Kategori Status"
              bordered={false}
              style={{
                  padding: 5,
                  width: '100%',
                  textAlign: 'left',
                  minHeight: 40,
                  height: '100%',
                  borderRadius: 16,
                  backgroundColor: "#FFF"
              }}
              suffixIcon={<FilterOutlined/>}
              showArrow={true}
              onChange={(value) => {handleFilter(value)}}
            >
              {dataLov.map(res => {
                return (<Option value={res.key}>{res.value}</Option>)
              })}
            </Select>
          </Col>
          <Col span={24} lg={18} md={16} style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button className="app-btn" style={{backgroundColor: '#FFE600', color: '#072A6F'}} 
              onClick={()=>setShow({show: true, record: null})}
            ><PlusOutlined/> Tambah</Button>
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
              />
            </Card>
          </Col>
        </Row>
        <FormStatus
          isShow={isShow.show}
          isEdit={isShow.record}
          onCancel={()=>setShow({show: false, record: null})}
          onRefresh={onRefresh}
          dataLov={dataLov}
          dataLovCreate={dataLovAddable}
          />
      </div>
    );
};

export default withRouter(AdminStatus);