import { Button, Col, Input, Modal, Row, Space, Form, Radio, Spin, Dropdown, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { confirmDialog, dialog } from '../../../functions/alert';
import { SheetJSFT } from '../../Admin/component/types';
import XLSX from 'xlsx';
import { make_cols } from '../../Admin/component/MakeColumns';
import { ArrowDownOutlined } from '@ant-design/icons';
import { LoyolaServices } from '../../../service';
import Downloader from "js-file-downloader";

FormPertanyaanUpload.propTypes = {
  onCancel: PropTypes.func,
  isPG: PropTypes.bool,
  lovMatpel: PropTypes.array
};

FormPertanyaanUpload.defaultProps  = {
  onCancel: () => {
    document.getElementById("file").value = ""
  },
  isPG: false,
  lovMatpel: []
}

const { Option } = Select

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const normalListBerkas = [
  {key: 'berkasPrestasi', label: 'Berkas Prestasi'},
  {key: 'berkasTambahan', label: 'Berkas Tambahan'},
  {key: 'kartuKeluarga', label: 'Kartu Keluarga'},
];

const raportListBerkas = [
  ...normalListBerkas,
  {key: 'fileRapor7Smster1', label: 'Rapor kelas 7 Semester 1'},
  {key: 'fileRapor7Smster2', label: 'Rapor kelas 7 Semester 2'},
  {key: 'fileRapor8Smster1', label: 'Rapor kelas 8 Semester 1'},
  {key: 'fileRapor8Smster2', label: 'Rapor kelas 8 Semester 2'}
]

export default function FormPertanyaanUpload(props) {
  const  {data, onCancel, isShow, lovMatpel, matpel, isPG} = props;
  const [form] = Form.useForm(); 
  const [isLoading, setIsLoading] = useState(false);
  const [matpelActive, setMatpelActived] = useState(matpel);
  const [loadedData, setLoadedData] = useState({});
  const [file, setFile] = useState({});

  useEffect(()=>{
    console.log('datanya adalah')
    form.setFieldsValue({
      matpel: matpel ? matpel.key : 0
    })
  }, [matpel]);

  const handleDownload = async () => {
    let param = 'esai'
    let key = matpel && matpel.key
    if(isPG){
      param= 'pg'
    }
    await LoyolaServices.getDownloadTamplateUploadSoal(param,key).then(res => {
      new Downloader({url: res.data.data}).then(file => {
      }).catch(err => {console.log(err)})
    }).catch(err => { 
      dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    })
  }

  const postUploadJadwal = (data) => {
    if(data.data && data.data.length > 0){
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        userId: userId,
        data:data.data ? data.data : [],
        jenis: isPG ? 'pg' : 'esai',
        matpelId: form.getFieldValue('matpel')
      }
      LoyolaServices.postUploadSoal(body).then(res => {
        if(res.data){
          dialog({icon: 'success', text:res.data.message}).then(()=>{
          })
        }
        setIsLoading(false)
        resetData()
      }).catch(err => {
        if(err){
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setIsLoading(false)
        }
      })
    }else{
      dialog({icon: 'error', text: 'Data tidak boleh kosong'})
      setIsLoading(false)
    }
  }
  
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0]);
  }

  const generateNameTamplate = (key) => {
      let nama = ''
      lovMatpel.map(res => {
        if(res.key == key){
          if(res.value){
            if(props.isPG){
              nama = 'template_soal-pg-'+res.value.toLowerCase()
            }else{
              nama = 'template_soal-esai-'+res.value.toLowerCase()
            }
          }
        }
      })
      return nama
  }

  const handleSubmit = () => {
    let fullPath = document.getElementById("file").value
    let isUpload = false
    let nameUpload = generateNameTamplate(matpel.key)
    if(fullPath){
      let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      let filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
        let indexNaming = (filename.indexOf('.') >= 0 && filename.indexOf('.'))
        let namingFile = filename.substring(0,indexNaming)
        lovMatpel.map(res => {
          if(res.key == matpel.key && namingFile.toLowerCase().indexOf(nameUpload) >= 0 ){
            isUpload = true
          }
        })
      }
      console.log(isUpload)
      if (isUpload) {
        setIsLoading(true)
    
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
    
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {raw: false});
          const temp = data.map(res => {
            if(isPG){
              res['PilihanA'] = res['Pilihan A']
              res['PilihanB'] = res['Pilihan B']
              res['PilihanC'] = res['Pilihan C']
              res['PilihanD'] = res['Pilihan D']
              res['PilihanE'] = res['Pilihan E']
              res['Jawaban'] = res['Jawaban (A/B/C/D/E)'] ? res['Jawaban (A/B/C/D/E)'] : res['Jawaban']
              delete res['Pilihan A']
              delete res['Pilihan B']
              delete res['Pilihan C']
              delete res['Pilihan D']
              delete res['Pilihan E']
              delete res['Jawaban (A/B/C/D/E)']
            }
            return res
          })
          /* Update state */
          postUploadJadwal({ data: temp, cols: make_cols(ws['!ref']), isLoadingSubmit: false })
    
        };
        if (rABS) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
      }else{
        dialog({icon: 'error', text: `Nama File yang di-upload tidak sesuai untuk Jadwal`})
      }
    }else{
      dialog({icon: 'error', text: 'File tidak boleh kosong'})
    }
  }

  const resetData = () => {
    document.getElementById("file").value = ""
    onCancel()
  }

  const isFormDisabled = loadedData.isVerified ? loadedData.isVerified : false;
  const isBeasiswaRaport = !!loadedData.beasiswaRapor;
  const listBerkas = isBeasiswaRaport ? raportListBerkas : normalListBerkas;
  
  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={isShow}
      onOk={_ => {}}
      onCancel={resetData}
      width="500px"
      footer={[
        <Button key="back" onClick={resetData}>
          Batal
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
        <Row gutter={[10,10]} align='middle' justify='center'>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Form Upload Soal {isPG ? 'Pilihan Ganda' : 'Esai'}</Title>
          </Col>
          <Col span={24} style={{textAlign: 'center'}}>
            <Button type="link" onClick={handleDownload}>
                <ArrowDownOutlined />Download Template
              </Button>
          </Col>
          <Col span={24} style={{textAlign: 'center'}}>
            <Row gutter={[10,10]}>
              <Col>
                Mata pelajaran
              </Col>
              <Col flex='auto'>
                <Form
                  form={form}
                >
                  <Form.Item name="matpel">
                    <Select style={{width: '100%'}} disabled>
                      {
                        matpel && lovMatpel && lovMatpel.map(res => {
                          return <Option value={res.key}>
                            {res.value}
                          </Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{textAlign: 'center'}}>
            <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={handleChange} />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
