export const dataLovJenisJadwal = [
  {
      "value": "Bip Test Jalur Prestasi",
      "key": 2
  },
  {
      "value": "Bip Test Jalur Tes Online",
      "key": 5
  },
  {
      "value": "Jadwal Daftar Ulang",
      "key": 6
  },
  {
      "value": "Interview Jalur Tes Online",
      "key": 4
  },
  {
      "value": "Interview Jalur Prestasi",
      "key": 1
  },
  {
      "value": "Tes Online",
      "key": 3
  }
];

export const data = [
  {
      'agama': "katolik",
      'priaPrestasi': 63,
      'wanitaPrestasi': 72,
      'priaTes': 52,
      'wanitaTes': 25,
  },
  {
      'agama': "protestan",
      'priaPrestasi': 22,
      'wanitaPrestasi': 18,
      'priaTes': 13,
      'wanitaTes': 9,
  },
  {
      'agama': "Islam",
      'priaPrestasi': 1,
      'wanitaPrestasi': 3,
      'priaTes': 2,
      'wanitaTes': 0,
  },
  {
      'agama': "Buddha",
      'priaPrestasi': 3,
      'wanitaPrestasi': 1,
      'priaTes': 2,
      'wanitaTes': 2,
  }
];