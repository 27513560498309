import verifikator from "./verifikator-get";
import verifikatorLovGet from "./verifikator-lov-get";
import dashboardGet from "./dashboard-get";
import verifikatorBiodataGet from "./verifikator-biodata-get";
import verifikatorBiodataOrtu from "./verifikator-biodata-ortu";
import verifikatorLovBool from "./verifikator-lov-bool";
import listSiswaGet from "./list-siswa";
import {dataKalkulasiSlot, dataDataRiil} from "./kalkulator-get";
import { dataLovJenisJadwal } from "./admin-penjadwalan";
import { dataListPertanyaanEsai, dataLovMatpel, dataListPertanyaanPG, dataListReviewSoal } from "./admin-pertanyaan";
import historyPengubahan from "./finance-history-pengubahan";
import dataLovDurasi from "./data-lov-durasi";
import dataLovUpsUsm from "./data-lov-ups-usm";
import getDataRincianBiaya from "./getDataRincianBiaya";
import listDaftarSiswa from "./list-daftar-siswa";
import listDataAdminFinance from './admin-finance'

export default class Dummy {
  static verifikator = verifikator
  static verifikatorLov = verifikatorLovGet
  static verifikatorLovBool = verifikatorLovBool
  static dashboard = dashboardGet
  static verifikatorBiodata = verifikatorBiodataGet
  static verifikatorBiodataOrtu = verifikatorBiodataOrtu
  static listSiswa = listSiswaGet
  static kalkulatorDataRiil = dataDataRiil
  static kalkulatorSlot = dataKalkulasiSlot
  static adminJenisPenjadwalanLov = dataLovJenisJadwal
  static adminDataPertanyaanEsai = dataListPertanyaanEsai
  static adminDataPertanyaanPG = dataListPertanyaanPG
  static adminDataPertanyaanReviewSoal = dataListReviewSoal
  static adminDataLovMatpel = dataLovMatpel
  static historyPengubahan = historyPengubahan
  static dataLovDurasi = dataLovDurasi
  static dataLovUpsUsm = dataLovUpsUsm
  static dataRincianBiaya = getDataRincianBiaya
  static listDaftarSiswa = listDaftarSiswa
  static listDataAdminFinance = listDataAdminFinance
}