import { Button, Col, Input, Modal, Row, Space, Form, Radio, DatePicker, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import MoneyInput from '../../../../component/money-input';
import UploadComponent from '../../../../component/upload-component-image-preview';
import { HistoryOutlined } from '@ant-design/icons';
import { FinanceService } from '../../finance-service';
import SelectComponent from '../../../../component/select-component';
import ModalHistoryPembayaran from '../beasiswa-ofon/modal-history-pembayaran';
import CONFIG from '../../../../service/config';
import moment from 'moment';

ModalInputPembayaran.propTypes = {
  onCancel: PropTypes.func,
};

ModalInputPembayaran.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > moment();
}

export default function ModalInputPembayaran(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm();
  const [lov, setLov] = useState({});
  const [loadedData, setLoadedData] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [isShowHistory, setIsShowHistory] = useState(false)

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    await FinanceService.getInputPembayaranBeasiswaPrestasi(data.komitSiswaId).then(res => {
      const {totalUps, sisaUps} = res.data.data;
      const tglInput = sisaUps <= 0 ? undefined : moment();
      setLoadedData(res.data.data);
      form.setFieldsValue({totalUps, sisaUps, tglInput});
    }).catch(err => {
      dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    })
    await FinanceService.getLovKetPembayaran(data.noRegistrasi).then(res => {
      const {pembayaranBelumLunas, cicilanTerakhir} = res.data;
      setLov({...lov, ketPembayaran: !!pembayaranBelumLunas ? [pembayaranBelumLunas] : [], isLast: cicilanTerakhir})
      if (!!pembayaranBelumLunas) {
        form.setFieldsValue({ketPembayaranId: pembayaranBelumLunas.ketPembayaranId})
      }
    }).catch(err => {})
    setIsLoading(false);
  }

  const handleSubmit = () => {
    form.validateFields().then(val => {
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan data pembayaran?',
        title: 'Input pembayaran'
      }).then(e => {
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const {totalUps, sisaUps, ...value} = val;
        const body = {
          ...value,
          uploadBuktiTrf: value.uploadBuktiTrf.fileName,
          komitSiswaId: data.komitSiswaId,
          userId: userId
        }
        FinanceService.postPembayaranBeasiswaPrestasi(body).then(res => {
          dialog({title: 'Pembayaran telah disimpan'}).then(_ => {
            onFinish();  
          });
        }).catch(err => {
          dialog({icon: 'error', title: 'Gagal menyimpan data', text: err.response ? err.response.data.message : err.message});
        })
      }).catch(e => {})
    }).catch(err => {})
  }

  const toggleHistory = () => {
    setIsShowHistory(!isShowHistory);
  }

  const isFormDisabled = loadedData.sisaUps <= 0;
  const minPayment = !!lov.isLast ? loadedData.sisaUps : 0;

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="600px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
      <Row>
        <Col span={24} style={{...borderedBottom}}>
          <Title level={4} className="app-title">Form Pembayaran</Title>
        </Col>
        <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
          {loadedData.noRegistrasi} - {loadedData.namaSiswa}
        </Col>
        <Col span={24} style={{marginBottom: '1em'}}>
          <Form form={form}>
            <Row>
              <Col span={24}>
                <Text>Total UPS <RedStar /></Text>
                <Form.Item
                  name="totalUps"
                  rules={[{ required: true, message: 'Harap masukan total UPS!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <MoneyInput className="app-input" disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>Saldo utang UPS <RedStar /></Text>
                <Form.Item
                  name="sisaUps"
                  rules={[{ required: true, message: 'Harap masukan saldo utang UPS!' }]}
                  style={{marginBottom: '2em'}}
                >
                  <MoneyInput className="app-input" disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24} style={{...borderedBottom}}>
                <Title level={4} className="app-title">Input Nilai Cicilan / Pelunasan</Title>
              </Col>
              <Col span={24}>
                <Text>Nilai <RedStar /></Text>
                <Form.Item
                  name="nilai"
                  rules={[{ required: true, message: 'Harap masukan Nilai!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <MoneyInput className="app-input" max={loadedData.sisaUps} min={minPayment} disabled={isFormDisabled}/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>Tanggal <RedStar /></Text>
                <Form.Item
                  name="tglInput"
                  rules={[{ required: true, message: 'Harap masukan Tanggal!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <DatePicker className="app-input" style={{width: '100%'}} disabledDate={disabledDate} disabled={isFormDisabled} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>Keterangan <RedStar /></Text>
                <Form.Item
                  name="ketPembayaranId"
                  rules={[{ required: true, message: 'Harap masukan Keterangan!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <SelectComponent items={lov.ketPembayaran} labelKey="ketPembayaran" valueKey="ketPembayaranId" showSearch={false} disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>Bukti Cicilan/Transfer <RedStar /></Text>
                <Form.Item
                  name="uploadBuktiTrf"
                  rules={[{ required: true, message: 'Harap masukan bukti cicilan/transfer!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <UploadComponent title="Upload bukti pembayaran" accept=".jpg,.jpeg,.pdf,.png" className="app-input" url={`${CONFIG.BASE_URL_NOAUTH}/api/upload/buktiCicilan?noRegistrasi=${data.komitSiswaId}`} disabled={isFormDisabled} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Space className="space-between">
            <Button className="app-btn" onClick={toggleHistory}><HistoryOutlined /> Lihat History Pembayaran</Button>
            <Space className="end" style={{width: '100%'}}>
              {!isFormDisabled && <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>}
              <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
            </Space> 
          </Space>
        </Col>
      </Row>
      </Spin>
      {isShowHistory && <ModalHistoryPembayaran data={{...loadedData, ...data}} onCancel={toggleHistory} />}
    </Modal>
  )
}
