export default {
  "data": [
      {
          "createdBy": 1,
          "createdOn": "2020-09-25T08:37:47.000+0000",
          "lastModifiedBy": null,
          "lastModifiedOn": null,
          "potBiayaId": 2,
          "jenisPotongan": "Beasiswa",
          "persenUps": 15.0,
          "nominalUps": 25500000.00,
          "persenUsm": 10.0,
          "nominalUsm": null
      },
      {
          "createdBy": 1,
          "createdOn": "2020-09-25T08:36:49.000+0000",
          "lastModifiedBy": null,
          "lastModifiedOn": null,
          "potBiayaId": 1,
          "jenisPotongan": "Pandemi",
          "persenUps": 10.0,
          "nominalUps": 3000000.00,
          "persenUsm": 5.0,
          "nominalUsm": 1900000.00
      }
  ],
  "status": "OK"
}