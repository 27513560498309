import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {
    InputNumber,
    Col,
    Layout,
    Row,
    Typography,
    Button,
    Space,
    Form,
    Card,
    Input,
    List,
    Spin,
    Modal
} from "antd";
import { LeftOutlined, TranslationOutlined } from "@ant-design/icons";
import { AppLoading } from '../../component/loading'
import {useForm} from "antd/lib/form/Form";
import axios from 'axios';
import  CONFIG_INITIAL_STATE  from '../../service/config';
import DropdownComponent from "../../component/dropdown-component";
import { LoyolaServices } from "../../service";
import { confirmDialog, dialog } from "../../functions/alert";
import XLSX from 'xlsx';
import { make_cols } from '../Admin/component/MakeColumns';
import { SheetJSFTExcel } from '../Admin/component/types';
import Downloader from 'js-file-downloader'
import Auth from "../../service/auth";

const { Content } = Layout;
const { Title, Text } = Typography;

const InputHasilInterview = (props) => {
    const [screenLoading, setScreenLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [form] = useForm();
    const [listCard, setListCard] = useState([]);
    const [lovStatus, setLovStatus] = useState([]);
    const [isShow, setShow] = useState(false)
    const [isLoadingSubmit, setLoadingSubmit] = useState(false)
    const [file, setFile] = useState({})
    const [isUploadDone, setUploadDone] = useState(props.match.params.flagDataKesungguhanExist == 1 ? true : false)
    const [isFilesWrong, setFilesWrong] = useState(true)
    
    const [downloadUrl, setDownload] = useState('')
    
    const config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    };

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Interviewer').then(res => {
			console.log('ada user ujian',res)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])

    useEffect(() => {
        document.title = "Interviewer | Loyola"
    });

    useEffect(()=>{
        getLovWawancara(config).then(res => {
            if(res.data){
                setListCard(res.data.data);
                setScreenLoading(false)
            }
        })
        getLovStatusRekomendasiInterview(config).then(res => {
            if(res.data){
                let dataTemp = []
                res.data.data.map(res => {
                    if(res.key != 27){
                        dataTemp.push(res)
                    }
                })
                setLovStatus(dataTemp);
                setLoading(false)
            }
        })
        let pewawancara1 = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).namaAdmin
        form.setFieldsValue({pewawancara1: pewawancara1})
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        props.history.goBack();
    };

    const handleDownloadKejujuran = () => {
        setLoadingDownload(true)
        LoyolaServices.downloadKejujuranKesungguhan(props.match.params.id).then(res => {
            new Downloader({url: res.data.Link}).then(file => {
            }).catch(err => {console.log(err)})
            setLoadingDownload(false)
        }).catch(err => {
            if(err){
              dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
              setLoadingDownload(false)
            }
        })
    }

    const handleSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then((values)=>{
                let text= isUploadDone ? 'Apakah Anda yakin akan menyimpan data ini ?' : 'Apakah Anda yakin akan menyimpan data tanpa upload Form Kesungguhan dan Kejujuran?'
                confirmDialog({text: text}).then(()=>saveInputHasil(values)).catch(()=>setLoading(false))
        }).catch(err => {
            setLoading(false)
        });
    };

    const saveInputHasil = (values) => {
        let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    
        let totalSkor = 0;
        let listJawaban = [];
        listCard.map(res => {
            let wawancaraId = res.key

            let strJawaban = `jawaban${res.key}`
            let jawaban = values[strJawaban]

            let strSkor = `skor${res.key}`;
            let skor = values[strSkor]

            totalSkor = totalSkor + values[strSkor];
            listJawaban.push({jawaban, skor, wawancaraId});
        })

        let body = {
            "noRegistrasi" : props.match.params.id,
            "listJawaban" : listJawaban,
            "rekomendasi" : values.rekomendasi,
            "pewawancara1" : values.pewawancara1,
            "pewawancara2" : values.pewawancara2,
            "skorWawancara": totalSkor/listCard.length
        }
        console.log('body parameter adlah : ',JSON.stringify(body))
        LoyolaServices.postDataInterviewer(userId,body).then(res => {
            if(res){
                dialog({icon:'success', text:'Data Nilai Wawancara berhasil disimpan'})
                setLoading(false)
                handleBack();
            }
        }).catch(err => {
            if(err){
                dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
                setLoading(false)
            }
        })
    }

    const getLovWawancara = (configParam) => {
        return new Promise((resolve,reject ) => {
            axios.get(CONFIG_INITIAL_STATE.BASE_URL + `/api/lov/wawancara`, configParam)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const getLovStatusRekomendasiInterview = (configParam) => {
        return new Promise((resolve,reject ) => {
            axios.get(CONFIG_INITIAL_STATE.BASE_URL + `/api/lov/status?kategoriId=4`, configParam)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const handleCloseModal = () => {
        setLoadingSubmit(false)
        setFilesWrong(true)
        setShow(false)
        document.getElementById("file").value = ""
    }

    const handleChange = (e) => {
        const typeList = SheetJSFTExcel.split(',')
        const files = e.target.files;
        const filesName = files[0].name;
        var hasValueLessThanTen = false;
        for (var i = 0; i < typeList.length; i++) {
            if (filesName.search(typeList[i]) >= 0) {
                hasValueLessThanTen = true;
                break;
            }
        }
        if (files && files[0] && hasValueLessThanTen) {
            setFile(files[0]);
            setFilesWrong(false)
        }else{
            dialog({icon: 'error', text: 'Format file yang di-upload salah'})
            setFilesWrong(true)
        }
    }

    const handleFile = () => {
        if(document.getElementById("file").value != ""){
            setLoadingSubmit(true)
        
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
         
            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws, {raw: false});
              const temp = data.map(res => {
                res['Keluarga Kuliah Di Luar Negeri'] = res['Keluarga Kuliah Di Luar Negeri (Ya, Tidak)']
                res['Memiliki Rumah Lain Orang Tua'] = res['Memiliki Rumah Lain Orang Tua (Ya, Tidak)']
                res['Tinggal Serumah dengan Orang Tua'] = res['Tinggal Serumah dengan Orang Tua (Kandung, tiri, angkat)']
                res['Pergi ke tempat ramai'] = res['Pergi ke tempat ramai (mall, plaza, supermarket) (ya/tidak)']
                res['Bermain game di smartphone'] = res['Bermain game di smartphone (ya/tidak)']
                res['Punya HP Pribadi'] = res['Punya HP Pribadi (ya/tidak)']
                res['Memiliki Villa'] = res['Memiliki Villa (Ya, Tidak)']
    
                delete res['Keluarga Kuliah Di Luar Negeri (Ya, Tidak)']
                delete res['Memiliki Rumah Lain Orang Tua (Ya, Tidak)']
                delete res['Tinggal Serumah dengan Orang Tua (Kandung, tiri, angkat)']
                delete res['Pergi ke tempat ramai (mall, plaza, supermarket) (ya/tidak)']
                delete res['Bermain game di smartphone (ya/tidak)']
                delete res['Punya HP Pribadi (ya/tidak)']
                delete res['Memiliki Villa (Ya, Tidak)']
                return res
              })
              /* Update state */
              postUploadKejujuran({ data: temp, cols: make_cols(ws['!ref'])})
            };
         
            if (rABS) {
              reader.readAsBinaryString(file);
            } else {
              reader.readAsArrayBuffer(file);
            }
        } else {
            dialog({icon: 'error', text: 'File tidak boleh kosong'})
        }
    }

    const postUploadKejujuran = (data) => {
        if(data.data){
          let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
          LoyolaServices.uploadKejujuranKesungguhan(userId ,data.data, props.match.params.id).then(res => {
            if(res.data.Status === 'OK'){
              dialog({icon:'success', text: res.data.Message ? res.data.Message : 'Upload Berhasil'}).then(() => {
                document.getElementById("file").value = ""
                setLoadingSubmit(false)
                setUploadDone(true)
                setShow(false)
              })
            }else {
              dialog({icon:'error', text: res.data.message}).then(() => {
                document.getElementById("file").value = ""
                setLoadingSubmit(false)
                setUploadDone(true)
                setShow(false)
              })
            }
          }).catch(err => {
            if(err){
              dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(() => {
                document.getElementById("file").value = ""
                if(err.response &&  err.response.data.message == 'Form Kesungguhan dan Kejujuran sudah di-upload'){
                    setUploadDone(true)
                }
                setLoadingSubmit(false)
                setShow(false)
              })
            }
          })
        }
    }

    return(
        screenLoading ?
        <Content className="contentContainer">
            <AppLoading/>
        </Content>
        :
        <Content className="contentContainer">
        <Spin spinning={loading}>
            <Row>
                <Col span={24} md={16}>
                    <Row align='middle' justify='center'>
                        <Col span={1} lg={2} style={{alignSelf: 'center'}}>
                            <LeftOutlined 
                                onClick={props.history.goBack} 
                                style={{fontSize: props.responsive && props.responsive.isMobile? 15 : 30}}
                            />
                        </Col>
                        <Col span={22}>
                            {
                                props.responsive && props.responsive.isMobile ?
                                <Title level={3} className="text-heading" style={{color: '#072a6f', marginLeft: 10}}>
                                    {props.match.params.name}
                                </Title> :
                                <Title level={1} className="text-heading" style={{color: '#072a6f'}}>
                                    {props.match.params.name}
                                </Title>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={24} md={8} style={{textAlign: 'right'}}>
                    <Space>
                        <Button
                            size="large"
                            className="cancel-button"
                            onClick={handleBack}
                        >
                            Batal
                        </Button>
                        <Button
                            size="large"
                            className='save-button'
                            onClick={handleSubmit}
                        >
                            Simpan
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form form={form}
                        initialValues={{
                            rekomendasi: 13
                        }}
                    >
                        {
                            listCard &&
                            <List
                                style={{marginTop:20}}
                                grid={{ gutter: 16, column: 2, xs: 1}}
                                dataSource={listCard}
                                renderItem={item => (
                                    <List.Item>
                                        <Col span={24}>
                                            <Title level={3} className="text-heading" style={{color: '#072a6f'}}>
                                                {item.value}
                                            </Title>
                                            <Card
                                                bordered={false}
                                                className="app-card"
                                            >
                                                <Text className={"text-title-admin"}>Jawaban</Text>
                                                <Form.Item
                                                    name={"jawaban"+item.key}
                                                    rules={[
                                                        { required: true, message: 'Jawaban harus diisi'},
                                                        { whitespace: true, message: 'Jawaban tidak boleh kosong'}
                                                    ]}
                                                    style={{marginBottom:10}}
                                                >
                                                    <Input.TextArea
                                                        style={{width: '100%', height: 80, backgroundColor: "#FFF", borderRadius: 16}}
                                                    />
                                                </Form.Item>
                                            </Card>
                                            <Row align='middle'>
                                                <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:20, marginTop:10}}>
                                                    Skor
                                                </Title>
                                                <Form.Item name={"skor"+item.key} style={{marginTop:20}}
                                                    rules={[
                                                        { required: true, message: 'Skor harus diisi'}
                                                    ]}>
                                                    <InputNumber
                                                        style={{borderColor: '#072a6f', borderRadius: 16}}
                                                        min={0}
                                                        max={100}
                                                    />
                                                </Form.Item>
                                            </Row>
                                        </Col>
                                    </List.Item>
                                )}
                            />
                        }
                        <Row>
                            <Col span={24} md={12}>
                                <Row align='middle'>
                                    <Col style={{minWidth: 215}}>
                                        <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:50, marginTop:10, paddingLeft: 8}}>
                                            Pewawancara 1
                                        </Title>
                                    </Col>
                                    <Col>
                                        <Form.Item noStyle name="pewawancara1"
                                            rules={[
                                                { required: true, message: 'Pewawancara harus diisi'}
                                            ]}
                                        >
                                            <Input
                                                style={{borderColor: '#072a6f', borderRadius: 16, width:'100%'}}
                                                placeholder={'Nama Pewawancara'}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col> 
                                </Row>
                                <Row align='middle'>
                                    <Col style={{minWidth: 215}}>
                                        <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:50, marginTop:10, paddingLeft: 8}}>
                                            Pewawancara 2
                                        </Title>
                                    </Col>
                                    <Col>
                                        <Form.Item noStyle name="pewawancara2"
                                        >
                                            <Input
                                                style={{borderColor: '#072a6f', borderRadius: 16}}
                                                placeholder={'Nama Pewawancara'}
                                            />
                                        </Form.Item>
                                    </Col>  
                                </Row>
                                <Row align="middle">
                                    <Col style={{minWidth: 215}}>
                                        <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:50, marginTop:10, paddingLeft: 8}}>
                                            Rekomendasi
                                        </Title>
                                    </Col>
                                    <Col>
                                        <Form.Item noStyle name="rekomendasi">
                                            <DropdownComponent
                                                defaultValue={13}
                                                data={lovStatus}
                                                style={{width:150}}    
                                            />
                                        </Form.Item>
                                    </Col>  
                                </Row>
                            </Col>
                            <Col span={24} md={12}>
                                <Row style={{marginLeft: props.responsive && props.responsive.isMobile ? 10 : 16}}>
                                    <Col span={24}>
                                        <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:20, marginTop:10}}>
                                            Form Kesungguhan dan Kejujuran
                                        </Title>
                                    </Col>
                                    <Col span={24}> 
                                        <Row>
                                            <Col style={{minWidth: 160}}>
                                                <div style={{ minWidth: '150px', marginTop: '10px'}}>
                                                    <Button color='#fff' className="download-btn" onClick={handleDownloadKejujuran} style={!isUploadDone ? {backgroundColor: '#072A6F', color: '#fff', borderColor:'#072A6F'} : null} loading={loadingDownload} disabled={isUploadDone}>
                                                        Download Form
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col flex='auto' >
                                                <div style={{ minWidth: '150px', marginTop: '10px'}}>
                                                    <Button className="upload-btn" onClick={() => setShow(true)} disabled={isUploadDone}>
                                                        Upload Form
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            </Spin>
            <Modal
                visible={isShow}
                onCancel={() => handleCloseModal()}
                title='Upload Form Kesungguhan dan Kejujuran'
                footer={[
                <Button key="back" onClick={() => handleCloseModal()}>
                    Tutup
                </Button>,
                <Button key="submit" type="primary" loading={isLoadingSubmit} onClick={() => handleFile()} disabled={isFilesWrong}>
                    Upload
                </Button>,
                ]}
                closeIcon={null}
            >              
                <input type="file" className="form-control" id="file" accept={SheetJSFTExcel} onChange={event => handleChange(event)} />
           </Modal>
        </Content>
    );
};

export default withRouter(InputHasilInterview);