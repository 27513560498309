import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

export function AppLoading ({text, showDots, style, ...props}) {
  return (
    <div style={{textAlign: 'center', padding: '1em', display: 'flex', flexDirection: 'column', width: '100%', ...style}} {...props}>
      <LoadingOutlined style={{fontSize: '2.4em', marginBottom: '.5em'}} />
      <Text style={{fontSize: '1.2em'}}>{text || "Loading"} </Text>
    </div>
  )
}