import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input, Form, Checkbox, DatePicker, TimePicker, Select, Space, Spin} from "antd";
import { confirmDialog, dialog } from "../../../functions/alert";
import TextArea from "antd/lib/input/TextArea";
import { FinanceService } from "../../Finance/finance-service";
import { LoyolaServices } from "../../../service";
import moment from 'moment';

const { Content } = Layout;
const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const JadwalNegosiasi = (props) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [form] = Form.useForm()

  useEffect(()=>{
    // getDataDummy()
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    await LoyolaServices.getJadwalNegosiasi().then(res => {
      let data = res.data.data;
      data = {
        ...data,
        // waktuNotifikasi: moment(data.waktuNotifikasi, 'hh:mm:ss'),
        // tglNotifikasi: moment(data.tglNotifikasi, 'YYYY-MM-DD'),
        waktuPelaksanaan: data.waktuPelaksanaan ? moment(data.waktuPelaksanaan, 'hh:mm:ss') : '',
        tglPelaksanaan: data.tglPelaksanaan ? moment(data.tglPelaksanaan, 'YYYY-MM-DD') : '',
      }
      form.setFieldsValue(data);
      setValue(data);
    }).catch(err => {});
    setLoading(false);
  }

  const handleSubmit = () => {
    form.validateFields().then(e => {
      confirmDialog({text: 'Apakah Anda yakin ingin menyimpan jadwal negosiasi?', title: 'Simpan jadwal negosiasi'}).then(async () => {
        let body = form.getFieldsValue();
        const user = JSON.parse(sessionStorage.getItem('user'));
        console.log(value)
        body = {
          // linkPelaksanaan: null,
          // passcode: null,
          // lokasi: null,
          ...body,
          // waktuNotifikasi: moment(body.waktuNotifikasi).format('hh:mm:ss'),
          // tglNotifikasi: moment(body.tglNotifikasi).format('YYYY-MM-DD'),
          waktuPelaksanaan: moment(body.waktuPelaksanaan).format('hh:mm:ss'),
          tglPelaksanaan: moment(body.tglPelaksanaan).format('YYYY-MM-DD'),
          createdBy: user.userId
        }
        console.log(body)
        await LoyolaServices.postJadwalNegosiasi(value.jadwalId ? value.jadwalId : '', body).then(res => {
          console.log(res);
          dialog({icon: 'success', title: 'Jadwal Negosiasi berhasil disimpan'}).then(_ => {getData()})
        }).catch(err => {
          let error = err.response
          dialog({icon: 'error', title: error.data.message})
        })
      }).catch(_ => {})
    }).catch(err => {

    })
  }

  const handleValueChange = () => {
    setValue({
      ...value,
      ...form.getFieldsValue()
    });
  }

    return(
      <div style={{marginTop:20}}>
      
        <Spin
          spinning={loading}
          centered
          size="large"
        >
          <Row>
            <Col span={24}>
              <Card bordered={false} className="app-card">
              <Row style={{borderBottom: '1px solid #d9d9d9', marginBottom: '2em'}} align='middle'>
                <Col xs={24} md={16} lg={16}><Title level={4}>Jadwal Negosiasi</Title></Col>
              </Row>
                <Form
                  form={form}
                  labelCol={{span : props.responsive.isMobile ? 24 : 4}}
                  wrapperCol={{ span: props.responsive.isMobile ? 24 :16 }}
                  name="jadwal-nego"
                  onValuesChange={handleValueChange}
                >
                  <Form.Item
                    label="Media Pelaksanaan"
                    name="media"
                    rules={[{ required: true, message: 'Waktu pelaksanaan harus diisi' }]}
                  > 
                    <Select placeholder="Online/Offline">
                      <Select.Option value="Online">Online</Select.Option>
                      <Select.Option value="Offline">Offline</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Tanggal Pelaksanaan"
                    name="tglPelaksanaan"
                    rules={[{ required: true, message: 'Tanggal pelaksanaan harus diisi' }]}
                  >
                    <DatePicker style={{width: '100%'}} placeholder="Masukan Tanggal Pelaksanaan" />
                  </Form.Item>
                  <Form.Item
                    label="Waktu Pelaksanaan"
                    name="waktuPelaksanaan"
                    rules={[{ required: true, message: 'Waktu pelaksanaan harus diisi' }]}
                  >
                    <TimePicker style={{width: '100%'}} placeholder="Masukan Waktu Pelaksanaan" />
                  </Form.Item>
                  
                  {/* <Form.Item
                    label="Tanggal Notifikasi"
                    name="tglNotifikasi"
                    rules={[{ required: true, message: 'Tanggal notifikasi harus diisi' }]}
                  >
                    <DatePicker style={{width: '100%'}} placeholder="Masukan Tanggal Notifikasi" />
                  </Form.Item>
                  <Form.Item
                    label="Waktu Notifikasi"
                    name="waktuNotifikasi"
                    rules={[{ required: true, message: 'Waktu notifikasi harus diisi' }]}
                  >
                    <TimePicker style={{width: '100%'}} placeholder="Masukan Waktu Notifikasi" />
                  </Form.Item> */}
                  {value.media === 'Offline' && (
                    <Form.Item
                      label="Lokasi Pelaksanaan"
                      name="lokasi"
                      rules={[{ required: true, message: 'Lokasi pelaksanaan harus diisi' }]}
                    >
                      <TextArea style={{width: '100%'}} placeholder="Alamat lengkap lokasi pelaksanaan" maxLength={255} />
                    </Form.Item>
                  )}
                  {value.media === 'Online' && (
                    <>
                      <Form.Item
                        label="Link Pelaksanaan"
                        name="link_pelaksanaan"
                        rules={[
                          { 
                            required: true, 
                            message: 'Link pelaksanaan harus diisi'
                          },
                          {
                            type: 'url',
                            message: 'Url link pelaksanaan tidak valid'
                          }
                        ]}
                      >
                        <Input style={{width: '100%'}} placeholder="https://..." maxLength={255} />
                      </Form.Item>
                      <Form.Item
                        label="Passcode"
                        name="passcode"
                        rules={[{ required: true, message: 'Passcode harus diisi' }]}
                      >
                        <Input style={{width: '100%'}} placeholder="Passcode link negosiasi" maxLength={50} />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item 
                    wrapperCol={
                      {
                        offset: props.responsive.isMobile ? 0 : 4, 
                        span: 16
                      }
                    }
                  >
                    {/* <pre style={{padding: '1em', background: '#eeeeee', marginBottom: '24px'}}>{JSON.stringify(value, '', 2)}</pre> */}
                    <Space style={{width: '100%', justifyContent: 'end'}} >
                      <Button className="app-btn primary" style={{minWidth: '150px'}} onClick={handleSubmit}>
                        Simpan
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    );
};

export default withRouter(JadwalNegosiasi);