export default {
  "totalCadanganJalurPrestasi": 1,
  "totalLolosJalurTesOnline": 0,
  "totalPendaftarJalurTesOnline": 3,
  "totalPendaftarTionghoa": 0,
  "totalPendaftarRasLain": 22,
  "totalLolosJalurPrestasi": 2,
  "totalPendaftarJawa": 1,
  "totalCadanganJalurTesOnline": 1,
  "totalPendaftarSekolahSwasta": 14,
  "totalPendaftarLakiLaki": 17,
  "listDataSekolah": [
      {
          "namaSekolah": "SMPN2Bandung",
          "jumlahPendaftar": 1
      },
      {
          "namaSekolah": "SMP N 10 Semarang",
          "jumlahPendaftar": 1
      },
      {
          "namaSekolah": "SMIT",
          "jumlahPendaftar": 13
      },
      {
          "namaSekolah": "SMP N 1 Semarang",
          "jumlahPendaftar": 1
      },
      {
          "namaSekolah": "SMP N 2 Banten",
          "jumlahPendaftar": 7
      }
  ],
  "totalPendaftar": 23,
  "totalPendaftarKatolik": 9,
  "totalPendaftarSekolahNegeri": 9,
  "totalPendaftarAgamaLain": 2,
  "totalPendaftarPerempuan": 6,
  "status": "OK",
  "totalPendaftarJalurPrestasi": 17,
  "totalPendaftarKristen": 12
}

//adrian 24-08-20