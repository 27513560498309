import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";

const { Title } = Typography
const { Option } = Select;
const FormStatus = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
      console.log('record : ',props.isEdit)
      if(props.isEdit){
        form.setFieldsValue(props.isEdit);
      }else{
        form.resetFields()
      }

       // eslint-disable-next-line
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        status: values.status.trim(),
        deskripsiStatus: values.deskripsiStatus,
        pengumuman: values.pengumuman,
        katStatusId: values.katStatusId
      }
      console.log('body form status : ',body)
      if(props.isEdit){
        body.id = props.isEdit.statusId
        console.log('body : ',body)
        LoyolaServices.putStatusAdmin(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh();
              setLoading(false);
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }else{
        LoyolaServices.postStatusAdmin(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh();
              setLoading(false);
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }
      console.log('Success:', body,userId);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Status</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="status"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item 
                  name="katStatusId"
                  label="Kategori Status"
                  rules={[
                    { required: true, message: 'Kategori Status harus diisi', },
                  ]}>
                  <Select disabled={props.isEdit} placeholder="Pilih Kategori Status" allowClear >
                    {
                      props.isEdit ?
                      props.dataLov.map(res => {
                      return (<Option value={res.key}>{res.value}</Option>)
                      }) :
                      props.dataLovCreate.map(res => {
                      return (<Option value={res.key}>{res.value}</Option>)
                      }) 
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="status" label="Status"
                  rules={[
                    {
                      required: true,
                      message: 'Status harus diisi',
                    },
                    { whitespace: true, message: 'Status harus diisi'}
                  ]}
                >
                  <Input maxLength={100} style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item name="deskripsiStatus" label="Deskripsi"
                  rules={[
                    {
                      required: true,
                      message: 'Deskripsi harus diisi',
                    },
                    { whitespace: props.isEdit && props.isEdit.isAnnouncement, message: 'Deskripsi harus diisi'}
                  ]}
                >
                  <Input maxLength={255} style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item name="pengumuman" label="Isi Pengumuman">
                  <Input.TextArea
                      style={{height: 170, backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormStatus);