import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { dialog } from "../../../functions/alert";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;

const deleteDialog = {
  icon: 'error', 
  text: 'Yakin ingin menghapus data?', 
  buttons: {
    confirm: {
      text: "Delete",
      value: true,
      visible: true,
      className: 'app-btn primary'
    },
    cancel: {
      text: "Batal",
      value: false,
      visible: true,
      className: 'app-btn danger'
    }
  }
}

const InputNilai = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('no_registrasi,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [dataLovMatpel, setDataLovMatpel] = useState([])

  useEffect(()=>{
    getDataLovMatpel();
    getData(pagination,sort,search)
  }, []);

  const getDataDummy = () => {
    setDataList(Dummy.adminDataPertanyaanReviewSoal.data)
    setLoading(false)
  }

  const getData = (page,sort,search) => {
    setLoading(true)
    LoyolaServices.getInputNilaiUjian(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        var data = res.data.data
        console.log(data)
        setDataList(data)
        setPagination({
          ...pagination,
          current: page.current,
          total: res.data.totalData
        });
        setLoading(false);
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message});
        setLoading(false);
      }
    })
  }

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearch(key);
  };
  

  const getDataLovMatpel = () => {
    LoyolaServices.getDataLovMatpel().then(res => {
      if(res.data){
        setDataLovMatpel(res.data.data)
      }
    }).catch(err => {
      if(err){
        dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  const processSearch = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
      sorter.order = "asc";
    } else if (sorter.order === "descend") {
      sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'no_registrasi,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const columns = [
    {
      title: 'No. Registrasi',
      dataIndex: 'noRegistrasi',
      key: 'no_registrasi',
      sorter: true,
    },
    {
      title: 'Nama Siswa',
      dataIndex: 'namaSiswa',
      key: 'cs.nama_siswa',
      sorter: true,
    },
    {
      title: 'Skor Rata-Rata',
      dataIndex: 'skorTes',
      key: 'skor_tes_siswa',
      sorter: true,
    },
    {
      title: 'Aksi',
      width: '15%',
      align: 'center',
      render: (record) => {
        if(record.isInput){
          return (
            <Button className="app-btn primary" style={{width: '100%'}} onClick={() => props.onChange(record)}>
              Input Nilai
            </Button>
          );
        }
      },
    },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}}>
        <Row>
          <Col span={24} lg={6} md={8}>
            <Input
              className="input-search"
              placeholder="Search"
              style={{
                width: '100%',
                marginRight: '10px',
                textAlign: 'left',
                marginBottom: '24px'
              }}
              prefix={<SearchOutlined/>}
              onChange={(event) => {handleSearch(event)}}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{ x: 'calc(800px)'}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default withRouter(InputNilai);