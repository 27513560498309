import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input,Form} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import FormPertanyaanEsai from "../component/form-pertanyaan-esai";
import { dialog, confirmDialog, confirmDeleteDialog } from "../../../functions/alert";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import { useForm } from "antd/lib/form/Form";
import FormPertanyaanUpload from "../component/form-pertanyaan-upload";

const { Content } = Layout;
const { Title, Text } = Typography;

const deleteDialog = {
  icon: 'error', 
  text: 'Yakin ingin menghapus data?', 
  buttons: {
    confirm: {
      text: "Delete",
      value: true,
      visible: true,
      className: 'app-btn primary'
    },
    cancel: {
      text: "Batal",
      value: false,
      visible: true,
      className: 'app-btn danger'
    }
  }
}
const PertanyaanUjianEssai = (props) => {

    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [sort, setSorting] = useState('pertanyaan_esai,desc');
    const [search, setSearch] = useState('');
    const [dataList, setDataList] = useState(null);
    const [isShow, setShow] = useState({show: false, record:null});
    const [records, setRecords] = useState([])
    const [isUploadShow, setUploadShow] = useState(false);
    const [matpel, setMatpel] = useState(null);
    const [form] = Form.useForm()

    useEffect(()=>{
      setMatpel(props.dataLovMatpel[0])
      getData(props.dataLovMatpel[0],pagination,sort,search)
      // getDataDummy()
    }, []);

    const deleteData = () => {
      confirmDeleteDialog({text: 'Apakah anda yakin ingin menghapus soal?'}).then(_ => {
        let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
        LoyolaServices.deletePertanyaanEsai(userId, records).then(res => {
          if(res.data){
            onRefresh()
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          }
        })
          
      })
    }
    
    const deleteAllData = () => {
      confirmDeleteDialog({text: 'Apakah anda yakin ingin menghapus soal?'}).then(_ => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        LoyolaServices.deleteAllPertanyaan(matpel.key, user.userId, 'esai').then(res => {
          if(res.data){
            onRefresh()
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          }
        });
      });
    }

    const getData = (matpel,page,sort,search) => {
      let keyword = search ? GlobalFunction.searchEncode(search) : ''
      setLoading(true)
      LoyolaServices.getDataPertanyaanEsai(matpel.key,page.current - 1,page.pageSize,sort,keyword).then(res => {
        if(res.data){
          var data = res.data.data
          console.log(data)
          data = data.map((e, i) => ({...e, key: e.esaiId}));
          setDataList(data)
          setPagination({
            ...pagination,
            current: res.data.page + 1,
            total: res.data.total
          })
          setLoading(false)
        }
      }).catch(err => {
        if(err){
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          // setDataList([])
          setLoading(false)
        }
      })
    }

    const handleTableChange = (paginationParam, filters, sorter) => {
      if (sorter.order === "ascend") {
          sorter.order = "asc";
      } else if (sorter.order === "descend") {
          sorter.order = "desc";
      }
      let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pertanyaan_esai,desc'}`
      setSorting(sorting)
      setPagination(paginationParam)
      getData(matpel,paginationParam,sorting,search)
    };

    const setSelectedata = (res) => {
      var page = {current: 1, pageSize: 10}
      var sorting = 'pertanyaan_esai,desc'
      setMatpel(res)
      setPagination(page)
      setSorting(sorting)
      setSearch('')
      form.resetFields()
      getData(res,page,sorting,'')
    }

    const onRefresh = () => {
      console.log('data onrefresh : ')
        setShow({show: false, record: null})
        getData(matpel,pagination,sort,search)
    }

    const handleSearch = (e) => {
      let key = e.target.value;
      processSearchCalonSiswa(key);
    };
  
    const processSearchCalonSiswa = _debounce((key) => {
      setSearch(key)
      getData(matpel,{current: 1, pageSize: 10},sort,key)
    }, 500);

    const columns = [
      {
          title: 'Pertanyaan',
          dataIndex: 'pertanyaanEsai',
          key: 'pertanyaan_esai',
          sorter: true,
      },
      {
        width: '120px',
        align: 'center',
        title: 'Aksi',
        render: (record) => {
          return (
            <Button className="app-btn" style={{backgroundColor: '#EF712B', color: '#fff', width: '100%'}} onClick={() => setShow({show: true, record: record})}>
              Ubah
            </Button>
          );
        },
      },
    ];

    const uploadClose = () => {
      setUploadShow(false)
      getData(matpel,pagination,sort,search)
    }

    const rowSelection = {
      type: 'checkbox',
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log("data adalah : ", selectedRowKeys);
        setRecords(selectedRowKeys);
      },
    };

    const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}} >
        <Row gutter={[20,0]}>
          <Col className="text-heading">
            <Title level={3} style={{color: '#BDBDBD',cursor: 'pointer'}} onClick={()=>props.changeMenu(0)}>
              Pilihan Ganda
            </Title>
          </Col>
          <Col className="text-heading">
            <Title level={3} style={{color: '#072a6f',cursor: 'pointer'}} onClick={()=>props.changeMenu(1)}>
              Esai
            </Title>
          </Col>
        </Row>
        <Row gutter={[10,10]}>
          <Col span={24} lg={6} md={8}>
            <Form form={form} onChange={handleSearch}>
              <Form.Item
                name='search'
                style={{marginBottom: '.5em'}}
              >
                <Input
                    className="input-search"
                    placeholder="Search"
                    style={{
                      width: '100%',
                      marginRight: '10px',
                      textAlign: 'left'
                    }}
                    prefix={<SearchOutlined/>}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col flex='auto' style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button 
              style={{margin: '0px 0px 1em 1em'}} 
              className="app-btn danger" 
              disabled={records.length <= 0}
              onClick={deleteData}
            >
              Hapus
            </Button>
            <Button 
              onClick={deleteAllData}
              style={{margin: '0px 0px 1em 1em'}} 
              className="app-btn danger"
            >
              Hapus semua
            </Button>
            <Button className="app-btn" style={{backgroundColor: '#FFE600', color: '#072A6F', margin: '0px 0px 1em 1em'}} 
              onClick={()=>setUploadShow(true)}
            ><UploadOutlined/> Upload</Button>
            <Button 
              className="app-btn"
              style={{backgroundColor: '#FFE600', color: '#072A6F', margin: '0px 0px 1em 1em'}} 
              onClick={()=>setShow({show: true, record: null})}
            >
              <PlusOutlined/> Tambah
            </Button>
          </Col>
        </Row>
        <Row style={{display: 'flex', marginBottom: '14px'}} gutter={[14, 14]}>
          {
            props.dataLovMatpel && props.dataLovMatpel.map(res => {
              const isMatpelActive = matpel && matpel.key == res.key;
              return(
                <Col style={{flex: 1}}>
                  <Button
                    className="app-btn secondary"
                    onClick={() => setSelectedata(res)}
                    style={{backgroundColor: isMatpelActive ? '#072A6F' : "#F5F6FA", color: isMatpelActive ? "#fff" : '#072A6F', border: '1px #072a6f solid', borderRadius: '20px', padding: '.5em 2em', width: '100%'}}
                  >
                    {res.value}
                  </Button>
                </Col>
              )
            })
          }
        </Row>
        <Row>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{ x: 'calc(500px)'}}
              />
            </Card>
          </Col>
        </Row>
        <FormPertanyaanEsai
          isShow={isShow.show}
          isEdit={isShow.record}
          onCancel={()=>setShow({show: false, record: null})}
          onRefresh={onRefresh}
          matpel={matpel}
        />
        <FormPertanyaanUpload
          isShow={isUploadShow}
          onCancel={uploadClose}
          matpel={matpel}
          lovMatpel={props.dataLovMatpel}
        />
      </div>
    );
};

export default withRouter(PertanyaanUjianEssai);