import axios from 'axios';
import CONFIG from './config';

export default class Auth {

  static login(user) {
    return new Promise((resolve, reject) => {
      axios.post(CONFIG.BASE_URL_LOGIN + '/login', user).then(({data, ...res}) => {
        console.log(res)
        sessionStorage.setItem('token', data.auth.Authorization)
        sessionStorage.setItem('user', JSON.stringify(data.data));
        sessionStorage.setItem('siswaId', JSON.stringify(data.data.userId));
        setTimeout(_ => {
          resolve(data);
        }, 300);
      }).catch(err => {
        reject(err);
      })
    })
  }
  
  static logout() {
    // sessionStorage.clear();
    // sessionStorage.removeItem('token')
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('siswaId');
    // sessionStorage.removeItem('signature');
    sessionStorage.clear() 
  }
  
  static isLogin() {
    return !!sessionStorage.getItem('token') && !!sessionStorage.getItem('user');
  }


  static securityRole(param) {
    return new Promise((resolve, reject) => {
        if(sessionStorage.getItem('user')){
          let role = JSON.parse(sessionStorage.getItem('user')).authorities
          console.log('ada user')
          if(role && role[0].authority !== param){
            console.log('ada user tidak sesuai')
            this.logout()
            return reject(true)
          }else{
            return resolve(true)
          }
        }else{
          console.log('ada user tidak ada')
          this.logout()
          return reject(true)
        }
      })
  }
}