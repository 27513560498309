import axios from "axios";
import CONFIG_INITIAL_STATE from '../service/config';
import Auth from "./auth";

const client = axios.create(CONFIG_INITIAL_STATE.BASE_URL);
// const clientForLogin = axios.create(CONFIG_INITIAL_STATE.BASE_URL_LOGIN);


// Request interceptor
client.interceptors.request.use(
    function (configuration) {
        const token = sessionStorage.getItem('token');
        if (token !== null) {
            configuration.headers.Authorization = `Bearer ${token}`;
        }
        return configuration;
    },
    function (err) {
        throw new Error(err);
    }
);

client.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    let messageErrorResponse = error.response && error.response.data && error.response.data.error || ''
    let messageErrorStatus = error.response && error.response.status || ''
    if(messageErrorResponse == 'Unauthorized' || messageErrorStatus == 401){
        Auth.logout()
        window.location = '/';
    }
    return Promise.reject(error);
  });

const LoyolaServices = {

    //Config
    getDataConfig() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/settingConfig/getConfig`
            },
            { crossdomain: true }
        )
    },
    putDataConfig(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/settingConfig/updateConfig`,
                data: data
            },
            { crossdomain: true }
        )
    },

    //Scan
    getDataSiswaScan(data, type) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/scan/registrasiSiswa?noRegistrasi=${data}&type=${type}`
            },
            { crossdomain: true }
        )
    },
    //
    //admin SMP
    getDataSMP(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jawabanSiswa/listss?sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    postAdminSMP(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/creates?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putAdminSMP(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/updates?userId=${userId}&statusId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deleteAdminSMP(esaiId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/deletes?statusId=${esaiId}`
            },
            { crossdomain: true }
        )
    },
    //end admin smp

    // Verifikator
    getLovKategoriStatusDokumen(params) {
        return client.request(
            {
                method: "get",
                // url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/noAuth/lov/byKategoriStatus?katStatusId=${params}`
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/byKategoriStatus?katStatusId=${params}`
            },
            { crossdomain: true }
        )
    },
    getCalonSiswaJalurPrestasi(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jalurPrestasi/listSiswa?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    getCalonSiswaJalurTestOnline(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jalurTestOnline/listSiswa?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    getNilaiCalonSiswa(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/isiDataAkademik/nilai?siswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    putVerifikasiData(id, userId, body) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/raporSiswa/verifikasi?siswaId=${id}&&userId=${userId}`,
                data: body
            },
            { crossdomain: true }
        )
    },

    getBiodataDiri(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/verifikasi/dataDiri?siswaId=${id}`
            },
            { crossdomain: true }
        )
    },
    getBiodataDiriOrtu(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/verifikasi/dataOrtu/${data.siswaId}/${data.parent}`
            },
            { crossdomain: true }
        )
    },
    putBiodataDiri(id, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/verifikasi/dataDiri/save?siswaId=${id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putBiodataDiriOrtu(id, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/verifikasi/dataOrtu/save/${data.siswaId}/${data.ibuAyahWali}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    // End

    // Interviewer
    getListInterViewJalurPrestasi(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/calonSiswa/listInterview?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    getListInterViewTestOnline(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/calonSiswa/listInterviewJalurTes?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    postDataInterviewer(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/hasilWawancara/inputPenilaianWawancara?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    // End

    //Bip Tester

    //End

    //Dashboard
    getDataDashboard() {
        // let uri = 'https://demo5436246.mockable.io/dashboard-ppdb'
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/admin/dashboard`
                // url: uri
            },
            { crossdomain: true }
        )
    },
    //End Dashboard

    //Kalkulator
    getDataKalkulator() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/admin/kalkulator`
            },
            { crossdomain: true }
        )
    },
    //End Kalkulator


    //admin-penjadwalan
    deleteJadwalUjianAkademik() {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwal/delete/JadwalUjian`
            },
            { crossdomain: true }
        )
    },
    getDataAdminJadwal(page, size, sort, params, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwal/list?jenisJadwalId=${params}&keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    getDataLovJenisJadwal() {
        return client.request(
            {
                method: "get",
                // url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/noAuth/admin/penjadwalan/jenisJadwal`
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/admin/penjadwalan/jenisJadwal`
            },
            { crossdomain: true }
        )
    },
    postUploadJadwal(userId, jenisId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwal/upload?userId=${userId}&jenisJadwalId=${jenisId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    postUploadJadwalHasilUjian(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwal/hasilUjian?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    donwloadDataPenjadwalan(jadwalId) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/downloadTemplatePenjadwalan?jenisJadwalId=${jadwalId}`
            },
            { crossdomain: true }
        )
    },
    donwloadDataPenjadwalanHasilUjian() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/download/jadwalHasilUjian`
            },
            { crossdomain: true }
        )
    },

    getDetailMatpel(id, page, size, sort) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwal/detailUjianAkademik?penerimaanId=${id}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    updateJadwalDaftarUlang(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwalDaftarUlang/update`,
                data: data
            },
            { crossdomain: true }
        )
    },

    //End penjadwalan

    //admin-input-nilai
    getInputNilaiUjian(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/listSiswaUjianAkademik?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    getInputNilaiUjianByRegistrasi(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/nilaiUjian?noRegistrasi=${id}`
            },
            { crossdomain: true }
        )
    },
    postNilaiUjian(noReg, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/nilaiUjian/input?noRegistrasi=${noReg}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putNilaiUjian(noReg, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/nilaiUjian/input?noRegistrasi=${noReg}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    //end admin-input-nilai
    //admin-pertanyaan
    getDownloadTamplateUploadSoal(param,key) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/download/templateSoal?jenisSoal=${param}&key=${key}`
            },
            { crossdomain: true }
        )
    },
    postUploadSoal(param) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/upload/tambahSoal?jenisSoal=`+param.jenis+'&userId='+param.userId+'&matpelId='+param.matpelId,
                data: param.data
            },
            { crossdomain: true }
        )
    },
    getDataLovMatpel() {
        return client.request(
            {
                method: "get",
                // url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/noAuth/lov/matpel`
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/matpel`
            },
            { crossdomain: true }
        )
    },

    getDataPertanyaanEsai(matpel, page, size, sort, search) {
        // let url = 'https://demo5436246.mockable.io/listApapun'
        let url = `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalEsai?matpelId=${matpel}&keyword=${search}&sort=${sort}&size=${size}&page=${page}`
        return client.request(
            {
                method: "get",
                url: url
            },
            { crossdomain: true }
        )
    },

    postPertanyaanEsai(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalEsai/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putPertanyaanEsai(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalEsai/edit?userId=${userId}&esaiId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deletePertanyaanEsai(userId, esaiId) {
        let soalId = '';
        esaiId.forEach(e => {soalId += (e + ',')});
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalEsai/deleteMany?soalEsaiId=${soalId}&userId=${userId}`
            },
            { crossdomain: true }
        )
    },

    deletePertanyaanPg(userId, pgId) {
        let soalId = '';
        pgId.forEach(e => {soalId += (e + ',')});
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalPilihanGanda/deleteMany?soalGandaId=${soalId}&userId=${userId}`
            },
            { crossdomain: true }
        )
    },

    deleteAllPertanyaan(matpelId, userId, type) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/resetSoal?matpelId=${matpelId}&userId=${userId}&type=${type}`
            },
            { crossdomain: true }
        )
    },

    getDataPertanyaanPg(matpel, page, size, sort, search) {
        // let url = 'https://demo5436246.mockable.io/listApapun'
        let url = `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalPilihanGanda?matpelId=${matpel}&keyword=${search}&sort=${sort}&size=${size}&page=${page}`
        return client.request(
            {
                method: "get",
                url: url
            },
            { crossdomain: true }
        )
    },

    postPertanyaanPg(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalPilihanGanda/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putPertanyaanPg(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/soalUjianAkademik/soalPilihanGanda/edit?userId=${userId}&soalGandaId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    //end-pertnyaan

    //admin-point-penilaian
    getDataPenilaianBipTester(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/bipTes?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    postPenilaianBipTester(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/bipTes/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putPenilaianBipTester(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/bipTes/edit?userId=${userId}&bipId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deletePenilaianBipTester(userId, aspekId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/bipTes/delete?userId=${userId}&bipId=${aspekId}`
            },
            { crossdomain: true }
        )
    },

    getDataPenilaianInterviewer(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/wawancara?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    postPenilaianInterviewer(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/wawancara/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putPenilaianInterviewer(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/wawancara/edit?userId=${userId}&wawancaraId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deletePenilaianInterviewer(userId, wawncaraId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/aspekPenilaian/wawancara/delete?userId=${userId}&wawancaraId=${wawncaraId}`,
            },
            { crossdomain: true }
        )
    },

    downloadKejujuranKesungguhan(params) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/downloadKesungguhanKejujuranPascaWawancara?noRegistrasi=${params}`
            },
            { crossdomain: true }
        )
    },

    uploadKejujuranKesungguhan(userId, data, noRegistrasi) {
        let uri = `${CONFIG_INITIAL_STATE.BASE_URL}/api/kesungguhanKejujuranPascaWawancara/upload?userId=${userId}`
        if (noRegistrasi) {
            uri += '&noRegistrasi=' + noRegistrasi
        }
        return client.request(
            {
                method: "post",
                url: uri,
                data: data
            },
            { crossdomain: true }
        )
    },
    //end-point-penilaian

    //admin status
    getDataLovStatusAdmin() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/kategoriStatus`
            },
            { crossdomain: true }
        )
    },
    getDataLovStatusAdminAddable() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/kategoriStatusAddable`
            },
            { crossdomain: true }
        )
    },
    getDataStatusAdmin(page, size, sort, filter) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/list?katStatusId=${filter}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    postStatusAdmin(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putStatusAdmin(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/update?userId=${userId}&statusId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deleteStatusAdmin(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/delete?statusId=${data.statusId}&userId=${data.userId}`
            },
            { crossdomain: true }
        )
    },
    //end admin status

    //admin review soal
    getDataReviewSoalDetail(siswaId, matpelId) {
        // let url = `https://demo5436246.mockable.io/listApapun`
        let url = `${CONFIG_INITIAL_STATE.BASE_URL}/api/jawabanSiswa/detail?siswaId=${siswaId}&matpelId=${matpelId}`
        return client.request(
            {
                method: "get",
                url: url
            },
            { crossdomain: true }
        )
    },
    putReviewSoalDetail(userId, data) {
        // let url = `https://demo5436246.mockable.io/listApapun`
        let url = `${CONFIG_INITIAL_STATE.BASE_URL}/api/jawabanSiswa/submitDetail?userId=${userId}`
        return client.request(
            {
                method: "put",
                url: url,
                data: data
            },
            { crossdomain: true }
        )
    },
    getDataReviewSoal(matpelId, page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jawabanSiswa/list?matpelId=${matpelId}&sort=${sort}&size=${size}&page=${page}&keyword=${search}`
            },
            { crossdomain: true }
        )
    },

    postReviewSoal(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putReviewAdmin(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/update?userId=${userId}&statusId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    deleteReviewAdmin(esaiId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterStatus/delete?statusId=${esaiId}`
            },
            { crossdomain: true }
        )
    },
    //end admin review

    //setting parameter
    getDataLovMatpelAll() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/getMatpel`
            },
            { crossdomain: true }
        )
    },

    getDataLovTahunAjar() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/parameter/lov/tahunAjar`
            },
            { crossdomain: true }
        )
    },

    getDataParameter(page, size, sort, filter) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/parameter/list?tahunAjaran=${filter}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    putDataParameter(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/parameter/submit`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getDataParameter() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/parameter/readAll`,
            },
            { crossdomain: true }
        )
    },

    deleteDataParameter(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/parameter/delete?userId=${userId}&paramId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },


    //end setting parameter

    // admin ptongan biaya

    putDataPotonganBiaya(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/potBiaya/submit`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getDataPotonganBiaya() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/potBiaya/readAll`,
            },
            { crossdomain: true }
        )
    },


    // endt admin ptongan biaya

    //admin master user
    getDataLovUserAdmin() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/lov/role`
            },
            { crossdomain: true }
        )
    },

    getDataUserAdmin(page, size, sort, search, filter) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterUser/read?keyword=${search}&roleId=${filter}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    postDataUserAdmin(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterUser/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    putDataUserAdmin(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterUser/update?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    deleteDataUserAdmin(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterUser/delete?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },
    //end admin master user

    //admin kategori status
    getDataKategoriStatus(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/kategoriStatus/list?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    postKategoriStatus(statusId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/kategoriStatus/create?userId=${statusId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    putKategoriStatus(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/kategoriStatus/update?userId=${userId}&katStatusId=${data.id}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    deleteKategoriStatus(userId, katStatusId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/kategoriStatus/delete?userId=${userId}&katStatusId=${katStatusId}`
            },
            { crossdomain: true }
        )
    },
    //end admin kategori status

    //admin download harian
    getDownloadHarian(min, max) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/downloadReportSiswa?min=${min}&max=${max}`,
            },
            { crossdomain: true }
        )
    },
    getDownloadDataSiswa(params) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/downloadReportSiswa?${params}`,
            },
            { crossdomain: true }
        )
    },
    getDownloadDataSiswaPdf(params) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/calonSiswa?siswaId=${params}`,
            },
            { crossdomain: true }
        )
    },
    getDownloadDataKejujuran(body) {
        console.log('download kejujuran ', body)
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/download/report/kesungguhan`,
                data: body,
            },
            { crossdomain: true }
        )
    },
    getDownloadDataUploadKejujuran(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/dataUploadFormKesungguhanKejujuranPascaWawancara?noRegistrasi=${data}`,
            },
            { crossdomain: true }
        )
    },
    //end download harian
    // Master data SMP
    getMasterDataSmp(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterSmp/list?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    postMasterDataSmp(userId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterSmp/create?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    putMasterDataSmp(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterSmp/edit?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    deleteMasterDataSmp(smpId, userId) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterSmp/delete?userId=${userId}&smpId=${smpId}`,
            },
            { crossdomain: true }
        )
    },
    // end Master data SMP

    //Finance
    getLovDiskon() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/masterPotBiaya`
            },
            { crossdomain: true }
        )
    },

    getInisiasiUpsUsm() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/finance/inisiasiUpsUsm`
            },
            { crossdomain: true }
        )
    },

    getDataSiswa(noRegistrasi) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/finance/dataSiswa?noRegistrasi=${noRegistrasi}`
            },
            { crossdomain: true }
        )
    },
    putInisiasiUpsUsm(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/finance/inisiasiUpsUsm/save`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getRincianBiaya(params) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/finance/dataSiswa/rincianBiaya?noRegistrasi=${params}`
            },
            { crossdomain: true }
        )
    },

    getDownloadRincianBiaya(params) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/download/report/finance?noRegistrasi=${params}`
            },
            { crossdomain: true }
        )
    },
    putUpdateRincianBiaya(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/finance/dataSiswa/updateRincianBiaya`,
                data: data
            },
            { crossdomain: true }
        )
    },

    // admin ujian daftar user 

    getDaftarSiswa(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/listSiswa?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    getNilaiSiswa(id, page, size, sort) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/nilaiUjianSiswa?penerimaanId=${id}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    getReport() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/downloadReportUjianAkademik`
            },
            { crossdomain: true }
        )
    },

    //end

    // jadwal akademik

    getJadwalMatpel() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/matpelUjian`
            },
            { crossdomain: true }
        )
    },
    putJadwalMatpel(userId, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ujianAkademik/waktuUjian?userId=${userId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    // end 



    //super admin developer
    getSiswaDaftarUlangSuperAdmin(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/daftarUlang/listSiswa?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    getCalonSiswaSuperAdmin(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/superAdmin/listSiswa?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },
    postSendEmailSiswa(data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/remindCalonSiswa`,
                data: data
            },
            { crossdomain: true }
        )
    },
    postSendEmailSiswaDaftarUlang(data,userId) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/daftarUlang/sendNotification?userId=`+userId,
                data: data
            },
            { crossdomain: true }
        )
    },
    //end super admin developer

    //finance - non beasiswa
    getLovCicilan() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ketPembayaran`
            },
            { crossdomain: true }
        )
    },

    getlistFinanceNonBeasiswa(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/listNonBeasiswa?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },
    getlistFinanceNonBeasiswaById(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },
    getlistFinanceNonBeasiswaKesanggupanById(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },
    postInputPembayaranFinanceNonBeasiswaById(data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },
    putInputKesanggupanFinanceNonBeasiswaById(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan/nonBeasiswaNegosiasi/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },
    getDownloadTamplateNonBeasiswa(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/nonBeasiswa?komitSiswaId=${data}`
            },
            { crossdomain: true }
        )
    },
    getDownloadHistoryNonBeasiswa(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/historyNegosiasi?komitSiswaId=${data}`
            },
            { crossdomain: true }
        )
    },

    //finance - non beasiswa

    //finance - negosisasi
    getlistFinanceNegosisasi(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/listNegosiasi?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },
    putVerifikasiFinanceNegosisasi(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiNegosiasi/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },
    getDownloadTamplateNegosiasi(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/negosiasi?komitSiswaId=${data}`
            },
            { crossdomain: true }
        )
    },
    getDownloadHistoryNegosiasi(data) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/historyNegosiasi?komitSiswaId=${data}`
            },
            { crossdomain: true }
        )
    },

    //finance - negosisasi


    //super admin developer
    getCalonSiswaSuperAdmin(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/superAdmin/listSiswa?keyword=${search}&sort=${sort}&size=${size}&page=${page}`
            },
            { crossdomain: true }
        )
    },

    getlistKesungguhan(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/interviewer/listKesungguhan?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },

    getListDownloadBerkas(page, size, sort, search) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/listDownloadBerkas?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },

    // superadmin finance
    getDataMasterFinance(param) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/listStatusFinance?keyword=${param.search}&sort=${param.sort}&size=${param.size}&page=${param.page}`
            },
            { crossdomain: true }
        )
    },
    handleChangeStatus(id, data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/updateStatusFinance?komitSiswaId=${id}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    // Save jadwal negosiasi


    postJadwalNegosiasi(jadwalId, data) {
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwalNegosiasi/submit?jadwalId=${jadwalId}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getJadwalNegosiasi() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/jadwalNegosiasi/getJadwal`
            },
            { crossdomain: true }
        )
    },

    // Master Data Komitmen UPS

    putKomitmenUPS(data) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/komitmenUps/submit`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getKomitmenUPS() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/komitmenUps/readAll`
            },
            { crossdomain: true }
        )
    },

    // Master Data Beasiswa

    putBeasiswa(data, isBeasiswaUtama) {
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/beasiswa/submit?isBeasiswaUtama=${isBeasiswaUtama}`,
                data: data
            },
            { crossdomain: true }
        )
    },

    getBeasiswa(isBeasiswaUtama) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/master/beasiswa/readAll?isBeasiswaUtama=${isBeasiswaUtama}`
            },
            { crossdomain: true }
        )
    },

}

export { LoyolaServices }