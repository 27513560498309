export default {
  "data": {
      "ups": 2000.00,
      "usm": 1000.00,
      "dpUpm": 400.00,
      "cicil1": 100.00,
      "cicil2": 200.00,
      "cicil3": 300.00,
      "historyPembayaran": [
          {
              "tglPerubahan": "2020-09-29",
              "tipePembayaran": "upm",
              "nominal": 2000.00,
              "catatanPerubahan": "miskin"
          },
          {
              "tglPerubahan": "2020-09-30",
              "tipePembayaran": "usm",
              "nominal": 3000.00,
              "catatanPerubahan": "nego"
          }
      ]
  },
  "status": "OK"
}