export const dataListPertanyaanEsai = [
  {
      "esaiId" : 1,
      "pertanyaan": "Siapa Penemu Lampu ? ",
      "jawaban": 'Thomas Alfa ',
      "image": 'https://simpelpas.files.wordpress.com/2013/11/simpel-lck-smp-nilai-1.png',
      "wawancara": 'sasuke'
  },
  {
      "esaiId" : 2,
      "pertanyaan": "Siapa Penemu Gardu ? ",
      "jawaban": 'Thomas Alfa ',
      "image": 'https://simpelpas.files.wordpress.com/2013/11/simpel-lck-smp-nilai-1.png',
      "wawancara": 'sasuke'
  },
  {
      "esaiId" : 3,
      "pertanyaan": "Siapa Penemu Listrik ? ",
      "jawaban": 'Thomas Alfa ',
  },
  {
      "esaiId" : 4,
      "pertanyaan": "Siapa Penemu Tiang ? ",
      "jawaban": 'Thomas Alfa ',
  },
];

export const dataLovMatpel = {
    "data": [
        {
            "key": 1,
            "value": "Bahasa Indonesia"
        },
        {
            "key": 2,
            "value": "Bahasa Inggris"
        },
        {
            "key": 3,
            "value": "Matematika"
        },
        {
            "key": 5,
            "value": "IPA"
        },
        {
            "key": 6,
            "value": "IPS"
        }
    ]
};

export const dataListPertanyaanPG = {
    "total": 2,
    "data": [
        {
            "createdBy": 1,
            "createdOn": "2020-09-28T08:13:13.994+0000",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "soalGandaId": 19,
            "matpelId": {
                "createdBy": 0,
                "createdOn": "2020-07-15T07:41:17.000+0000",
                "lastModifiedBy": 0,
                "lastModifiedOn": "2020-07-15T07:41:17.000+0000",
                "matpelId": 3,
                "matpelSikap": "Matematika",
                "isMatpelUjian": null
            },
            "pertanyaanGanda": "sdw",
            "jawabanGanda": "B",
            "pilihanA": null,
            "pilihanB": null,
            "pilihanC": null,
            "pilihanD": null,
            "pilihanE": null,
            "gambarGanda": null,
            "durasiGanda": 121,
            "jawabanTipeTeksPilA": true,
            "jawabanTipeTeksPilB": true,
            "jawabanTipeTeksPilC": true,
            "jawabanTipeTeksPilD": true,
            "deleted": false
        },
        {
            "createdBy": 1,
            "createdOn": "2020-09-11T03:46:11.000+0000",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "soalGandaId": 13,
            "matpelId": {
                "createdBy": 0,
                "createdOn": "2020-07-15T07:41:17.000+0000",
                "lastModifiedBy": 0,
                "lastModifiedOn": "2020-07-15T07:41:17.000+0000",
                "matpelId": 3,
                "matpelSikap": "Matematika",
                "isMatpelUjian": null
            },
            "pertanyaanGanda": "Perkalian 3 X 3 X 3 X 3 X 3 jika dituliskan dalam bentuk pangkat menjadi…",
            "jawabanGanda": "C",
            "pilihanA": " 3^-5",
            "pilihanB": "5^-3",
            "pilihanC": "3^5",
            "pilihanD": "5^3",
            "pilihanE": "3^3",
            "gambarGanda": null,
            "durasiGanda": 180,
            "jawabanTipeTeksPilA": true,
            "jawabanTipeTeksPilB": true,
            "jawabanTipeTeksPilC": true,
            "jawabanTipeTeksPilD": true,
            "deleted": false
        }
    ],
    "size": 10,
    "page": 0,
    "sort": "soal_ganda_id",
    "direction": "DESC"
}

export const dataListReviewSoal = {
    "data" :[
    {
        "noRegister" : 1,
        "namaSiswa": 'sasuke',
        "skorPG": 'sasuke',
        "skorEsai": 'sasuke',
        "namaSiswa": 'sasuke',
    },
  ]
};


export const dataListMatpel = {
    "data" :[
        {
            'key': '100',
            'value': 'Basa Indonesia'
        },
        {
            'key': '101',
            'value': 'Basa Indonesia'
        },
        {
            'key': '102',
            'value': 'Basa Indonesia'
        },
        {
            'key': '103',
            'value': 'Basa Indonesia'
        },
        {
            'key': '104',
            'value': 'Basa Indonesia'
        },
  ]
};