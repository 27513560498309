import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Table, Card, Input, Menu, Dropdown} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import {DownOutlined, SearchOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import Downloader from "js-file-downloader";

const { Content } = Layout;
const { Title, Text } = Typography;


const keyBerkas = [
  {key: "kartuKeluarga", label: "Kartu Keluarga"},
  {key: "aktaKelahiran", label: "Akta Kelahiran"},
  {key: "suratPermandian", label: "Surat Baptis"},
  {key: "foto", label: "Foto"},
  {key: "npwpAyah", label: "NPWP Ayah"},
  {key: "npwpIbu", label: "NPWP Ibu"},
  {key: "npwpWali", label: "NPWP Wali"},
  {key: "slipGajiAyah", label: "Slip Gaji Ayah"},
  {key: "slipGajiIbu", label: "Slip Gaji Ibu"},
  {key: "slipGajiWali", label: "Slip Gaji Wali"},
  {key: "ktpAyah", label: "KTP Ayah"},
  {key: "ktpIbu", label: "KTP Ibu"},
  {key: "ktpWali", label: "KTP Wali"},
  {key: "sptAyah", label: "SPT Ayah"},
  {key: "sptIbu", label: "SPT Ibu"},
  {key: "sptWali", label: "SPT Wali"},
  {key: "pbbAyah", label: "PBB Ayah"},
  {key: "pbbIbu", label: "PBB Ibu"},
  {key: "pbbWali", label: "PBB Wali"},
  {key: "suratKeputusan", label: "Surat Keputusan"},
  {key: "scanIjazah", label: "Scan Ijazah"},
  {key: "scanSkl", label: "Scan SKL"},
  {key: "fotoSmp", label: "Foto SMP"},
]

const DownloadBerkasSiswa = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('pn.no_registrasi,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [records, setRecord] = useState([]);

  useEffect(()=>{
    getData(pagination,sort,search)
    // getDataDummy()
  }, []);
  
  useEffect(()=>{
    setRecord([])
  }, [pagination]);

  const getData = (page,sort,search) => {
    setLoading(true);
    LoyolaServices.getListDownloadBerkas(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        var data = res.data.data;
        setDataList(data);
        setPagination({
          ...pagination,
          current: res.data.page + 1,
          total: res.data.totalData
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  const processSearchCalonSiswa = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const handleDownloadBerkas = (e, id) => {
    const url = dataList.find(e => e.noRegistrasi === id)[e.key]
    console.log({e, id, url})
    new Downloader({url: url}).then(file => {
    }).catch(err => {
      dialog({
        icon: 'error',
        title: 'Gagal mengunduh file',
        text: err.status === '404' ? 'File tidak ditemukan' : ''
      })
    })
  }

  const Downloads = (id) => {
    const newKeyBerkas = keyBerkas.filter(key => {
      const data = dataList.find(e => e.noRegistrasi === id);
      return !!data[key.key];
    })
    return (
      <Menu onClick={e => handleDownloadBerkas(e, id)}>
        { newKeyBerkas.map(({key, label}, index) => <Menu.Item key={key}>{label}</Menu.Item>)}
      </Menu>
    )
  }

  const columns = [
    {
      title: 'No. Registrasi',
      dataIndex: 'noRegistrasi',
      key: 'pn.no_registrasi',
      sorter: true,
      width: '20%'
    },
    {
      title: 'Nama Siswa',
      dataIndex: 'nama',
      key: 'nama_siswa',
      sorter: true,
      width: '60%'
    },
    {
      title: 'Action',
      dataIndex: 'noRegistrasi',
      width: '20%',
      render: id => {
        return (
          <Dropdown overlay={Downloads(id)}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Download Berkas Siswa&nbsp;&nbsp;<DownOutlined />
            </a>
          </Dropdown>
        )
      },
    }
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}} >
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
                className="input-search"
                placeholder="No. Registrasi"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={handleSearch}
            />
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : 0}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default withRouter(DownloadBerkasSiswa);