import React from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Layout, Row, Typography, Col, Input, Space, Card, Table, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, QrcodeOutlined, DownOutlined } from "@ant-design/icons";
import { LoyolaServices } from "../../service";
import _debounce from "lodash.debounce"
import { GlobalFunction } from "../../global/function";
import { dialog } from "../../functions/alert";
import Auth from "../../service/auth";

const { Content } = Layout;
const { Title, Text } = Typography;
const menuKey = [
    { key: 'Prestasi', label: 'Jalur Prestasi' },
    { key: 'TesOnline', label: 'Jalur Tes' },
]

class Verifikator_Jalur_Prestasi extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Verifikator | Loyola"
        this.state = {
            // visibleAkademik: false,
            nilaiCalonSiswa: [],
            sort: "pn.no_registrasi,desc",
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            responsive: props.responsive,
            jalur: 'Prestasi'
        }
    }

    componentWillMount() {
		Auth.securityRole('Verifikator').then(res => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				this.props.history.replace('/')
			}
		})
    }

    async getDataCalonSiswa(page, size, sort, search) {
        this.setState({
            loadingCalonSiswa:true,
        })
        let listTemp = [];
        await LoyolaServices.getCalonSiswaJalurPrestasi(page, size, sort, search)
        .then(res => {
            console.log('res:',res)
            listTemp = res && res.data && res.data.data ? res.data.data : [];
            this.setState({
                totalCalonSiswa : res && res.data && res.data.totalDataCalonSiswa ? res.data.totalDataCalonSiswa : 0,
                totalCalonSiswaVerifikasi : res && res.data && res.data.totalVerifiedCalonSiswa ? res.data.totalVerifiedCalonSiswa : 0,
                pagination: {
					...this.state.pagination,
					total: res && res.data && res.data.total ? res.data.total : 0
				}
            })
        }).catch(err => {
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        })
        this.setState({
            listCalonSiswa: listTemp,
            loadingCalonSiswa:false
        })
    }

    // search handler
    searchHandler(e) {
        let key = e.target.value;
        this.processSearchCalonSiswa(key);
    }

    processSearchCalonSiswa = _debounce((key) => {
        this.setState({
            search: GlobalFunction.searchEncode(key),
            pagination: {
                ...this.state.pagination,
                current: 1
            }
        }, () => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
        });
    }, 500)

    handleJalur(e){
        if(e == 'TesOnline'){
            this.props.history.push('/verifikator-jalur-test-online')
        }
    }

    // Table Change
    handleTableChange = (pagination, search, sorter) => {
        if (sorter.order === "ascend") {
            sorter.order = "asc";
        } else if (sorter.order === "descend") {
            sorter.order = "desc";
        }
        this.setState({
            sort: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`,
            pagination: pagination
        }, () => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
        });
    };

    render() {

        const tagColor = (tag) => {
            tag = tag.toLowerCase();
            switch (tag) {
                case 'sesuai':
                case 'lolos seleksi administrasi':
                    return '#219653';
                case 'tidak sesuai':
                case 'reject':
                case 'revisi data input':
                    return '#F2994A';
                case 'belum diverifikasi':
                case 'tidak lolos':
                    return '#EB5757';
                default:
                    return '#222222';
            }
        };

        const columns = [
            {
                title: 'No. Registrasi',
                dataIndex: 'noRegistrasi',
                key: 'pn.no_registrasi',
                sorter: true,
            },
            {
                title: 'Nama',
                dataIndex: 'namaSiswa',
                key: 'nama_siswa',
                sorter: true,
            },
            {
                title: 'Asal Sekolah',
                dataIndex: 'sekolahAsal',
                key: 'sekolah_asal',
                sorter: true,
            },
            {
                title: 'Status Dokumen',
                dataIndex: 'statusDokumen',
                key: 'status_dokumen',
                sorter: true,
                render: tag => {
                    if (tag !== null)
                    {let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )}
                },
            },
            {
                title: 'Status Data Inputan',
                dataIndex: 'statusDataInputan',
                key: 'status_data_inputan',
                sorter: true,
                render: tag => {
                    if (tag !== null)
                    {let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )}
                },
            },
            {
                title: 'Status Data Diri',
                dataIndex: 'statusDataDiri',
                key: 'status_data_diri',
                sorter: true,
                render: tag => {
                    if (tag !== null)
                    {let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )}
                },
            },
            {
                title: 'Status Pendaftaran',
                dataIndex: 'statusAdministrasi',
                key: 's_status_administrasi.status',
                sorter: true,
                render: tag => {
                    if (tag !== null)
                    {let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )}
                },
            },
            {
                render: (record) => {
                    let statusAdministrasi = record.statusAdministrasi ? (record.statusAdministrasi.toLowerCase() != 'belum diverifikasi' ? true : false): false
                    return (
                        <Space>
                            <Link to={"/verifikasi/" + record.siswaId + "/" + record.namaSiswa + "/prestasi"}>
                                <Button disabled={statusAdministrasi} className="admin-update-button" type="primary" size="small">
                                    Verifikasi
                                </Button>
                            </Link>
                        </Space>
                    )
                }
            },
        ];

        const { responsive, jalur, loadingCalonSiswa ,listCalonSiswa, totalCalonSiswa, totalCalonSiswaVerifikasi, pagination, listMatpel, nilaiCalonSiswa } = this.state
        
        const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

        const dropdownLabel = menuKey.find(e => e.key === jalur).label;

        return(
            <Content className="contentContainer">                
                {responsive && responsive.grid.md ? (
                    <Row>
                        <Col>
                            <Space size={"large"}>
                                {menuKey.map(({key, label}) => (
                                    <a type="text" onClick={() => {this.handleJalur(key)}} className="text-heading">
                                        <Title level={3} style={{color: jalur === key ? '#072a6f' : '#BDBDBD' }}>
                                            {label}
                                        </Title>
                                    </a>
                                ))}
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <div style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                        <Dropdown 
                            overlay={(
                                <Menu onClick={e => this.handleJalur(e.key)}>
                                    {menuKey.map(({key, label}) => (
                                        <Menu.Item key={key}>{label}</Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <Button style={{width: '100%', marginBottom: '24px'}}> {dropdownLabel}<DownOutlined/> </Button>
                        </Dropdown>
                    </div>
                )}
                <Row align="middle">
                    <Col span={24} lg={6}>
                        <Input
                            className="input-search"
                            placeholder="No. Registrasi"
                            style={{
                                width: '100%',
                                marginRight: '10px',
                                textAlign: 'left'
                            }}
                            prefix={<SearchOutlined/>}
                            onChange={(e) => this.searchHandler(e)}
                        />
                    </Col>
                    <Col xs={24} lg={18} style={{ textAlign: 'right' }}>
                        <Text className="title-verifikator" style={responsive.grid.xs ? {fontSize: 16}: {}}>{totalCalonSiswaVerifikasi} dari {totalCalonSiswa} calon siswa telah diverifikasi</Text>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10, marginTop:24}}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Table
                                columns={columns}
                                size="middle"
                                bordered={false}
                                pagination={paginationCus}
                                onChange={this.handleTableChange}
                                loading={loadingCalonSiswa}
                                dataSource={listCalonSiswa}
                                scroll={{x: responsive && responsive.grid.xs ? responsive.width : 0}}
                                />
                        </Card>
                    </Col>
                </Row>
            </Content>
        );
    }
}

export default withRouter(Verifikator_Jalur_Prestasi)