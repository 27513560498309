import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {Layout, Row, Typography, Col, Input, Space, Card, Table, Button, Dropdown, Menu} from 'antd';
import { SearchOutlined, QrcodeOutlined, DownOutlined } from "@ant-design/icons";
import axios from "axios";
import CONFIG_INITIAL_STATE from "../../service/config";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../global/function";
import {confirmDialog, dialog} from "../../functions/alert";
import Downloader from "js-file-downloader";
import { LoyolaServices } from "../../service";
import listDaftarSiswa from "../../dummy/list-daftar-siswa";
import Auth from "../../service/auth";

const { Content } = Layout;
const { Title, Text } = Typography;
const menuKey = [
    { key: 'Prestasi', label: 'Jalur Prestasi' },
    { key: 'TesOnline', label: 'Jalur Tes' },
    { key: 'Transfer', label: 'Jalur Transfer' },
    { key: 'Report', label: 'Report' },
]

class Eksekutif extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Executive | Loyola";

        this.state = {
            loading: true,
            search: "",
            page: "listCalonSiswa",
            columns: [...this.columns],
            data: [],
            sort: "pn.no_registrasi,desc",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            selectedRowKeys: [],
            jalur:"Prestasi",
            totalDataCalonSiswaEksekutif: 0,
            totalCalonSiswa: 0,
            isLolos: false,
            isTidakLolos: false,
            isDownload: false
        }
    }

    handleSearch = (e) => {
        let key = e.target.value;
        this.setState({
            search: key,
            pagination: {
                current: 1,
                pageSize: 10,
            } 
        }, () => this.processSearchCalonSiswa(key))
    };

    processSearchCalonSiswa = _debounce((key) => {
        this.getDataCalonSiswa(
            this.state.pagination.current - 1,
            this.state.pagination.pageSize,
            this.state.sort,
            key
        );
    }, 500);

    componentDidMount(){
		Auth.securityRole('Executive').then(res => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            );
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				this.props.history.replace('/')
			}
		})
    }

    config(){
        return {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }
    };

    async getDataCalonSiswa(page, size, sort, search){
        let keyword = search ? GlobalFunction.searchEncode(search) : ''
        this.setState({
            loading: true,
        });

        let api = `/api/eksekutif/listJalur${this.state.jalur}?keyword=${keyword}&sort=${sort}&size=${size}&page=${page}`;

        let temp = [];
        await axios.get(CONFIG_INITIAL_STATE.BASE_URL+api,this.config())
            .then(res => {
                temp = res && res.data && res.data.data ? res.data.data : [];
                this.setState({
                    totalCalonSiswa : res && res.data && res.data.totalDataCalonSiswa ? res.data.totalDataCalonSiswa : 0,
                    totalCalonSiswaDaftarUlang : res && res.data && res.data.totalCalonSiswaDaftarUlang ? res.data.totalCalonSiswaDaftarUlang : 0,
                    totalDataCalonSiswaEksekutif : res && res.data && res.data.totalDataCalonSiswaEksekutif ? res.data.totalDataCalonSiswaEksekutif : 0,
                    pagination: {
                        ...this.state.pagination,
                        total: res && res.data && res.data.totalDataCalonSiswa ? res.data.totalDataCalonSiswa : 0
                    }
                })
            }).catch(err => {
                console.error(err);
            });

        this.setState({
            data: temp,
            loading:false
        })
    }

    handleTableChange = (pagination, search, sorter) => {
        if (sorter.order === "ascend") {
            sorter.order = "asc";
        } else if (sorter.order === "descend") {
            sorter.order = "desc";
        }
        this.setState({
            sort: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`,
            pagination: pagination
        }, () => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
        });
    };

    handleSubmit = async(keputusan) => {
        const {selectedRowKeys,loading, data, isLolos, isTidakLolos} = this.state;
        let dataRecords = []
        if(selectedRowKeys.length > 0){
            confirmDialog({
                text: `Apakah Anda yakin ${keputusan == 1 ? 'akan meloloskan' : 'tidak akan meloloskan'} siswa?\nPengumuman akan dikirimkan lewat email`
            }).then(async() => {
                this.setState({
                    loading: !loading,
                    isLolos: keputusan == 1 ? !isLolos : isLolos,
                    isTidakLolos: keputusan != 1 ? !isTidakLolos : isTidakLolos
                });
                dataRecords = await this.mappingDataRecords(selectedRowKeys,data)
                if(dataRecords.length > 0){
                    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId;
                    let body = {
                        userId: userId,
                        data: dataRecords,
                        keputusan: keputusan == 1 ? true : false
                    };
            
                    let api = "/api/eksekutif/updateKeputusan";
                    let params = '?keputusan='+body.keputusan+'&userId='+body.userId
                    console.log('data body adalah : ',body)
                    axios.put(CONFIG_INITIAL_STATE.BASE_URL+api+params,body.data,this.config())
                        .then(res => {
                            if(res.data){
                                dialog({icon: 'success', text:res.data.message}).then(this.refreshData())
                            }
                        }).catch(err => {
                        console.error(err);
                        if(err){
                            let text = err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
                            dialog({icon: 'error', text: text}).then(this.refreshData());
                        }
                    });
                }else{
                    dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
                    this.setState({
                        loading: false,
                        isLolos: false,
                        isTidakLolos: false
                    })
                }
            })
        }else{
            dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
        }
    };

    refreshData = () => {
        const {pagination,sort,search,loading} = this.state
        this.setState({
            loading: !loading,
            isLolos: false,
            isTidakLolos: false,
            isDownload: false
        },() => {
            this.getDataCalonSiswa(pagination.current - 1, pagination.pageSize, sort, search)
        });
    }

    handleDownload = async() => {
        this.setState({
            loading: true,
            isDownload: true
        })
        let api = "/api/saveReportExecutive";
        await axios.get(CONFIG_INITIAL_STATE.BASE_URL + api, this.config()).then(res => {
          if(res.data){
            console.log('data url : ',res.data.url)
            new Downloader({url: res.data.url}).then(file => {
            }).catch(err => {console.log(err)})
          }
        }).catch(err => {
          dialog({icon: 'error', text: 'Terjadi Kesalahan Pada Server'})
          // reject(err)
        } );
        this.setState({
            loading: false,
            isDownload: false
        })
    };

    mappingDataRecords(data,compareList){
        let dataRecords = []
        data.forEach(element => {
            compareList.map(res => {
                if(element == res.key){
                    dataRecords.push(element)
                }
            })
        });

        return dataRecords
    }

    handleDownloadList = async() => {
        this.setState({
            loading: true,
            isDownload: true
        })
        const {selectedRowKeys, data } = this.state
        console.log('data compare : ',selectedRowKeys, data)
        let dataRecords = []
        if(selectedRowKeys.length > 0){
            dataRecords = await this.mappingDataRecords(selectedRowKeys,data)
            if(dataRecords.length > 0){
                let downloadNumber = 0
                let downloadStatus = null
                for(downloadNumber; downloadNumber<dataRecords.length ; downloadNumber++){
                    downloadStatus = await this.downloadPdf(dataRecords[downloadNumber])
                    if(downloadStatus){
                        downloadNumber += 1
                        downloadStatus = null
                    }
                }
            }else{
                dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
            }
        }else{
            dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
        }
        this.setState({
            loading: false,
            isDownload: false
        })
    };

    downloadExcel = async() => {
        let api = "/api/saveReportExecutive";
        let noRegistrasi = ''
        this.state.selectedRowKeys.map(res => {
            noRegistrasi += (','+res)
        })
        await axios.get(CONFIG_INITIAL_STATE.BASE_URL + api + '?noRegistrasi='+noRegistrasi.substr(1), this.config()).then(res => {
            if(res.data){
              console.log('data url : ',res.data.url)
              new Downloader({url: res.data.url}).then(file => {
              }).catch(err => {console.log(err)})
            }
          }).catch(err => {
            dialog({icon: 'error', text: 'Terjadi Kesalahan Pada Server'})
            // reject(err)
          } );
    }

    downloadPdf = async(params) => {
        await LoyolaServices.getDownloadDataSiswaPdf(params).then(res => {
        if(res){
            new Downloader({url: res.data.uri}).then(file => {
            }).catch(err => {console.log(err)})
            dialog({icon: 'success', text: 'Data Calon Siswa berhasil di-download'})
            this.setState({
                loading: false
            })
        }
        }).catch(err => {
        if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            this.setState({
                loading: false
            })
        }
        })
    }

    tagColor = (tag) => {
        if(tag !== null){
            tag = tag.toLowerCase();
            switch (tag) {
                case 'lolos':
                case 'diterima':
                case 'sudah daftar ulang':
                    return '#219653';
                case 'belum daftar ulang':
                case 'belum di-test':
                case 'belum diwawancara':
                    return '#F2994A';
                case 'cadangan':
                case 'ragu2':
                case 'ragu-ragu':
                    return '#F2C94C';
                case 'tidak lolos':
                case 'ditolak':
                case 'tidak diterima':
                case 'mengundurkan diri':
                    return '#EB5757';
                default:
                    return '#072A6F';
            }
        }

    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
        });
    };

    handleJalur = (jalur) => {
        this.setState({
            jalur:jalur,
            pagination: {
                ...this.state.pagination,
                current: 1
            },
            search: '',
            sort: "pn.no_registrasi,desc",
        },() => {
            if(jalur != 'Report'){
                this.getDataCalonSiswa(
                    this.state.pagination.current - 1,
                    this.state.pagination.pageSize,
                    this.state.sort,
                    this.state.search
                )
            }
        })
    };

    columns = [
        {
            title: 'No. Registrasi',
            dataIndex: 'noRegistrasi',
            key: 'pn.no_registrasi',
            sorter: true
        },
        {
            title: 'Nama',
            dataIndex: 'namaSiswa',
            key: 'nama_siswa',
            sorter: true
        },
        {
            title: 'Asal Sekolah',
            dataIndex: 'asalSekolah',
            key: 'sekolah_asal',
            sorter: true
        },
        {
            title: 'Skor Ujian',
            dataIndex: 'skorTes' ,
            key: 'hts.skor_tes',
            sorter: true
        },
        {
            title: 'Rekomendasi Wawancara',
            dataIndex: 'rekomedasiWawancara',
            key: 's_rekomendasi_wawancara.status',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        },
        {
            title: 'Rekomendasi Beep Test',
            dataIndex: 'rekomedasiBip',
            key: 's_rekomendasi_bip_tes.status',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        },
        {
            title: 'Status Keputusan',
            dataIndex: 'keputusan',
            key: 'pn.keputusan',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        }
    ];
    columnsPrestasi = [
        {
            title: 'No. Registrasi',
            dataIndex: 'noRegistrasi',
            key: 'pn.no_registrasi',
            sorter: true
        },
        {
            title: 'Nama',
            dataIndex: 'namaSiswa',
            key: 'nama_siswa',
            sorter: true
        },
        {
            title: 'Asal Sekolah',
            dataIndex: 'asalSekolah',
            key: 'sekolah_asal',
            sorter: true
        },
        {
            title: 'Rekomendasi Wawancara',
            dataIndex: 'rekomedasiWawancara',
            key: 's_rekomendasi_wawancara.status',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        },
        {
            title: 'Rekomendasi Beep Test',
            dataIndex: 'rekomedasiBip',
            key: 's_rekomendasi_bip_tes.status',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        },
        {
            title: 'Status Keputusan',
            dataIndex: 'keputusan',
            key: 'pn.keputusan',
            sorter: true,
            render: tag => {
                if (tag === undefined || tag === null) tag = "";
                let color = this.tagColor(tag);
                return (
                    <Text style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        }
    ];

    render() {
        const { columns, data, loading, pagination, jalur, search, totalDataCalonSiswaEksekutif, totalCalonSiswa, isDownload, isLolos, isTidakLolos } = this.state;
        const {responsive} = this.props;
        const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] };
        const rowSelection = { onChange: this.onSelectChange, };
        const dropdownLabel = menuKey.find(e => e.key === jalur).label;


        return(
            <Content className="contentContainer">
                {responsive.grid.md ? (
                    <Row>
                        <Col>
                            <Space size={"large"}>
                                {menuKey.map(({key, label}) => (
                                    <a type="text" onClick={() => {this.handleJalur(key)}} className="text-heading">
                                        <Title level={3} style={{color: jalur === key ? '#072a6f' : '#BDBDBD' }}>
                                            {label}
                                        </Title>
                                    </a>
                                ))}
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <div style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                        <Dropdown 
                            overlay={(
                                <Menu onClick={e => this.handleJalur(e.key)}>
                                    {menuKey.map(({key, label}) => (
                                        <Menu.Item key={key}>{label}</Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <Button style={{width: '100%', marginBottom: '24px'}}> {dropdownLabel}<DownOutlined/> </Button>
                        </Dropdown>
                    </div>
                )}
                {
                    jalur == 'Report' ? 
                    <Row style={{marginTop: 20}}>
                        <Col>
                            <Space>
                                <Button className="app-btn" type="primary" style={{backgroundColor: '#072A6F'}}
                                    onClick={this.handleDownload}
                                    disabled={loading}
                                    loading={isDownload}
                                >
                                    Download All Report
                                </Button>
                            </Space>
                        </Col>
                    </Row> :
                    <div>
                        <Row justify="center" align='middle'>
                            <Col span={24} lg={6} md={8}>
                                <Input
                                    className="input-search"
                                    placeholder="No. Registrasi"
                                    style={{
                                        width: '100%',
                                        marginRight: '10px',
                                        textAlign: 'left',
                                        marginBottom: '24px'
                                    }}
                                    prefix={<SearchOutlined/>}
                                    onChange={(e) => this.handleSearch(e)}
                                    value={search}
                                />
                            </Col>
                            <Col span={24} lg={18} md={16} style={{textAlign: 'right'}}>
                                <Space style={{flexWrap: 'wrap'}}>
                                    <Button className="app-btn" type="primary" style={{backgroundColor: '#072A6F', marginBottom: '12px'}}
                                        onClick={this.handleDownloadList}
                                        disabled={loading}
                                        loading={isDownload}
                                    >
                                        Download
                                    </Button>
                                    <Button className="app-btn" type="primary" style={{backgroundColor: '#219653', marginBottom: '12px'}}
                                        onClick={() => this.handleSubmit(1)}
                                        disabled={loading}
                                        loading={isLolos}
                                    >
                                        Ubah menjadi Lolos
                                    </Button>
                                    <Button className="app-btn" type="primary" style={{backgroundColor: '#EB5757', marginBottom: '12px'}}
                                        onClick={() => this.handleSubmit()}
                                        disabled={loading}
                                        loading={isTidakLolos}
                                    >
                                        Ubah menjadi Tidak Lolos
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={24} style={{textAlign: 'right'}}>
                                <Text className="title-verifikator">{totalDataCalonSiswaEksekutif} dari {totalCalonSiswa} calon siswa telah diberi keputusan</Text>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 10, marginTop:24}}>
                            <Col span={24}>
                                <Card
                                    bordered={false}
                                    style={{overflowY: 'auto'}}
                                    className="app-card"
                                >
                                    <Table
                                        rowSelection={rowSelection}
                                        columns={jalur == 'Prestasi'? this.columnsPrestasi : columns}
                                        size="middle"
                                        bordered={false}
                                        dataSource={data}
                                        loading={loading}
                                        pagination={paginationCus}
                                        onChange={this.handleTableChange}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </Content>
        );
    }
}

export default withRouter(Eksekutif)