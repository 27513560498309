import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Card, Space, InputNumber,Layout, Spin} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import ImagePreviewComponent from "../../../component/image-preview";
import Dummy from "../../../dummy";

const { Content } = Layout;
const { Text,Title } = Typography

const CardMatpel = (props) => {
    return (
      <Row style={{marginBottom: '1em', justifyContent: 'center'}}>
        <Col flex="auto" style={{textAlign: 'left'}}>
          <Title level={4} style={{margin: 0, lineHeight: '32px'}}>{props.matpel}</Title> 
        </Col>
        <Col span={8} lg={4} md={6} sm={8}>
          <Form.Item name={"skor"+props.index}
            rules={[
              {
                required: true,
                message: 'Skor harus diisi',
              },
            ]}
            style={{margin: '0px'}}
          >
            <InputNumber
              min={0}
              max={100}
              style={{textAlign: 'center', margin: 0, width: '100%'}}
              placeholder="Skor"
            />
          </Form.Item>
        </Col>
      </Row>
    )
    
}

const DataSiswa = (props) => {
  return(
    <Card bordered={false} className="app-card">
      <Row>
        <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
          <Title level={4} style={{color: '#072A6F'}}>Data Siswa</Title>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              No. Registrasi
            </Col>
            <Col>
              <Title level={4} >{props.dataRegister.noRegistrasi}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Nama
            </Col>
            <Col>
              <Title level={4} > {props.dataRegister.namaSiswa} </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const InputNilaiDetail = (props) => {

    const [form] = Form.useForm();
    const [totalSkor, setTotalSkor] = useState(0);

    const [data, setData] = useState([])
    const [nilaiUjian, setNilaiUjian] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isLoadingSubmit, setLoadingSubmit] = useState(false)

    useEffect(() => {
      getDataDummy()
      getDataLovMatpel()
      // getDataNilaiId()
    },[])

    const getDataDummy = () => {
      setData(Dummy.adminDataLovMatpel.data)
    }

    const onFinish = (values) => {
      setLoadingSubmit(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        nilaiUjian: nilaiUjian,
        userId: userId
      }
      if(props.isEdit){
        console.log('edit')
        LoyolaServices.putNilaiUjian(props.dataRegister.noRegistrasi,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Nilai Ujian Akademik berhasil diubah'}).then(()=>{
              props.goToList()
            })
          }
        })
      }else{
        LoyolaServices.postNilaiUjian(props.dataRegister.noRegistrasi,body).then(res => {
          if(res.data){
            setLoadingSubmit(false)
            dialog({icon: 'success', text:'Nilai Ujian Akademik berhasil disimpan'}).then(()=>{
              props.goToList()
            })
          }
        }).catch(err => {
          if(err){
            setLoadingSubmit(false)
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          }
        })
      }
      console.log('Success: ',body);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const getDataLovMatpel = () => {
      LoyolaServices.getDataLovMatpel().then(res => {
        console.log("response data ",res.data)
        if(res.data){
          setData(res.data.data)
          setLoading(false)
          // getDataNilaiId()
        }
      }).catch(err => {
        if(err){
          dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
        }
      })
    }

    const getDataNilaiId = () => {
      LoyolaServices.getInputNilaiUjianByRegistrasi(props.dataRegister.noRegistrasi).then(res => {
        console.log("response data ",res.data)
        // if(res.data){
        //   setData(res.data.data)
        // }
      }).catch(err => {
        if(err){
          dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
        }
      })
    }

    const handleReset = ()=>{
      form.resetFields() ;
      setTotalSkor(0);
    }

    const handleKembali = ()=>{
      props.goToList()
    }

    const onChangeFormValue = () => {
      let tempData = []
      let tempValue = form.getFieldsValue()
      let totalSkor = 0
      data.map((res,index) => {
        let skorUjian = tempValue['skor'+index] ? parseFloat(tempValue['skor'+index]) : 0
        totalSkor +=skorUjian
        tempData.push({
          matpelId: res.key,
          skorUjian: skorUjian
        })
      })
      setTotalSkor(totalSkor/data.length)
      setNilaiUjian(tempData)
      console.log('data temp adalah : ',tempData, totalSkor)
    }

    const mappingDataEdit = (response) => {
      let tempData = []
      let tempDataForm = {}
      let tempValue = response
      let totalSkor = 0
      data.map((res,index) => {
        let skorUjian = res.skorUjian
        totalSkor +=skorUjian
        tempData.push({
          matpelId: res.key,
          skorUjian: skorUjian
        })
        tempDataForm['skor'+index] = res.skorUjian
      })

      form.setFieldsValue(tempDataForm);
      setTotalSkor(totalSkor/data.length)
      setNilaiUjian(tempData)
    }

    return(
      <div style={{marginTop:20}}>
        <Spin spinning={isLoading}>
          <Row gutter={[20,20]}>
            <Col span={props.responsive.isMobile ? 24 : 8}>
              <Row>
                <Col span={24}>
                  { <DataSiswa dataRegister={props.dataRegister} nama={'samuel'}/> }
                </Col>
              </Row>
            </Col>
            <Col span={props.responsive.isMobile ? 24 : 16} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="Review Soal"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                onChange={onChangeFormValue}>
                <Card bordered={false} className="app-card" style={{marginBottom: 10}}>
                  { data.length > 0 && data.map((res,index) => (
                    <>
                      <CardMatpel
                        responsive={props.responsive}
                        matpel={res.value} 
                        index={index}
                      />
                      {(index < data.length-1) && <div style={{width: '100%', borderBottom: '2px solid #d9d9d9', marginBottom: '1em'}} />}
                    </>
                  ))
                  }
                </Card>
                <Form.Item>
                  <Card className="app-card">
                    <Row gutter={[20,20]}>
                      <Col span={props.responsive.isMobile ? 12 : 6} style={{textAlign: 'left'}} >
                        <Title level={4} >Skor Rata-Rata</Title> 
                      </Col>
                      <Col span={props.responsive.isMobile ? 8 : 6} style={{textAlign: 'right'}}>
                        <InputNumber
                          size="large"
                          value={totalSkor.toFixed(2)}
                          min={0}
                          max={100}
                          style={{width: '100%'}}
                          placeholder="skor"
                          disabled={true}
                        />
                      </Col>
                      <Col span={props.responsive.isMobile ? 24 : 12} style={{textAlign: 'right'}}>
                        <Space size="large">
                          <Button onClick={handleKembali}
                            className="app-btn danger"
                          >
                            Batal
                          </Button>
                          <Button onClick={handleReset}
                            type="text"
                          >
                            Reset
                          </Button>
                          <Button 
                            type="primary"
                            htmlType="submit" 
                            className="app-btn primary"
                            loading={isLoadingSubmit}
                          >
                            Simpan
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </div>
    );
};

export default withRouter(InputNilaiDetail);