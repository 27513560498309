import {message} from "antd";
import CONFIG from '../../service/config'

const HandleRole = (role) => {

    if(role === "Verifikator"){
        console.log('verifikator')
        return '/verifikator-jalur-prestasi';
    }else if(role === "Admin Finance"){
        return '/finance'; 
    }else if(role === "Interviewer"){
        console.log('Interviewer')
        return '/interviewer-jalur-prestasi'; 
    }else if(role === "Beep Tester"){
        console.log('Bip Tester')
        return '/beeptester-jalur-prestasi';
    }else if(role === 'Executive'){
        console.log('Executive')
        return '/executive';
    }else if(role === 'Admin'){
        console.log('admin')
        return '/admin';
        // history.push('/admin'); //belum ada
    }else if(role === 'Admin Seleksi'){
        console.log('admin-seleksi')
        return '/seleksi';
        // history.push('/admin'); //belum ada
    }else if(role === 'Admin Ujian Akademik'){
        console.log('admin-ujian')
        return '/ujian';
        // history.push('/admin'); //belum ada
    }else{
        console.log('siapa kamu : ',role)
        message.info('anda sudah login namun screen admin belum tersedia');
        return '/';
        // history.push('/admin'); //belum ada
    }
};

export default HandleRole;