import { Button, Col, Input, Modal, Row, Space, Form, Radio, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { confirmDialog, dialog } from '../../functions/alert';
import { ArrowDownOutlined } from '@ant-design/icons';
import { FinanceService } from '../Finance/finance-service';
import Downloader from "js-file-downloader";
import { RedStar } from '../../component/common';

EditKeputusan.propTypes = {
  onCancel: PropTypes.func,
};

EditKeputusan.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const normalListBerkas = [
  {key: 'berkasSertifikat', label: 'Berkas Sertifikat'},
];

export default function EditKeputusan(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm(); 
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState({});

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setIsLoading(true);
    // await FinanceService.getVerifikasiBeasiswaPrestasi(data.komitSiswaId).then(res => {
    //   const verifikasiData = res.data.data;
    //   console.log(verifikasiData)
      form.setFieldsValue({
        ...data,
        statusSeleksi: data.statusSeleksi !== 'Belum diverifikasi' ? data.statusSeleksi == 'Lolos' ? 1 : 2 : null,
        catatanSeleksi: data.catatanSeleksi
      });
      setLoadedData(data);
    // }).catch(err => { 
    //   dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    // })
    setIsLoading(false);
  }

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        text: 'Apakah anda yakin ingin mengubah keputusan seleksi beasiswa prestasi Seni dan Olahraga',
        title: 'Simpan keputusan?'
      }).then(e => {
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const body = {
          userId: userId,
          beasiswaSiswaId: data.beasiswaSiswaId,
          kemampuanId: data.kemampuanId,
          ...form.getFieldsValue()
        }
        FinanceService.editKeputusanSeleksi(body).then(res => {
          dialog({title: 'Keputusan seleksi telah tersimpan', icon: 'success'}).then(_ => {
            onFinish()
          });
        }).catch(err => {
          dialog({title: 'Gagal mengubah keputusan seleksi', icon: 'error'})
        })
      }).catch(e => {})
    }).catch(err => {

    })
  }

  const handleDownload = (fileKey) => {
    console.log(fileKey, loadedData[fileKey])
    new Downloader({url: loadedData[fileKey]}).then(file => {
    }).catch(err => {
      console.log(err);
      dialog({title: 'Gagal mengunduh file', icon: 'error'})
    })
  }

  const isFormDisabled = !!data.statusSeleksi && data.statusSeleksi !== 'Belum diverifikasi';
  const listBerkas = normalListBerkas;
  
  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
        <Row>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Keputusan seleksi beasiswa prestasi Seni dan Olahraga</Title>
          </Col>
          <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
            {data.noRegistrasi} - {data.namaSiswa}
          </Col>
          <Col span={24} style={{fontSize: '1.2em', paddingBottom: '.5em',  ...borderedBottom}}>
            <Space direction="vertical" size={0}>
              {listBerkas.map(berkas => {
                const isDownloadEnabled = !!loadedData[berkas.key]
                return (
                  <Button type="link" style={{padding: '0px'}} disabled={!isDownloadEnabled} onClick={_ => handleDownload(berkas.key)}>
                    <ArrowDownOutlined />Download {berkas.label}
                  </Button>
                )
              })}
            </Space>
          </Col>
          <Col span={24}>
            <Form form={form} onValuesChange={e => {console.log(form.getFieldsValue())}}>
              <Text>Status <RedStar/></Text>
              <Form.Item
                name="statusSeleksi"
                rules={[{ required: true, message: 'Status harus dipilih!' }]}
                style={{marginBottom: '1em'}}
              >
                <Radio.Group disabled={isFormDisabled}>
                  <Radio value={1}>Lolos</Radio>
                  <Radio value={2}>Tidak Lolos</Radio>
                </Radio.Group>
              </Form.Item>
              <Text>Catatan</Text>
              <Form.Item name="catatanSeleksi">
                <Input.TextArea className="app-input" disabled={isFormDisabled}/>
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <Space className="end" style={{width: '100%'}}>
              {!isFormDisabled && <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>}
              <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
            </Space>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
