import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const {Option} = Select;

DropdownComponent.propTypes = {
  classname: PropTypes.string,
  data: PropTypes.array,
  dropdownClassName: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string || PropTypes.number,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  optionClassName: PropTypes.string,
  dropdownStyle: PropTypes.object,
  isNoStyle: PropTypes.bool
};

DropdownComponent.defaultProps  = {
  classname: "custom-dropdown-admin",
  data: [],
  dropdownClassName: "",
  style: {},
  placeholder: "-- Pilih --",
  optionClassName: "custom-dropdown",
  dropdownStyle: {backgroundColor: "#072A6F"},
  isNoStyle: false
};

function DropdownComponent(props) {

  return (
      <Select
          className= {!props.isNoStyle && "custom-select"}
          placeholder={props.placeholder}
          dropdownClassName={!props.isNoStyle && props.dropdownClassName}
          defaultValue={props.defaultValue}
          style={props.style}
          dropdownStyle={!props.isNoStyle && props.dropdownStyle}
          onChange={(value)=>props.onChange(value)}>
        {
          props.data.map(res => {
            return <Option key={res.key + res.value} className={!props.isNoStyle && props.optionClassName} style={{minWidth: 100}} value={res.key}>{res.value}</Option>
          })
        }
      </Select>
  );
}

export default DropdownComponent;