import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Modal, Row, Typography, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
import Title from 'antd/lib/typography/Title';
import Dummy from "../../../dummy";
import { dialog } from "../../../functions/alert";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import { SearchOutlined } from "@ant-design/icons";
import Downloader from "js-file-downloader";

const { Text } = Typography;

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const DaftarSiswa = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('total,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [keterangan, setKeterangan] = useState('')
  const [loadingMatpel, setLoadingMatpel] = useState(false);
  const [paginationMatpel, setPaginationMatpel] = useState({current: 1, pageSize: 10});
  const [sortMatpel, setSortingMatpel] = useState('skor,desc');
  const [dataListMatpel, setDataListMatpel] = useState(null);
  const [dataSiswa, setDataSiswa] = useState({ noRegistrasi : '', namaSiswa: ''})
  const [idSiswa, setIdSiswa]= useState(null)
  const [isVisible, setVisible] = useState(false)

  useEffect(()=>{
    getData(pagination,sort,search)
    // getDataDummy()
  }, []);

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearch(key);
  };

  const processSearch = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
			sorter.order = "asc";
    } else if (sorter.order === "descend") {
			sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'total,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const handleTableChangeMatpel = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
			sorter.order = "asc";
    } else if (sorter.order === "descend") {
			sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'skor,desc'}`
    setSortingMatpel(sorting)
    setPaginationMatpel(pagination)
    getDataPenerimaan(idSiswa ,pagination, sorting)
  };

  const getData = (page,sort,search) => {
    setLoading(true)
    LoyolaServices.getDaftarSiswa(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        setKeterangan(res.data.keterangan)
        var data = res.data.data
        console.log(data)
        setDataList(data)
        setPagination({
          ...pagination,
          current: page.current,
          total: res.data.total
        })
        setLoading(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        setDataList([])
        setLoading(false)
      }
    })
  }

  const getDataPenerimaan = (id, page, sort) => {
    setLoadingMatpel(true)
    LoyolaServices.getNilaiSiswa(id, page.current - 1, page.pageSize, sort).then(res => {
      if(res.data){
        var data = res.data.data
        setDataListMatpel(data.dataNilaiUjianDTO)
        setDataSiswa(data)
        setPaginationMatpel({
          ...paginationMatpel,
          current: page.current,
          total: res.data.total
        })
        setLoadingMatpel(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        setDataListMatpel([])
        setDataSiswa({ noRegistrasi : '', namaSiswa: ''})
        setLoadingMatpel(false)
      }
    })
  }

  const handleDataPopup = data => {
    const page = {
      current: 1,
      pageSize: 10
    }
    setIdSiswa(data.penerimaanId)
    getDataPenerimaan(data.penerimaanId, page, sortMatpel)
    setShow(true)
  }

  const tagColor = (tag) => {
    tag = tag.toLowerCase();
    switch (tag) {
      case 'nilai belum lengkap':
        return '#EB5757'; //merah
      default:
        return '#219653'; //hijau
    }
  };

  const setShow = visible => {
    setVisible(visible)
    if (!visible) {
      setSortingMatpel('skor,desc')
      setPaginationMatpel({
        current: 1, 
        pageSize: 10
      })
    }
  }

  const handleDownload = () => {
    setLoadingButton(true)
    LoyolaServices.getReport().then(res => {
      if (res.data.Status === 'OK') {
        new Downloader({ url : res.data.Link})
        dialog({icon: 'success', text: 'Download Sukses'})
        setLoadingButton(false)
      }else {
        dialog({icon: 'error', text: 'Terjadi kesalahan pada server'})
        setLoadingButton(false)
      }
    }).catch(err => {
        if(err){
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoadingButton(false)
        }
      })
  }

  const columns = [
    {
			title: 'No. Registrasi',
			dataIndex: 'noRegistrasi',
			key: 'no_registrasi',
			sorter: true,
    },
    {
			title: 'Nama Siswa',
			dataIndex: 'namaSiswa',
			key: 'cs.nama_siswa',
			sorter: true,
    },
    {
			title: 'Mata Pelajaran',
			render: (record) => {
        return (
          <Button className="app-btn secondary" onClick={() => handleDataPopup(record)}>
            Detail
          </Button>
        );
      },
    },
    
    {
			title: 'Total',
			dataIndex: 'totalSkor',
			key: 'total',
			sorter: true,
    },
    {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sorter: true,
      render: tag => {
        if (tag !== null){
          let color = tagColor(tag);
          return (
            <Text color={color} style={{color: color}}>
              {tag.toUpperCase()}
            </Text>
          )
        }
      },
    },
  ];

  const columnsMatpel = [
    {
			title: 'Mata Pelajaran',
			dataIndex: 'matpel',
			key: 'matpel_sikap',
			sorter: true,
    },
    {
			title: 'Nilai',
			dataIndex: 'skor',
			key: 'skor',
			sorter: true,
    },
  ]


	const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

	const paginationCusMatpel = { ...paginationMatpel, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  return(
    <div>
      <Row>
        <Col span={24} lg={6} md={8}>
          <Input
            className="input-search"
            placeholder="No. Registrasi"
            style={{
                width: '100%',
                marginRight: '10px',
                textAlign: 'left',
                marginBottom: '24px',
                marginTop: '20px'
            }}
            prefix={<SearchOutlined/>}
            onChange={(event) => {handleSearch(event)}}
          />
        </Col>
        <Col style={{textAlign: 'right'}} span={24} lg={18} md={16}>
				  <Button 
            className="app-btn" 
            type="primary"
            loading={loadingButton}
            style={{backgroundColor: '#072A6F'}}
            onClick={() => handleDownload()}
          >
            Report
          </Button>
        </Col>
        <Col style={{textAlign: 'right'}} span={24} lg={24} md={24}>
					<span className='title-verifikator'>
						{keterangan}
					</span>
        </Col>
      </Row>
      <Row style={{marginTop: 24}}>
        <Col span={24}>
          <Card bordered={false} className="app-card">
            <Table
							columns={columns}
							size="middle"
							bordered={false}
							pagination={paginationCus}
							onChange={handleTableChange}
							loading={loading}
							dataSource={dataList}
              scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        className="app-modal"
        visible={isVisible}
        onCancel={()=>setShow(false)}
        footer={null}
        closeIcon={null}
        width="500px"
        closable={false}
      >
        <Row style={{marginTop: 24}}>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Detail Mata Pelajaran</Title>
          </Col>
          <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
            {dataSiswa.noRegistrasi} - {dataSiswa.namaSiswa}
          </Col>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columnsMatpel}
                size="middle"
                bordered={false}
                pagination={paginationCusMatpel}
                onChange={handleTableChangeMatpel}
                loading={loadingMatpel}
                dataSource={dataListMatpel}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
              />
            </Card>
          </Col>
          <Col span={24} style={{width: '100%', marginTop: 20, textAlign: 'center'}}>
            <Button className="app-btn primary padded" onClick={() => setShow(false)}>OK</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default withRouter(DaftarSiswa);