import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Row, Typography, Button, InputNumber, Form, Table, Card, TimePicker, DatePicker, Input} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { dialog } from "../../../functions/alert";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import { SearchOutlined } from "@ant-design/icons";
import moment from 'moment'


const JadwalUjian = (props) => {
  const format = 'HH:mm';

  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [tempData, setTempData] = useState([])
  const [initValues, setInit] = useState({})
  const [form] = Form.useForm();

  useEffect(()=>{
    getData()
    // getDataDummy()
  }, []);

  const getData = () => {
    setLoading(true)
    LoyolaServices.getJadwalMatpel().then(res => {
      if(res.data){
        var data = res.data.data
        let tempObj = data.reduce((res, key) => {
          res[key.matpelSikap] = key.durasiUjian
          return res
        }, {})
        setInit(tempObj)
        setDataList(data)
        setTempData(data)
        setLoading(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        setLoading(false)
      }
    })
  }

  const handleTimechange = (e, id) => {
    let tempList = []
    tempData.map(data => {
      if (id === data.matpelId) {
        tempList.push({
          matpelId: data.matpelId,
          matpelSikap: data.matpelSikap,
          waktuUjianStart: data.waktuUjianStart,
          durasiUjian: e,
          tglUjian: data.tglUjian,
        })
      }else {
        tempList.push({
          matpelId: data.matpelId,
          matpelSikap: data.matpelSikap,
          waktuUjianStart: data.waktuUjianStart,
          durasiUjian: data.durasiUjian,
          tglUjian: data.tglUjian
        })
      }
    })
    setTempData(tempList)
    setDataList(tempList)
  }

  const handleSubmit = () => {
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    setLoading(true)
    LoyolaServices.putJadwalMatpel(userId, tempData).then(res => {
      if(res.data){
        dialog({icon: 'success', text:'Durasi Ujian Akademik berhasil diubah'}).then(()=>{
          getData()
        })
      } 
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        setLoading(false)
      }
    })
  }

  return(
    <div style={{marginTop:20}}>
      <Row>
        <Col span={24}>
          <Card 
            bordered={false}
            className="app-card"
            loading={loading}
          >
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Form
                  form={form}
                  name="card-pg"
                  onFinish={handleSubmit}
                  initialValues={initValues}
                >
                  <Row gutter={[20, 20]}>

                    {
                      dataList.length > 0 ?
                      (
                        <>
                          {dataList.map(res => (
                            <>
                              <Col span={24}>
                                <span className='text-label-matpel'>
                                  {res.matpelSikap}
                                </span>
                              </Col> 
                              <Col 
                                // style={{ alignSelf: 'center'}} 
                                span={props.responsive.isMobile ? 24 : 8}
                              >
                                <span className='text-label-waktu'>
                                  Waktu pelaksanaan
                                </span>
                              </Col>
                              <Col 
                                // style={{ alignSelf: 'center'}} 
                                span={props.responsive.isMobile ? 6 : 6}
                              >
                                <span className='text-label-waktu'>
                                  {`${moment(res.tglUjian).format('LL')} ${res.waktuUjianStart}`} 
                                </span>
                              </Col>
                              <Col 
                                style={{
                                  textAlign: 'right', 
                                  // alignSelf: 'center'
                                }} 
                                span={props.responsive.isMobile ? 6 : 2}
                              >
                                <span 
                                  className='text-label-waktu'
                                  >
                                  Durasi
                                </span>
                              </Col>
                              <Col span={props.responsive.isMobile ? 6 : 4} style={{alignSelf: 'center'}}>
                                <Form.Item
                                  name={res.matpelSikap}
                                  // noStyle={true}
                                  rules={[{required: true, message: 'Durasi harus diisi' }]}
                                >
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder='Menit'
                                    value={res.durasiUjian}
                                    min={1}
                                    step={1}
                                    parser={value => value.replace('.', '')}
                                    // suffix='Menit'
                                    onChange={(e) => handleTimechange(e, res.matpelId)}
                                    />
                                </Form.Item>
                              </Col>
                              <Col style={{
                                textAlign: 'left', 
                                // alignSelf: 'center'
                              }} 
                              span={props.responsive.isMobile ? 6 : 2}>
                                <span 
                                  className='text-label-waktu'
                                  >
                                  Menit
                                </span>
                              </Col>
                            </>
                          ))}
                          <Col span={24} style={{textAlign: 'right'}}>
                            <Form.Item>
                              <Button
                                className="app-btn" 
                                type='primary' 
                                style={{backgroundColor: '#EF712B', color: '#fff'}}
                                htmlType="submit"
                                // onClick={() => handleSubmit()}
                                >
                                Ubah
                              </Button>
                            </Form.Item>
                          </Col>
                        </>
                      ) 
                      :
                      (
                        <Col span={24} style={{textAlign: 'center'}}>
                          <span className='text-label-matpel'>
                            Data Jadwal Ujian Akademik Tidak di temukan
                          </span>
                        </Col>
                      )
                    }
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(JadwalUjian);