import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Radio, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import UploadComponent from "../../../component/upload-component-image-preview";
import CONFIG from '../../../service/config'
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography
const alfabet=['A','B','C','D','E']

const CardJawaban = (props) => {
  const [isTeks, setTeks] = useState(true);

  const onChange = e => {
    let value = e.target.value
    setTeks(value)
    props.onChange({huruf: props.huruf, value: value})
  };

  useEffect(() => {
    if(props.isEdit){
      alfabet && alfabet.map(res => {
        let checkData = props.isEdit['jawabanTipeTeksPil'+res] !== null && props.isEdit['jawabanTipeTeksPil'+res] !== undefined && !props.isEdit['jawabanTipeTeksPil'+res]
        if(props.huruf == res){
          setTeks(checkData ? false : true)
        }
      })
    }
  }, [props.isEdit])

  return(
    <Row>
      <Col offset={2} span={22} style={{textAlign: 'left'}}>
        <Form.Item
          name={"jawabanTipeTeksPil"+props.huruf}
        >
          <Radio.Group onChange={onChange} value={"jawabanTipeTeksPil"+props.huruf} defaultValue={true}>
            <Radio value={true}>Teks</Radio>
            <Radio value={false}>Gambar</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row gutter={[20,0]}>
          <Col span={2}>
            {props.huruf}
          </Col>
          <Col span={20}>
            <Form.Item
              name={"pilihan"+props.huruf}
            >
              {
                !isTeks ?
                <UploadComponent fluidBtn
                  showPreview = {true}
                  classBtn='app-btn primary'
                  url={CONFIG.BASE_URL_NOAUTH+'/api/upload/image?path=soalKey'+props.huruf}
                  title="Upload Soal Gambar"
                  onChange={_ => {}}
                /> :
                <Input
                    style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                    placeholder="jawaban"
                />
              }
            </Form.Item>
          </Col>
          <Col span={2}>
            <CheckCircleOutlined onClick={()=>props.onSelected(props.huruf)} style={{color: props.isSelected ? '#389e0d' : '#dedede', fontSize: 24}}/>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const FormPertanyaanPG = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState('A')
    const [listAlfabet, setListAlfabet] = useState([])

    useEffect(()=>{
      setListAlfabet(alfabet)
      if(props.isEdit){
        setSelected(props.isEdit.jawabanGanda)
        let dataTemp = props.isEdit;
        alfabet.map(res => {
          if(dataTemp['jawabanTipeTeksPil'+res] == true){
            dataTemp['pilihan'+res] = dataTemp['pilihan'+res] ? dataTemp['pilihan'+res].fileName : ''
          }
        })
        form.setFieldsValue(dataTemp)
      }else{
        form.resetFields()
      }
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId

      const jawabanTipeTeksPilA = values.jawabanTipeTeksPilA != undefined ? values.jawabanTipeTeksPilA : true;
      const jawabanTipeTeksPilB = values.jawabanTipeTeksPilB != undefined ? values.jawabanTipeTeksPilB : true;
      const jawabanTipeTeksPilC = values.jawabanTipeTeksPilC != undefined ? values.jawabanTipeTeksPilC : true;
      const jawabanTipeTeksPilD = values.jawabanTipeTeksPilD != undefined ? values.jawabanTipeTeksPilD : true;
      const jawabanTipeTeksPilE = values.jawabanTipeTeksPilE != undefined ? values.jawabanTipeTeksPilE : true;

      let body = {
        matpelId: props.matpel.key,
        gambar: values.gambarGanda ? values.gambarGanda.fileName : null,
        isTeks: values.gambarGanda ? false : true,
        pertanyaan: values.pertanyaanGanda,
        jawabanTipeTeksPilA: jawabanTipeTeksPilA,
        jawabanTipeTeksPilB: jawabanTipeTeksPilB,
        jawabanTipeTeksPilC: jawabanTipeTeksPilC,
        jawabanTipeTeksPilD: jawabanTipeTeksPilD,
        jawabanTipeTeksPilE: jawabanTipeTeksPilE,
        pilihanA: jawabanTipeTeksPilA ? values.pilihanA : values.pilihanA.fileName ,
        pilihanB: jawabanTipeTeksPilB ? values.pilihanB : values.pilihanB.fileName,
        pilihanC: jawabanTipeTeksPilC ? values.pilihanC : values.pilihanC.fileName,
        pilihanD: jawabanTipeTeksPilD ? values.pilihanD : values.pilihanD.fileName,
        pilihanE: jawabanTipeTeksPilE ? values.pilihanE : values.pilihanE.fileName,
        jawaban: selected
      }

      if(props.isEdit){
        body.id = props.isEdit.soalGandaId
        LoyolaServices.putPertanyaanPg(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Pertanyaan Telah Diubah'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }else{
        LoyolaServices.postPertanyaanPg(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Pertanyaan Telah Disimpan'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const handleReset = (e) =>{
      let data = {
        ...form.getFieldsValue(),
        ['pilihan'+e.huruf]: '',
        ['jawabanTipeTeksPil'+e.huruf]: e.value
      }
      form.setFieldsValue(data)
    }

    const handleDestroy = () =>{
      form.resetFields()
      props.onCancel()
      setListAlfabet([])
    }

    return(
        <Modal 
          visible={props.isShow}
          onCancel={handleDestroy}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Soal Pilihan Ganda</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="input PG"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                  <Row style={{alignItems: 'center'}}>
                    <Col>
                      <Text>Mata Pelajaran :</Text>
                    </Col>
                    <Col>
                      <Title level={4} style={{color: '#072A6F', marginTop: 8, marginLeft: 5}}> {props.matpel && props.matpel.value}</Title>
                    </Col>
                </Row>
                <Form.Item name="pertanyaanGanda"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Pertanyaan',
                    },
                  ]}
                >
                  <Input.TextArea
                      maxLength={255}
                      style={{height: 170, backgroundColor: "#F2F2F2", borderRadius: 16}}
                      placeholder="Pertanyaan"
                  />
                </Form.Item>
                <Form.Item
                  name="gambarGanda"
                >
                  <UploadComponent fluidBtn
                    showPreview = {true}
                    classBtn='app-btn primary'
                    accept=".jpg,.jpeg,.pdf"
                    url={CONFIG.BASE_URL_NOAUTH+'/api/upload/image?path=pgSoal'}
                    title="Upload Gambar Soal"
                    onChange={_ => {}}
                  />
                </Form.Item>
                {
                  listAlfabet && listAlfabet.map((res,index) => {
                    return <CardJawaban 
                    onChange={handleReset}
                    huruf={res} key={index} 
                    isEdit={props.isEdit} 
                    onSelected={(e) => setSelected(e)} 
                    isSelected={selected == res}/>
                  })
                }
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={handleDestroy} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        {/* { props.isEdit ? 'Ubah' : 'Tambah'} */}
                        Simpan
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormPertanyaanPG);