import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {
    InputNumber,
    Col,
    Layout,
    Row,
    Typography,
    Divider,
    Button,
    Space,
    Form,
    List,
    Spin,
    Modal
} from "antd";
import XLSX from 'xlsx';
import { make_cols } from '../Admin/component/MakeColumns';
import { SheetJSFTExcel } from '../Admin/component/types';
import { LoyolaServices } from '../../service';
import { LeftOutlined } from "@ant-design/icons";
import { AppLoading } from '../../component/loading'
import {useForm} from "antd/lib/form/Form";
import axios from 'axios';
import { confirmDialog, dialog } from "../../functions/alert";
import CONFIG_INITIAL_STATE  from '../../service/config';
import DropdownComponent from "../../component/dropdown-component";
import Downloader from 'js-file-downloader'
import Auth from "../../service/auth";

const { Content } = Layout;
const { Title, Text } = Typography;

const InputHasilBip = (props) => {

    const [loading, setLoading] = useState(false);
    const [screenLoading, setScreenLoading] = useState(true);
    const [form] = useForm();
    const [listAspek, setListAspek] = useState([]);
    const [lovStatus, setLovStatus] = useState([]);
    const [isShow, setShow] = useState(false)
    const [isLoadingSubmit, setLoadingSubmit] = useState(false)
    const [file, setFile] = useState({})
    const [downloadUrl, setDownload] = useState('')
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [isUploadDone, setUploadDone] = useState(props.match.params.flagDataKesungguhanExist == 1 ? true : false)
    const [isFilesWrong, setFilesWrong] = useState(true)

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Beep Tester').then(res => {
			console.log('ada user ujian',res)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])

    useEffect(() => {
        document.title = "Beep Tester | Loyola"
    });

    useEffect(()=>{
        getLovBipTes(config).then(res => {
            if(res.data){
                setListAspek(res.data.data);
                setScreenLoading(false)
            }
        })
        getLovStatusRekomendasiBip(config).then(res => {
            if(res.data){
                let dataTemp = []
                res.data.data.map(res => {
                    if(res.key != 28){
                        dataTemp.push(res)
                    }
                })
                setLovStatus(dataTemp);
                setLoading(false)
            }
        })
    }, []);

    const getLovBipTes = (configApi) => {
        return new Promise((resolve,reject ) => {
            axios.get(CONFIG_INITIAL_STATE.BASE_URL + `/api/lov/bipTes`, configApi)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const getLovStatusRekomendasiBip = (configApi) => {
        return new Promise((resolve,reject ) => {
            axios.get(CONFIG_INITIAL_STATE.BASE_URL + `/api/lov/status?kategoriId=5`, configApi)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    };

    const handleBack = () => {
        props.history.goBack();
    };

    const handleDownloadKejujuran = () => {
        setLoadingDownload(true)
        LoyolaServices.downloadKejujuranKesungguhan(props.match.params.id).then(res => {
            new Downloader({url: res.data.Link}).then(file => {
            }).catch(err => {console.log(err)})
            setLoadingDownload(false)
        }).catch(err => {
            if(err){
              dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
              setLoadingDownload(false)
            }
        })
    }

    const handleSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then((values)=>{
                let text= isUploadDone ? 'Apakah Anda yakin akan menyimpan data ini ?' : 'Apakah Anda yakin akan menyimpan data tanpa upload Form Kesungguhan dan Kejujuran?'
                confirmDialog({text: text}).then(()=>saveInputHasil(values)).catch(()=>setLoading(false))
            }).catch(err=>{
            setLoading(false);
        });
    };

    const saveInputHasil = (values) => {
        let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId;
        let hasilBip = []
        listAspek.map(res => {
            let str = `aspek${res.key}`;
            hasilBip.push({bipId: res.key, skor: values[str]})
        })

        let body = {
            "noRegistrasi" : props.match.params.id,
            "rekomendasiBip" : values.rekomendasi,
            "hasilBip" : hasilBip,
            "lastModifiedBy" : userId
        };

        putInputHasilBipTest(body,config)
            .then(r => {
                if (r.data.Status === "OK") {
                    dialog({icon: 'success',
                    // text: r.data.Message,
                    text: 'Data Nilai Beep Test berhasil disimpan',
                    });
                    handleBack();
                } else {
                    setLoading(false);
                    dialog({icon: 'error', title: 'Gagal', text:'Terjadi Kesalahan Pada Server.'})
                }
            }).catch(err => {
                setLoading(false);
                dialog({icon: 'error', title: 'Gagal', text: err.response ? err.response.data.message : err.message})
        });
    }

    const putInputHasilBipTest = (data, configApi) => {
        return new Promise((resolve,reject ) => {
            axios.put(CONFIG_INITIAL_STATE.BASE_URL + `/api/bipTester/statusBeep`, data, configApi)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const handleCloseModal = () => {
        setLoadingSubmit(false)
        setFilesWrong(true)
        setShow(false)
        document.getElementById("file").value = ""
    }

    const handleChange = (e) => {
        const typeList = SheetJSFTExcel.split(',')
        const files = e.target.files;
        const filesName = files[0].name;
        var hasValueLessThanTen = false;
        for (var i = 0; i < typeList.length; i++) {
            if (filesName.search(typeList[i]) >= 0) {
                hasValueLessThanTen = true;
                break;
            }
        }
        if (files && files[0] && hasValueLessThanTen) {
            setFile(files[0]);
            setFilesWrong(false)
        }else{
            dialog({icon: 'error', text: 'Format file yang di-upload salah'})
            setFilesWrong(true)
        }
    }

    const handleFile = () => {
        if(document.getElementById("file").value != ""){
            setLoadingSubmit(true)
        
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
         
            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws, {raw: false});
              const temp = data.map(res => {
                res['Keluarga Kuliah Di Luar Negeri'] = res['Keluarga Kuliah Di Luar Negeri (Ya, Tidak)']
                res['Memiliki Rumah Lain Orang Tua'] = res['Memiliki Rumah Lain Orang Tua (Ya, Tidak)']
                res['Tinggal Serumah dengan Orang Tua'] = res['Tinggal Serumah dengan Orang Tua (Kandung, tiri, angkat)']
                res['Pergi ke tempat ramai'] = res['Pergi ke tempat ramai (mall, plaza, supermarket) (ya/tidak)']
                res['Bermain game di smartphone'] = res['Bermain game di smartphone (ya/tidak)']
                res['Punya HP Pribadi'] = res['Punya HP Pribadi (ya/tidak)']
                res['Memiliki Villa'] = res['Memiliki Villa (Ya, Tidak)']
    
                delete res['Keluarga Kuliah Di Luar Negeri (Ya, Tidak)']
                delete res['Memiliki Rumah Lain Orang Tua (Ya, Tidak)']
                delete res['Tinggal Serumah dengan Orang Tua (Kandung, tiri, angkat)']
                delete res['Pergi ke tempat ramai (mall, plaza, supermarket) (ya/tidak)']
                delete res['Bermain game di smartphone (ya/tidak)']
                delete res['Punya HP Pribadi (ya/tidak)']
                delete res['Memiliki Villa (Ya, Tidak)']
                return res
              })
              /* Update state */
              postUploadJadwal({ data: temp, cols: make_cols(ws['!ref'])})
         
            };
            console.log(file, 'file upload')
            if (rABS) {
              reader.readAsBinaryString(file);
            } else {
              reader.readAsArrayBuffer(file);
            }
            
        } else {
            dialog({icon: 'error', text: 'File tidak boleh kosong'})
        }
    }

    const postUploadJadwal = (data) => {
        if(data.data){
            console.log(data)
          let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
          LoyolaServices.uploadKejujuranKesungguhan(userId ,data.data).then(res => {
            if(res.data.Status === 'OK'){
                setUploadDone(true)
                dialog({icon:'success', text: res.data.Message ? res.data.Message : 'Upload Berhasil'}).then(() => {
                document.getElementById("file").value = ""
                setLoadingSubmit(false)
                setShow(false)
              })
            } else {
              dialog({icon:'error', text: res.data.message}).then(() => {
                document.getElementById("file").value = ""
                setLoadingSubmit(false)
                setShow(false)
              })
            }
          }).catch(err => {
            if(err){
              dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(() => {
                document.getElementById("file").value = ""
                setLoadingSubmit(false)
                if(err.response && err.response.data.message == 'Form Kesungguhan dan Kejujuran sudah di-upload'){
                    setUploadDone(true)
                }
                setShow(false)
              })
            }
          })
        }
    }

    return(
        screenLoading ?
        <Content className="contentContainer">
            <AppLoading/>
        </Content>
        :
        <Content className="contentContainer">
            <Spin spinning={loading}>
            <Row>
                <Col span={24} md={16}>
                    <Row align='middle' justify='center'>
                        <Col span={1} lg={2} style={{alignSelf: 'center'}}>
                            <LeftOutlined 
                                onClick={props.history.goBack} 
                                style={{fontSize: props.responsive && props.responsive.isMobile? 15 : 30}}
                            />
                        </Col>
                        <Col span={22}>
                            {
                                props.responsive && props.responsive.isMobile ?
                                <Title level={3} className="text-heading" style={{color: '#072a6f', marginLeft: 10}}>
                                    {props.match.params.name}
                                </Title> :
                                <Title level={1} className="text-heading" style={{color: '#072a6f'}}>
                                    {props.match.params.name}
                                </Title>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={24} md={8} style={{textAlign: 'right'}}>
                    <Space>
                        <Button
                            size="large"
                            className="cancel-button"
                            onClick={handleBack}
                        >
                            Batal
                        </Button>
                        <Button
                            size="large"
                            className='save-button'
                            onClick={handleSubmit}
                        >
                            Simpan
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Form 
                form={form}
                initialValues={{
                    rekomendasi: 16
                }}
                >

            <Row align="middle"> 
                <Col span={14} md={10}>
                    <Text className={"text-title-admin"}>
                        Aspek Penilaian
                    </Text>
                </Col>
                <Col span={10} md={14}>
                    <Text className={"text-title-admin"}>
                        Skor
                    </Text>
                </Col>
                <Col span={24} md={15}>
                    <Divider className={'bip-divider'} />
                </Col>
            </Row>
                    {
                        listAspek &&
                        <List
                            dataSource={listAspek}
                            renderItem={item => (
                                <List.Item>
                                <Col span={14} md={10}>
                                        <Title level={3} className="text-heading" style={{color: '#072A6F'}}>
                                            {item.value}
                                        </Title>
                                    </Col>
                                <Col span={10} md={14}>
                                        <Form.Item name={"aspek"+item.key}  
                                            rules={[
                                                { required: true, message: 'Skor harus diisi'}
                                            ]}>
                                            <InputNumber
                                                style={{borderColor: '#072A6F', borderRadius: 16}}
                                                min={0}
                                                max={10}
                                            />
                                        </Form.Item>
                                    </Col>
                                </List.Item>
                            )}
                        />
                    }
                    <Row align="middle">
                        <Col span={24}>
                            <Row align='middle'>
                                <Col style={{minWidth: 160}}>
                                    <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:20, marginTop:40}}>
                                        Rekomendasi
                                    </Title>
                                </Col>
                                <Col flex='auto'>
                                    <Form.Item noStyle name="rekomendasi">
                                        <DropdownComponent
                                            defaultValue={16}
                                            data={lovStatus}
                                            style={{width:150, marginTop:30}}    
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Title level={3} className="text-heading" style={{color: '#072a6f', marginRight:20, marginTop:40}}>
                                Form Kesungguhan dan Kejujuran
                            </Title>
                        </Col>
                        <Col span={24}>
                            <Row style={{marginBottom: 30}}>
                                <Col style={{minWidth: 160}}>
                                    <div style={{ minWidth: '150px', marginTop: '10px'}}>
                                        <Button className="download-btn" onClick={handleDownloadKejujuran} style={!isUploadDone ? {backgroundColor: '#072A6F', color: '#fff', borderColor:'#072A6F'} : null} loading={loadingDownload} disabled={isUploadDone}>
                                            Download Form
                                        </Button>
                                    </div>
                                </Col>
                                <Col flex='auto' >
                                    <div style={{ minWidth: '150px', marginTop: '10px'}}>
                                        <Button className="upload-btn" onClick={() => setShow(true)} disabled={isUploadDone}>
                                            Upload Form
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Row>
            </Spin>
            <Modal
              visible={isShow}
              onCancel={() => handleCloseModal()}
              title='Upload Form Kesungguhan dan Kejujuran'
              footer={[
                <Button key="back" onClick={() => handleCloseModal()}>
                  Tutup
                </Button>,
                <Button key="submit" type="primary" loading={isLoadingSubmit} onClick={() => handleFile()} disabled={isFilesWrong}>
                  Upload
                </Button>,
              ]}
              closeIcon={null}
            >              
              <input type="file" className="form-control" id="file" accept={SheetJSFTExcel} onChange={event => handleChange(event)} />
            </Modal>
        </Content>
    );
};

export default withRouter(InputHasilBip);