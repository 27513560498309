import React, {useState, useEffect} from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Layout, Row, Typography, Col, Input, Space, Card, Table, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, QrcodeOutlined, DownOutlined } from "@ant-design/icons";
import axios from 'axios';
import CONFIG_INITIAL_STATE  from '../../service/config';
import ModalQR from '../../component/modal-scan-qr';
import _debounce from "lodash.debounce"
import { GlobalFunction } from "../../global/function";
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";

const { Content } = Layout;
const { Title, Text } = Typography;
const menuKey = [
    { key: 'Prestasi', label: 'Jalur Prestasi' },
    { key: 'TesOnline', label: 'Jalur Tes' },
]

const BipTester = (props) => {
    const [listCalonSiswa, setListCalonSiswa] = useState([])
    const [isModalShow, setModalShow] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [sort, setSort] = useState("pn.no_registrasi,desc")
    const [pagination, setPagination] = useState({current: 1, pageSize:10})
    const [totalData, setTotalData] = useState (0)
    const [totalCalonSiswa, setTotalCalonSiswa] = useState (0)
    const [totalCalonSiswaDiwawancara, setTotalCalonSiswaDiwawancara] = useState (0)
    const [responsive, setResponsive] = useState(props.responsive)
    const [jalur, setJalur] = useState ('Prestasi')

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Beep Tester').then(res => {
			console.log('ada user ujian',res)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])

    useEffect(() => {
        document.title = "Beep Tester | Loyola"
    });

    const handleModalQR = () => {
        setModalShow(!isModalShow)
    }
    
    const searchHandler = e => {
        let key = e.target.value;
        processSearchCalonSiswa(key);
    }

    const processSearchCalonSiswa = _debounce((key) => {
        const param = GlobalFunction.searchEncode(key);
        setSearch(param)
    }, 100)

    const handleTableChange = (paginationParam, searching, sorter) => {
        if (sorter.order === "ascend") {
            sorter.order = "asc";
        } else if (sorter.order === "descend") {
            sorter.order = "desc";
        }
        let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`
        setSort(sorting)
        setPagination(paginationParam)
    };

    useEffect(() => {
        getDataCalonSiswa(search, sort, pagination.current-1, pagination.pageSize)
    }, [search, sort, pagination])

    const getDataCalonSiswa = async (searchParam, sortParam, page, size) => {
        setIsLoading(true);
        let listTemp = [];
       
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        let api = `/api/bipTester/dataJalurPrestasi?noRegistrasi=${searchParam}&sort=${sortParam}&size=${size}&page=${page}`
        await axios.get(CONFIG_INITIAL_STATE.BASE_URL + api, config)
            .then(res => {
                listTemp = (res && res.data && res.data.data) ? res.data.data : [];
                setTotalCalonSiswa(res.data.totalDataSiswa ? res.data.totalDataSiswa : 0)
                setTotalCalonSiswaDiwawancara(res.data.totalDiTes ? res.data.totalDiTes : 0)
                setTotalData(res.data.totalDataSiswa)
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
            });

        setListCalonSiswa(listTemp)
    }

    const tagColor = (tag) => {
        tag = tag.toLowerCase();
        switch (tag) {
            case 'lolos':
            case 'diterima':
                return '#219653';
            case 'belum di-test':
            case 'belum diwawancara':
                return '#072A6F';
            case 'cadangan':
            case 'ragu-ragu':
                return '#F2C94C';
            case 'tidak lolos':
            case 'ditolak':
            case 'tidak diterima':
                return '#EB5757';
            default:
                return '#072A6F';
        }
    };

    const columns = [
        {
            title: 'No. Registrasi',
            dataIndex: 'noRegistrasi',
            key: 'pn.no_registrasi',
            width: '20%',
            sorter: true,
        },
        {
            title: 'Nama',
            dataIndex: 'namaSiswa',
            key: 'nama_siswa',
            width: '20%',
            sorter: true,
        },
        {
            title: 'Asal Sekolah',
            dataIndex: 'asalSekolah',
            key: 'sekolah_asal',
            width: '20%',
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 's_rekomendasi_bip_tes.status',
            width: '20%',
            sorter: true ,
            render: tag => {
                if(tag === null || tag === undefined){
                    tag = "belum di-test"
                }
                let color = tagColor(tag);
                return (
                    <Text color={color} style={{color: color}}>
                        {tag.toUpperCase()}
                    </Text>
                )
            }
        },
        {
            width: '20%',
            render: (record) => {
                let value = record.status
                if(!value){
                    value = "belum di-test"
                }
                if (value.toLowerCase() === "belum di-test"){
                    return (
                        <Space>
                            <Link to={"/input-hasil-bip/" + record.noRegistrasi + "/" + record.namaSiswa + "/prestasi/"+ record.flagDataKesungguhanExist}>
                                <Button className="admin-update-button" type="primary" size="small">
                                    Input Penilaian
                                </Button>
                            </Link>
                        </Space>
                    )
                }
            }
        },
    ];

    const handleJalur = (e) => {
        if(e == 'TesOnline'){
            props.history.push('/beeptester-jalur-test-online')
        }
    }
    const dropdownLabel = menuKey.find(e => e.key === jalur).label;

    return(
        <Content className="contentContainer">                
            {responsive && responsive.grid.md ? (
                <Row>
                    <Col>
                        <Space size={"large"}>
                            {menuKey.map(({key, label}) => (
                                <a type="text" onClick={() => handleJalur(key)} className="text-heading">
                                    <Title level={3} style={{color: jalur === key ? '#072a6f' : '#BDBDBD' }}>
                                        {label}
                                    </Title>
                                </a>
                            ))}
                        </Space>
                    </Col>
                </Row>
            ) : (
                <div style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                    <Dropdown 
                        overlay={(
                            <Menu onClick={e => handleJalur(e.key)}>
                                {menuKey.map(({key, label}) => (
                                    <Menu.Item key={key}>{label}</Menu.Item>
                                ))}
                            </Menu>
                        )}
                    >
                        <Button style={{width: '100%', marginBottom: '24px'}}> {dropdownLabel}<DownOutlined/> </Button>
                    </Dropdown>
                </div>
            )}
            <Row align="middle" >
                    <Col span={24} lg={6}>
                        <Input
                            className="input-search"
                            placeholder="No. Registrasi"
                            style={{
                                width: '100%',
                                marginRight: '10px',
                                textAlign: 'left'
                            }}
                            prefix={<SearchOutlined/>}
                            onChange={searchHandler}
                        />
                    </Col>
                    <Col span={24} lg={18} style={{ textAlign: responsive.grid.xs && responsive.width < 640 ? 'left' : 'right' }}>
                        <Row justify='end'>
                            <Col>                        
                            <Button type="text" onClick={handleModalQR}>
                                <QrcodeOutlined style={{fontSize:25, verticalAlign:'middle', justifyContent:'right', color: '#072A6F'}}/> <Text className='title-scanqr'>Scan QR</Text>
                            </Button>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Text className="title-verifikator" >{totalCalonSiswaDiwawancara} dari {totalCalonSiswa} calon siswa telah dites</Text>
                        </Row>
                    </Col>
                    <ModalQR
                        visible={isModalShow}
                        buttonCancel={handleModalQR}
                        routeRole={"input-hasil-bip"}
                        routeJalur={"prestasi"}
                        type={'biptes'}
                        isPrestasi={true}
                    />
            </Row>
            <Row style={{marginBottom: 10, marginTop:24}}>
                <Col span={24}>
                    <Card
                        bordered={false}
                        className="app-card"
                    >
                        <Table
                            columns={columns}
                            size="middle"
                            bordered={false}
                            loading={isloading}
                            dataSource={listCalonSiswa}
                            rowKey={record => record.noRegistrasi}
                            pagination={{...pagination, total: totalData, showSizeChanger: false, position: ['none', 'bottomCenter']}}
                            onChange={handleTableChange}
                            scroll={{x: responsive && responsive.grid.xs ? responsive.width : 0}}
                        />
                    </Card>
                </Col>
            </Row>
        </Content>
    );
}

export default withRouter(BipTester)