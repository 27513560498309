import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { dialog, confirmDeleteDialog } from "../../../functions/alert";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import FormSmp from "../component/form-smp";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";

const { Content } = Layout;
const { Title, Text } = Typography;

const AdminSMP = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('nama_smp,asc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [isShow, setShow] = useState({show: false, record:null});

  useEffect(()=>{
    getData(pagination,sort,search)
    // getDataDummy()
  }, []);

  const getDataDummy = () => {
    setDataList(Dummy.smp.data)
    setLoading(false)
  }

  const getData = (page,sort,search) => {
    setLoading(true);
    LoyolaServices.getMasterDataSmp(page.current - 1,page.pageSize,sort,GlobalFunction.searchEncode(search)).then(res => {
      if(res.data){
        var data = res.data.data;
        setDataList(data);
        setPagination({
          ...pagination,
          current: res.data.page + 1,
          total: res.data.total
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = err.response && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        // setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'nama_smp,asc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const onRefresh = () => {
      setShow({show: false, record: null})
      getData(pagination,sort,search)
  }

  const deleteData = (record) => {
    setLoading(true)
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId;
    LoyolaServices.deleteMasterDataSmp(record.smpId,userId).then(res => {
      if(res.data){
        onRefresh()
        dialog({icon: 'success', text: res.data.message})
      }
    }).catch(err => {
      if(err){
        setLoading(false)
        let text = err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
      }
    })
  }

  const handleSearch = (e) => {
    let key = e.target.value;
    setSearch(key)
    processSearchCalonSiswa(key);
  };

  const processSearchCalonSiswa = _debounce((key) => {
    const param = key;
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const columns = [
    {
        title: 'Nama Sekolah',
        dataIndex: 'namaSMP',
        key: 'nama_smp',
        width: '30%',
        sorter: true,
    },
    {
        title: 'Alamat Sekolah',
        dataIndex: 'alamatSMP',
        key: 'alamat_smp',
        sorter: true,
    },
    {
        title: 'Aksi',
        width: '25%',
        render: (record) => {
          return (
            <Row gutter={[20,10]}>
              <Col>
                <Button className="app-btn" type='primary' style={{backgroundColor: '#EF712B', color: '#fff'}} onClick={() => setShow({show: true, record: record})}>
                  Ubah
                </Button>
              </Col>
              <Col>
                <Button className="app-btn" type='primary' danger onClick={() => confirmDeleteDialog({text: 'Apakah Anda yakin akan menghapus data ini ?'}).then(()=>deleteData(record))}>
                  Hapus
                </Button>
              </Col>
            </Row>
          );
        },
      },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}}>
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
                className="input-search"
                placeholder="Nama Sekolah"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={handleSearch}
            />
          </Col>
          <Col span={24} lg={18} md={16} style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button className="app-btn" type="primary" style={{backgroundColor: '#FFE600', color: '#072A6F'}}
              onClick={()=>setShow({show: true, record: null})}
            ><PlusOutlined/> Tambah</Button>
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
              />
            </Card>
          </Col>
        </Row>
        <FormSmp
          isShow={isShow.show}
          isEdit={isShow.record}
          dataList={dataList}
          onCancel={()=>setShow({show: false, record: null})}
          onRefresh={onRefresh}
          />
      </div>
    );
};

export default withRouter(AdminSMP);