import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { Input, Row, Col, Typography, InputNumber, Button, Form} from 'antd'
import { AppLoading } from '../../../component/loading';
import Dummy from '../../../dummy';
import DropdownComponent from '../../../component/dropdown-component';
import { dialog } from '../../../functions/alert'
import { LoyolaServices } from '../../../service';
import SelectComponent from '../../../component/select-component';
import MoneyInput from '../../../component/money-input';

const { TextArea, Title } = Input;
const { Text } = Typography;

const mappingDataOrtu = (res) => {
    if(res.Data){
        let response = res.Data
     return {
        nama                : response.ortuNama || '',
        agama               : response.ortuAgama || '',
        mobilePhone         : response.ortuHp || '',
        alamat              : response.ortuAlamat || '',
        rt                  : response.ortuRt || '',
        rw                  : response.ortuRw || '',
        pos                 : response.ortuKodepos || '',
        kelurahan           : response.ortuKelurahan || '',
        kecamatan           : response.ortuKecamatan || '',
        kotaKabupaten       : response.ortuKota || '',
        email               : response.ortuEmail || '',
        telefonRumah        : response.ortuTelpRumah || '',
        pendidikanTerakhir  : response.ortuPendidikan || '',
        profesi             : response.ortuProfesi || '',
        namaKantor          : response.ortuNamaKantor || '',
        bidangUsaha         : response.ortuBidangUsaha || '',
        alamatKantor        : response.ortuAlamatKantor || '',
        telefonKantor       : response.ortuTelpKantor || '',
        penghasilan         : response.ortuPenghasilan || '',
        npwp                : response.ortuNpwp || '',
        pajakBumiBangunan   : response.ortuPbb || '',

        slipGaji            : response.slipGaji || '',
        uploadNpwp          : response.uploadNpwp || '',
        ortuKtp             : response.ortuKtp || '',
        verifikasiDataOrtu  : response.verifikasiDataOrtu,
        ketVerifDataOrtu    : response.ketVerifDataOrtu
        }
    }else{
        return null
    }
}

const DataOrtu = (props) => {
    const dataOrtu = mappingDataOrtu(props.data);
    const [keterangan, setKeterangan] = useState(dataOrtu.ketVerifDataOrtu);
    const [isLoading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useState(_ => {
        form.setFieldsValue({verifikasi: dataOrtu.verifikasiDataOrtu});
    }, [])

    const handleSubmit = (verifikasi) => {
        setLoading(true)
        let data = {
            "siswaId": props.match.params.id,
            "ibuAyahWali": props.kode,
            "verifikasiDataOrtu": verifikasi, 
            "ketVerifDataOrtu": keterangan,
        }
        LoyolaServices.putBiodataDiriOrtu(dataOrtu.siswaId, data).then(() => {
            dialog({icon:'success', text: 'Data berhasil disimpan'}).then(() => {
                setLoading(false)
                props.onSubmit()
            })
        }).catch(err => {
            if(err){
                dialog({icon: 'error', text: err.response ? err.response.data.message : err.message}).then(setLoading(false))
            }
        })
    }
    
    const onChange = ({ target: { value } }) => {
        setKeterangan(value);
    };

    if(!dataOrtu){
        return (
            <AppLoading/>
        )
    }

    return (
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Text>Nama {props.role}</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.nama} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Agama</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.agama} readOnly={true} />
            </Col>
            <Col xs={24} md={12}>
                <Text>No. HP</Text>
                <Input className='input-modal' maxLength={15} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.mobilePhone} readOnly={true} />
            </Col>
            <Col span={24}>
                <Text>Alamat</Text>
                <Input className='input-modal' maxLength={255} disabled={true} defaultValue={dataOrtu.alamat} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>RT</Text>
                <Input className='input-modal' maxLength={5} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.rt} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>RW</Text>
                <Input className='input-modal' maxLength={5} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.rw} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kode Pos</Text>
                <Input className='input-modal' maxLength={5} disabled={true} defaultValue={dataOrtu.pos} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kelurahan</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.kelurahan} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kecamatan</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.kecamatan} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kota/Kabupaten</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.kotaKabupaten} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>E-mail</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataOrtu.email} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>No. Telepon Rumah</Text>
                <Input className='input-modal' maxLength={15} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.telefonRumah} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Pendidikan Terakhir</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.pendidikanTerakhir} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Profesi</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.profesi} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Nama Kantor</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.namaKantor} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Bidang Usaha</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.bidangUsaha} readOnly={true}/>
            </Col>
            <Col span={24}>
                <Text>Alamat Kantor</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.alamatKantor} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>No. Telepon Kantor</Text>
                <Input className="input-modal" disabled={true} defaultValue={dataOrtu.telefonKantor} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Penghasilan</Text>
                <MoneyInput className="input-modal" disabled={true} defaultValue={dataOrtu.penghasilan ? dataOrtu.penghasilan : 0} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>NPWP</Text>
                <InputNumber className='input-modal' maxLength={20} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.npwp} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Pajak Bumi Bangunan</Text>
                <MoneyInput className='input-modal' maxLength={15} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataOrtu.pajakBumiBangunan ? dataOrtu.pajakBumiBangunan : 0} readOnly={true}/>
            </Col>
            <Col span={24} style={{marginBottom: '1em'}}>
                <Text>Catatan</Text>
                <TextArea rows={6} value={keterangan} onChange={onChange}/>
            </Col>
            <Col xs={24} md={12}>
                <Form form={form}>
                    <Form.Item 
                        label="Verifikasi Data"
                        name="verifikasi"
                        rules={[{required: true, message: 'Status Verifikasi Data harus diisi!'}]}
                        style={{marginBottom: '0px'}}
                    >    
                        <SelectComponent 
                            isNoStyle={true} 
                            items={Dummy.verifikatorLovBool} 
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} style={{textAlign: 'right'}}>
                <Button
                    className='app-btn primary padded'
                    onClick={() => {form.validateFields().then(e => {handleSubmit(e.verifikasi)}).catch(err => {})}}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    Simpan
                </Button>
            </Col>
        </Row>
    )
  }

export default withRouter(DataOrtu)