import React, {useEffect, useState} from "react";
import {NavLink, Redirect, Route, Switch, useLocation, withRouter} from "react-router-dom";
import _debounce from "lodash.debounce";
import NonBeasiswa from "./non-beasiswa";
import { Button, Col, Row, Space } from "antd";
import Negosiasi from "./negosiasi";
import BeasiswaOfon from "./beasiswa-ofon";
import BeasiswaPrestasi from "./beasiswa-prestasi";
import BeasiswaKekl from "./beasiswa-kekl"

const tabs = [
  {path: '/finance/scholarship/non-beasiswa', title: 'NON BEASISWA'},
  {path: '/finance/scholarship/beasiswa-prestasi', title: 'BEASISWA PRESTASI'},
  {path: '/finance/scholarship/beasiswa-ofon', title: 'BEASISWA OfoN'},
  {path: '/finance/scholarship/beasiswa-kekl', title: 'BEASISWA KEKL'},
  {path: '/finance/scholarship/negosiasi', title: 'NEGOSIASI'},
]

function Scholarship (props) {
  const {pathname} = useLocation();

  return ['/finance/scholarship/', '/finance/scholarship'].includes(pathname) ? (
    <Redirect to={{ pathname: "/finance/scholarship/non-beasiswa", state: { from: pathname } }} /> 
  ) : (
    <div>
      <Row gutter={[10, 10]}>
        {tabs.map(tab => {
          const isActive = pathname === tab.path;
          return (
            <Col span={12} xl={4} lg={5} md={6}>
              <NavLink to={tab.path}>
                <Button style={{width: '100%'}} className={`app-btn circle no-hover ${isActive ? 'secondary' : 'primary'}`}>
                  {tab.title}
                </Button>
              </NavLink>
            </Col>
          )
        })}
      </Row>
      <Switch>
        <Route path="/finance/scholarship/non-beasiswa" component={NonBeasiswa} />
        <Route path="/finance/scholarship/beasiswa-prestasi" component={BeasiswaPrestasi} />
        <Route path="/finance/scholarship/beasiswa-ofon" component={BeasiswaOfon} />
        <Route path="/finance/scholarship/beasiswa-kekl" component={BeasiswaKekl} />
        <Route path="/finance/scholarship/negosiasi" component={Negosiasi} />
      </Switch>
    </div>
  );
};

export default withRouter(Scholarship);