import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import UploadComponent from "../../../component/upload-component-image-preview";
import CONFIG from '../../../service/config'
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import Dummy from "../../../dummy";

const { Text, Title } = Typography
const FormPertanyaanEsai = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
      if(props.isEdit){
        form.setFieldsValue(props.isEdit);
      }else{
        form.resetFields()
      }
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        matpelId: props.matpel.key,
        gambar : values.gambarEsai ? values.gambarEsai.fileName : null,
        // isTeks: values.gambarEsai ? false : true,
        pertanyaan: values.pertanyaanEsai,
        durasi: values.durasiEsai ? values.durasiEsai : 30
      }
      if(props.isEdit){
        body.id = props.isEdit.esaiId
        LoyolaServices.putPertanyaanEsai(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Pertanyaan Telah Diubah'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }else{
        LoyolaServices.postPertanyaanEsai(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Pertanyaan Telah Disimpan'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Soal Essai</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="essai"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                  <Row style={{alignItems: 'center'}}>
                    <Col>
                      <Text>Mata Pelajaran :</Text>
                    </Col>
                    <Col>
                      <Title level={4} style={{color: '#072A6F', marginTop: 8, marginLeft: 5}}> {props.matpel && props.matpel.value}</Title>
                    </Col>
                </Row>
                  {/* <Row style={{alignItems: 'center'}}>
                    <Col>
                    <Text>Waktu Pengerjaan : </Text>
                    </Col>
                    <Col offset={1} span={6} style={{margin: 5}}>
                      <Form.Item name="durasiEsai">
                        <Select
                          style={{marginLeft: 5, marginRight: 5}}
                          defaultValue={30} //detik
                        >
                          {
                            Dummy.dataLovDurasi && Dummy.dataLovDurasi.data.map(res => {
                              return <Select.Option value={res.key}>{res.value}</Select.Option>
                            })
                          }
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col offset={1}>
                    <Text> Detik</Text>
                  </Col>
                </Row> */}
                <Form.Item name="pertanyaanEsai"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Pertanyaan',
                    },
                  ]}
                >
                  <Input.TextArea
                      style={{height: 170, backgroundColor: "#F2F2F2", borderRadius: 16}}
                      placeholder="Pertanyaan"
                  />
                </Form.Item>
                <Form.Item
                  name="gambarEsai"
                >
                  <UploadComponent fluidBtn
                    showPreview={true}
                    classBtn='app-btn primary'
                    accept=".jpg,.jpeg,.pdf,.png"
                    url={CONFIG.BASE_URL_NOAUTH+'/api/upload/image?path=esaiSoal'}
                    title="Upload Gambar Soal"
                    onChange={_ => {}}
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormPertanyaanEsai);