import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Card, Spin, InputNumber} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { confirmDialog, dialog } from "../../../functions/alert";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import ImagePreviewComponent from "../../../component/image-preview";

const { Title,Text } = Typography

const CardSoal = (props) => {
    const data = props.data
    const [collapse, setCollapse] = useState(true)
    return (
      <Row style={{borderBottom: '2px solid #d9d9d9', marginBottom: 10}}>
        <Col span={1}>
          {
            collapse ?
            <CaretDownOutlined onClick={()=>setCollapse(false)}/> :
            <CaretRightOutlined onClick={()=>setCollapse(true)}/>
          }
        </Col>
        <Col span={20} style={{textAlign: 'left'}}>
          {data.pertanyaan}
        </Col>
        {
          data.image &&
          <ImagePreviewComponent type='text' text='Gambar' uri={data.image}/>
        }
        <Col span={3}>
          <Form.Item name={'skor'+props.index}
            rules={[
              {
                required: true,
                message: 'Harap diisi!',
              },
              {
                  pattern: new RegExp('^[0-9]+$'), 
                  message: "Harap diisi dengan angka"
              }
            ]}
          >
            <InputNumber
                max={100}
                style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                placeholder="skor"
            />
          </Form.Item>
        </Col>
        {
          collapse &&
          <Col offset={1} span={23} style={{textAlign: 'left'}}>
            <Title level={4} >Jawaban : {data.jawaban || '-'}</Title> 
          </Col>
        }
      </Row>
    )
    
}

const DataSiswa = (props) => {
  return(
    <Card bordered={false} className="app-card">
      <Row>
        <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
          <Title level={4} style={{color: '#072A6F'}}>Data Siswa</Title>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              No Registrasi
            </Col>
            <Col>
              <Title level={4} >{props.dataSiswa.noRegistrasi}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Nama
            </Col>
            <Col>
              <Title level={4} > {props.dataSiswa.namaSiswa} </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const DataPG = (props) => {
  const dataSkor = props.data
  return(
    <Card bordered={false} className="app-card">
      <Row>
        <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
          <Title level={4} style={{color: '#072A6F'}}>Skor Pilihan Ganda</Title>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Total Soal
            </Col>
            <Col>
              <Title level={4} >{ dataSkor && dataSkor.totalSoal ? dataSkor.totalSoal: 0}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Soal Yang terjawab
            </Col>
            <Col>
              <Title level={4} >{ dataSkor && dataSkor.soalYangTerjawab ? dataSkor.soalYangTerjawab : 0}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              Total Skor
            </Col>
            <Col>
              <Title level={4} >{ dataSkor && dataSkor.totalSkor ? dataSkor.totalSkor : 0}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Jawaban Benar
            </Col>
            <Col>
              <Title level={4} >{ dataSkor && dataSkor.jawabanBenar ? dataSkor.jawabanBenar : 0}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              Jawaban Salah
            </Col>
            <Col>
              <Title level={4} >{ dataSkor && dataSkor.jawabanBenar ? dataSkor.jawabanSalah : 0}</Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const ReviewJawaban = (props) => {

    const [form] = Form.useForm();
    const [dataSoal, setDataSoal] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalSkor, setTotalSkor] = useState(0);
    const [totalSoal, setTotalSoal] = useState(0);
    const [dataPG, setPG] = useState({});
    const [isDisabled, setDisabled] = useState(true);

    useEffect(()=>{
      setLoading(true)
      {
        props.dataSiswa &&
        getData(props.dataSiswa)
      }
    }, []);
    
    const getData = (record) =>{
      let siswaId = record.siswaId
      let matpelId = record.matpel && record.matpel.key
      LoyolaServices.getDataReviewSoalDetail(siswaId, matpelId).then(res => {
        if(res.data){   
          let data = res.data.data
          console.log('res adalah : ',res,data)
          let dataEsai = data.skorJawabanEsai
          let dataPG = data.skorJawabanGanda
          let jawabanEsaiList = addSkorIndex(dataEsai.jawabanEsaiList ? dataEsai.jawabanEsaiList : [])
          console.log('addSkorIndex : ',jawabanEsaiList)
          setDataSoal(jawabanEsaiList ? jawabanEsaiList  : [])
          setTotalSoal(dataEsai.totalSoal ? dataEsai.totalSoal  : 0)
          setDisabled(dataEsai.totalSkor ? true : false)
          setTotalSkor(dataEsai.totalSkor ? dataEsai.totalSkor  : 0)
          setPG(dataPG)
          setLoading(false)
        }
      }).catch(err => {
        if(err){
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoading(false)
        }
      })
    }

    const addSkorIndex = (list) => {
      let dataTemp = []
      if(list.length > 0 && list){
        list.map((res,index) =>{
          form.setFieldsValue({['skor'+index]: res.skor})
          dataTemp.push({
            ...res,
            ['skor'+index]: res.skor
          })
        })
        return dataTemp
      }else{
        return dataTemp
      }
    }

    const onFinish = (values) => {
      setLoading(true)
      confirmDialog({text: 'Apakah anda yakin ingin simpan nilai siswa ?', title: 'Simpan Nilai Siswa'}).then(res => {
        let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
        let matpleId = props.dataSiswa && props.dataSiswa.matpel && props.dataSiswa.matpel.key ? props.dataSiswa.matpel.key : props.dataSiswa.matpel
        let noReg = props.dataSiswa && props.dataSiswa && props.dataSiswa.noRegistrasi
        let dataTemp = []
        let body = {}
        dataSoal.map((res,index) => {
          dataTemp.push({
            soalEsaiId: res.soalEsaiId,
            pertanyaan: res.pertanyaan,
            skor: parseInt(values['skor'+index])
          })
        })
        body = {
          noRegistrasi: noReg,
          nilai: dataTemp,
          matpelId: matpleId
        }
        LoyolaServices.putReviewSoalDetail(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:res.data.message ? res.data.message : 'Data Nilai Ujian Siswa berhasil disimpan'}).then(()=>{
              setLoading(false)
              handleToList()
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }).catch(err => {
          setLoading(false)
      })
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const handleToList = () => {
      props.goToList()
    }

    const onChange = (e) => {
      let dataTemp = form.getFieldsValue()
      let total = 0
      dataSoal.map((res,index)=>{
        if(dataTemp['skor'+index]){
          let data = dataTemp['skor'+index]
          if(data > 100){
            data = 100
          }else if(data< 0){
            data = 0
          }
          total+=data
        }
      })
      setTotalSkor(total)
    }

    const handleReset = () => {
      let total = 0
      form.resetFields()
      setTotalSkor(total)
    }

    return(
      <Spin spinning={isLoading}>
        <Row gutter={[20,20]} style={{ marginTop: 20}}>
          <Col span={props.responsive.isMobile ? 24 : 8}>
            <Row>
              <Col span={24}>
                { props.dataSiswa && <DataSiswa dataSiswa={props.dataSiswa} nama={'samuel'}/> }
              </Col>
            </Row>
            <Row style={{marginTop: 20}}>
              <Col span={24}>
                { <DataPG data={dataPG}/>}
              </Col>
            </Row>
          </Col>
          <Col span={props.responsive.isMobile ? 24 : 16} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <Card bordered={false} className="app-card">
              <Row>
                <Col span={8}>
                  <Title level={4} >Skor Jawaban Esai</Title>
                </Col>
                <Col span={6}>
                  <Title level={4} >Total Soal : {totalSoal}</Title> 
                </Col>
                <Col span={6}>
                  <Title level={4} >Total Skor : {totalSkor}</Title> 
                </Col>
              </Row>
              <Form
                name="Review Soal"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onChange={onChange}
                form={form}
              >
                  {
                      dataSoal && dataSoal.map((res,index) => {
                        return <CardSoal 
                          index={index}
                          data={res}
                        />
                      })
                  }
                <Form.Item>
                  <Row gutter={[10,10]}>
                    <Col span={24} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>handleToList()} style={{borderRadius: 8, margin: 10}}>
                        Kembali
                      </Button>
                      <Button type='text' onClick={handleReset} disabled={isDisabled} style={{borderRadius: 8, margin: 10}}>
                        Reset
                      </Button>
                      <Button type="primary" loading={isLoading} disabled={isDisabled} htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60', margin: 10}}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    );
};

export default withRouter(ReviewJawaban);