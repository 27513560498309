import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { Layout } from 'antd';
import PenilaianInterview from "./penilaian-interviewer";
import PenilaianBiptester from "./penilaian-biptester";

const { Content } = Layout;

const PoinPenilaian = (props) => {
    const [menu, setMenu] = useState(0)

    const changeMenu = (params) => {
        setMenu(params)
    }

    return(
        <div style={{marginTop: 24}}>
          {
            menu == 0 ?
            <PenilaianInterview isActived={props.isActived} responsive={props.responsive} changeMenu={(event)=>changeMenu(event)}/> :
            <PenilaianBiptester isActived={props.isActived} responsive={props.responsive} changeMenu={(event)=>changeMenu(event)}/>
          }
        </div>
    );
}

export default withRouter(PoinPenilaian)