import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";

const { Title } = Typography
const FormPenilaianBiptester = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
      console.log('record : ',props.isEdit)
      if(props.isEdit){
        form.setFieldsValue(props.isEdit);
      }else{
        form.resetFields()
      }
    }, [props.isShow]);

    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        aspekPenilaian: values.aspekPenilaian,
      }
      if(props.isEdit){
        body.id = props.isEdit.bipId
        console.log('apinya mana?')
        LoyolaServices.putPenilaianBipTester(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Aspek Penilaian Beep Test berhasil diubah'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }else{
        LoyolaServices.postPenilaianBipTester(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:'Aspek Penilaian Beep Test berhasil disimpan'}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }
      console.log('Success:', body,userId);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Aspek Penilaian Beep Test</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="biptester"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item name="aspekPenilaian"
                  rules={[
                    {
                      required: true,
                      message: 'Aspek Penilaian harus diisi',
                    },
                  ]}
                  style={{textAlign: 'left'}}
                >
                  <Input
                      maxLength={255}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                      placeholder="Aspek Penilaian"
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}
                        loading={loading}
                      >
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormPenilaianBiptester);