import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import { PlusOutlined } from '@ant-design/icons';


SelectComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array,
  onAddItem: PropTypes.func,
  notFoundMessage: PropTypes.node,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  showSearch: PropTypes.bool
};


SelectComponent.defaultProps  = {
  onChange: () => {},
  onError: () => {},
  items: [],
  placeholder: 'Pilih',
  labelKey: 'value',
  valueKey: 'key',
  showSearch: true
}

function SelectComponent({ 
  value,
  onChange,
  onError,
  items,
  onAddItem,
  notFoundMessage,
  placeholder,
  labelKey,
  valueKey,
  showSearch,
  disabled,
  ...props
}) {
  const [inputValue, setInputValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!!value) { setInputValue(value); }
  }, [value])

  const handleChange = async (val) => {
    onChange(val);
    setInputValue(val);
  }

  const handleSearch = async (text) => {
    setSearchTerm(text);
  }

  const handleAddItem = () => {
    const newOption = {};
    newOption[valueKey] = searchTerm;
    newOption[labelKey] = searchTerm;
    onAddItem(newOption);
  }
  
  let notFoundProps = !!onAddItem ? {
    notFoundContent: 
      <div>
        {!!searchTerm && ( 
          <div className="app-add-item" onClick={handleAddItem} style={{padding: '5px 12px', color: '#1890ff', cursor: 'pointer'}}>
            <PlusOutlined style={{marginRight: '10px'}} />
            <Text style={{color: '#1890ff'}}>Tambah '{searchTerm}' kedalam list</Text>
          </div>
        )}
      </div>
  } : notFoundMessage ? {notFoundContent: notFoundMessage} : {};

  const seachProps = showSearch ? {
    showSearch: true,
    onSearch: e => handleSearch(e)
  } : {}

  return (
    <>
      <Select
        disabled={disabled}
        {...seachProps}
        {...notFoundProps}
        style={{ width: '100%' }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={handleChange}
        value={inputValue}
        filterOption={(input, option) => {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
      >
        {items.map((item, index) => <Select.Option value={item[valueKey]} key={`selectOption-${index}`}>{item[labelKey]}</Select.Option>)}
      </Select>
    </>
  );
}

export default SelectComponent;
