export default {
  "total": 8,
  "data": [
      {
          "siswaId": 123,
          "key": 123,
          "namaSiswa": "Theone Maximillia Yoediarto",
          "sekolahAsal": "SMP Terang Bangsa Semarang",
          "statusAdministrasi": null,
          "noRegistrasi": "202101122",
          "email": "theonemaximillia@gmail.com",
          "hpSiswa": "085881118910",
          "pembayaranRegistrasi": "Belum"
      },
      {
          "siswaId": 121,
          "key": 121,
          "namaSiswa": "SAMUEL LUCKY ADE KURNIAWAN",
          "sekolahAsal": "SMP Krista Mitra Semarang",
          "statusAdministrasi": null,
          "noRegistrasi": "202101120",
          "email": "samuellucky24.sl@gmail.com",
          "hpSiswa": "081215793230",
          "pembayaranRegistrasi": "Belum"
      },
      {
          "siswaId": 130,
          "key": 130,
          "namaSiswa": "Nathania Nandika Calluella",
          "sekolahAsal": "SMP DOMINICO SAVIO SEMARANG",
          "statusAdministrasi": null,
          "noRegistrasi": "202101129",
          "email": "nathania.nandika@gmail.com",
          "hpSiswa": "0816532327",
          "pembayaranRegistrasi": "Belum"
      },
      {
          "siswaId": 122,
          "key": 122,
          "namaSiswa": "Matthew Fitch Aurick",
          "sekolahAsal": "SMP Pius Pekalongan",
          "statusAdministrasi": "Belum diverifikasi",
          "noRegistrasi": "202101121",
          "email": "Aurick1605@gmail.com",
          "hpSiswa": "085335200909",
          "pembayaranRegistrasi": "Bayar"
      },
      {
          "siswaId": 129,
          "key": 129,
          "namaSiswa": "Jevan Nathaniel Malau",
          "sekolahAsal": "SMPN 5 Semarang",
          "statusAdministrasi": "Belum diverifikasi",
          "noRegistrasi": "202101128",
          "email": "jevannathaniel12@gmail.com",
          "hpSiswa": "085155112986",
          "pembayaranRegistrasi": "Bayar"
      },
      {
          "siswaId": 124,
          "key": 124,
          "namaSiswa": "Grisella Nethania Lukman",
          "sekolahAsal": "\tSMP SUSTERAN PURWOKERTO\t",
          "statusAdministrasi": "Belum diverifikasi",
          "noRegistrasi": "202101123",
          "email": "grisellanethania@gmail.com",
          "hpSiswa": "081392418199",
          "pembayaranRegistrasi": "Bayar"
      },
      {
          "siswaId": 128,
          "key": 128,
          "namaSiswa": "Freysia Chandra Saliman",
          "sekolahAsal": "SMP DOMENICO SAVIO SEMARANG",
          "statusAdministrasi": "Belum diverifikasi",
          "noRegistrasi": "202101127",
          "email": "freysia.chandra@gmail.com",
          "hpSiswa": "085700303398",
          "pembayaranRegistrasi": "Bayar"
      },
      {
          "siswaId": 127,
          "key": 127,
          "namaSiswa": "Brigita Natalie Defina Kurniawan",
          "sekolahAsal": "SMP DOMENICO SAVIO SEMARANG",
          "statusAdministrasi": "Belum diverifikasi",
          "noRegistrasi": "202101126",
          "email": "brigita.natalie.defina81@gmail.com",
          "hpSiswa": "081391152808",
          "pembayaranRegistrasi": "Bayar"
      }
  ],
  "size": 10,
  "page": 0,
  "sort": "nama_siswa",
  "direction": "DESC"
}