/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Button, Table, Card, Input, Tooltip, Space, Dropdown, Menu} from "antd";
import { LoyolaServices } from "../../../../service";
import {ArrowDownOutlined, ArrowUpOutlined, DownloadOutlined, DownOutlined, EditOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import Downloader from "js-file-downloader";
import { dialog } from "../../../../functions/alert";
import { GlobalFunction } from "../../../../global/function";
import ModalBuktiKesanggupan from "./modal-bukti-kesanggupan";
import ModalInputPembayaran from "./modal-input-pembayaran";
import ModalVerifikasi from "./modal-verifikasi"
import { FinanceService } from "../../finance-service";

const { Content } = Layout;

const keyActions = [
  {
    key: "verifikasi",
    label: "Verifikasi", 
    icon: <EditOutlined /> },
    {
      key: "downloadTemplate",
      label: "Download Template Bukti Kesanggupan", 
      icon: <ArrowDownOutlined /> },
    {
      key: "downloadBuktiKesanggupan",
      label: "Download Bukti Kesanggupan", 
      icon: <ArrowDownOutlined /> },
    {
    key: "inputBukti",
    label: "Input Bukti Kesanggupan", disabledRuleKey: 'statusPengajuanPrestasi', 
    icon: <EditOutlined /> },
  {
    key: "inputPembayaran",
    label: "Input Pembayaran", disabledRuleKey: 'statusPengajuanPrestasi', 
    icon: <EditOutlined /> },
]

function BeasiswaOfon (props) {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('pn.no_registrasi,asc');
  const [dataList, setDataList] = useState(null);
  const [openModal, setOpenModal] = useState({key: null, data: null})

  useEffect(()=>{
    getData(pagination,sort,'')
    // getDataDummy()
  }, []);
  
  useEffect(()=>{
  }, [pagination]);

  const getData = (page,sort,search) => {
    setLoading(true);
    FinanceService.getListBeasiswaOfon(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        const {data, page = 0, totalData} = res.data;
        // var data = res.data.data;
        console.log({data})
        setDataList(data.map((e, i) => ({...e, key: i})));
        setPagination({
          ...pagination,
          current: page+1,
          total: totalData
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setDataList([])
        setLoading(false)
      }
    })
  }

  const processSearchCalonSiswa = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : ',desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,'')
  };

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  const handleDownload = (data) => {
    FinanceService.getDownloadTamplateBeasiswaOfon(data).then(res => {
      if(res.data){
        new Downloader({url: res.data.Link}).then(file => {
        }).catch(err => {console.log(err)})
        // dialog({icon: 'success', text: 'Data berhasil di download'})
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setLoading(false)
      }
    })
  }

  const handleActions = (key, record) => {
    if (key === 'downloadTemplate') {
      handleDownload(record.komitSiswaId);
    } else {
      if(key === 'downloadBuktiKesanggupan'){
        new Downloader({url: record.buktiKesanggupan}).then(file => {
        }).catch(err => {console.log(err)})
      } else{
        setOpenModal({key: key, data: record});
      }
    }
  }

  const handleCloseModal = (isRefresh) => {
    setOpenModal({key: null, data: null});
    if (!!isRefresh) {
      getData(pagination,sort,'')
    }
  }

  const Actions = (id, record) => {
    return (
      <Menu onClick={e => handleActions(e.key, record)}>
        { keyActions.map(({key, label, icon}, index) => {
          let disabledAction = false;
          if (key === 'inputBukti') {
            disabledAction = record.statusPengajuanBeasiswa === 'Belum Diverifikasi' || !!record.inputKesanggupan;
          } else if (key === 'inputPembayaran') {
            disabledAction = record.statusPengajuanBeasiswa === 'Belum Diverifikasi' || !record.inputKesanggupan
          } else if(key == 'downloadBuktiKesanggupan'){
            disabledAction = record.statusPengajuanBeasiswa === 'Belum Diverifikasi' || !record.buktiKesanggupan
          }
          return <Menu.Item key={key} disabled={disabledAction}>{icon} {label}</Menu.Item>
        })}
      </Menu>
    )
  }
  

  const columns = [
    {
      title: 'No. Registrasi',
      dataIndex: 'noRegistrasi',
      key: 'pn.no_registrasi',
      sorter: true,
    },
    {
      title: 'Nama Siswa',
      dataIndex: 'namaSiswa',
      key: 'cs.nama_siswa',
      sorter: true,
    },
    {
      title: 'Jalur',
      dataIndex: 'jalurPenerimaan',
      key: 'pn.jalur_penerimaan',
      sorter: true,
      width: '5%',
      align: 'center'
    },
    {
      title: 'Komitmen UPS & USM',
      dataIndex: 'komitmenUps',
      key: 'ku.komitmen_ups',
      sorter: true,
      width: '10%',
      align: 'center'
    },
    {
      title: 'Kategori OfoN',
      dataIndex: 'kategoriOfon',
      key: 'db.diskon_ofon',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Diskon OfoN',
      dataIndex: 'diskonOfon',
      key: 'db.diskon_ofon',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Beasiswa Tambahan',
      dataIndex: 'beasiswaTambahan',
      key: 'beasiswa_tambahan',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Status Pengajuan Beasiswa',
      dataIndex: 'statusPengajuanBeasiswa',
      key: 's.status',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Catatan',
      dataIndex: 'catatanSeleksi',
      key: 'catatan_verifikasi',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Action',
      dataIndex: 'noRegistrasi',
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (id, record) => {
        return (
          <Dropdown overlay={Actions(id, record)}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Action&nbsp;&nbsp;<DownOutlined />
            </a>
          </Dropdown>
        )
      },
    },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  return(
    <div style={{marginTop: 20, width: '100%'}}>
      <Space className="space-between" style={{marginBottom: '20px'}}>
        <Input
          className="input-search"
          placeholder="No. Registrasi"
          style={{
            width: '270px',
            marginRight: '10px',
            textAlign: 'left'
          }}
          prefix={<SearchOutlined/>}
          onChange={handleSearch}
        />
      </Space>
      <Card bordered={false} className="app-card" style={{overflowY: 'auto'}}>
        <Table
          columns={columns}
          size="middle"
          bordered={false}
          pagination={paginationCus}
          onChange={handleTableChange}
          loading={loading}
          dataSource={dataList}
          scroll={{ x: 'calc(75% + 1000px)'}}
        />
      </Card>
      {openModal.key === 'inputBukti' && <ModalBuktiKesanggupan data={openModal.data} onFinish={e => handleCloseModal(true)} onCancel={_ => handleCloseModal()} />}
      {openModal.key === 'inputPembayaran' && <ModalInputPembayaran data={openModal.data} onFinish={e => handleCloseModal(true)} onCancel={_ => handleCloseModal()} />}
      {openModal.key === 'verifikasi' && <ModalVerifikasi data={openModal.data} onFinish={e => handleCloseModal(true)} onCancel={_ => handleCloseModal()} />}
    </div>
  );
};

export default withRouter(BeasiswaOfon);