export default {
  "data": [
    {
      "key": 10,
      "value": "10 detik"
    },
    {
      "key": 15,
      "value": "15 detik"
    },
    {
      "key": 30,
      "value": "30 detik"
    },
    {
      "key": 60,
      "value": "60 detik"
    },
    {
      "key": 120,
      "value": "2 menit"
    },
    {
      "key": 180,
      "value": "3 menit"
    },
    {
      "key": 300,
      "value": "5 menit"
    },
    {
      "key": 600,
      "value": "10 menit"
    },
    {
      "key": 900,
      "value": "15 menit"
    },
  ]
}