import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Button, Table, Card, Input, Tooltip} from "antd";
import { LoyolaServices } from "../../../service";
import {EditOutlined, SearchOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import { dialog } from "../../../functions/alert";
import { GlobalFunction } from "../../../global/function";
import EditKeputusan from "./modal-edit-keputusan";
import { FinanceService } from "../finance-service";

function SeleksiPrestasi (props) {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('pn.no_registrasi,desc');
  const [dataList, setDataList] = useState(null);
  const [selectedData, setSelectedData] = useState();

  useEffect(()=>{
    getData(pagination,sort,'')
    // getDataDummy()
  }, []);
  
  useEffect(()=>{

  }, [pagination]);

  const getData = (page,sort,search) => {
    setLoading(true);
    FinanceService.getListSeleksiPrestasi(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        const {data, page = 0, totalDataCalonSiswa} = res.data;
        // var data = res.data.data;
        console.log({data})
        setDataList(data.map((e, i) => ({...e, key: i})));
        setPagination({
          ...pagination,
          current: page+1,
          total: totalDataCalonSiswa
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,'')
  };

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  const processSearchCalonSiswa = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const columns = [
    {
      title: 'No. Registrasi',
      dataIndex: 'noRegistrasi',
      key: 'pn.no_registrasi',
      sorter: true,
      fixed: 'left',
      width: '15%'
    },
    {
      title: 'Nama Siswa',
      dataIndex: 'namaSiswa',
      key: 'nama_siswa',
      sorter: true,
    },
    {
      title: 'Beasiswa',
      dataIndex: 'beasiswa',
      key: 'b.beasiswa',
      sorter: true,
    },
    {
      title: 'Status Seleksi',
      dataIndex: 'statusSeleksi',
      key: 'tk.status_seleksi',
      sorter: true,
    },
    {
      title: 'Catatan',
      dataIndex: 'catatanSeleksi',
      key: 'pn.no_registrasi',
    },
    {
      title: 'Action',
      dataIndex: 'noRegistrasi',
      key: 'pn.no_registrasi',
      width: '10%',
      align: 'center',
      render: (id, record) => {
        return (
          <Tooltip title="Ubah keputusan seleksi">
            <Button type="link" disabled={!!record.statusSeleksi && record.statusSeleksi !== 'Belum diverifikasi'}  onClick={_ => handleEditData(id, record)} icon={<EditOutlined style={{fontSize: '1em'}} />} />
          </Tooltip>
        )
      },
    },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  const handleEditData = (id, record) => {
    // console.log({id, item})
    setSelectedData(record);
  }

    return(
      <div>
        <Row style={{marginBottom: '20px'}}>
          <Col span={12}>
            <Input
              className="input-search"
              placeholder="No. Registrasi"
              style={{
                width: '270px',
                marginRight: '10px',
                textAlign: 'left'
              }}
              prefix={<SearchOutlined/>}
              onChange={handleSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
              />
            </Card>
          </Col>
        </Row>
        {!!selectedData && <EditKeputusan 
          data={selectedData}
          onCancel={_ => setSelectedData(undefined)}
          onFinish={_ => {
            setSelectedData(undefined);
            getData({current: 1, pageSize: 10},sort, '')
          }}
          />
        }
      </div>
    );
};

export default withRouter(SeleksiPrestasi);