import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, DatePicker, Layout} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import Downloader from 'js-file-downloader'


const { RangePicker } = DatePicker;
const { Title } = Typography
const { Content } = Layout;
const fileDownload = ('js-file-download')

const DownloadHarian = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [url, setLinkUrl] = useState('')
    
    const onFinish = (values) => {
      setLoading(true)
      const rangeValue = values['range-picker'];
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      console.log('body form status : ',rangeValue[0].format('YYYY/MM/DD'),rangeValue[1].format('YYYY/MM/DD'))
      LoyolaServices.getDownloadHarian(rangeValue[0].format('YYYY/MM/DD'),rangeValue[1].format('YYYY/MM/DD')).then(res => {
        if(res.data){
          console.log('data url : ',res.data.url)
          new Downloader({url: res.data.url}).then(file => {
          }).catch(err => {console.log(err)})
          setLoading(false)
        }
      }).catch(err => {
        console.log('data url error : ',err)
        if(err){
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoading(false)
        }
      })
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
      <div style={{marginTop:20}}>
          <Row>
            <Col span={24} style={{marginBottom: 10}}>
              {<Title level={4} style={{color: '#072A6F'}}> Download Daftar Calon Siswa</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                name="download"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Row gutter={[20]}>
                  <Col span={props.responsive.isMobile ? 24 : 12}>
                    <Form.Item name="range-picker"
                      rules={[
                        {
                          required: true,
                          message: 'Tanggal harus diisi',
                        },
                      ]}
                    >
                      <RangePicker style={{width: '100%'}}/>
                    </Form.Item>
                  </Col>
                  <Col style={{textAlign: 'left'}}>
                    <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                      Download
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
      </div>
    );
};

export default withRouter(DownloadHarian);