import { Button, Col, Input, Modal, Row, Space, Form, Radio, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import { ArrowDownOutlined } from '@ant-design/icons';
import { FinanceService } from '../../finance-service';
import Downloader from "js-file-downloader";

ModalVerifikasi.propTypes = {
  onCancel: PropTypes.func,
};

ModalVerifikasi.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const normalListBerkas = [
  {key: 'berkasPrestasi', label: 'Berkas Prestasi'},
  {key: 'berkasTambahan', label: 'Berkas Tambahan'},
  {key: 'kartuKeluarga', label: 'Kartu Keluarga'},
];

const raportListBerkas = [
  ...normalListBerkas,
  {key: 'fileRapor7Smster1', label: 'Rapor kelas 7 Semester 1'},
  {key: 'fileRapor7Smster2', label: 'Rapor kelas 7 Semester 2'},
  {key: 'fileRapor8Smster1', label: 'Rapor kelas 8 Semester 1'},
  {key: 'fileRapor8Smster2', label: 'Rapor kelas 8 Semester 2'}
]

export default function ModalVerifikasi(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm(); 
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState({});

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setIsLoading(true);
    await FinanceService.getVerifikasiBeasiswaPrestasi(data.komitSiswaId).then(res => {
      const verifikasiData = res.data.data;
      console.log(verifikasiData)
      form.setFieldsValue({
        statusSeleksi: verifikasiData.statusSeleksi,
        statusPengajuanPrestasi: verifikasiData.statusPengajuanPrestasi,
        statusPengajuanTambahan: verifikasiData.statusPengajuanTambahan,
        catatanVerifikasi: verifikasiData.catatanVerifikasi,
        catatanSeleksi: verifikasiData.catatanSeleksi
      });
      setLoadedData(verifikasiData);
    }).catch(err => { 
      dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    })
    setIsLoading(false);
  }

  const handleSubmit = () => {
    form.validateFields().then((val) => {
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan keputusan verifikasi?',
        title: 'Simpan keputusan verifikasi'
      }).then(e => {
        const {statusSeleksi, catatanSeleksi, ...value} = form.getFieldsValue();
        console.log(value);
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const body = {
          komitSiswaId: data.komitSiswaId,
          userId: userId,
          statusPengajuanTambahan: null,
          statusPengajuanPrestasi: null,
          ...value
        }
        FinanceService.putVerifikasiBeasiswaPrestasi(body).then(res => {
          dialog({title: 'Data berhasil diverifikasi', icon: 'success'}).then(_ => {
            onFinish();  
          });
        }).catch(err => {
          dialog({icon: 'error', title: 'Gagal menyimpan data', text: err.response ? err.response.data.message : err.message});
        }) 
      }).catch(e => {})
    }).catch(err => {})
  }

  const handleDownload = (fileKey) => {
    console.log(fileKey, loadedData[fileKey])
    new Downloader({url: loadedData[fileKey]}).then(file => {
    }).catch(err => {
      console.log(err);
      dialog({title: 'Gagal mengunduh file', icon: 'error'})
    })
  }

  const isFormDisabled = loadedData.isVerified ? loadedData.isVerified : false;
  const isBeasiswaRaport = !!loadedData.beasiswaRapor;
  const listBerkas = isBeasiswaRaport ? raportListBerkas : normalListBerkas;
  
  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
        <Row>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Form verifikasi</Title>
          </Col>
          <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
            {loadedData.noRegistrasi} - {loadedData.namaSiswa}
          </Col>
          <Col span={24} style={{fontSize: '1.2em', paddingBottom: '.5em',  ...borderedBottom}}>
            <Space direction="vertical" size={0}>
              {listBerkas.map(berkas => {
                const isDownloadEnabled = !!loadedData[berkas.key]
                return (
                  <Button type="link" style={{padding: '0px'}} disabled={!isDownloadEnabled} onClick={_ => handleDownload(berkas.key)}>
                    <ArrowDownOutlined />Download {berkas.label}
                  </Button>
                )
              })}
            </Space>
          </Col>
          <Col span={24}>
            <Form form={form}>
              <Row>
                {(!isBeasiswaRaport && !!data.beasiswaPrestasi) && (
                  <>
                    <Col span={24}>
                      <Text>Seleksi Prestasi</Text>
                      <Form.Item
                        name="statusSeleksi"
                        style={{marginBottom: '1em'}}
                      >
                        <Radio.Group disabled={true}>
                          <Radio value={1}>Lolos</Radio>
                          <Radio value={2}>Tidak Lolos</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} >
                      <Text>Catatan Seleksi</Text>
                      <Form.Item name="catatanSeleksi">
                        <Input.TextArea className="app-input" disabled={true} />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {data.beasiswaPrestasi && (
                  <Col span={24}>
                    <Text>Beasiswa Prestasi <RedStar /></Text>
                    <Form.Item
                      name="statusPengajuanPrestasi"
                      rules={[{ required: true, message: 'Status beasiswa prestasi harus diisi!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <Radio.Group disabled={isFormDisabled}>
                        <Radio value={43}>Lolos</Radio>
                        <Radio value={44}>Tidak Lolos</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
                {data.beasiswaTambahan && (
                  <Col span={24}>
                    <Text>Beasiswa Tambahan <RedStar /></Text>
                    <Form.Item
                      name="statusPengajuanTambahan"
                      rules={[{ required: true, message: 'Status beasiswa tambahan harus diisi!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <Radio.Group disabled={isFormDisabled}>
                        <Radio value={43} >Lolos</Radio>
                        <Radio value={44} >Tidak Lolos</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Text>Catatan</Text>
                  <Form.Item name="catatanVerifikasi">
                    <Input.TextArea className="app-input" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24}>
            <Space className="end" style={{width: '100%'}}>
              {!isFormDisabled && <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>}
              <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
            </Space>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
