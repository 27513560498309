import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { Input, Row, Col, Typography, InputNumber, Button, Form} from 'antd'
import { AppLoading } from '../../../component/loading';
import Dummy from '../../../dummy';
import DropdownComponent from '../../../component/dropdown-component';
import { dialog } from '../../../functions/alert'
import { LoyolaServices } from '../../../service';
import ImagePreviewComponent from '../../../component/image-preview';
import SelectComponent from '../../../component/select-component';

const { TextArea, Title } = Input;
const { Text } = Typography;

const mappingDataSiswa = (response) => {
    if(response.Data) {
        var res = response.Data
        let countKakaLulus = 0;
        let countKakakSekolah = 0;

        res.kakakLoyola.map(res => {
            if(!res.masihSekolah){
                countKakaLulus += 1
            }else{
                countKakakSekolah += 1
            }
        })

        return{
                nama : res.namaSiswa,
                asalSmp : res.sekolahAsal,
                nisn : res.nisn,
                nik : res.nik,
                tempatLahir : res.tempatLahir,
                tanggalLahir : res.tglLahir,
                jenisKelamin : res.jenisKelamin == 1 ? 'Laki-laki' : 'Perempuan',
                agama : res.agama,
                keturunan : res.keturunan,
                alamat : res.alamatSiswa,
                rt : res.rt,
                rw : res.rw,
                kelurahan : res.kelurahan,
                kodePos : res.kodePos,
                kecamatan : res.kecamatan,
                kotaKabupaten : res.kota,
                email : res.emailSiswa,
                noTelp : res.hpSiswa,
                anakKe : res.anakKe,
                jumlahSaudara : res.jumlahSaudara,
                jumlahKakakBelajar : res.siswaJumlahKakakBelajar,
                jumlahKakakBekerja : res.siswaJumlahKakakBekerja,
                jumlahAdikLakiLaki : res.jumlahAdikLakiBelajar,
                jumlahAdikPerempuan : res.jumlahAdikPerempuanBelajar,
                jumlahAdikTiriLakiLaki : res.siswaSaudaraTiriLakilaki,
                jumlahAdikTiriPerempuan : res.siswaSaudaraTiriPerempuan,
                namaKakak : res.namaKakak,
                kelasKakak : res.kelasKakak,
                namaKakakLulus : res.namaKakakLulus,
                tahunLulusKakak : res.tahunLulus,
                fotoSiswa: res.fotoSiswa,
                akteKelahiran: res.akteKelahiran,
                kartuKeluarga: res.kartuKeluarga,
                suratPermandian: res.suratPermandian,

                verifikasiDataDiri: res.verifikasiDataDiri,
                ketVerifDataDiri: res.ketVerifDataDiri,

                kakakLoyola: res.kakakLoyola,

                isKakakMasihSekolah: countKakakSekolah,
                isKakakLulusSekolah: countKakaLulus
        }
    } else {
        return null
    }
}

const DataSiswa = (props) => {
    const dataSiswa = mappingDataSiswa(props.data)
    const [keterangan, setKeterangan] = useState(dataSiswa.ketVerifDataDiri)
    const [isLoading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useState(_ => {
        form.setFieldsValue({verifikasi: dataSiswa.verifikasiDataDiri});
        console.log('data siswa : ',mappingDataSiswa(props.data))
    }, [])

    const handleSubmit = (verifikasi) => {
        setLoading(true)
        let data = {
            "siswaId": props.match.params.id,
            "verifikasiDataDiri": verifikasi,
            "ketVerifDataDiri" : keterangan
        }
        LoyolaServices.putBiodataDiri(data.siswaId,data).then(() => {
            dialog({icon:'success', text: 'Data berhasil disimpan'}).then(() => {
                setLoading(false)
                props.onSubmit()
            })
        }).catch(err => {
            if(err){
                dialog({icon: 'error', text: err.response ? err.response.data.message : err.message}).then(setLoading(false))
            }
        })
    }
    
    const onChange = ({ target: { value } }) => {
        setKeterangan(value);
    };

    if(!dataSiswa){
        return (
            <AppLoading/>
        )
    }

    return (
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Text>Nama Siswa</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataSiswa.nama} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Asal SMP</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataSiswa.asalSmp} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>NISN</Text>
                <InputNumber className='input-modal' maxLength={10} style={{width: '100%' }} disabled={true} defaultValue={dataSiswa.nisn} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>NIK</Text>
                <InputNumber className='input-modal' maxLength={16} style={{width: '100%' }} disabled={true} defaultValue={dataSiswa.nik} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Tempat Lahir</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataSiswa.tempatLahir} readOnly={true} />
            </Col>
            <Col xs={24} md={12}>
                <Text>Tanggal Lahir</Text>
                <Input className='input-modal' style={{width: '100%'}} disabled={true} defaultValue={dataSiswa.tanggalLahir} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jenis Kelamin</Text><br/>
                <Input className='input-modal' maxLength={20} disabled={true} defaultValue={dataSiswa.jenisKelamin} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Agama</Text>
                <Input className='input-modal' maxLength={20} disabled={true} defaultValue={dataSiswa.agama} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Keturunan</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataSiswa.keturunan} readOnly={true}/>
            </Col>
            <Col span={24}>
                <Text>Alamat Lengkap </Text>
                <Input className='input-modal' maxLength={255} disabled={true} defaultValue={dataSiswa.alamat} readOnly={true}/>
            </Col>
            <Col xs={12} md={3}>
                <Text>RT</Text>
                <Input className='input-modal' maxLength={5} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.rt} readOnly={true}/>
            </Col>
            <Col xs={12} md={3}>
                <Text>RW</Text>
                <Input className='input-modal' maxLength={5} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.rw} readOnly={true}/>
            </Col>
            <Col xs={12} md={14}>
                <Text>Kelurahan</Text>
                <Input className='input-modal' maxLength={100} disabled={true} defaultValue={dataSiswa.kelurahan} readOnly={true}/>
            </Col>
            <Col xs={12} md={4}>
                <Text>Kode Pos</Text>
                <InputNumber className='input-modal' style={{width: '100%'}} maxLength={5} disabled={true} defaultValue={dataSiswa.kodePos} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kecamatan</Text>
                <Input className='input-modal' maxLength={100} disabled={true} defaultValue={dataSiswa.kecamatan} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Kota/Kabupaten</Text>
                <Input className='input-modal' maxLength={100} disabled={true} defaultValue={dataSiswa.kotaKabupaten} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>E-mail</Text>
                <Input className='input-modal' maxLength={50} disabled={true} defaultValue={dataSiswa.email} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>No. Telepon</Text>
                <Input className='input-modal' maxLength={15} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.noTelp} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Anak ke-</Text>
                <InputNumber className='input-modal' maxLength={2} min={1} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.anakKe} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>dari Total Anak</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahSaudara} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Kakak Belajar</Text>
                <InputNumber className='input-modal' maxLength={2} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahKakakBelajar} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Kakak Bekerja</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahKakakBekerja} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Adik Laki-Laki Belajar</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahAdikLakiLaki} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Adik Perempuan Belajar</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahAdikPerempuan}  readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Saudara Tiri Laki-laki</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahAdikTiriLakiLaki} readOnly={true}/>
            </Col>
            <Col xs={24} md={12}>
                <Text>Jumlah Saudara Tiri Perempuan</Text>
                <InputNumber className='input-modal' maxLength={2} min={0} style={{width: "100%"}} disabled={true} defaultValue={dataSiswa.jumlahAdikTiriPerempuan} readOnly={true}/>
            </Col>
            {/* Kakak loyola */}
            <Col span={24}>
                <div>
                    <div className="subtitle">Kakak yang bersekolah di Loyola </div>
                    {
                        dataSiswa.kakakLoyola.length > 0 && dataSiswa.kakakLoyola.map(res => {
                            if(res.masihSekolah){
                                return(
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} md={18}>
                                            <Text>Nama Kakak</Text>
                                            <Input className='input-modal' disabled={true} defaultValue={res.namaKakak} readOnly={true}/>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Text>Kelas</Text>
                                            <Input className='input-modal' disabled={true} defaultValue={res.kelasKakak} readOnly={true}/>
                                        </Col>
                                    </Row>
                                )
                            }
                        })
                    }
                    {
                        dataSiswa.isKakakMasihSekolah == 0 && <Col span={24}>-</Col>
                    }
                </div>
            </Col>
            <Col span={24}>
                <div>
                    <div className="subtitle">Kakak yang pernah bersekolah di Loyola</div>
                        { 
                            dataSiswa.kakakLoyola.length > 0 && dataSiswa.kakakLoyola.map(res => {
                                if(!res.masihSekolah){
                                    return(
                                        <Row gutter={[16,16]}>
                                            <Col xs={24} md={18}>
                                                <Text>Nama Kakak</Text>
                                                <Input className='input-modal' disabled={true} defaultValue={res.namaKakak} readOnly={true}/>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Text>Tahun Lulus</Text>
                                                <Input className='input-modal' disabled={true} defaultValue={res.tahunLulus} readOnly={true}/>
                                            </Col>
                                        </Row>
                                    )
                                }
                            })
                        }
                        {
                            dataSiswa.isKakakLulusSekolah == 0 && <Col span={24}>-</Col>
                        }
                </div>
            </Col>
            <Col span={5} style={{alignItems: 'center'}}>
                <ImagePreviewComponent uri={dataSiswa.fotoSiswa} styles={{height: '100%', width:150}}/>
            </Col>
            <Col span={24} style={{marginBottom: '1em'}}>
                <Text>Catatan</Text>
                <TextArea rows={6} value={keterangan} onChange={onChange}/>
            </Col>
            <Col xs={24} md={12}>
                <Form form={form}>
                    <Form.Item 
                        label="Verifikasi Data"
                        name="verifikasi"
                        rules={[{required: true, message: 'Status Verifikasi Data harus diisi!'}]}
                        style={{marginBottom: '0px'}}
                    >    
                        <SelectComponent 
                            isNoStyle={true} 
                            items={Dummy.verifikatorLovBool} 
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} style={{textAlign: 'right'}}>
                <Button
                    className='app-btn primary padded'
                    onClick={() => {form.validateFields().then(e => {handleSubmit(e.verifikasi)}).catch(err => {})}}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    Simpan
                </Button>
            </Col>
        </Row>
    )
  }

export default withRouter(DataSiswa)