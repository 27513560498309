import React, {useState, useEffect} from "react";
import { Layout, Space} from 'antd';
import { NavLink, Redirect, Route, useLocation } from "react-router-dom";
import { PrivateRoute } from "../../component/route";
import {AppLink} from '../../component/common';

import './finance.scss';
import SeleksiPrestasi from './seleksi-prestasi'
import Scholarship from "./scholarship";
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";

const { Content } = Layout;

export default function Finance(props) {

  useEffect(() => {
    document.title = props.name + " | Loyola"
  })

  const {pathname, ...rest} = useLocation();
  return ['/finance/', '/finance'].includes(pathname) ? (
    <Redirect to={{ pathname: "/finance/scholarship", state: { from: pathname } }} /> 
  ) : (
    <Content className="contentContainer">
      <Route path="/finance/:menu" component={FinanceMenu} />
    </Content>
  )
}


export function FinanceMenu(props) {
  const {match} = props;
  const [isFinance, setIsFinance] = useState(false)
  console.log(props.match)

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Admin Finance').then(res => {
			console.log('ada user ujian',res)
      setIsFinance(true)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])

  return (
      isFinance && 
      <div>
        <div id="links" style={{marginBottom: '2em'}}>
          <Space size="large">
            {/* <AppLink title="Seleksi Prestasi" to="/finance/seleski-prestasi" curent={match.url} /> */}
            <AppLink title="Finance Scholarship" to="/finance/scholarship" curent={match.url} />
          </Space>
        </div>
        <div>
          {/* <PrivateRoute path="/finance/seleski-prestasi" component={SeleksiPrestasi} /> */}
          <PrivateRoute path="/finance/scholarship" component={Scholarship} />

        </div>
      </div>
  )
}