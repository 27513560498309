import React, {useEffect, useState} from 'react';
import { withRouter} from "react-router-dom";
import { Typography,Space, Row, Col, Tabs, Layout, Select, Button} from 'antd'
import {LeftOutlined, LoadingOutlined, FileImageFilled} from "@ant-design/icons";
import { LoyolaServices } from '../../service';
import { dialog } from '../../functions/alert';
import DataOrtu from './component/data-ortu';
import DataSiswa from './component/data-siswa';
import { AppLoading } from '../../component/loading';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ImagePreviewComponent from '../../component/image-preview';
import NilaiPengetahuan from './component/nilai-pengetahuan';
import Auth from "../../service/auth";

const { Title, Text } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;
const settings = {
    dots: true,
    slidesToShow: 1,
    infinite: false
  }

const VerifikatorDataDiri = (props) => {
    const [isLoading,setLoading] = useState(true);
    const [dataSiswa,setSiswa] = useState(null);
    const [dataAkademik,setAkademik] = useState(null);
    const [dataOrtuAyah,setOrtuAyah] = useState(null);
    const [dataOrtuIbu,setOrtuIbu] = useState(null);
    const [dataOrtuWali,setOrtuWali] = useState(null);
    const [isTab,setTab] = useState('0');

    useEffect(() => {
        getNilaiCalosSiswa()
        getData()
    },[]);

	useEffect(() => {
		console.log('ada user check')     
		Auth.securityRole('Verifikator').then(res => {
			console.log('ada user ujian',res)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])

    const getNilaiCalosSiswa = () => {
        LoyolaServices.getNilaiCalonSiswa(props.match.params.id).then(res => {
            setAkademik(res.data)
        }).catch(err=>{
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        });
    }

    const getData = () =>{
        LoyolaServices.getBiodataDiri(props.match.params.id).then(res => {
            setSiswa(res.data)
            setLoading(false)
        }).catch(err => {})
        LoyolaServices.getBiodataDiriOrtu({siswaId: props.match.params.id, parent: 1})
        .then(res => setOrtuAyah(res.data)).catch(err => {})
        LoyolaServices.getBiodataDiriOrtu({siswaId: props.match.params.id, parent: 2})
        .then(res => setOrtuIbu(res.data)).catch(err => {})
        LoyolaServices.getBiodataDiriOrtu({siswaId: props.match.params.id, parent: 3})
        .then(res => setOrtuWali(res.data)).catch(err => {})
    }

    const nextTab = () => {
        let number = parseInt(isTab)+1
        if(number == 1 && !dataOrtuAyah){
            number+=1
        }
        if(number == 2 && !dataOrtuIbu){
            number+=1
        }
        if(number == 3 && !dataOrtuWali){
            number+=1
        }
        if(number < 5){
            setTab(number.toString())
            window.scrollTo(0, 0)
        }else{
            props.history.goBack()
        }
    }
    
    if(isLoading){
        return(
            <Content className="contentContainer">
                <AppLoading style={{height: '100%'}}/>
            </Content>
        )
    }

    return (
        <Content className="contentContainer">            
            <Row align='middle'>
                <Col span={1}>
                    <LeftOutlined 
                        onClick={props.history.goBack} 
                        style={{fontSize: props.responsive && props.responsive.isMobile? 15 : 30}}
                    />
                </Col>
                <Col span={23}>
                    {
                        props.responsive && props.responsive.isMobile ?
                        <Title level={4} className="text-heading" style={{color: '#072a6f', marginLeft: 10}}>
                            Verifikasi {props.match.params.name}
                        </Title> :
                        <Title level={1} className="text-heading" style={{color: '#072a6f'}}>
                            Verifikasi {props.match.params.name}
                        </Title>
                    }
                </Col>
            </Row>
            <Row gutter={[16, 0]} style={{marginBottom: '60px'}}>
                <Col xs={24} md={19} style={{backgroundColor: '#fff', borderRadius: 8, padding: 20}}>
                    {
                        dataSiswa && 
                        <Tabs type="card" activeKey={isTab} defaultActiveKey={0} onChange={event => setTab(event)}>
                            { dataSiswa && 
                            <TabPane tab="Data Diri" key="0" disabled={isTab != '0'}>
                                <DataSiswa data={dataSiswa} onSubmit={nextTab}/>
                            </TabPane>
                            }
                            { dataOrtuAyah &&
                            <TabPane tab="Data Ayah" key="1" disabled={isTab != '1'}>
                                <DataOrtu data={dataOrtuAyah} role={'Ayah'} kode={1} onSubmit={nextTab}/>
                            </TabPane>
                            }
                            { dataOrtuIbu &&
                            <TabPane tab="Data Ibu" key="2" disabled={isTab != '2'}>
                                <DataOrtu data={dataOrtuIbu} role={'Ibu'} kode={2} onSubmit={nextTab}/>
                            </TabPane>
                            }
                            { dataOrtuWali &&
                            <TabPane tab="Data Wali" key="3" disabled={isTab != '3'}>
                                <DataOrtu data={dataOrtuWali} role={'Wali'} kode={3} onSubmit={nextTab}/>
                            </TabPane>
                            }
                            { dataAkademik && 
                            <TabPane tab="Nilai" key="4" disabled={isTab != '4'}>
                                <NilaiPengetahuan 
                                    listNilai={dataAkademik} 
                                    onSubmit={nextTab}
                                />
                            </TabPane>
                            }
                        </Tabs>
                    }
                </Col>
                <Col xs={24} md={5}>
                    <Row offset={1} style={{marginTop: 20}}>
                        {/* image preview */}
                        <Col span={20} offset={2} style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            {
                                dataAkademik && dataAkademik.scanRapor && dataAkademik.scanRapor.length > 0 ?
                                <Slider {...settings}>
                                    {
                                        dataAkademik.scanRapor.map((response,index) => {
                                            return (
                                                <div style={{maxHeight: 300, width: 200}}>
                                                    <ImagePreviewComponent jenisBerkas={response.jenisBerkas} uri={response.url} styles={{height: '100%', width:'100%'}} index={index}/>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider> :
                                isLoading ? <LoadingOutlined /> : <div style={{minWidth: 200, minHeight: 200, alignItems: 'center', justifyContent: 'center'}}><FileImageFilled/><br/><Text>Image Preview Tidak Ada</Text></div>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Content>
    );
};

export default withRouter(VerifikatorDataDiri)
