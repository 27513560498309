import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Typography, Row, Col, Space, Menu, Dropdown, Button } from 'antd';
import Penjadwalan from "./screen/penjadwalan";
import PoinPenilaian from "./screen/penilaian";
import { CaretDownOutlined, LoadingOutlined, DownOutlined } from "@ant-design/icons";
import MasterSMP from "./screen/master-smp";
import MasterKategoriStatus from "./screen/master-kategori-status";
import MasterStatus from "./screen/master-status";
import MasterUser from "./screen/master-user";
import MasterSettingParameter from "./screen/master-setting-parameter";
import MasterKomitmenUPS from "./screen/master-komitmen-ups";
import MasterBeasiswa from "./screen/master-beasiswa";
import DownloadHarian from "./screen/download";
import DaftarSiswa from "./screen/daftar-siswa";
import DownloadDataSiswa from "./screen/download-data-siswa";
import DownloadBerkasSiswa from "./screen/download-berkas-siswa";
import DownloadBerkasKesungguhan from "./screen/download-berkas-kesungguhan";
import MasterFinance from './screen/master-finance'
import CONFIG from "../../service/config";
import JadwalNegosiasi from "./screen/jadwal-negosiasi";
import SettingDisable from "./screen/setting-disable";
import PotonganBiaya from "./screen/potongan-biaya";
import DaftarUlang from "./screen/daftar-ulang";
import JadwalUjian from './screen/jadwal-ujian'
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";

const { Content } = Layout;
const { Title } = Typography;

const menuActive = !!sessionStorage.getItem('menuAdminActive') ? sessionStorage.getItem('menuAdminActive') : 6;
const dropdownStyle = {
	position: 'absolute',
	zIndex: 20,
	backgroundColor: '#F5F6FA',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	boxShadow: 'rgb(0, 0, 0, .10) 0px 5px 5px',
	padding: '20px 10px 15px'
};

const dropdownChildStyle = {
	marginBottom: '0px',
	padding: '5px',
	// color: "#BDBDBD"
}

const { SubMenu } = Menu;


const titleMenu = (params) => {
	const menuKeys = parseInt(params)
	if (menuKeys === 0) {
		return 'Poin Penilaian'
	} else if (menuKeys === 11) {
		return 'Daftar Jadwal'
	} else if (menuKeys === 12) {
		return 'Jadwal Negosiasi'
	} else if (menuKeys === 2) {
		return 'Daftar Ulang'
	}else if (menuKeys === 13) {
		return 'Durasi Ujian Akademik'
	} else if (menuKeys === 21) {
		return 'Input Soal'
	} else if (menuKeys === 22) {
		return 'Input Nilai'
	} else if (menuKeys === 23) {
		return 'Review Jawaban'
	} else if (menuKeys === 41) {
		return 'Download Daftar Calon Siswa'
	} else if (menuKeys === 42) {
		return 'Download Data Calon Siswa'
	} else if (menuKeys === 43) {
		return 'Download Berkas Siswa'
	} else if (menuKeys === 44) {
		return 'Download Berkas Kesungguhan & Kejujuran'
	} else if (menuKeys === 31) {
		return 'SMP'
	} else if (menuKeys === 32) {
		return 'Kategori Status'
	} else if (menuKeys === 33) {
		return 'Status'
	} else if (menuKeys === 34) {
		return 'User'
	} else if (menuKeys === 35) {
		return 'Setting Parameter'
	} else if (menuKeys === 36) {
		return 'Finance'
	} else if (menuKeys === 37) {
		return 'Data Config'
	} else {
		return 'Daftar Siswa'
	}
}

const Admin = (props) => {
	const [menu, setMenu] = useState(100)
	const [menuName, setMenuName] = useState(titleMenu(menuActive))
	const [dataRegister, setDataRegister] = useState(null)
	const [isShowMenuUjian, setShowMenuUjian] = useState(false)
	const [isShowMenuMaster, setShowMenuMaster] = useState(false)
	const [isShowMenuReport, setShowMenuReport] = useState(false)
	const [showMenuPenjadwalan, setShowMenuPenjadwalan] = useState(null);
	const [activeDiv, setActiveDiv] = useState(0)
	const penjadwalanRef = useRef()
	const menuMaster = useRef()
	const menuReport = useRef()

	const handleClickOut = (e) => {
		if (penjadwalanRef.current && (!penjadwalanRef.current.contains(e.target))) {
			setShowMenuPenjadwalan(false)
		} else if (menuMaster.current && (!menuMaster.current.contains(e.target))) {
			setShowMenuMaster(false)
		} else if (menuReport.current && (!menuReport.current.contains(e.target))) {
			setShowMenuReport(false)
		}
	}

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Admin').then(res => {
			console.log('ada user ujian',res)
			setMenu(menuActive)
			setMenuName(titleMenu(menuActive))
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])
	
	useEffect(() => {
		document.title = "Admin | Loyola"
	});
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOut)
		return () => {
			document.addEventListener('mousedown', handleClickOut)
		}
	}, [])


	const changeMenu = (params) => {
		sessionStorage.setItem('menuAdminActive', params)
		setMenuName(titleMenu(params))
		setMenu(params)
		setMenuHide()
	}

	const resetData = () => {
		setDataRegister(null)
		setMenu(menu)
		setMenuHide()
	}

	const setMenuActived = (params) => {
		setMenuHide()
		setActiveDiv(params)
		console.log('params: ', activeDiv)
		if (params === 1) { //1 untuk ujian akademik
			setShowMenuUjian(true)
		} else if (params === 2) {
			setShowMenuReport(true)
		} else if (params === 3) {
			setShowMenuPenjadwalan(true)
		} else {
			setShowMenuMaster(true)
		}
	}
	const setMenuHide = (params) => {
		setShowMenuMaster(false);
		setShowMenuUjian(false);
		setShowMenuReport(false);
		setShowMenuPenjadwalan(false);
	}

	const handleClicked = (e) => {
		console.log('data clicked : ', e)
		changeMenu(e.key)
	}

	const menuMobile = (
		<Menu onClick={handleClicked}>
			<Menu.Item key={6}>Daftar Siswa</Menu.Item>
			<Menu.Item key={0}>Poin Penilaian</Menu.Item>
					<SubMenu title="Penjadwalan">
						<Menu.Item key={11}>Daftar Jadwal</Menu.Item>
						<Menu.Item key={12}>Jadwal Negosiasi</Menu.Item>
						<Menu.Item key={13}>Durasi Ujian Akademik</Menu.Item>
					</SubMenu>
			<Menu.Item key={2}>Daftar Ulang</Menu.Item>
			<SubMenu title="Report">
				<Menu.Item key={41}>Download Daftar Calon Siswa</Menu.Item>
				<Menu.Item key={42}>Download Data Calon Siswa</Menu.Item>
				<Menu.Item key={43}>Download Berkas Siswa</Menu.Item>
				<Menu.Item key={44}>Download Berkas Kesungguhan & Kejujuran</Menu.Item>
			</SubMenu>
			<SubMenu title="Master Data">
				<Menu.Item key={31}>SMP</Menu.Item>
				<Menu.Item key={32}>Kategori Status</Menu.Item>
				<Menu.Item key={33}>Status</Menu.Item>
				<Menu.Item key={35}>Setting Parameter</Menu.Item>
				{
					CONFIG.isServer !== 'Production'&& <Menu.Item key={40}>Beasiswa</Menu.Item>
				}
				{
					CONFIG.isServer !== 'Production'&& <Menu.Item key={39}>Komitmen UPS</Menu.Item>
				}
				{
					CONFIG.isServer !== 'Production'&& <Menu.Item key={38}>Potongan Biaya</Menu.Item>
				}
				{
					CONFIG.isServer !== 'Production'&& <Menu.Item key={36}>Finance</Menu.Item>
				}
				<Menu.Item key={34}>User</Menu.Item>
				<Menu.Item key={37}>Data Config</Menu.Item>
			</SubMenu>
		</Menu>
	);

	return (
		<Content className="contentContainer">
			<Row>
				<Col span={24} style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
					{
						!props.responsive.grid.md ?
							<div style={{ justifyContent: 'flex-start', textAlign: 'left' }}>
								<Dropdown overlay={menuMobile}>
									<Button style={{ width: '100%' }}>
										{menuName}<DownOutlined />
									</Button>
								</Dropdown>
							</div>
							:
							<Space size={"large"}>
								<Col className="text-heading">
									<Title level={3} style={{ color: menu == 6 ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => changeMenu(6)}>
										Daftar Siswa
								</Title>
								</Col>
								<Col className="text-heading">
									<Title level={3} style={{ color: menu == 0 ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => changeMenu(0)}>
										Poin Penilaian
								</Title>
								</Col>
									<Col className="text-heading">
										<Title level={3} style={{ color: [11, 12, 13].includes(parseInt(menu)) ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => { !showMenuPenjadwalan ? setMenuActived(3) : setMenuHide() }}>
											Penjadwalan <CaretDownOutlined />
										</Title>
										{
											showMenuPenjadwalan &&
											<Row ref={penjadwalanRef} style={{ ...dropdownStyle, cursor: 'pointer', width: '150%' }} align='middle'>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(11)}>
														Daftar Jadwal
													</Title>
												</Col>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(12)}>
														Jadwal Negosiasi
													</Title>
												</Col>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(13)}>
														Durasi Ujian Akademik
													</Title>
												</Col>
											</Row>
										}
									</Col>
								
								<Col className="text-heading">
									<Title level={3} style={{ color: menu == 2 ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => changeMenu(2)}>
										Daftar Ulang
									</Title>
								</Col>
								<Col className="text-heading">
									<Title level={3} style={{ color: (menu == 41 || menu == 42 || menu == 43 || menu == 44) ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => { !isShowMenuReport ? setMenuActived(2) : setMenuHide() }}>
										Report <CaretDownOutlined />
									</Title>
									{
										isShowMenuReport &&
										<Row ref={menuReport} style={{ ...dropdownStyle, cursor: 'pointer', width: '300%' }} align='middle'>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(41)}>
													Download Daftar Calon Siswa
											</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(42)}>
													Download Data Calon Siswa
											</Title>
											</Col>
											{/* {
											CONFIG.isServer !== 'Production' &&
											<div> */}
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(43)}>
													Download Berkas Siswa
													</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(44)}>
													Download Berkas Kesungguhan & Kejujuran
													</Title>
											</Col>
											{/* </div>
										} */}
										</Row>
									}
								</Col>
								<Col className="text-heading">
									<Title level={3} style={{ color: (menu >= 30 && menu <= 40) ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => { !isShowMenuMaster ? setMenuActived() : setMenuHide() }}>
										Master Data <CaretDownOutlined />
									</Title>
									{
										isShowMenuMaster &&
										<Row ref={menuMaster} style={{ ...dropdownStyle, cursor: 'pointer', width: '150%' }} align='middle'>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(31)}>
													SMP
											</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(32)}>
													Kategori Status
											</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(33)}>
													Status
											</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(35)}>
													Setting Parameter
											</Title>
											</Col>
											{ CONFIG.isServer !== 'Production' &&
											<div>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(40)}>
														Beasiswa
												</Title>
												</Col>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(39)}>
														Komitmen UPS
												</Title>
												</Col>
												<Col span={24}>
													<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(38)}>
														Potongan Biaya
												</Title>
												</Col>
													<Col span={24}>
														<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(36)}>
															Finance
													</Title>
													</Col>
											</div>
											}
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(34)}>
													User
											</Title>
											</Col>
											<Col span={24}>
												<Title level={4} style={{ ...dropdownChildStyle }} onClick={() => changeMenu(37)}>
													Data Config
											</Title>
											</Col>
										</Row>
									}
								</Col>
							</Space>
					}
				</Col>
			</Row>
			{
				menu == 0 ?
					<PoinPenilaian isActived={menu == 0} responsive={props.responsive} /> :
					menu == 1 ?
						<Penjadwalan isActived={menu == 1} responsive={props.responsive} /> :
						menu == 11 ?
							<Penjadwalan isActived={menu == 11} responsive={props.responsive} /> :
							menu == 12 ?
								<JadwalNegosiasi isActived={menu == 12} responsive={props.responsive} /> :
								menu == 13 ?
									<JadwalUjian isActived={menu == 13} responsive={props.responsive} /> :
									menu == 2 ?
										<DaftarUlang isActived={menu == 2} responsive={props.responsive} /> :
											menu == 31 ?
												<MasterSMP isActived={menu == 31} responsive={props.responsive} /> :
												menu == 32 ?
													<MasterKategoriStatus isActived={menu == 32} responsive={props.responsive} /> :
													menu == 33 ?
														<MasterStatus isActived={menu == 33} responsive={props.responsive} /> :
														menu == 34 ?
															<MasterUser isActived={menu == 34} responsive={props.responsive} /> :
															menu == 35 ?
																<MasterSettingParameter isActived={menu == 35} responsive={props.responsive} /> :
																menu == 39 ?
																	<MasterKomitmenUPS isActived={menu == 39} /> :
																	menu == 40 ?
																		<MasterBeasiswa isActived={menu == 40} /> :
																		// hide temporary
																		menu == 36 ?
																			<MasterFinance isActived={menu == 36} responsive={props.responsive} /> :
																			menu == 37 ?
																				<SettingDisable isActived={menu == 37} responsive={props.responsive} /> :
																				menu == 38 ?
																					<PotonganBiaya isActived={menu == 38} /> :
																					menu == 39 ?
																						(<div>Under Construction</div>) :
																						menu == 40 ?
																							(<div>Under Construction</div>) :
																							menu == 41 ?
																								<DownloadHarian isActived={menu == 41} responsive={props.responsive} /> :
																								menu == 42 ?
																									<DownloadDataSiswa isActived={menu == 42} responsive={props.responsive} /> :
																									menu == 43 ?
																										<DownloadBerkasSiswa isActived={menu == 43} responsive={props.responsive} /> :
																										menu == 44 ?
																											<DownloadBerkasKesungguhan isActived={menu == 44} responsive={props.responsive} /> :
																												menu == 6 ?
																													<DaftarSiswa isActived={menu == 6} responsive={props.responsive} /> :
																													<LoadingOutlined />
			}
		</Content>
	);
}

export default withRouter(Admin)