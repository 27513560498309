import { Button, Col, Modal, Row, Space, Form, Radio, Input, InputNumber, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import MoneyInput from '../../../../component/money-input';
import UploadComponent from '../../../../component/upload-component-image-preview';
import { FinanceService } from '../../finance-service';
import CONFIG from '../../../../service/config';
// import { Redstar } from '../../../component/common';

ModalBuktiKesanggupan.propTypes = {
  onCancel: PropTypes.func,
};

ModalBuktiKesanggupan.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

export default function ModalBuktiKesanggupan(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm();
  const [isAngsuran, setIsAngsuran] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState({});
  const [minUangMuka, setMinUangMuka] = useState('');

  useEffect(() => {
    loadData();
  }, [])
  
  useEffect(() => {
    if (isAngsuran) {
      form.setFieldsValue({uangPengembangan: loadedData.totalUps});
    } else {
      form.setFieldsValue({uangPengembangan: loadedData.totalUpsLunas});
    }
  }, [isAngsuran])

  const loadData = async () => {
    setIsLoading(true);
    await FinanceService.getBuktiKesanggupanBeasiswaPrestasi(data.komitSiswaId).then(res => {
      const {totalUps, totalUs} = res.data.data
      const dp = Math.ceil((totalUps * .3) / 1000)  * 1000;
      setMinUangMuka(dp)
      setLoadedData(res.data.data);
      form.setFieldsValue({uangPengembangan: totalUps, uangSekolahMin: totalUs})
    }).catch(err => {
      dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    })
    setIsLoading(false);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    await form.validateFields().then(val => {
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan bukti kesanggupan?',
        title: 'Simpan bukti kesanggupan'
      }).then(e => {
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const body = {
          angsuran: null,
          dpUPS: null,
          ...val,
          komitSiswaId: data.komitSiswaId,
          buktiKesanggupan: val.buktiKesanggupan.fileName,
          userId: userId
        }
        FinanceService.putBuktiKesanggupanBeasiswaPrestasi(body).then(res => {
          dialog({title: 'Bukti kesanggupan telah disimpan'}).then(_ => {
            onFinish();  
          });
        }).catch(err => {})
      }).catch(e => {})
    }).catch(err => {})
    setIsLoading(false);
  }

  const handleValueChange = (val) => {
    if ('bayarLunas' in val) {
      setIsAngsuran(val.bayarLunas === false);
      console.log(val);
    }
  }

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
      <Row>
        <Col span={24} style={{...borderedBottom}}>
          <Title level={4} className="app-title">Form Bukti Kesanggupan</Title>
        </Col>
        <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
          {loadedData.noRegistrasi} - {loadedData.namaSiswa}
        </Col>
        <Col span={24}>
          <Form form={form} onValuesChange={handleValueChange}>
            <Row>
              <Col span={24}>
                <Text>Sepakat, bersedia dan sanggup :</Text>
                <Form.Item
                  name="bayarLunas"
                  rules={[{ required: true, message: 'Jenis pembayaran harus diisi!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <Radio.Group>
                    <Radio value={true}>Dibayar Lunas</Radio>
                    <Radio value={false}>Diangsur</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>UPS <RedStar /></Text>
                <Form.Item
                  name="uangPengembangan"
                  rules={[{ required: true, message: 'Uang Pengembangan Sekolah harus diisi!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <MoneyInput className="app-input" disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>US <RedStar /></Text>
                <Form.Item
                  name="uangSekolahMin"
                  rules={[{ required: true, message: 'Uang Sekolah harus diisi!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <MoneyInput className="app-input" disabled={true} />
                </Form.Item>
              </Col>

              {!!isAngsuran && (
                <>
                  <Col span={24}>
                    <Text>Angsuran (Maksimal 10 kali angsuran) <RedStar /></Text>
                    <Form.Item
                      name="angsuran"
                      rules={[{ required: true, message: 'Jumlah angsuran harus diisi!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <InputNumber className="app-input" min={1} max={10} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Text>Uang Muka (Minimal 30%) <RedStar /></Text>
                    <Form.Item
                      name="dpUPS"
                      rules={[{ required: true, message: 'Nilai uang muka harus diisi!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <MoneyInput className="app-input" min={minUangMuka} placeholder={`${minUangMuka}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={24}>
                <Text>Bukti Kesanggupan <RedStar /></Text>
                <Form.Item
                  name="buktiKesanggupan"
                  rules={[{ required: true, message: 'Harap upload bukti kesanggupan!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <UploadComponent title="Upload bukti kesanggupan" accept=".jpg,.jpeg,.pdf,.png" className="app-input" url={`${CONFIG.BASE_URL_NOAUTH}/api/upload/buktiKesanggupan?noRegistrasi=${data.komitSiswaId}`} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Space className="end" style={{width: '100%'}}>
            <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>
            <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
          </Space>
        </Col>
      </Row>
      </Spin>
    </Modal>
  )
}
