import React, { useState, useEffect } from "react";
import { Route, Redirect, HashRouter } from "react-router-dom";
import "./app/css/global.css";
import HeaderLayout from './app/layouts/HeaderLayout'
import Auth from "./app/service/auth";
import InterviewerJalurPrestasi from "./app/views/Interviewer/jalur-prestasi"
import InterviewerJalurTestOnline from "./app/views/Interviewer/jalur-test-online"
import VerifikatorJalurPrestasi from "./app/views/Verifikator/jalur-prestasi"
import VerifikatorJalurTestOnline from "./app/views/Verifikator/jalur-test-online"
import Verifikasi from "./app/views/Verifikator/verifikasi"
import BipTesterJalurPrestasi from "./app/views/BipTester/jalur-prestasi"
import BipTesterJalurTestOnline from "./app/views/BipTester/jalur-test-online"
import Eksekutif from "./app/views/Eksekutif/eksekutif"
import InputHasilBip from "./app/views/BipTester/input-hasil"
import InputHasilInterview from "./app/views/Interviewer/input-hasil"
import LoginAdmin from "./app/views/Login"
import { Layout, Switch } from "antd";
import { AppFooter } from "./app/component/app-footer";
import Dashboard from "./app/views/Dashboard/dashboard";
import KalkulatorScenario from "./app/views/Eksekutif/kalkulator-scenario";
import Admin from "./app/views/Admin";
import RincianBiaya from "./app/views/Finance/rincian-biaya";
import ForgotPassword from "./app/views/ForgotPassword/lupa-password";
import ResetPassword from "./app/views/ForgotPassword/buat-password-baru";
import Finance from "./app/views/Finance";
import { PrivateRoute } from "./app/component/route";
import SeleksiPrestasi from "./app/views/Seleksi";
import Ujian from "./app/views/Ujian";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  var isMobile = false
  var isLandscape = false
  let grid = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
  }
  let responsive = {}
  
  if( width < 991 ){
    isMobile = true
  }
  if( width < 576 ){
    isLandscape = false
  }
  if( width > 576 ){
    isLandscape = true
  }
  //grid
  if( width < 768){
    grid.xs = true
  }
  if( width >= 768){
    grid.sm = true
  }
  if( width >= 992){
    grid.md = true
  }
  if( width >= 1200){
    grid.lg = true
  }
  if( width >= 1600){
    grid.xl = true
  }
  
  responsive = {
    width,
    height,
    isMobile: isMobile,
    isLandscape: isLandscape,
    grid: grid
  }
  
  // console.log('data responsive : ',responsive)
  return responsive;
}

function Routes() {
  const [isLogin, setIsLogin] = useState(false)
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      // console.log('data ini adalah : ',getWindowDimensions())
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // console.log(Auth.isLogin())
    setIsLogin(Auth.isLogin())
  }, []) 

  const handleLogin = () => {
    setIsLogin(true);
  }

  const logout = () => { setIsLogin(false) }

  return (
    <Layout>
      <HashRouter>
        <React.Fragment>
          <HeaderLayout userName={sessionStorage.getItem("userName")} responsive={windowDimensions} logout={logout} /> 
          <Route exact path="/" render={props => <LoginAdmin name="Login" onLogin={handleLogin} {...props}/>} /> 
          <Route exact path="/login-admin" render={props => <LoginAdmin name="Login-admin"/>} />
          <Route exact path="/lupa-password" component={ForgotPassword} />
          <Route exact path="/ubah-password" component={ResetPassword} />
          <PrivateRoute path="/finance" name="Finance" component={Finance} responsive={windowDimensions} />
          <PrivateRoute path="/seleksi" name="Seleksi Prestasi" component={SeleksiPrestasi} />
          <Route path="/verifikator-jalur-prestasi" render={props => <VerifikatorJalurPrestasi responsive={windowDimensions} {...props}/>} />
          <Route path="/verifikator-jalur-test-online" render={props => <VerifikatorJalurTestOnline responsive={windowDimensions} {...props}/>} />
          <Route path="/verifikasi/:id/:name/:jalur" render={props => <Verifikasi responsive={windowDimensions} {...props}/>}/>
          <Route path="/interviewer-jalur-prestasi" render={props => <InterviewerJalurPrestasi responsive={windowDimensions} {...props}/>} />
          <Route path="/interviewer-jalur-test-online" render={props => <InterviewerJalurTestOnline responsive={windowDimensions} {...props}/>} />
          <Route path="/beeptester-jalur-prestasi" render={props => <BipTesterJalurPrestasi responsive={windowDimensions} {...props}/>} />
          <Route path="/beeptester-jalur-test-online" render={props => <BipTesterJalurTestOnline responsive={windowDimensions} {...props}/>} />
          <PrivateRoute path="/executive" responsive={windowDimensions} component={Eksekutif} />
          <PrivateRoute path="/kalkulator" component={KalkulatorScenario} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/admin" render={props => <Admin responsive={windowDimensions}/>}/>
          <Route path="/ujian" render={props => <Ujian responsive={windowDimensions}/>}/>
          <Route path="/input-hasil-bip/:id/:name/:jalur/:flagDataKesungguhanExist" render={props => <InputHasilBip responsive={windowDimensions} {...props}/>}/>
          <Route path="/input-hasil-interview/:id/:name/:jalur/:flagDataKesungguhanExist" render={props => <InputHasilInterview responsive={windowDimensions} {...props}/>}/>
          <PrivateRoute path="/rincian-biaya/:id/:name/:jalur" component={RincianBiaya}/>
        </React.Fragment>
      </HashRouter>
      <AppFooter/>
    </Layout>
  )
}

export default Routes;
