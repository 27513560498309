import React, { useEffect, useState } from "react";
import { Layout, Typography, Row, Col, Select, message, Dropdown, Button, Menu } from "antd";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import Auth from "../../service/auth";
import Logo from '../../../assets/logo-loyola-300x300.svg'
import HandleRole from "../../views/Login/handle-role";
import { CaretDownOutlined, DownOutlined, SettingFilled } from "@ant-design/icons";

const { Header } = Layout
const { Text } = Typography
const { Option } = Select

const HeaderLayout = (props) => {
    const history = useHistory();
    let location = useLocation();
    const user = JSON.parse(sessionStorage.getItem('user'));
    const isExecutive = user ? user.authorities[0].authority === "Executive" : false;
    
    const handleLogout = () => {
      Auth.logout();
      props.history.replace("/")
    }

    const goToHome = () =>{
      var res = JSON.parse(sessionStorage.getItem('user'))
      var role = res.authorities[0].authority // get role
      console.log("data user ",res,role)
      if(sessionStorage.getItem('token')){
        const loc = HandleRole(role);
        history.push(loc);
      }
    }

    const pengaturanMobile = (
      <Menu>
        <Menu.Item value="change-password" onClick={_ => {
          history.push('/ubah-password');
        }}> 
          Ubah Password
        </Menu.Item>
        <Menu.Item value="logout" style={{color: '#eb5757'}} onClick={_ => {
          handleLogout();
        }}>
          Logout
        </Menu.Item>
      </Menu>
    );

    const pengaturan = (
      <Menu>
        <Menu.Item value="change-password" onClick={_ => {
          history.push('/ubah-password');
        }}> 
          Ubah Password
        </Menu.Item>
      </Menu>
    )

    const menu = (
      <Menu>
        <Menu.Item value="dashboard" onClick={_ => {
          history.push('/dashboard');
        }}>
          Dashboard
        </Menu.Item>
        <Menu.Item value="kalkulator" onClick={_ => {
          history.push('/kalkulator');
        }}> 
          Kalkulator Skenario
        </Menu.Item>
      </Menu>
    )
    
    useEffect(() => {
    }, [])

    return !['/', '/lupa-password', '/ubah-password', '/login-admin'].includes(location.pathname) ? (
      <div style={{height: 80}}>
        <Header className="header-loyola">
          <Row style={{justifyContent: 'space-between'}}>
            <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
              <img src={Logo} alt="logo" style={{height: "50px", cursor: 'pointer'}} onClick={_ => goToHome()} />
              {
                // !props.responsive.isMobile && 
                <Text className="title">{JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).namaAdmin}</Text>
              }
            </Col>
            {props.responsive.isMobile ? (
              <Col style={{flex: '1', textAlign: 'right'}}>
                {!isExecutive ? (
                  <Text 
                    className="menu"
                    onClick={() => history.push('/dashboard')}
                  >
                    Dashboard
                  </Text>
                ) : (
                  <Dropdown overlay={menu} placement="bottomCenter" arrow>
                    <Text className="menu" style={{marginLeft: '1em'}}>Menu <DownOutlined /></Text>
                  </Dropdown>
                )}
                <Dropdown overlay={pengaturanMobile} placement="bottomCenter" arrow>
                  <Text className="menu" style={{marginLeft: '1em'}}>Pengaturan <DownOutlined /></Text>
                </Dropdown>
              </Col>
            ) : (
              <Col style={{flex: '1', textAlign: 'right'}}>
                {isExecutive && (
                <Text 
                  className="menu"
                  onClick={() => history.push('/kalkulator')}
                >
                  Kalkulator Skenario
                </Text>
                )}
                <Text 
                  className="menu"
                  onClick={() => history.push('/dashboard')}
                >
                  Dashboard
                </Text>
                <Dropdown overlay={pengaturan} placement="bottomCenter"  trigger={['click']}>
                  <Text className="menu" style={{marginLeft: '1em'}}>Pengaturan <DownOutlined /></Text>
                </Dropdown>
                <Text 
                  className="menu"
                  style={{color: '#eb5757'}}
                  onClick={() => {handleLogout();}}
                >
                  Logout
                </Text>
              </Col>
            )}
          </Row>
        </Header>
      </div>
    ) : (<></>);
}

export default withRouter(HeaderLayout);