export default [
    {
        tanggal: "20/07/2020",
        tipe: "UPS",
        nominal: "2000000",
        catatan: "Hasil negosiasi akhir dengan orang tua murid"
    },
    {
        tanggal: "20/07/2020",
        tipe: "USM",
        nominal: "800000",
        catatan: "Hasil negosiasi akhir dengan orang tua murid"
    },
    {
        tanggal: "19/07/2020",
        tipe: "UPS",
        nominal: "3500000",
        catatan: "nominal awal"
    },
    {
        tanggal: "19/07/2020",
        tipe: "USM",
        nominal: "800000",
        catatan: "nominal awal"
    },
]