import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";

const { Title } = Typography
const FormSMP = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
      if(props.isEdit){
        form.setFieldsValue(props.isEdit);
      }else{
        form.resetFields()
      }
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      if(props.isEdit){
        let body = {
          smpId: props.isEdit.smpId,
          namaSMP: values.namaSMP,
          alamatSMP: values.alamatSMP
        };
        LoyolaServices.putMasterDataSmp(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:res.data.message}).then(()=>{
              props.onRefresh();
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            let text = err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
            dialog({icon: 'error', text: text});
            setLoading(false)
          }
        })
      }else{
        let body = {
          namaSMP: values.namaSMP,
          alamatSMP: values.alamatSMP
        };
        LoyolaServices.postMasterDataSmp(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text:res.data.message}).then(()=>{
              props.onRefresh();
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            let text = err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
            dialog({icon: 'error', text: text});
            setLoading(false)
          }
        })
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row gutter={[0,24]}>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} SMP</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                layout="vertical"
                name="SMP"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item name="namaSMP"
                  label="Nama Sekolah"
                  rules={[
                    {
                      required: true,
                      message: 'Nama Sekolah harus diisi',
                    },
                  ]}
                >
                  <Input
                      maxLength={255}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                      placeholder="Nama Sekolah"
                  />
                </Form.Item>
                <Form.Item name="alamatSMP"
                  label="Alamat Sekolah"
                  rules={[
                    {
                      required: true,
                      message: 'Alamat Sekolah harus diisi',
                    },
                  ]}
                >
                  <Input.TextArea
                      maxLength={255}
                      style={{height: 170, backgroundColor: "#F2F2F2", borderRadius: 16}}
                      placeholder="Alamat Sekolah"
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormSMP);