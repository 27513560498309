import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { Form, Input, Row, Col, Typography, Checkbox, Select, Button, Spin } from 'antd'
import { CheckCircleFilled, CloseCircleFilled, CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons';
import Dummy from '../../../dummy';
import DropdownComponent from '../../../component/dropdown-component';
import { LoyolaServices } from '../../../service';
import { dialog } from '../../../functions/alert'
import moment from 'moment';
import { AppLoading } from '../../../component/loading';
import SelectComponent from '../../../component/select-component';


const { TextArea } = Input;
const { Option } = Select
const { Text, Title } = Typography;
const borderedCellStyle = { borderBottom: "1px solid #e0e0e0", marginBottom: '1em' };
const inputNumberConfig = { min: 0, max: 100, maxLength: 4, style: { width: "100%", fontWeight: 'bold' } }

const mapingDataRapor = (data) => {
  let dataTemp = []
  data.map(res => {
      dataTemp.push({raporId : res.raporId, verifikasi: res.verifikasi ? true : false})
  })
  return dataTemp
}
const NilaiPengetahuan = (props) => {
  
    const point = props.listNilai.poin ? props.listNilai.poin : 0;
    const [keterangan, setKeterangan] = useState(props.listNilai.catatan)
    const [lulusKkm, setLulusKkm] = useState(true)
    const [jumlahNilaiMatpel, setJumlahNilaiMatpel] = useState(point>=400)
    const [jumlahNilaiMatpelPerSemester, setJumlahNilaiMatpelPerSemester] = useState(props.listNilai.nilaiPerSemester)
    const [listMatpel, setListMatpel] = useState(props.listNilai.nilai)
    const [listKehadiran, setListKehadiran] = useState(props.listNilai.kehadiran)
    const [isLoading, setLoading] = useState(false)
    const [lov, setLovStatusDokumen] = useState([])
    const [isSelectAll, setIsSelectAll] = useState(true)
    const [isSelectAllKehadiran, setIsSelectAllKehadiran] = useState(true)
    const [form] = Form.useForm();
    
    const isJalur = (props.match.params.jalur == 'prestasi')

    useEffect(()=>{
      if(props.listNilai.nilai){
        if(isJalur){
          props.listNilai.nilai.map(res => {
            console.log("matpel : ",res.namaMatpel,res.kkmKelas7,parseInt(res.kelas7Sem1),parseInt(res.kelas7Sem2),parseInt(res.kkmKelas8),res.kelas8Sem1,res.kelas8Sem2)
            let kkm7 = parseInt(res.kkmKelas7)
            let n71 = parseInt(res.kelas7Sem1)
            let n72 = parseInt(res.kelas7Sem2)
            let kkm8 = parseInt(res.kkmKelas8)
            let n81 = parseInt(res.kelas8Sem1)
            let n82 = parseInt(res.kelas8Sem2)
            console.log(kkm7>n71,kkm7>n72,kkm8>n81,kkm8>n82)
            if(kkm7>n71 || kkm7>n72 || kkm8>n81 || kkm8>n82){
                  setLulusKkm(false)
              }
            })
          }
      }
    },[props.listNilai.nilai])

    useEffect(() => {
      form.setFieldsValue({verifikasi: props.listNilai.statusVerifikasiDokumen});
      getLovKategoriStatus(6) //statusDokument
      checkSelectedAll(true)
      checkSelectedAllKehadiran(true)
    }, []) 
    
    const handleSubmit = async (verifikasi) => {
      setLoading(true)
      let raporSiswa = await mapingDataRapor(listMatpel)
      await listKehadiran.map(res => {
        raporSiswa.push({raporId: res.raporId, verifikasi: res.verifikasi})
      })
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let data = {
          siswaId: props.match.params.id,
          keterangan: keterangan,
          statusDokumen: verifikasi,
          lulusKkm: lulusKkm ? lulusKkm : false, //jika set false jika tidak ada
          jumlahNilaiMatpel: jumlahNilaiMatpel ? jumlahNilaiMatpel : false, //set false jika tidak ada
          raporSiswa: raporSiswa
      }

      console.log("data : ",data)
      LoyolaServices.putVerifikasiData(data.siswaId,userId,data).then(() => {
          dialog({icon:'success', text: 'Data berhasil disimpan'}).then(() => {
              setLoading(false)
              props.onSubmit()
          })
      }).catch(err => {
        if(err){
          console.log(err)
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message}).then(setLoading(false))
        }
      })
    }

    const getLovKategoriStatus = (params) => {
      setLoading(true)
      LoyolaServices.getLovKategoriStatusDokumen(params).then(res => {
          setLovStatusDokumen(res.data.data)
          setLoading(false)
      }).catch(err=>{
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      });
  }
    const handleChange = (record,index,verifikasi) => {
      let newArr = [...listMatpel];
      newArr[index].verifikasi = verifikasi; 
      checkSelectedAll(verifikasi)
      setListMatpel(newArr);
    }

    const handleChangeKehadiran = (record,index,verifikasi) => {
      let newArr = [...listKehadiran];
      newArr[index].verifikasi = verifikasi; 
      checkSelectedAllKehadiran(verifikasi)
      setListKehadiran(newArr);
    }

    const setSelectAll = (verifikasi) => {
      let newArr = [...listMatpel]; 
      newArr.map(res => {
        res.verifikasi = verifikasi
      })
      setIsSelectAll(verifikasi)
      setListMatpel(newArr); 
    }
    
    const setSelectAllKehadiran = (verifikasi) => {
      let newArr = [...listKehadiran]; 
      newArr.map(res => {
        res.verifikasi = verifikasi
      })
      setIsSelectAllKehadiran(verifikasi)
      setListKehadiran(newArr); 
    }

    const checkSelectedAll = (status) => {
      console.log("checkSelectedAll : ",status)
      let value = status
      listMatpel.map(res => {
        if(!res.verifikasi){
          value=false
        }
      })
      setIsSelectAll(value)
    }

    const checkSelectedAllKehadiran = (status) => {
      console.log('checking kehadiran : ',status,listKehadiran)
      let value = status
      listKehadiran.map(res => {
        if(!res.verifikasi){
          value=false
        }
      })
      setIsSelectAllKehadiran(value)
    }

    const colorNilai = (kkm,value) => {
      let color = '#219653'
      if(kkm > value){
        color = '#EB5757'
      }
      return color
    }

    return (
      <Spin spinning={isLoading}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <div className="ant-table app-table">
              <div className="ant-table-container">
                <div className="ant-table-content" style={{overflowX: 'auto'}}>
                  <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
                    <thead style={{textAlign: "center"}}>
                      <tr>
                        <td style={{...borderedCellStyle, width: "30%", textAlign: "left"}} rowSpan={2} >Mata Pelajaran</td>
                        {/* <td style={{...borderedCellStyle, width: "40px", textAlign: "right"}} rowSpan={2}></td> */}
                        <td colSpan={3}>KELAS VII</td>
                        <td colSpan={3}>KELAS VIII</td>
                      </tr>
                      <tr>
                        <td style={borderedCellStyle}>KKM</td>
                        <td style={borderedCellStyle}>Smt 1</td>
                        <td style={borderedCellStyle}>Smt 2</td>
                        <td style={borderedCellStyle}>KKM</td>
                        <td style={borderedCellStyle}>Smt 1</td>
                        <td style={borderedCellStyle}>Smt 2</td>
                        <td style={borderedCellStyle}>
                        { isSelectAll ?
                          (<CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid" onClick={()=>setSelectAll(false)} />) : 
                          (<CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  onClick={()=>setSelectAll(true)}/>)
                        }
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      listMatpel && listMatpel.map((field, index) => {
                            const mataPelajaran = listMatpel[index]; 
                            // const matpelValidProp = !!value.value ? countValidProp(value.value[index]) : 0;

                              return (
                                <tr key={'field-nilai'+index}>
                                  <td>{mataPelajaran.namaMatpel} </td>
                                  {/* <td></td> */}
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kkmKelas7} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: '#219653'}}/>
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kelas7Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(mataPelajaran.kkmKelas7,mataPelajaran.kelas7Sem1)}} />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kelas7Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(mataPelajaran.kkmKelas7,mataPelajaran.kelas7Sem2)}}  />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kkmKelas8} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: '#219653'}} />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kelas8Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(mataPelajaran.kkmKelas8,mataPelajaran.kelas8Sem1)}} />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={mataPelajaran.kelas8Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(mataPelajaran.kkmKelas8,mataPelajaran.kelas8Sem2)}} />
                                  </td>
                                  <td style={{width: '20px'}}>
                                    {mataPelajaran.verifikasi ? 
                                    (<CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid" onClick={()=>handleChange(mataPelajaran,index,false)} />) : 
                                    (<CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  onClick={()=>handleChange(mataPelajaran,index,true)}/>)}
                                  </td>
                                </tr>
                              )
                          })
                    }
                    </tbody>
                    {
                      jumlahNilaiMatpelPerSemester &&
                      <tfoot>
                        <tr>
                          <td>Jumlah nilai 5 mapel </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={'-'} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: '#219653'}}/>
                          </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={jumlahNilaiMatpelPerSemester.kelas7Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(400,jumlahNilaiMatpelPerSemester.kelas7Sem1)}} />
                          </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={jumlahNilaiMatpelPerSemester.kelas7Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(400,jumlahNilaiMatpelPerSemester.kelas7Sem2)}}  />
                          </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={'-'} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: '#219653'}}/>
                          </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={jumlahNilaiMatpelPerSemester.kelas8Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(400,jumlahNilaiMatpelPerSemester.kelas8Sem1)}} />
                          </td>
                          <td>
                            <Input className="input-modal"  {...inputNumberConfig} defaultValue={jumlahNilaiMatpelPerSemester.kelas8Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(400,jumlahNilaiMatpelPerSemester.kelas8Sem2)}} />
                          </td>
                        </tr>
                      </tfoot>
                    }
                  </table>
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="ant-table app-table">
              <div className="ant-table-container">
                <div className="ant-table-content" style={{overflowX: 'auto'}}>
                  <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
                    <thead style={{textAlign: "center"}}>
                      <tr>
                      <td style={{...borderedCellStyle, width: "30%", textAlign: "left"}} rowSpan={2} >Jenis Kehadiran</td>
                        {/* <td style={{...borderedCellStyle, width: "40px", textAlign: "right"}} rowSpan={2}></td> */}
                        <td colSpan={2}>KELAS VII</td>
                        <td colSpan={2}>KELAS VIII</td>
                      </tr>
                      <tr>
                        <td style={borderedCellStyle}>Smt 1</td>
                        <td style={borderedCellStyle}>Smt 2</td>
                        <td style={borderedCellStyle}>Smt 1</td>
                        <td style={borderedCellStyle}>Smt 2</td>
                        <td style={borderedCellStyle}>
                        { isSelectAllKehadiran ?
                          (<CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid" onClick={()=>setSelectAllKehadiran(false)} />) : 
                          (<CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  onClick={()=>setSelectAllKehadiran(true)}/>)
                        }
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      listKehadiran && listKehadiran.map((field, index) => {
                            const kehadiran = listKehadiran[index]; 
                            // const matpelValidProp = !!value.value ? countValidProp(value.value[index]) : 0;

                              return (
                                <tr key={'field-nilai'+index}>
                                  <td>{kehadiran.jenisKehadiran} </td>
                                  {/* <td></td> */}
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={kehadiran.kelas7Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(0,kehadiran.kelas7Sem1)}} />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={kehadiran.kelas7Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(0,kehadiran.kelas7Sem2)}}  />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={kehadiran.kelas8Sem1} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(0,kehadiran.kelas8Sem1)}} />
                                  </td>
                                  <td>
                                    <Input className="input-modal"  {...inputNumberConfig} defaultValue={kehadiran.kelas8Sem2} readOnly={true} style={{fontWeight: 'bold', padding: '4px', textAlign: 'center', color: colorNilai(0,kehadiran.kelas8Sem2)}} />
                                  </td>
                                  <td style={{width: '20px'}}>
                                    {kehadiran.verifikasi ? 
                                    (<CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid" onClick={()=>handleChangeKehadiran(kehadiran,index,false)} />) : 
                                    (<CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  onClick={()=>handleChangeKehadiran(kehadiran,index,true)}/>)}
                                  </td>
                                </tr>
                              )
                          })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
          <Col span={24} style={{marginBottom: '1em'}}>
            <Text style={{fontWeight: "bold",fontSize: 24, color: '#000000'}}>Catatan</Text>
            <TextArea style={{minHeight:150}} value={keterangan} onChange={text => setKeterangan(text.target.value)}/>
          </Col>
            <Col xs={24} md={12}>
              <Form form={form}>
                <Form.Item 
                  label="Verifikasi Data"
                  name="verifikasi"
                  rules={[{required: true, message: 'Status Verifikasi Data harus diisi!'}]}
                  style={{marginBottom: '0px'}}
                >    
                  <SelectComponent 
                    isNoStyle={true} 
                    items={lov} 
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </Form>
            </Col>
          <Col span={1}></Col>
            {
              isJalur &&
                <Col xs={24} md={8} style={{display: 'flex', alignItems: 'center'}}>
                  <Form.Item>
                    { lulusKkm ? <CheckSquareFilled style={{color: "#27ae60", fontSize: "1.2em"}} /> : <CloseSquareFilled style={{color: "#eb5757", fontSize: "1.2em"}} /> }<Text style={{marginLeft: 5, marginRight: 20}}> Lulus KKM</Text> 
                    { jumlahNilaiMatpel ? <CheckSquareFilled style={{color: "#27ae60", fontSize: "1.2em"}} /> : <CloseSquareFilled style={{color: "#eb5757", fontSize: "1.2em"}} /> }<Text style={{marginLeft: 5}}> Jumlah Nilai</Text>
                  </Form.Item>
                </Col>
            }
            <Col span={24} style={{textAlign: 'right'}}>
                <Button
                  className='app-btn primary padded'
                  onClick={() => {form.validateFields().then(e => {handleSubmit(e.verifikasi)}).catch(err => {})}}
                  disabled={isLoading}
                  loading={isLoading}
                >
                    Simpan
                </Button>
            </Col>
          {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
        </Row>
      </Spin>
    )
  }

export default withRouter(NilaiPengetahuan)