import React from "react";
import {NavLink, withRouter, Link} from "react-router-dom";
import { Layout, Row, Typography, Col, Input, Space, Card, Table, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, QrcodeOutlined, DownOutlined } from "@ant-design/icons";
import ModalQR from "../../component/modal-scan-qr";
import axios from "axios";
import CONFIG_INITIAL_STATE from "../../service/config";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../global/function";
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";

const {Content} = Layout;
const {Title, Text} = Typography;
const menuKey = [
    { key: 'Prestasi', label: 'Jalur Prestasi' },
    { key: 'TesOnline', label: 'Jalur Tes' },
]

class BipTesterOnline extends React.Component{
    constructor(props) {
        super(props);
        document.title = "Beep Tester | Loyola"

        this.state = {
            isModalShow: false,
            loading: true,
            search: "",
            sort: "nama_siswa,desc",
            totalCalonSiswa : 0,
            totalCalonSiswaDiwawancara : 0,
            pagination: {
                current: 1,
                pageSize: 10,
            },
            responsive: props.responsive,
            jalur: 'TesOnline'
        }
    }

    handleModalQR = () => {
        this.setState({isModalShow : !this.state.isModalShow})
    };

    handleSearch = (e) => {
        let key = e.target.value;
        this.processSearchCalonSiswa(key);
    };

    handleTableChange = (pagination, search, sorter) => {
        if (sorter.order === "ascend") {
            sorter.order = "asc";
        } else if (sorter.order === "descend") {
            sorter.order = "desc";
        }

        this.setState({
            sort: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'nama_siswa,desc'}`,
            pagination: pagination
        }, () => {
            this.getDataCalonSiswa(
                this.state.search,
                this.state.sort,
                this.state.pagination.current - 1,
			    this.state.pagination.pageSize
            )
        });
    };

    componentDidMount() {
		Auth.securityRole('Beep Tester').then(res => {
            this.getDataCalonSiswa(
                this.state.search,
                this.state.sort,
                this.state.pagination.current - 1,
                this.state.pagination.pageSize
            )
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				this.props.history.replace('/')
			}
		})
    }

    config(){
       return {
           headers: {
               Authorization: `Bearer ${sessionStorage.getItem('token')}`
           }
       }
    };

    async getDataCalonSiswa(search, sort, page, size){
        this.setState({
            loading: true,
        });
        let temp = [];
        let totalData = 0;
        let totalDataCalonSiswa = 0;
        let totalDataCalonSiswaDiwawancara = 0;

        let api = `/api/bipTester/dataJalurTesOnline?noRegistrasi=${search}&sort=${sort}&size=${size}&page=${page}`
        await axios.get(CONFIG_INITIAL_STATE.BASE_URL + api, this.config())
            .then(res => {
                temp = res && res.data && res.data.data ? res.data.data : []
                totalDataCalonSiswa = res && res.data && res.data.totalDataSiswa ? res.data.totalDataSiswa : 0
                totalDataCalonSiswaDiwawancara = res && res.data && res.data.totalDiTes? res.data.totalDiTes : 0
                totalData = res.data.totalDataSiswa
            }).catch(err => {
                console.error(err);
            });

        this.setState({
            listCalonSiswa: temp,
            loading:false,
            totalCalonSiswa : totalDataCalonSiswa,
            totalCalonSiswaDiwawancara : totalDataCalonSiswaDiwawancara,
            pagination: {
                ...this.state.pagination,
                total: totalData
            }
        })
    }

    processSearchCalonSiswa = _debounce((key) => {
        this.setState({
            search: GlobalFunction.searchEncode(key)
        }, () => {
            this.getDataCalonSiswa(
                this.state.search,
                this.state.sort,
                this.state.pagination.current - 1,
                this.state.pagination.pageSize
            );
        });
    }, 500);

    handleJalur(e){
        if(e == 'Prestasi'){
            this.props.history.push('/beeptester-jalur-prestasi')
        }
    }

    render() {
        const { responsive, jalur, listCalonSiswa, loading, pagination, totalCalonSiswaDiwawancara, totalCalonSiswa } = this.state;
        const dropdownLabel = menuKey.find(e => e.key === jalur).label;

        const tagColor = (tag) => {
            tag = tag.toLowerCase();
            switch (tag) {
                case 'lolos':
                case 'diterima':
                    return '#219653';
                case 'belum di-test':
                case 'belum diwawancara':
                    return '#072A6F';
                case 'cadangan':
                case 'ragu-ragu':
                    return '#F2C94C';
                case 'tidak lolos':
                case 'ditolak':
                case 'tidak diterima':
                    return '#EB5757';
                default:
                    return '#072A6F';
            }
        };

        const columns = [
            {
                title: 'No. Registrasi',
                dataIndex: 'noRegistrasi',
                key: 'pn.no_registrasi',
                width: '20%',
                sorter: true,
            },
            {
                title: 'Nama',
                dataIndex: 'namaSiswa',
                key: 'nama_siswa',
                width: '20%',
                sorter: true,
            },
            {
                title: 'Asal Sekolah',
                dataIndex: 'asalSekolah',
                key: 'sekolah_asal',
                width: '20%',
                sorter: true,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 's_rekomendasi_bip_tes.status',
                width: '20%',
                sorter: true,
                render: tag => {
                    if(tag === null || tag === undefined){
                        tag = "belum di-test"
                    }
                    let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )
                }
            },
            {
                width: '20%',
                render: (record) => {
                    let value = record.status
                    if(!value){;
                        value = "belum di-test"
                    }
                    if (value.toLowerCase() === "belum di-test"){
                        return (
                            <Space>
                                <Link to={"/input-hasil-bip/" + record.noRegistrasi + "/" + record.namaSiswa + "/tes-online/"+ record.flagDataKesungguhanExist}>
                                    <Button className="admin-update-button" type="primary" size="small">
                                        Input Penilaian
                                    </Button>
                                </Link>
                            </Space>
                        )
                    }
                }
            },
        ];

        return(
            <Content className="contentContainer">                
                {responsive && responsive.grid.md ? (
                    <Row>
                        <Col>
                            <Space size={"large"}>
                                {menuKey.map(({key, label}) => (
                                    <a type="text" onClick={() => {this.handleJalur(key)}} className="text-heading">
                                        <Title level={3} style={{color: jalur === key ? '#072a6f' : '#BDBDBD' }}>
                                            {label}
                                        </Title>
                                    </a>
                                ))}
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <div style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                        <Dropdown 
                            overlay={(
                                <Menu onClick={e => this.handleJalur(e.key)}>
                                    {menuKey.map(({key, label}) => (
                                        <Menu.Item key={key}>{label}</Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <Button style={{width: '100%', marginBottom: '24px'}}> {dropdownLabel}<DownOutlined/> </Button>
                        </Dropdown>
                    </div>
                )}
                <Row align="middle" >
                    <Col span={24} lg={6}>
                        <Input
                            className="input-search"
                            placeholder="No. Registrasi"
                            style={{
                                width: '100%',
                                marginRight: '10px',
                                textAlign: 'left'
                            }}
                            prefix={<SearchOutlined/>}
                            onChange={(e) => this.handleSearch(e)}
                        />
                    </Col>
                    <Col span={24} lg={18} style={{ textAlign: responsive.grid.xs && responsive.width < 640 ? 'left' : 'right' }}>
                        <Row justify='end'>
                            <Col>
                                <Button type="text" onClick={this.handleModalQR}>
                                    <QrcodeOutlined style={{fontSize:25, verticalAlign:'middle', justifyContent:'right', color: '#072A6F'}}/> <Text className='title-scanqr'>Scan QR</Text>
                                </Button>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Text className="title-verifikator" >{totalCalonSiswaDiwawancara} dari {totalCalonSiswa} calon siswa telah dites</Text>
                        </Row>
                    </Col>
                    <ModalQR
                        visible={this.state.isModalShow}
                        buttonCancel={this.handleModalQR}
                        routeRole={"input-hasil-bip"}
                        routeJalur={"test-online"}
                        type={'biptes'}
                    />
                </Row>
                <Row style={{marginBottom: 10, marginTop:24}}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Table
                                columns={columns}
                                size="middle"
                                bordered={false}
                                dataSource={listCalonSiswa}
                                loading={loading}
                                pagination={{...pagination, showSizeChanger: false, position: ['none', 'bottomCenter']}}
                                onChange={this.handleTableChange}
                                scroll={{x: responsive && responsive.grid.xs ? responsive.width : 0}}
                            />
                        </Card>
                    </Col>
                </Row>
            </Content>
        );
    }
}

export default withRouter(BipTesterOnline)