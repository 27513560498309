import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
// import Dummy from "../../../dummy";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { dialog, confirmDialog } from "../../../functions/alert";
import FormPenilaianBiptester from "../component/form-penilaian-biptester";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";

const { Content } = Layout;
const { Title, Text } = Typography;

const deleteDialog = {
  icon: 'info', 
  text: 'Apakah Anda yakin akan menghapus data ini?', 
  buttons: {
    confirm: {
      text: "Hapus",
      value: true,
      visible: true,
      className: 'app-btn primary'
    },
    cancel: {
      text: "Batal",
      value: false,
      visible: true,
      className: 'app-btn danger'
    }
  }
}

const PenilaianBipTes = (props) => {

    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [sort, setSorting] = useState('created_on,desc');
    const [search, setSearch] = useState('');
    const [dataList, setDataList] = useState(null);
    const [isShow, setShow] = useState({show: false, record:null});

    useEffect(()=>{
      getData(pagination,sort, search)
      // getDataDummy()
    }, []);

    // const getDataDummy = () => {
    //   setDataList(Dummy.adminDataPertanyaanEsai)
    //   setLoading(false)
    // }

    const deleteData = (record) => {
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      LoyolaServices.deletePenilaianBipTester(userId,record.bipId).then(res => {
        if(res.data){
          dialog({icon: 'success', text: 'Aspek Penilaian Beep Test berhasil dihapus'})
          onRefresh()
        }
      }).catch(err => {
        if(err){
          if(props.isActived){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          }
        }
      })
    }

    const getData = (page,sortParam, search) => {
      let keyword = search ? GlobalFunction.searchEncode(search)  : ''
      setLoading(true)
      LoyolaServices.getDataPenilaianBipTester(page.current - 1, page.pageSize, sortParam, keyword).then(res => {
        if(res.data){
          var data = res.data.data
          // console.log(data)
          setDataList(data)
          setPagination({
            ...pagination,
            current: res.data.page + 1,
            total: res.data.total
          })
          setLoading(false)
        }
      }).catch(err => {
        if(err){
          if(props.isActived){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          }
          // setDataList([])
          setLoading(false)
        }
      })
    }

    const handleTableChange = (paginationParam, filters, sorter) => {
      // console.log(paginationParam, filters, sorter, extra);
      if (sorter.order === "ascend") {
          sorter.order = "asc";
      } else if (sorter.order === "descend") {
          sorter.order = "desc";
      }
      let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'aspek_penilaian,desc'}`
      setSorting(sorting)
      setPagination(paginationParam)
      getData(paginationParam,sorting,search)
    };

    const onRefresh = () => {
      // console.log('data onrefresh : ')
        setShow({show: false, record: null})
        getData(pagination,sort,search)
    }

    const handleSearch = (e) => {
      let key = e.target.value;
      processSearchCalonSiswa(key);
    };
  
    const processSearchCalonSiswa = _debounce((key) => {
      setSearch(key)
      getData({current: 1, pageSize: 10},sort,key)
    }, 500);

    const columns = [
      {
          width: '70%',
          title: 'Aspek Penilaian',
          dataIndex: 'aspekPenilaian',
          key: 'aspek_penilaian',
          sorter: true,
      },
      {
          width: '20%',
          title: 'Aksi',
          render: (record) => {
            return (
              <Row gutter={[20,10]}>
                <Col>
                  <Button className="app-btn" style={{backgroundColor: '#EF712B', color: '#fff'}} onClick={() => setShow({show: true, record: record})}>
                    Ubah
                  </Button>
                </Col>
                <Col>
                  <Button className="app-btn" type='primary' danger onClick={() => confirmDialog(deleteDialog).then(()=>deleteData(record))}>
                    Hapus
                  </Button>
                </Col>
              </Row>
            );
          },
        },
    ];

    const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div>
        <Row gutter={[20,0]}>
          <Col className="text-heading">
            <Title level={3} style={{color: '#BDBDBD',cursor: 'pointer'}} onClick={()=>props.changeMenu(0)}>
              Interviewer
            </Title>
          </Col>
          <Col className="text-heading">
            <Title level={3} style={{color: '#072a6f',cursor: 'pointer'}} onClick={()=>props.changeMenu(1)}>
              Beep Tester
            </Title>
          </Col>
        </Row>
        <Row gutter={[0,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
                className="input-search"
                placeholder="Search"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={handleSearch}
            />
          </Col>
          <Col span={24} lg={18} md={16} className="text-heading" flex='auto' style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button className="app-btn" style={{backgroundColor: '#FFE600', color: '#072A6F'}} 
              onClick={()=>setShow({show: true, record: null})}
            ><PlusOutlined/> Tambah</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                rowKey={record => record.bipId}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
              />
            </Card>
          </Col>
          <FormPenilaianBiptester
            isShow={isShow.show}
            isEdit={isShow.record}
            onCancel={()=>setShow({show: false, record: null})}
            onRefresh={onRefresh}
            isActived={props.isActived}
          />
        </Row>
      </div>
    );
};

export default withRouter(PenilaianBipTes);