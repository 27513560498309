export default {
    "scanRapor": [
        {
            "nama": "assets/rapor1.png",
            "url": "https://simpelpas.files.wordpress.com/2013/11/simpel-lck-smp-nilai-1.png"
        },
        {
            "nama": "assets/rapor1.png",
            "url": "https://simpelpas.files.wordpress.com/2013/11/simpel-lck-smp-nilai-1.png"
        },
        {
            "nama": "assets/rapor1.png",
            "url": "https://storage.fsm79.com:9000/loyola-dev/assets/rapor1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200828%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200828T022803Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d96a772093a63a85c950a4001fbe7972034d20993367ac956b9649e777eb1d8a"
        }
    ],
    "lulusKkm": true,
    "nilai": [
        {
            "kelas7Sem1": "80",
            "kelas7Sem2": "75",
            "verifikasi": false,
            "raporId": 1,
            "matpel": "Matematika",
            "matpelId": 3,
            "kelas8Sem2": "81",
            "kkmKelas7": 75,
            "kelas8Sem1": "70",
            "kkmKelas8": 75
        },
        {
            "kelas7Sem1": "10",
            "kelas7Sem2": "10",
            "verifikasi": false,
            "raporId": 2,
            "matpel": "Bahasa Inggris",
            "matpelId": 2,
            "kelas8Sem2": "10",
            "kkmKelas7": 75,
            "kelas8Sem1": "10",
            "kkmKelas8": 75
        }
    ],
    "penilaianSikap": [],
    "kehadiran": {
        "kelas7Sem1": null,
        "kelas7Sem2": null,
        "kelas8Sem1": null,
        "kelas8Sem2": null,
        "kehadiranId": null
    },
    "statusVerifikasiDokumen": "sesuai",
    "catatan": null,
    "prestasi": [
        {
            "jenisPrestasi": "Akademik",
            "prestasiId": 1,
            "prestasi": "Olimpiade IPA tingkat provinsi"
        }
    ],
    "status": "OK"
}