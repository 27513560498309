export default {
	"Status": "OK",
	"Data": {
		"namaSiswa": "Dian Sudian",
		"nisn": "8973841284",
		"sekolahAsal": "SMIT",
		"nik": "3123123213",
		"tempatLahir": "Mexico",
		"tglLahir": "2020-08-13",
		"jenisKelamin": 2,
		"agama": "Kristen",
		"keturunan": "Amerika latin",
		"alamatSiswa": "Jalan bueno aieres no 7 cimanuk",
		"rt": "09",
		"rw": "03",
		"kodePos": "21312",
		"kelurahan": "argentina",
		"kecamatan": "america",
		"kota": "eropa",
		"emailSiswa": "dian@gmail.com",
		"hpSiswa": "0",
		"anakKe": 1,
		"jumlahSaudara": 1,
		"siswaJumlahKakakBelajar": 1,
		"siswaJumlahKakakBekerja": 0,
		"jumlahAdikLakiBelajar": 0,
		"jumlahAdikPerempuanBelajar": 0,
		"siswaSaudaraTiriLakilaki": 0,
		"siswaSaudaraTiriPerempuan": 0,
		"fotoSiswa": "https://storage.fsm79.com:9000/loyola-dev/foto/wahyu.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200827T125841Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=1172480ccae89313983934b236d61eae63287142a343a7fb6cd958c14bf7bdf0",
		"akteKelahiran": "https://storage.fsm79.com:9000/loyola-dev?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200827T125841Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=63bb4ec2e81f9b8bc3ac0899b078f1afce9dc6dbd8228eaf9fb25cb6d8e3d23e",
		"kartuKeluarga": "https://storage.fsm79.com:9000/loyola-dev?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200827T125841Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=63bb4ec2e81f9b8bc3ac0899b078f1afce9dc6dbd8228eaf9fb25cb6d8e3d23e",
		"suratPermandian": "https://storage.fsm79.com:9000/loyola-dev?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200827T125841Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=63bb4ec2e81f9b8bc3ac0899b078f1afce9dc6dbd8228eaf9fb25cb6d8e3d23e",
		"namaKakak": null,
		"kelasKakak": 0,
		"tahunLulus": null,
		"verifikasiDataDiri": null,
		"ketVerifDataDiri": null
	}
}