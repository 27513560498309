import React, { useEffect,useState } from 'react'
import { withRouter } from "react-router-dom"
import { Typography, Layout, Row, Col} from 'antd'
import { LoyolaServices } from '../../service'
import { dialog } from '../../functions/alert'
import { LeftOutlined, ManOutlined, WomanOutlined } from '@ant-design/icons'
import { PieChart} from 'react-minimal-pie-chart'
import { AppLoading } from '../../component/loading'
import HandleRole from '../Login/handle-role'

const { Text, Title } = Typography;
const { Content } = Layout;

const progressBarBar = (data1,data2,text1,text2) => {
    let dataA = data1 ? data1 : 0
    let dataB = data2 ? data2 : 0
    var leftdata = Math.floor((dataA/(dataA+dataB))*100)
    var rightdata = Math.floor(100 - leftdata)
    return (
      <table width={'100%'}>
        <tbody>
          <tr>
            <td width={'10%'}  style={{fontWeight: 'bold', marginLeft:8}}>{text1}</td>
            <td style={{backgroundColor: '#072A6F', borderTopLeftRadius: 8, borderBottomLeftRadius: 8, color: '#fff',fontWeight: 'bold'}} width={leftdata-10}> {dataA} </td>
            <td style={{backgroundColor: '#FFE600', borderTopRightRadius: 8, borderBottomRightRadius: 8, color: '#fff',fontWeight: 'bold'}} width={rightdata-10}> {dataB} </td>
            <td width={'10%'}  style={{fontWeight: 'bold', marginRight:8}}>{text2}</td>
          </tr>
          <tr>
            <td width={'10%'}></td>
            <td width={leftdata} style={{fontWeight: 'bold'}}> {leftdata ? leftdata : 0}% </td>
            <td width={rightdata}  style={{fontWeight: 'bold'}}> {rightdata ? rightdata : 0}% </td>
            <td width={'10%'}></td>
          </tr>
        </tbody>
      </table>
    )
}

const cardPie = (data, title) =>{
  return (
    <Row className='cardDashboard' style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: 20}}>
      <Col style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight:'bold'}}>
        <Row span={24} style={{justifyContent: 'center'}}>
          <Title level={4} style={{color: '#072A6F'}} >{title}</Title>
        </Row>
        <Row span={24}>
          <br></br>
          <div style={{width: 150, height: 150, position: 'relative'}}>
            <PieChart data={data}
              labelStyle={{
                ...defaultLabelStyle
              }}
              animate
              startAngle={270}
              label={({ dataEntry }) => dataEntry.percent ? dataEntry.percent :  Math.round(dataEntry.percentage) + '%'}
              labelPosition={50}
              radius={40}
            />
          </div>
        </Row>
        <Col>
        {
          data.map(res => {
            return (
              <Row style={{marginLeft:12, textAlign: 'left', justifyContent: 'center', alignItems: 'center'}}>
                <Col span={3}>
                  <div style={{backgroundColor: res.color, height: 10, width: 10, borderRadius: 10}}/>
                </Col>
                <Col span={20}>
                  {res.title}
                </Col>
              </Row>
            )
          })
        }
        </Col>
      </Col>
    </Row>
  )
} 

const tableListSmp = (data) => {
  return (
    <table width={'80%'} style={{justifyContent: 'flex-start', textAlign: 'left', marginTop: 30, marginBottom: 20, fontWeight:'bold'}}>
    <thead style={{marginBottom:20}}>
      <tr style={{borderBottom: 'solid #072A6F', borderWidth: 4}} >
        <td style={{width: '60%'}}>Nama SMP</td>
        <td style={{width: '40%',textAlign:'right'}}>Jumlah</td>
      </tr>
    </thead>
    <tbody>
      {
        data.map(res => {
          return(
            <tr style={{marginTop:20, paddingTop:20}}>
              <td>{res.namaSekolah}</td>
              <td style={{textAlign:'right'}}>{res.jumlahPendaftar} anak</td>
            </tr>
          )
        })
      }
    </tbody>
  </table>
  )
}
const defaultLabelStyle = {
  fontSize: 8,
  fill: '#ffffff'
};
const titleTable = {
  fontWeight: 'bold',
}
const valueTable = {
  fontSize: 25,
  fontWeight: 'bold'
}
const titleTableDaftar = {
  fontWeight: 'bold',
  textAlign: 'left'
}
const valueTableDaftar = {
  fontSize: 25,
  fontWeight: 'bold',
  textAlign: 'left'
}

const Dashboard = (props) => {
  const [dataDashboard, setDataDashboard] = useState({})
  const [dataAgama, setDataAgama] = useState([])
  const [dataRas, setDataRas] = useState([])
  const [isLoading, setLoading] = useState(true)


  useEffect(() => {
    document.title = "Dashboard | Loyola";
    getDataDashboard()
  }, [])

  const goToHome = () =>{
    var res = JSON.parse(sessionStorage.getItem('user'))
    var role = res.authorities[0].authority // get role
    console.log("data user ",res,role)
    if(sessionStorage.getItem('token')){
      const loc = HandleRole(role);
      props.history.push(loc);
    }
  }

  const getDataDashboard = () => {
      LoyolaServices.getDataDashboard().then(res => {
        console.log("response data : ",res)
        setLoading(false)
        setDataDashboard(res.data)
        setDataAgama(res.data.listDataAgama)
        setDataRas(res.data.listDataSuku)
      }).catch(err => {
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      })
    }

    const dataPendaftar = [
      {title: 'Jalur Prestasi', value: dataDashboard.totalPendaftarJalurPrestasi, color: '#072A6F'},
      {title: 'Tes', value: dataDashboard.totalPendaftarJalurTesOnline, color: '#FFE600'},
      {title: 'Jalur Transfer', value: dataDashboard.totalPendaftarJalurTransfer, color: '#EB5757'}
    ]

      if(isLoading){
        return (
          <Content className='contentDashboard'>
            <AppLoading/>
          </Content>
        )
      }

    return (
      <Content className='contentDashboard'>
        <Row>
          <Col span={1} style={{fontWeight: 'bold', paddingTop:3, marginRight:5}}>
            <a onClick={()=>goToHome()}>
                <h3><LeftOutlined/></h3>
            </a>
          </Col>
          <Col span={9}>
            <Title level={3}>
                Dashboard
            </Title>
          </Col>
        </Row>

        <Row style={{height: '100%'}}>
          <Col span={7} offset={1} className='cardDashboard' style={{textAlign: 'center', padding: 20}}>
            <Title level={4} style={{color: '#072A6F'}}>Total Pendaftar</Title>
              
              {/* Total Pendaftar */}
              <div className="d-flex align-items-center justify-content-center pension-summary-chart flex-grow-1">
                <PieChart data={dataPendaftar}
                  labelStyle={{
                    ...defaultLabelStyle
                  }}
                  label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
                  labelPosition={80}
                  radius={45}
                  lineWidth={40} //donut
                  animate
                  startAngle={270}
                  style={{position: "relative"}}
                >
                </PieChart> 
                <div className='chart-inner-text'>
                    <Text className='chart-inner-value'>
                      {dataDashboard.totalPendaftar}
                    </Text>
                </div>
              </div>

              <Row>
                <Col>
                  <div style={{width: 11, height: '60%', backgroundColor: '#072A6F'}}/>
                </Col>
                <Col offset={1} span={10} style={titleTableDaftar}>
                  Jalur Prestasi 
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalPendaftarJalurPrestasi || 0}
                  </Col>
                </Col>
                <Col span={10} style={titleTableDaftar}>
                  Lolos Seleksi
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalLolosJalurPrestasi || 0}
                  </Col>
                </Col>
              </Row>

              <Row style={{marginTop: 20}}>
                <Col>
                  <div style={{width: 11, height: '60%', backgroundColor: '#FFE600'}}/>
                </Col>
                <Col offset={1} span={10} style={titleTableDaftar}>
                  Jalur Tes
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalPendaftarJalurTesOnline || 0}
                  </Col>
                </Col>
                <Col span={10} style={titleTableDaftar}>
                  Lolos Seleksi
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalLolosJalurTesOnline || 0}
                  </Col>
                </Col>
              </Row>

              <Row style={{marginTop: 20}}>
                <Col>
                  <div style={{width: 11, height: '60%', backgroundColor: '#EB5757'}}/>
                </Col>
                <Col offset={1} span={10} style={titleTableDaftar}>
                  Jalur Transfer
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalPendaftarJalurTransfer || 0}
                  </Col>
                </Col>
                <Col span={10} style={titleTableDaftar}>
                  Lolos Seleksi
                  <Col style={valueTableDaftar}>
                    {dataDashboard.totalLolosJalurTransfer || 0}
                  </Col>
                </Col>
              </Row>
          </Col>

          {/* Jenis Kelamin */}
          <Col span={7} offset={1} className='colDashboard'>
            <Row className='cardDashboard' style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: 20}}>
                <Title level={4} style={{color: '#072A6F'}}>Jenis Kelamin</Title>
                <br></br>
                { progressBarBar(dataDashboard.totalPendaftarLakiLaki,dataDashboard.totalPendaftarPerempuan,<ManOutlined/>,<WomanOutlined/>) }
            </Row>
            <Row className='cardDashboard' style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingTop:20, paddingLeft: 5, paddingRight: 5, paddingBottom:20 }}>
              <Title level={4} style={{textAlign: 'center', alignItems: 'center', color: '#072A6F'}}>Asal Sekolah</Title>
                <br></br>
                { progressBarBar(dataDashboard.totalPendaftarSekolahNegeri,dataDashboard.totalPendaftarSekolahSwasta,'Negeri','Swasta') }
                <br/>
                {
                  dataDashboard && dataDashboard.listDataSekolah &&
                  tableListSmp(dataDashboard.listDataSekolah)
                }
            </Row>

            {/* Laporan Daftar Ulang */}
            <Row className='cardDashboard' style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingTop:20, paddingLeft: 5, paddingRight: 5, paddingBottom:20}}>
              <Col>
                <Title level={4} style={{color: '#072A6F'}}>Laporan Daftar Ulang</Title>
                <Row>
                  <Col span={8} style={titleTable}>
                    Total Lolos Seleksi
                  </Col>
                  <Col span={8} style={titleTable}>
                    Konversi Daftar Ulang
                  </Col>
                  <Col span={8} style={titleTable}>
                    Mengundurkan Diri
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={valueTable}>
                    {dataDashboard.totalLolosSeleksiDaful || 0}
                  </Col>
                  <Col span={8} style={valueTable}> 
                    {dataDashboard.totalKonversiDaftarUlang ? dataDashboard.totalKonversiDaftarUlang+"%" : 0+"%"}
                  </Col>
                  <Col span={8} style={valueTable}>
                    {dataDashboard.totalKonversiMengundurkanDiri ? dataDashboard.totalKonversiMengundurkanDiri+"%" : 0+"%"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Agama & Ras */}
            <Col span={5} offset={1} className='colDashboard'>
              { dataAgama && dataAgama.length > 0 && cardPie(dataAgama, 'Agama') }
              { dataRas && dataRas.length > 0 && cardPie(dataRas, 'Suku') }
            </Col>
        </Row>
      </Content>
    )
  }

export default withRouter(Dashboard)