import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Typography, Col, Row, Space, Layout, Switch, Spin} from "antd";
import { LoyolaServices } from "../../../service";
import { confirmDialog, dialog } from "../../../functions/alert";
import TextArea from "antd/lib/input/TextArea";
import CONFIG from "../../../service/config";

const { Content } = Layout;
const { Text,Title } = Typography

const SettingDisabled = (props) => {

    const [form] = Form.useForm();
    const [data, setData] = useState({})
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
      getDataConfig()
    },[])

    const onFinish = (values) => {
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        ...values,
        userId: userId
      }

      setData(values)
      confirmDialog({text: 'Apakah Anda yakin akan menyimpan data ini?'}).then(()=> {
        setLoading(true)
        LoyolaServices.putDataConfig(body).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message})
            getDataConfig()
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            getDataConfig()
          }
        })
      })
    };
  
    const onFinishFailed = (errorInfo) => {
    };

    const getDataConfig = () => {
      LoyolaServices.getDataConfig().then(res => {
        if(res.data){
          let data = JSON.stringify(res.data.data)
          setLoading(false)
          setData(JSON.parse(data))
          form.setFieldsValue(JSON.parse(data))
        }
      }).catch(err => {
        if(err){
          dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
        }
      })
    }

    const filterData = () => {
      console.log('filter tes login : ',data.tesLogin, form.getFieldValue('tesLogin'))
      if(
        data.prestasiLogin != form.getFieldValue('prestasiLogin') ||
        data.prestasiDaftar != form.getFieldValue('prestasiDaftar') ||
        data.prestasiBayar != form.getFieldValue('prestasiBayar') ||
        data.prestasiAjukanTahapAwal != form.getFieldValue('prestasiAjukanTahapAwal') ||
        data.prestasiPindahJalur != form.getFieldValue('prestasiPindahJalur') ||
        data.tesLogin != form.getFieldValue('tesLogin') ||
        data.tesDaftar != form.getFieldValue('tesDaftar') ||
        data.tesBayar != form.getFieldValue('tesBayar') ||
        data.hasilUjianAkademik != form.getFieldValue('hasilUjianAkademik') ||
        data.tesAjukanTahapAwal != form.getFieldValue('tesAjukanTahapAwal')
      ){
        setData(form.getFieldsValue())
      }
    }

    const ComponentSetting = (props) => {
      return (
        <div>
          <Row>
            <Col span={ props.responsive.isMobile ? 24 : 9}>
              <Text style={{fontSize: 16}}>{props.labelSwitch} </Text>
            </Col>
            <Col flex={'auto'}>
              <Form.Item name={props.nameSwitch} valuePropName="checked" initialValue={true} style={{marginBottom: 5}}>
                <Switch unCheckedChildren={'Off'} checkedChildren={'On'}/>
              </Form.Item>
            </Col>
            <Col span={24} style={{marginBottom: 10}}>
              <Text style={{fontStyle: 'italic', color: '#416AFC', fontSize: 14}}>{props.deskripsi}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={props.responsive.isMobile ? 24 : 9}>
              <Text style={{fontSize: 16}}>{props.labelTextArea} </Text>
            </Col>
            <Col flex={'auto'}>
              <Form.Item name={props.nameTextArea} 
                rules={[{required: !props.disabledText, message: props.labelTextArea.replace(':','')+'harus diisi' },
                { whitespace: !props.disabledText, message: props.labelTextArea.replace(':','')+'harus diisi'}]}
              >
                <TextArea disabled={props.disabledText} style={{borderRadius: 10, borderWidth: 1, borderColor: '#000'}}/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )
    }

    return(
      <div style={{marginBottom: 20}}>
        <Row style={{marginTop: 20, marginBottom: 20}}>
          <Title level={3} style={{color: '#072A6E'}}>Setting Data Configuration</Title>
        </Row>
        <Content style={{backgroundColor: '#fff'}} className='app-card'>
          <Spin spinning={isLoading}>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 9 }}
              form={form}
              onValuesChange={filterData}
              >
              <Row gutter={[50,35]}>
                <Col span={12}>
                  <Col span={24} style={{textAlign: 'center'}}>
                    <Title level={3}>Jalur Prestasi</Title>
                  </Col>
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'prestasiLogin'}
                    labelSwitch={'Prestasi Login :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Prestasi untuk login'}
                    nameTextArea={'prestasiLoginInfo'}
                    labelTextArea={'Prestasi Login Info:'}
                    disabledText={data.prestasiLogin}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'prestasiDaftar'}
                    labelSwitch={'Prestasi Daftar :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Prestasi untuk pendaftaran diri'}
                    nameTextArea={'prestasiDaftarInfo'}
                    labelTextArea={'Prestasi Daftar Info:'}
                    disabledText={data.prestasiDaftar}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'prestasiBayar'}
                    labelSwitch={'Prestasi Pembayaran :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Prestasi untuk melakukan pembayaran'}
                    nameTextArea={'prestasiBayarInfo'}
                    labelTextArea={'Prestasi Info Pembayaran :'}
                    disabledText={data.prestasiBayar}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'prestasiAjukanTahapAwal'}
                    labelSwitch={'Prestasi Ajukan Tahap Awal :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Prestasi untuk pengajuan tahap awal'}
                    nameTextArea={'prestasiAjukanTahapAwalInfo'}
                    labelTextArea={'Prestasi Info Ajukan Tahap Awal :'}
                    disabledText={data.prestasiAjukanTahapAwal}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'prestasiPindahJalur'}
                    labelSwitch={'Prestasi Pindah Jalur :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Prestasi untuk pindah jalur'}
                    nameTextArea={'prestasiPindahJalurInfo'}
                    labelTextArea={'Prestasi Info Pindah Jalur :'}
                    disabledText={data.prestasiPindahJalur}
                  />
                </Col>

                <Col span={12}>
                  <Col span={24} style={{textAlign: 'center'}}>
                    <Title level={3}>Jalur Tes</Title>
                  </Col>
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'tesLogin'}
                    labelSwitch={'Tes Login :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Tes untuk login'}
                    nameTextArea={'tesLoginInfo'}
                    labelTextArea={'Tes Login Info:'}
                    disabledText={data.tesLogin}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'tesDaftar'}
                    labelSwitch={'Tes Daftar :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Tes untuk pendaftaran diri'}
                    nameTextArea={'tesDaftarInfo'}
                    labelTextArea={'Tes Daftar Info:'}
                    disabledText={data.tesDaftar}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'tesBayar'}
                    labelSwitch={'Tes Pembayaran :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Tes untuk melakukan pembayaran'}
                    nameTextArea={'tesBayarInfo'}
                    labelTextArea={'Tes Info Pembayaran :'}
                    disabledText={data.tesBayar}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'tesAjukanTahapAwal'}
                    labelSwitch={'Tes Ajukan Tahap Awal :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan user Jalur Tes untuk pengajuan tahap awal'}
                    nameTextArea={'tesAjukanTahapAwalInfo'}
                    labelTextArea={'Tes Info Ajukan Tahap Awal :'}
                    disabledText={data.tesAjukanTahapAwal}
                  />
                  <ComponentSetting 
                    responsive={props.responsive}
                    nameSwitch={'hasilUjianAkademik'}
                    labelSwitch={'Upload Hasil Ujian Akademik :'}
                    deskripsi={'Untuk mengaktifkan / menonaktifkan upload hasil ujian akademik'}
                    nameTextArea={'hasilUjianAkademikInfo'}
                    labelTextArea={'Upload Info Hasil Ujian Akademik :'}
                    disabledText={data.hasilUjianAkademik}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{textAlign: 'right', marginBottom: 20}}>
                  <Space size="large">
                    <Button type="primary" htmlType="submit" 
                          size="large"
                          className='save-button'
                      >
                          Simpan
                      </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
            {/* <pre>
              {JSON.stringify(data, '', 2)}
            </pre> */}
        </Content>
      </div>
    );
};

export default withRouter(SettingDisabled);