import {withRouter} from "react-router-dom"
import {Card, Col, Layout, Row, Space, Table, Form, Button, Input, message, Tooltip} from "antd";
import {InfoCircleOutlined, LeftOutlined, ManOutlined, ToolFilled, WomanOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Typography} from "antd";
import {AppLoading} from "../../component/loading";
import {LoyolaServices} from "../../service";
import {dialog} from "../../functions/alert";
import HandleRole from "../Login/handle-role";

const {Title, Text} = Typography;
const { Content } = Layout;
const infoTotal = `Terjadi perbedaan angka antara jumlah siswa yang dimasukkan dengan total hasil kalkulator, yang terjadi karena pembulatan angka sebelum/sesudah perhitungan.
Untuk menjaga hasil sesuai peruntukkannya, maka kekurangan/kelebihan dari total hasil kalkulator dapat ditentukan sendiri oleh pihak penghitung akan dimasukkan/dikurangi dari mananya.`

const getDataKalkulator = () => {
    return new Promise((resolve,reject) => {
        LoyolaServices.getDataKalkulator()
            .then(res => {
                resolve(res);
            }).catch(err => {
            reject(err);
        });
    });
};

const KalkulatorScenario = (props) => {
    const [formValue,setFormValue] = useState({});
    const [isLoading,setIsLoading] = useState(true);
    const [dataSlot,setDataSlot] = useState([]);
    const [dataRiil,setDataRiil] = useState([]);
    const [totalReal,setTotalReal] = useState({totalReal: 0, total: 0});
    const [formDataSlot] = Form.useForm();
    const [formDataRiil] = Form.useForm();

    useEffect(() => {
        document.title = "Executive | Loyola";
        getDataKalkulator().then(r => {
            setFormValue(r.data);
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
            message.error("Cannot fetch data");
            setIsLoading(false);
        })
    },[]);

    useEffect(()=>{
        if (formValue) {
            setDataRiil(formValue.Data || []);
            setDataSlot(formValue.DataSlot || []);
            mappingTableSlot(formValue.DataSlot || [], {kuota: formValue.totalDataCalonSiswa})
        }
    },[formValue]);

    const columnsKalkulasiSlot = [
        {
            title: 'Agama',
            dataIndex: 'agama',
            key: 'agama',
            align: 'center',
        },
        {
            title: 'Persen',
            dataIndex: 'persen',
            key: 'persen',
            align: 'center',
            render: (data) => {
                return (
                    <Text>
                        {data}%
                    </Text>
                )
            }
        },
        {
            title: 'Total',
            children: [
                {
                    title: (<ManOutlined/>),
                    dataIndex: 'pria',
                    key: 'pria',
                    align: 'center',
                    render: (data, row) => {
                        let dataTemp = formDataSlot.getFieldValue("kuota") % 1 == 0 ? formDataSlot.getFieldValue("kuota") : 0
                        let total = (row.persen*dataTemp)/100;
                        let pria = Math.round((formValue.persentasePria/100)*total);
                        return (
                            <Text>
                                {pria}
                            </Text>
                        )
                    }
                },
                {
                    title: (<WomanOutlined/>),
                    dataIndex: 'wanita',
                    key: 'wanita',
                    align: 'center',
                    render: (data, row) => {
                        let dataTemp = formDataSlot.getFieldValue("kuota") % 1 == 0 ? formDataSlot.getFieldValue("kuota") : 0
                        let total = (row.persen*dataTemp)/100;
                        let wanita = Math.round((formValue.persentaseWanita/100)*total);
                        return (
                            <Text>
                                {wanita}
                            </Text>
                        )
                    }
                },
                {
                    title: (

                        <Form.Item
                            name={"kuota"}
                            initialValue={formValue.totalDataCalonSiswa}
                            rules={[
                                {   pattern: new RegExp('^[0-9]+$'),
                                    required: true, 
                                    message: "Harap isi dengan bilangan bulat!"},
                            ]}
                        >
                            <Input
                                type={"number"}
                                style={{width:70}}/>
                        </Form.Item>
                    ),
                    dataIndex: 'total',
                    key: 'total',
                    align: 'center',
                    render: (data, row) => {
                        let dataTemp = formDataSlot.getFieldValue("kuota") % 1 == 0 ? formDataSlot.getFieldValue("kuota") : 0
                        let total = (row.persen*dataTemp)/100;
                        let jumlahL = Math.round(((formValue.persentasePria/100)*total));
                        let jumlahP = Math.round(((formValue.persentaseWanita/100)*total))
                        return (
                            <Text>
                                { jumlahL + jumlahP}
                            </Text>
                        )
                    }
                },
            ],
        }
    ];

    const columnsDataRiil = [
        {
            title: 'Agama',
            dataIndex: 'agama',
            key: 'agama',
        },
        {
            title: 'Jalur Prestasi',
            children: [
                {
                    title: (<ManOutlined/>),
                    dataIndex: 'priaJalurPrestasi',
                    key: 'priaJalurPrestasi',
                    align: 'center',
                    render: (data,row,index) => {
                        return (
                            <Form.Item
                                name={"priaPrestasi"+index}
                                initialValue={data}
                                rules={[{ whitespace: true, message: 'Harus diisi'}]}
                                noStyle
                                shouldUpdate
                                rules={[
                                    { 
                                        pattern: new RegExp('^[0-9]+$'),
                                        required: true, 
                                        message: "Harap isi dengan bilangan bulat!"},
                                ]}
                            >
                                <Input
                                    type={"number"}
                                    style={{width:'100%'}}
                                    value={data}
                                />
                            </Form.Item>
                        )
                    }
                },
                {
                    title: (<WomanOutlined/>),
                    dataIndex: 'wanitaJalurPrestasi',
                    key: 'wanitaJalurPrestasi',
                    align: 'center',
                    render: (data,row,index) => {
                        return (
                            <Form.Item
                                name={"wanitaPrestasi"+index}
                                initialValue={data}
                                rules={[{ whitespace: true, message: 'Harus diisi'}]}
                                noStyle
                                rules={[
                                    { 
                                        pattern: new RegExp('^[0-9]+$'),
                                        required: true, 
                                        message: "Harap isi dengan bilangan bulat!"},
                                ]}
                            >
                                <Input
                                    type={"number"}
                                    style={{width:'100%'}}
                                    value={data}
                                />
                            </Form.Item>
                        )
                    }
                },
            ],
        },
        {
            title: 'Jalur Tes',
            children: [
                {
                    title: (<ManOutlined/>),
                    dataIndex: 'priaJalurTesOnline',
                    key: 'priaJalurTesOnline',
                    align: 'center',
                    render: (data,row,index) => {
                        return (
                            <Form.Item
                                name={"priaTes"+index}
                                initialValue={data}
                                rules={[{ whitespace: true, message: 'Harus diisi'}]}
                                noStyle
                                rules={[
                                    { 
                                        pattern: new RegExp('^[0-9]+$'),
                                        required: true, 
                                        message: "Harap isi dengan bilangan bulat!"},
                                ]}
                            >
                                <Input
                                    type={"number"}
                                    style={{width:'100%'}}
                                    value={data}
                                />
                            </Form.Item>
                        )
                    }
                },
                {
                    title: (<WomanOutlined/>),
                    dataIndex: 'wanitaJalurTesOnline',
                    key: 'wanitaJalurTesOnline',
                    align: 'center',
                    render: (data,row,index) => {
                        return (
                            <Form.Item
                                name={"wanitaTes"+index}
                                initialValue={data}
                                rules={[{ whitespace: true, message: 'Harus diisi'}]}
                                noStyle
                                rules={[
                                    { 
                                        pattern: new RegExp('^[0-9]+$'),
                                        required: true, 
                                        message: "Harap isi dengan bilangan bulat!"},
                                ]}
                            >
                                <Input
                                    type={"number"}
                                    style={{width:'100%'}}
                                    value={data}
                                />
                            </Form.Item>
                        )
                    }
                },
            ],
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            render: (data, row) => {
                return (
                    <Text style={{fontWeight: "bold"}}>
                        {row.priaJalurPrestasi + row.wanitaJalurPrestasi + row.priaJalurTesOnline + row.wanitaJalurTesOnline}
                    </Text>
                )
            }
        },
        {
            title: 'Persen',
            dataIndex: 'persen',
            key: 'persen',
            align: 'center',
            render: (data, row) => {
                let grandTotal = 0;
                dataRiil.forEach((key)=>{
                    grandTotal += key.priaJalurPrestasi + key.wanitaJalurPrestasi + key.priaJalurTesOnline + key.wanitaJalurTesOnline;
                });
                let total = row.priaJalurPrestasi + row.wanitaJalurPrestasi + row.priaJalurTesOnline + row.wanitaJalurTesOnline;
                let persen = parseFloat((total/grandTotal*100).toFixed(1)) || 0;
                return (
                    <Text style={{fontWeight: "bold"}}>
                        {persen}%
                    </Text>
                )
            },
        },
    ];

    const checkStringing = (str) => {
        const string = str.toString()
        const search1 = '.'
        const search2 = ','
        if(string.indexOf(search1) == -1 || string.indexOf(search2) !== -1){
            return false
        }else{
            return true
        }
        
    }

    const mappingTableDataRiil = () => {
        let isSubmit = true
        let isInt = true
        let dataTable = [...dataRiil];
        let temp = [];
        dataTable.forEach((key,index,arr)=>{
            let priaJalurPrestasi = formDataRiil.getFieldValue("priaPrestasi"+index)
            let wanitaJalurPrestasi = formDataRiil.getFieldValue("wanitaPrestasi"+index)
            let priaJalurTesOnline = formDataRiil.getFieldValue("priaTes"+index)
            let wanitaJalurTesOnline = formDataRiil.getFieldValue("wanitaTes"+index)
            if(priaJalurPrestasi == null || wanitaJalurPrestasi == null || priaJalurTesOnline == null || wanitaJalurTesOnline == null){
                isSubmit = false
            }
            if(
                priaJalurPrestasi % 1 !== 0 ||
                checkStringing(priaJalurPrestasi) ||
                wanitaJalurPrestasi % 1 !== 0 ||
                checkStringing(wanitaJalurPrestasi) ||
                priaJalurTesOnline % 1 !== 0 ||
                checkStringing(priaJalurTesOnline) ||
                wanitaJalurTesOnline % 1 !== 0 ||
                checkStringing(wanitaJalurTesOnline)
            ){
                isInt = false
            }
            arr[index].priaJalurPrestasi =  priaJalurPrestasi != null ? parseInt(priaJalurPrestasi): 0;
            arr[index].wanitaJalurPrestasi = wanitaJalurPrestasi != null ? parseInt(wanitaJalurPrestasi) : 0
            arr[index].priaJalurTesOnline = priaJalurTesOnline != null ? parseInt(priaJalurTesOnline) : 0
            arr[index].wanitaJalurTesOnline = wanitaJalurTesOnline != null ? parseInt(wanitaJalurTesOnline) : 0
            temp = [...temp,key];
        });
        console.log('data temp adalah : ',temp)
        if(isSubmit && isInt){
            setDataRiil(temp);
        }else{
            if(!isInt){
                dialog({icon: 'error', text: 'Harap isi dengan bilangan bulat!'})
            }else{
                dialog({icon: 'error', text: 'Data tidak boleh kosong'})
            }
        }
    };

    const mappingTableSlot = (dataTable,dataForm)=> {
        let temp = [];
        let totalTemp = 0;
        let totalRealTemp = 0;
        dataTable.forEach((key,index,arr)=>{
            let total = (arr[index].persen*dataForm["kuota"])/100;
            arr[index].pria = Math.round((formValue.persentasePria/100)*total);
            arr[index].wanita = Math.round((formValue.persentaseWanita/100)*total);
            arr[index].total = key.pria+key.wanita;
            totalRealTemp += ((formValue.persentasePria/100)*total) + ((formValue.persentaseWanita/100)*total);
            totalTemp += (Math.round((formValue.persentasePria/100)*total) + Math.round((formValue.persentaseWanita/100)*total));
            console.log('datanya adalah : ',((formValue.persentasePria/100)*total),'+', ((formValue.persentaseWanita/100)*total),'=',(((formValue.persentasePria/100)*total) + ((formValue.persentaseWanita/100)*total)),'totalRealTemp : ',totalRealTemp, 'totalTemp : ',totalTemp)
            temp = [...temp,key];
        });
        setTotalReal({totalReal: totalRealTemp, total: totalTemp})
        setDataSlot(temp);
    };

    const handleReset = ()=>{
        getDataKalkulator().then(r => {
            setDataRiil(r.data.Data);
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
            message.error("Cannot fetch data");
            setIsLoading(false);
        })
    };

    const goToHome = () =>{
        var res = JSON.parse(sessionStorage.getItem('user'))
        var role = res.authorities[0].authority // get role
        console.log("data user ",res,role)
        if(sessionStorage.getItem('token')){
          const loc = HandleRole(role)
          props.history.push(loc);
        }
      }

    return (
		<Content className="contentContainer">
            <Row>
            <Col span={1} style={{fontWeight: 'bold', paddingTop:3, marginRight:5}}>
                <a onClick={()=>goToHome()}>
                    <h3><LeftOutlined/></h3>
                </a>
            </Col>
            <Col span={20}>
                <Title level={3}>
                    Kalkulator Skenario
                </Title>
            </Col>
            </Row>
            {isLoading ? (<AppLoading/>) : (
                <Row gutter={[24,16]}>
                    <Col xs={0} md={1}/>
                    <Col span={9} xs={24} md={9}>
                        <Title level={3}>
                            Kalkulasi Slot
                        </Title>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Form form={formDataSlot}
                                form={formDataSlot}
                                onValuesChange={()=>{
                                    mappingTableSlot(dataSlot,formDataSlot.getFieldsValue());
                                }}>
                            <Table
                                columns={columnsKalkulasiSlot}
                                dataSource={dataSlot}
                                bordered={false}
                                size="middle"
                                pagination={false}
                                summary={() => {
                                    return (
                                        <>
                                            {dataSlot.length !== 0 ? (
                                                <>
                                                    <Table.Summary.Row style={{textAlign: "center",fontWeight: "bold"}}>
                                                        <Table.Summary.Cell>
                                                            Total
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            { formDataSlot.getFieldsValue().kuota % 1 == 0 ? totalReal.total : 0}<br/>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </>
                                            ) : (<></>)}
                                        </>
                                    );
                                }}
                            />
                            {
                                totalReal.total !== formDataSlot.getFieldsValue().kuota && formDataSlot.getFieldsValue().kuota % 1 == 0 &&
                                <Row>
                                    <Col span={24} style={{textAlign: 'right', marginTop: 10}}>
                                            <Tooltip placement="bottom" title={infoTotal}>
                                                <InfoCircleOutlined style={{color: '#FF0101'}} />
                                            </Tooltip>
                                            {' Total real di angka '+totalReal.totalReal}
                                    </Col>
                                </Row>
                            }
                            {/* <pre>{JSON.stringify(dataSlot,'',2)}</pre> */}
                            </Form>
                        </Card>
                    </Col>
                    <Col span={13} xs={24} md={13}>
                        <Title level={3}>
                            Data Riil
                        </Title>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Form form={formDataRiil}>
                                <Table
                                    columns={columnsDataRiil}
                                    dataSource={dataRiil}
                                    bordered={false}
                                    size="middle"
                                    pagination={false}
                                    summary={pageData => {
                                        let totalPriaPrestasi = 0;
                                        let totalWanitaPrestasi = 0;
                                        let totalPriaTes = 0;
                                        let totalWanitaTes = 0;
                                        let totalCalonSiswa, persenPriaPrestasi, persenWanitaPrestasi, persenPriaTes, persenWanitaTes;

                                        pageData.forEach(({ priaJalurPrestasi, wanitaJalurPrestasi, priaJalurTesOnline, wanitaJalurTesOnline }) => {
                                            totalPriaPrestasi += priaJalurPrestasi;
                                            totalWanitaPrestasi += wanitaJalurPrestasi;
                                            totalPriaTes += priaJalurTesOnline;
                                            totalWanitaTes += wanitaJalurTesOnline;
                                        });
                                        totalCalonSiswa = totalPriaPrestasi+totalWanitaPrestasi+totalPriaTes+totalWanitaTes;

                                        return (
                                            <>
                                                {dataRiil.length !== 0 ? (
                                                    <>
                                                        <Table.Summary.Row style={{textAlign: "center",fontWeight: "bold"}}>
                                                            <Table.Summary.Cell>
                                                                <div style={{textAlign: "left"}}>
                                                                    Total
                                                                </div>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {totalPriaPrestasi}
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {totalWanitaPrestasi}
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {totalPriaTes}
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {totalWanitaTes}
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {totalCalonSiswa}
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                        <Table.Summary.Row style={{textAlign: "center",fontWeight: "bold"}}>
                                                            <Table.Summary.Cell>
                                                                <div style={{textAlign: "left"}}>
                                                                    Persen
                                                                </div>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {persenPriaPrestasi = parseFloat((totalPriaPrestasi/totalCalonSiswa*100).toFixed(1)) || 0}%
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {persenWanitaPrestasi = parseFloat((totalWanitaPrestasi/totalCalonSiswa*100).toFixed(1)) || 0}%
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {persenPriaTes = parseFloat((totalPriaTes/totalCalonSiswa*100).toFixed(1)) || 0}%
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {persenWanitaTes = parseFloat((totalWanitaTes/totalCalonSiswa*100).toFixed(1)) || 0}%
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell>
                                                                {Math.round(persenPriaPrestasi+persenWanitaPrestasi+persenPriaTes+persenWanitaTes) || 0}%
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    </>
                                                ) : (<></>)}
                                            </>
                                        );
                                    }}
                                />
                                <Button
                                    style={{marginTop:"1.5em", marginRight:"1.5em"}}
                                    type="primary"
                                    className="btn-eksekutif"
                                    onClick={mappingTableDataRiil}>
                                    Hitung
                                </Button>
                                <Button
                                    style={{marginTop:"1.5em"}}
                                    type="primary"
                                    className="btn-eksekutif"
                                    onClick={()=>{
                                        handleReset();
                                        setTimeout(()=>{
                                            formDataRiil.resetFields();
                                        },1000);
                                    }}>
                                    Kembali ke Data Riil
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={0} sm={1}/>
                </Row>
            )}
        </Content>
    )
};

export default withRouter(KalkulatorScenario);