import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input, Tooltip} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { confirmDialog, dialog } from "../../../functions/alert";
import {MailOutlined, SearchOutlined, WhatsAppOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";

const { Content } = Layout;
const { Title, Text } = Typography;


const handleWindow = (name, value) => {
  console.log(name)
  if (name === 'mail') {
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`)
  }else {
    window.open(`https://api.whatsapp.com/send?phone=62${value}`)
  }
}

const DaftarUlang = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('pn.no_registrasi,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [records, setRecord] = useState([]);

  useEffect(()=>{
    getData(pagination,sort,search)
  }, []);

  const getData = (page,sort,search) => {
    console.log('data call : ',page,sort,search)
    setLoading(true);
    LoyolaServices.getSiswaDaftarUlangSuperAdmin(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        var data = res.data.data;
        setDataList(data);
        setPagination({
          ...pagination,
          current: res.data.page + 1,
          total: res.data.totalData
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra, search);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const handleSubmit = (param) => {
    confirmDialog({text: 'Apakah Anda yakin ingin mengirim E-mail Notifikasi kepada calon siswa?'}).then(_ => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let dataRecords = []
      if(!param && records && records.length > 0){
        records.map(res => {
          dataList.map(resList => {
            if(resList.noRegistrasi == res.noRegistrasi){
              dataRecords.push({
                siswaId: res.siswaId
              })
            }
          })
        })
  
        if(dataRecords.length > 0){
          LoyolaServices.postSendEmailSiswaDaftarUlang(dataRecords, userId).then(res => {
            if(res){
              console.log('success response : ',res.data && res.data.message)
              dialog({icon: 'success', text: 'E-mail Notifikasi berhasil dikirim'})
              onRefresh()
              setLoading(false)
            }
          }).catch(err => {
            if(err){
              dialog({icon: 'error', text: err.response ? err.response.data.Message : err.message})
              setLoading(false)
            }
          })
        }else{
          dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
          setLoading(false)
        }
      } else {
        dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
        setLoading(false)
      }
    }).catch(err => {})
  }

  const onRefresh = () => {
      getData(pagination,sort,search)
  }

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  const processSearchCalonSiswa = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);


  const tagColor = (tag) => {
    tag = tag.toLowerCase();
    switch (tag) {
        case 'lunas':
        case 'sesuai':
        case 'lolos seleksi administrasi':
        case 'sudah daftar ulang':
        case 'sudah melakukan pembayaran ups':
            return '#219653'; //hijau
        case 'tidak sesuai':
        case 'reject':
        case 'revisi data input':
            return '#F2994A'; //orange
        case 'belum diverifikasi':
        case 'belum lunas':
        case 'tidak lolos':
          case 'belum melakukan pembayaran ups':
          case 'belum daftar ulang':
            return '#EB5757'; //merah
        default:
            return '#222222'; //hitam
    }
  };

  const columns = [
    {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'pn.no_registrasi',
        sorter: true
    },
    {
        title: 'Jalur',
        dataIndex: 'jalurPenerimaan',
        key: 'pn.jalur_penerimaan',
        sorter: true,
        width: 80
    },
    {
        title: 'Nama Siswa',
        dataIndex: 'namaSiswa',
        key: 'nama_siswa',
        sorter: true,
    },
    {
        title: 'Status Pembayaran UPS',
        dataIndex: 'statusFinance',
        key: 'status_finance',
        sorter: true,
        render: tag => {
          if (tag !== null){
            let color = tagColor(tag);
            return (
                <Text color={color} style={{color: color}}>
                    {tag.toUpperCase()}
                </Text>
            )
          }
        },
    },
    {
        title: 'Status Daftar Ulang',
        dataIndex: 'statusDaftarUlang',
        key: 'status_daftarulang',
        sorter: true,
        render: tag => {
          if (tag !== null){
            let color = tagColor(tag);
            return (
                <Text color={color} style={{color: color}}>
                    {tag.toUpperCase()}
                </Text>
            )
          }
        },
    }
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("data adalah : ",selectedRows)
      setRecord(selectedRows)
    },
  };

    return(
      <div style={{marginTop:20}}>
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
                className="input-search"
                placeholder="Search"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={handleSearch}
            />
          </Col>
          <Col span={24} lg={18} md={16} style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button className="app-btn" type="primary" style={{backgroundColor: '#FFE600', color: '#072A6F'}}
              onClick={()=>handleSubmit()}
            ><MailOutlined/> Kirim Notifikasi</Button>
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default withRouter(DaftarUlang);