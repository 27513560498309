export default {
  "Status": "OK",
  "Data": {
      "ortuNama": "maximus",
      "ortuAgama": "Kristen",
      "ortuHp": "31232132132",
      "ortuAlamat": "Jalan bueno aieres no 7 cimanuk",
      "ortuRt": "09",
      "ortuRw": "03",
      "ortuKodepos": "12333",
      "ortuKelurahan": "argentina",
      "ortuKecamatan": "america",
      "ortuKota": "america",
      "ortuTelpRumah": "3122312312",
      "ortuEmail": "maximus@gmail.com",
      "ortuPendidikan": "sma",
      "ortuProfesi": "2321",
      "ortuNamaKantor": "kantor",
      "ortuAlamatKantor": "dsadw",
      "ortuBidangUsaha": "dadas",
      "ortuTelpKantor": "dsadw",
      "ortuPenghasilan": null,
      "ortuNpwp": "",
      "ortuPbb": null,
      "slipGaji": null,
      "uploadNpwp": null,
      "ortuKtp": null,
      "verifikasiDataOrtu": false,
      "ketVerifDataOrtu": null
  }
}