import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Spin, Row, Button, Table, Card, Input, Select, Typography, Form, DatePicker} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog, confirmDialog } from "../../../functions/alert";
import { FilterOutlined } from "@ant-design/icons";
import moment from 'moment'
import Title from "antd/lib/typography/Title";
import MoneyInput from "../../../component/money-input";
const { Option } = Select;

const MasterSettingParameter = (props) => {

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dataLovMatpel, setDataLovMatpel] = useState([]);
  const [dataLovTahunAjar, setDataLovTahunAjar] = useState([]);
  const [form] = Form.useForm();

  useEffect(()=>{
    getDataLovMatpel();
  }, []);

  let listYear = [];
  for(var i=1; i<=5; i++){
      let year = moment().add(i, 'years').format('YYYY');  
      listYear.push(year)
  }

  const getDataLovTahunAjar = () => {
    setLoading(true)
    LoyolaServices.getDataLovTahunAjar().then(res => {
      if(res.data){
        var data = res.data.data
        setDataLovTahunAjar(data)
        getData()
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  const getDataLovMatpel = () => {
    setLoading(true)
    LoyolaServices.getDataLovMatpelAll().then(res => {
      if(res.data){
        var data = res.data.data
        setDataLovMatpel(data)
        getDataLovTahunAjar()
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  const getData = async () => {
    setLoading(true);
    await LoyolaServices.getDataParameter().then(res => {
      if(res.data){
        const {data} = res.data;
        let newValue = {
          waktuPersetujuanFinance: moment(data.waktuPersetujuanFinance),
          tahunAjar: data.tahunAjar,
          biayaPendaftaran: data.biayaPendaftaran,
        };
        let matpelUjian = [];
        for(let i = 1; i <= 5; i++) {
          matpelUjian[i-1] = data['matpelUjian'+i];
        }
        newValue.matpelUjian = matpelUjian.filter(e => !!e);
        form.setFieldsValue(newValue)
      }
    }).catch(err => {
      if(err){
        const message = !!err.response && err.response.data ? err.response.data.message : err.message;
        dialog({icon: 'error', title: 'Gagal memuat parameter', text: message})
        setLoading(false)
      }
    })
    setLoading(false);
  }

  const onFinish = async () => {
    confirmDialog({text: 'Apakah Anda yakin ingin mengubah data parameter?', title: 'Simpan Data Parameter'}).then(async () => {
      setLoadingSubmit(true);
      const _currentDate = moment().format();
      const value = form.getFieldValue();
      const user = JSON.parse(sessionStorage.getItem('user'));
      console.log({value: value})
      let body = {
        ...value,
        lastModifiedBy: user.userId,
        waktuPersetujuanFinance: moment(value.waktuPersetujuanFinance).format()
      }
  
      for(let i = 1; i <= 5; i++) {
        body['matpelUjian'+i] = value.matpelUjian[i-1] || null;
      }
  
      delete body.matpelUjian
      await LoyolaServices.putDataParameter(body).then(res => {
        if(res.data){
          dialog({icon: 'success', title: 'Data Parameter berhasil disimpan'})
        }
      }).catch(err => {
        if(err){
          const message = !!err.response && err.response.data ? err.response.data.message : err.message;
          dialog({icon: 'error', title: 'Gagal memuat parameter', text: message})
          setLoading(false)
        }
      })
      setLoadingSubmit(false);
    }).catch(err => {

    })
  }

  const onFinishFailed = () => {}

  return(
    <div style={{width: '100%'}}>
      <Row style={{marginTop:24}}>
        <Col span={24} lg={{span: '12', offset: '6'}} md={{span: '16', offset: '4'}}>
          <Card bordered={false} className="app-card">
            <Spin spinning={loading}>
              <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                {<Title level={4} style={{color: '#072A6F'}}>Ubah Data Parameter</Title>}
              </Col>
              <Col span={24}>
                <Form 
                  name="status"
                  layout="vertical"
                  onFinish={onFinish}
                  // initialValues={{ tglParam: currentDate }}
                  onFinishFailed={onFinishFailed}
                  form={form}
                >
                  <Row gutter={[20, 0]}>
                    <Col span={24} xl={12}>
                      <Form.Item name="tahunAjar" label="Tahun Ajar"
                        rules={[
                          {
                            required: true,
                            message: 'Tanggal Parameter harus diisi',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Tahun Ajar"
                          size="large"
                          allowClear
                        >
                          {
                            dataLovTahunAjar.map((res, i) => {
                            return (<Option value={res} key={'tahunAjaran'+i}>{res}</Option>)
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} xl={12}>
                      <Form.Item name="biayaPendaftaran" label="Biaya Pendaftaran"
                        rules={[
                          { required: true, message: 'Biaya Pendaftaran harus diisi' },
                          { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
                        ]}
                      >
                        <MoneyInput
                          size="large"
                          maxLength={15}
                          style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Waktu Persetujuan Finance"
                        name="waktuPersetujuanFinance"
                        rules={[{ required: true, message: 'Waktu Persetujuan Finance harus diisi!' }]}
                      >
                        <DatePicker showTime={true} size="large" style={{width: '100%'}} placeholder="Masukan Waktu Persetujuan Finance" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="matpelUjian" label="Mata Pelajaran yang Diujikan"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              if(value) {
                                if (value.length <= 5) {
                                  return Promise.resolve();
                                }else{
                                  return Promise.reject('Hanya dapat memilih maksimal 5 mata pelajaran');
                                }
                              }else{
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Select
                          size="large"
                          mode="multiple"
                          allowClear
                          placeholder="Pilih"
                          suffixIcon={<FilterOutlined/>}
                          showArrow={true}
                        >
                          {dataLovMatpel.map((res, i) => {
                            return (<Option value={res.key} key={'mataPelajaran'+i}>{res.value}</Option>)
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item>
                        <Button loading={loadingSubmit} size="large" type="primary" htmlType="submit" style={{borderRadius: '4px', backgroundColor: '#27AE60', borderColor: 'transparent', width: '100%'}}>
                          Simpan
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Spin>
          </Card>
          
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(MasterSettingParameter);