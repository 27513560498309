import React, {useState, useEffect} from 'react'
import {Button, Col, Form, Input, Modal, Row, Spin} from 'antd'
import QrReader from 'react-qr-reader'
import {useForm} from "antd/lib/form/Form";
import {GlobalFunction} from "../global/function";
import {EnterOutlined, SearchOutlined} from "@ant-design/icons";
import axios from "axios";
import CONFIG_INITIAL_STATE from "../service/config";
import {useHistory} from 'react-router-dom';
import {dialog} from "../functions/alert";
import PropTypes from 'prop-types';

ModalScanQr.propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    footer: PropTypes.string,
    maskClosable: PropTypes.bool,
    onCancel: PropTypes.func,
    closable: PropTypes.bool,
    centered: PropTypes.bool,
    width: PropTypes.string,
    closeText: PropTypes.string,
    btnClassName: PropTypes.string,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    routeRole: PropTypes.string,
    routeJalur: PropTypes.string,
    type: PropTypes.string,
    isPrestasi: PropTypes.bool
};

ModalScanQr.defaultProps  = {
    className: "form-modal",
    footer: null,
    maskClosable: false,
    centered: true,
    width: "400px",
    closeText: "Tutup",
    btnClassName: "save-button",
    placeholder: "atau masukkan No. Registrasi",
    help: "Press ENTER to Proceed",
    type: 'interviewer',
    isPrestasi: false
};

function ModalScanQr(props) {
    const history = useHistory();
    const [result, setResult] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [search,setSearch] = useState("");
    const [form] = useForm();

    const config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    };

    const getDataSiswa = (data) => {
        return new Promise((resolve,reject ) => {
            axios.get(CONFIG_INITIAL_STATE.BASE_URL + `/api/scan/registrasiSiswa?noRegistrasi=${data}&type=${props.type}&isPrestasi=${props.isPrestasi}`, config)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    const getData = (value) =>{
        getDataSiswa(value)
            .then(r=>{
                handleResponse(r);
            }).catch(err=>{
                if(err.response){
                    let error = err.response
                    dialog({icon: 'error',
                        text: error.data.message,
                    }).then(()=>{
                        console.log(err);
                        handleClearForm();
                    });
                }
        });
    };

    const handleResponse = (res) => {
        if (res.data.Data) {
            handleCloseModal();
            history.push({
                pathname: `/${props.routeRole}/${(result||search)}/${res.data.Data.namaSiswa}/${props.routeJalur}/${res.data.Data.flagDataKesungguhanExist}`
            });
        }
    };

    const handleScan = (data) => {
        if (data) {
            setIsLoading(true);
            const noReg = data;
            if (noReg){
                setResult(noReg);
            } else {
                dialog({icon: 'error',
                    title: 'Gagal',
                    text: 'QR Code Tidak diketahui',
                }).then(()=>{
                    handleClearForm();
                });
            }
            setIsLoading(false);
        }
    };

    const handleError = (err) => {
        console.error(err)
    };

    const handleSearch = (e) => {
        let key = e.target.value;
        setIsLoading(true);
        setSearch(GlobalFunction.searchEncode(key));
    };

    const handleCloseModal = () => {
        props.buttonCancel();
        handleClearForm();
    };

    const handleClearForm = () => {
        setSearch("");
        setResult("");
        form.resetFields();
        setIsLoading(false);
    };

    useEffect(()=>{
        if (result || search){
            getData(result || search);
        }
    },[result,search]);

    return(
        <Modal
            destroyOnClose={true}
            visible={props.visible}
            className={props.className}
            footer={props.footer}
            closable={false}
            maskClosable={props.maskClosable}
            onCancel={handleCloseModal}
            centered={props.centered}
            width={props.width}
        >
            <Spin
                spinning={isLoading}
                centered
                size="large"
            >
                <p style={{ color: "#072A6F", fontSize: "20px"}} align="center">Scan QR Code</p>

                <Row gutter={[8,24]} style={{justifyContent: 'center'}}>
                    <QrReader
                        style={{ width:"95%", align:"center"}}
                        onError={handleError}
                        onScan={handleScan}
                    />
                </Row>
                <Row gutter={[8,16]} style={{justifyContent: 'center'}}>
                    <Col span={24}>
                        <Form
                            form={form}
                        >
                            <Form.Item
                                name="noReg"
                                help={props.help}
                            >
                                <Input
                                    style={{borderRadius: 32}}
                                    placeholder={props.placeholder}
                                    onPressEnter={(e) => {
                                        handleSearch(e);
                                    }}
                                    prefix={<SearchOutlined/>}
                                    suffix={<EnterOutlined/>}
                                    disabled={isLoading}
                                />
                            </Form.Item>
                        </Form>
                        <Row style={{ justifyContent: 'center'}}>
                        </Row>
                        <Row style={{ justifyContent: 'center'}}>
                            <Button
                                style={{marginTop: "10px"}}
                                className={props.btnClassName}
                                onClick={handleCloseModal}
                                disabled={isLoading}
                            >
                                {props.closeText}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

export default ModalScanQr;