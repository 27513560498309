export const dataKalkulasiSlot = [
    {
        'agama': "Katholik",
        'persen': 75
    },
    {
        'agama': "Protestan",
        'persen': 20
    },
    {
        'agama': "Lain-Lain",
        'persen': 5
    },
];

export const dataDataRiil = [
    {
        'agama': "katolik",
        'priaPrestasi': 63,
        'wanitaPrestasi': 72,
        'priaTes': 52,
        'wanitaTes': 25,
    },
    {
        'agama': "protestan",
        'priaPrestasi': 22,
        'wanitaPrestasi': 18,
        'priaTes': 13,
        'wanitaTes': 9,
    },
    {
        'agama': "Islam",
        'priaPrestasi': 1,
        'wanitaPrestasi': 3,
        'priaTes': 2,
        'wanitaTes': 0,
    },
    {
        'agama': "Buddha",
        'priaPrestasi': 3,
        'wanitaPrestasi': 1,
        'priaTes': 2,
        'wanitaTes': 2,
    }
];