import axios from "axios";
import Auth from "../../service/auth";
import CONFIG_INITIAL_STATE  from '../../service/config';

const client = axios.create(CONFIG_INITIAL_STATE.BASE_URL);
// const clientForLogin = axios.create(CONFIG_INITIAL_STATE.BASE_URL_LOGIN);


// Request interceptor
client.interceptors.request.use(
    function (configuration) {
        const token = sessionStorage.getItem('token');
        if (token !== null) {
            configuration.headers.Authorization = `Bearer ${token}`;
        }
        return configuration;
    },
    function (err) {
        throw new Error(err);
    }
);

client.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    let messageErrorResponse = error.response && error.response.data && error.response.data.error || ''
    let messageErrorStatus = error.response && error.response.status || ''
    if(messageErrorResponse == 'Unauthorized' || messageErrorStatus == 401){
        Auth.logout()
        window.location = '/';
    }
    return Promise.reject(error);
  });

const FinanceService = {

    getLovKetPembayaran(noReg) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/ketPembayaran?noRegistrasi=${noReg}`
            },
            { crossdomain: true }
        )
    },

    downloadHistoryPembayaran(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/download/historyPembayaran?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },


    // ! SELEKSI PRESTASI
    getListSeleksiPrestasi(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/listSiswa/seleksiKemampuan?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },

    editKeputusanSeleksi(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/tesKemampuan/inputHasil`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // ! BEASISWA PRESTASI

    getListBeasiswaPrestasi(page, size, sort, search) {
        let specialSort = sort.split(',');
        if (specialSort[0] === 'beasiswaTambahan') { sort = ''; }
        else { specialSort = null; }
        let url = `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/listBeasiswaPrestasi?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`;
        if (specialSort) { 
            url += `&${specialSort[0]}=${specialSort[1]}`
        }
        return client.request(
            {
                method: "get",
                url: url
            },
            { crossdomain: true }
        )
    },
    

    // * VERIFIKASI
    
    getVerifikasiBeasiswaPrestasi(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaPrestasi?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },
    
    putVerifikasiBeasiswaPrestasi(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaPrestasi/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // * BUKTI KESANGUUPAN

    getDownloadTamplateBeasiswaPrestasi(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/templateBuktiKesanggupan?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    getBuktiKesanggupanBeasiswaPrestasi(id){
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan?komitSiswaId=${id}`,
            },
            { crossdomain: true }
        )
    },

    putBuktiKesanggupanBeasiswaPrestasi(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // * INPUT PEMBAYARAN

    getInputPembayaranBeasiswaPrestasi(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    postPembayaranBeasiswaPrestasi(data){
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // ! BEASISWA OFON

    getListBeasiswaOfon(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/listBeasiswaOfon?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },

    // ! BEASISWA KEKL

    getListBeasiswaKekl(page, size, sort, search) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/listBeasiswaTambahan?size=${size}&sort=${sort}&page=${page}&noRegistrasi=${search}`
            },
            { crossdomain: true }
        )
    },
    

    // * VERIFIKASI

    //Ofon
    
    getVerifikasiBeasiswaOfon(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaOfon?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    // KEKL

    getVerifikasiBeasiswaKekl(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaTambahan?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    getListDiskonOfon() {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/diskonOfon`
            },
            { crossdomain: true }
        )
    },
    
    putVerifikasiBeasiswaOfon(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaOfon/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },

    // KEKL
    putVerifikasiBeasiswaKekl(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/verifikasiBeasiswaOfon/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // * BUKTI KESANGUUPAN

    getDownloadTamplateBeasiswaOfon(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/pdf/finance/templateBuktiKesanggupan?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },


    getBuktiKesanggupanBeasiswaOfon(id){
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan?komitSiswaId=${id}`,
            },
            { crossdomain: true }
        )
    },

    putBuktiKesanggupanBeasiswaOfon(data){
        return client.request(
            {
                method: "put",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/inputBuktiKesanggupan/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // * INPUT PEMBAYARAN

    getInputPembayaranBeasiswaOfon(id) {
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran?komitSiswaId=${id}`
            },
            { crossdomain: true }
        )
    },

    postPembayaranBeasiswaOfon(data){
        return client.request(
            {
                method: "post",
                url: `${CONFIG_INITIAL_STATE.BASE_URL}/api/financeScholarship/pembayaran/simpan`,
                data: data
            },
            { crossdomain: true }
        )
    },


    // ! NON BEASISWA
    
    // * BUKTI KESANGUUPAN

    getTempleteKesanggupanNonBeasiswa(id){
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/pdf/finance/nonBeasiswa?komitSiswaId=${id}`,
            },
            { crossdomain: true }
        )
    },
    
}

export {FinanceService}