
export default {
  'Data': [
    {
      'id': 11,
      'noRegis': 122311,
      'namaSiswa': 'Testing Dummy',
      'status': 1,
    },
    {
      'id': 12,
      'noRegis': 122312,
      'namaSiswa': 'Testing Dummy 2',
      'status': 2,
    },
    {
      'id': 13,
      'noRegis': 122313,
      'namaSiswa': 'Testing Dummy 3',
      'status': 3,
    },
    {
      'id': 14,
      'noRegis': 122314,
      'namaSiswa': 'Testing Dummy 4',
      'status': 4,
    }
  ]
} 