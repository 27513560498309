export default [
    {
        noRegistrasi: '2020001',
        namaSiswa: 'Aaron Wijaya',
        asalSekolah: 'SMP NEGERI 1 SEMARANG',
        status: 'belum di-test',
        statusKeputusan: 'Lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020002',
        namaSiswa: 'Abraham Tjandra',
        asalSekolah: 'SMP NEGERI 2 SEMARANG',
        status: 'diterima',
        statusKeputusan: 'Lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Tidak Lolos',
        statusBP: 'Lolos',
        catatanDaftarUlang: 'Lolos menggantikan yang mengundurkan diri',    
    },
    {
        noRegistrasi: '2020003',
        namaSiswa: 'Brianna Cantika',
        asalSekolah: 'SMP NEGERI 3 SEMARANG',
        status: 'belum di-test',
        statusKeputusan: 'Cadangan',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020004',
        namaSiswa: 'Bernanrdius Wicaksono',
        asalSekolah: 'SMP NEGERI 4 SEMARANG',
        status: 'diterima',
        statusKeputusan: 'Lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020005',
        namaSiswa: 'Dienal Baskara Putra',
        asalSekolah: 'SMP NEGERI 5 SEMARANG',
        status: 'lolos',
        statusKeputusan: 'Lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: "Lolos dong", 
    },
    {
        noRegistrasi: '2020006',
        namaSiswa: 'Fransiska Putri',
        asalSekolah: 'SMP NEGERI 6 SEMARANG',
        status: 'tidak lolos',
        statusKeputusan: 'Tidak lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'tidak lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020007',
        namaSiswa: 'Ivanna Pramudita',
        asalSekolah: 'SMP NEGERI 9 SEMARANG',
        status: 'lolos',
        statusKeputusan: 'Cadangan',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020008',
        namaSiswa: 'Joe Black',
        asalSekolah: 'SMP NEGERI 7 SEMARANG',
        status: 'ragu-ragu',
        statusKeputusan: 'tidak lolos',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'tidak lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020009',
        namaSiswa: 'Febriyoga BS',
        asalSekolah: 'SMP NEGERI 1 BANDUNG',
        status: 'lolos',
        statusKeputusan: 'Cadangan',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020010',
        namaSiswa: 'Agung TA',
        asalSekolah: 'SMP NEGERI 10 BANDUNG',
        status: 'ragu-ragu',
        statusKeputusan: 'Cadangan',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
    {
        noRegistrasi: '2020011',
        namaSiswa: 'Dede Kasep',
        asalSekolah: 'SMP NEGERI 3 BANDUNG',
        status: 'lolos',
        statusKeputusan: 'Cadangan',
        statusDaftarUlang: 'Belum daftar ulang',
        statusInterview: 'Lolos',
        statusBP: 'Belum di-test',
        catatanDaftarUlang: null, 
    },
]