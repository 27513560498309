import React from 'react';
import { Redirect, Route } from "react-router-dom";
import Auth from '../service/auth';

export function PrivateRoute({ component: Component, path, name, responsive, ...rest }) {
  return (
    <Route
      path={path}
      {...rest}
      render={(routeProps) =>
        Auth.isLogin() ? ( <Component name={name} {...routeProps} responsive={responsive} /> ) : 
        ( <Redirect to={{ pathname: "/", state: { from: routeProps.location } }} /> )
      }
    />
  );
}