import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Form, Input} from "antd";
import { LoyolaServices } from "../../../service";
// import Dummy from "../../../dummy";
import { dialog } from "../../../functions/alert";
import { SearchOutlined } from "@ant-design/icons";
import _debounce from "lodash.debounce";

const { Content } = Layout;
const { Title, Text } = Typography;

const deleteDialog = {
  icon: 'error', 
  text: 'Yakin ingin menghapus data?', 
  buttons: {
    confirm: {
      text: "Delete",
      value: true,
      visible: true,
      className: 'app-btn primary'
    },
    cancel: {
      text: "Batal",
      value: false,
      visible: true,
      className: 'app-btn danger'
    }
  }
}

const Review = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('nama_siswa,desc');
  const [dataLovMatpel, setDataLovMatpel] = useState([])
  const [dataList, setDataList] = useState(null);
  const [matpel, setMatpel] = useState(props.matpel)
  const [form] = Form.useForm()
  const [search, setSearch] = useState('');

  useEffect(()=>{
    getDataLovMatpel();
    // getData(null, pagination,sort);
  }, []);


  const getData = (matpelId, page,sortParam,search) => {
    setLoading(true)
    LoyolaServices.getDataReviewSoal(matpelId,page.current - 1,page.pageSize,sortParam,search).then(res => {
      if(res.data){
        var data = res.data.data
        // console.log(data)
        setDataList(data)
        setPagination({
          ...pagination,
          current: res.data.page + 1,
          total: res.data.total
        });
        setLoading(false);
      }
    }).catch(err => {
      if(err){
        setLoading(false);
      }
    })
  }

  const getDataLovMatpel = async () => {
    setLoading(true);
    await LoyolaServices.getDataLovMatpel().then(res => {
      if(res.data){
        const {data} = res.data;
        setDataLovMatpel(data);
        setSelectedata(matpel ? matpel : data[0]);
      }
    }).catch(err => {
      if(err){
        dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
      }
    })
    setLoading(false);
  }

  
  const setSelectedata = (res) => {
    var page = {current: 1, pageSize: 10, }
    var sorting = 'nama_siswa,desc';
    setMatpel(res)
    setPagination(page)
    setSorting(sorting)
    form.resetFields()
    getData(res.key, page, sorting, search);
    props.setMatpelReview(res)
  }

  const handleTableChange = (paginationParam, filters, sorter = {}) => {
    console.log({paginationParam, filters, sorter});
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'nama_siswa,desc'}`
    setSorting(sorting)
    setPagination(paginationParam)
    getData(matpel.key, paginationParam,sorting)
  };

  const columns = [
    {
      title: 'No. Registrasi',
      dataIndex: 'noRegistrasi',
      key: 'pn.no_penerimaan',
      sorter: true,
    },
    {
      title: 'Nama Siswa',
      dataIndex: 'namaSiswa',
      key: 'nama_siswa',
      sorter: true,
    },
    {
      title: 'Skor PG',
      dataIndex: 'scorePG',
      key: 'rata_rata_pg',
      sorter: true,
    },
    {
      title: 'Skor Esai',
      dataIndex: 'scoreEssay',
      key: 'rata_rata_esai',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    {
      title: 'Aksi',
      align: 'center',
      width: '10%',
      render: (record) => {
        let data = {
          ...record,
          matpel: matpel
        }
        return (
          <Button className="app-btn" style={{backgroundColor: '#072A6F', color: '#fff', width: '100%'}} onClick={() => props.onChange(data)}>
            Detail
          </Button>
        );
      },
    },
  ];

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  
  const processSearchCalonSiswa = _debounce((key) => {
    var page = {current: 1, pageSize: 10, }
    setPagination(page)
    setSearch(key)
    getData(matpel.key, page,sort, key)
  }, 500);

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

    return(
      <div style={{marginTop:20}} >
        <Row>
          <Col span={24} lg={6} md={8}>
            <Form form={form} onChange={handleSearch}>
              <Form.Item
                name='search'
                style={{marginBottom: '.5em'}}
              >
                <Input
                  className="input-search"
                  placeholder="Search"
                  style={{
                    width: '100%',
                    marginRight: '10px',
                    textAlign: 'left'
                  }}
                  prefix={<SearchOutlined/>}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} style={{margin: '14px 0px'}}>
            <Row style={{display: 'flex'}} gutter={[14, 14]}>
              {dataLovMatpel && dataLovMatpel.map(res => {
                  const isMatpelActive = matpel && matpel.key == res.key;
                  return(
                    <Col style={{flex: 1}}>
                      <Button
                        className="app-btn secondary"
                        onClick={() => setSelectedata(res)}
                        style={{backgroundColor: isMatpelActive ? '#072A6F' : "#F5F6FA", color: isMatpelActive ? "#fff" : '#072A6F', border: '1px #072a6f solid', borderRadius: '20px', padding: '.5em 2em', width: '100%'}}
                      >
                        {res.value}
                      </Button>
                    </Col>
                  )
                })
              }
            </Row>
          </Col>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                dataSource={dataList}
                rowKey={record => record.siswaId}
                loading={loading}
                scroll={{ x: 'calc(700px)'}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default withRouter(Review);