import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Typography } from 'antd';
import {FilePdfOutlined, FileImageFilled} from "@ant-design/icons";
import Downloader from "js-file-downloader";
import { dialog } from '../functions/alert';

const { Text } = Typography
ImagePreviewComponent.propTypes = {
  uri: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  styles: PropTypes.object
};

ImagePreviewComponent.defaultProps  = {
  uri: '',
  type: '',
  text: 'Lihat',
  styles: {}
};

const kelasSemester = ['Kelas 7 Semester 1','Kelas 7 Semester 2','Kelas 8 Semester 1','Kelas 8 Semester 2']

function ImagePreviewComponent(props) {
  const [isShow, setShow] = useState(false)
  
  const handleDownload = () => {
    console.log('di clicked')
    new Downloader({url: props.uri}).then(file => {
    }).catch(err => {
      console.log(err);
      dialog({title: 'Gagal mengunduh file', icon: 'error'})
    })
  }

  return (
    <Row style={{margin: 10, alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
    { props.type !== 'text' ? 
    
      props.uri.search('.pdf') > 0 ?
      (
        <form>
          <Button 
            type='text'
            htmlType='submit'
            style={{
              width: '100%',
              height: '100%'
            }}
            onClick={()=>handleDownload()}
            icon={
              <FilePdfOutlined
                style={{ fontSize: '48px', lineHeight: '0px'}}
              />
            }
          />
          <Text>PDF File</Text><br/>
          <Text>{props.jenisBerkas}</Text>
        </form>
      )
      :
      <div style={props.styles}  onClick={()=>setShow(true)}>
        {
          props.uri != '' ?
          <img src={props.uri} style={{maxHeight: '100%', maxWidth: '100%'}}/> :
          <div><FileImageFilled/><br/><Text>Image Preview Tidak Ada</Text></div>
        }
        <Text>{props.jenisBerkas}</Text>
      </div> : 
      <Text style={{textDecoration: 'underline'}} onClick={()=>setShow(true)}>{props.text}</Text>
    }
    {/* <Text>Semester {index+1}</Text> */}
      <Modal 
        visible={isShow}
        onCancel={()=>setShow(false)}
        footer={null}
        closeIcon={null}
      >
        <div style={{textAlign: 'center'}}>
          {
            props.uri != '' ?
            <img src={props.uri} style={{maxHeight: '100%', maxWidth: '100%'}}/> :
            <div><FileImageFilled/><br/><Text>Image Preview Tidak Ada</Text></div>
          }
          <br/><Text>{props.jenisBerkas}</Text>
        </div>
      </Modal>
    </Row>
  );
}

export default ImagePreviewComponent;