import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';

export function RedStar(props) {
  return <span style={{color: 'red'}} {...props}>*</span>
}

export function AppHint ({children, text}) {
  return (
    <Tooltip title={text}>
      {children || <QuestionCircleFilled style={{color: "rgba(0, 0, 0, 0.35)", fontSize: '.9em', margin: '0px 5px'}} />}
    </Tooltip>
  )
}


export const AppLink = ({title, to = '', curent = ''}) => {
  const isActive =  curent === to;
  return (
    <NavLink to={to} className="text-heading">
      <Text style={{color: isActive ? '#072a6f' : '#BDBDBD', fontSize: '1.8em', fontWeight: 'bold'}}>
        {title}
      </Text>
    </NavLink>
  )
}
