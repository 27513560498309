import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Typography, Row, Col, Space, Menu, Dropdown, Button } from 'antd';
import { LoadingOutlined, DownOutlined } from "@ant-design/icons";
import Pertanyaan from "./screen/pertanyaan";
import Review from "./screen/review";
import ReviewJawaban from "./screen/review-jawaban";
import InputNilai from "./screen/input-nilai";
import InputNilaiDetail from "./screen/input-nilai-detail";
// import JadwalUjian from './screen/jadwal-ujian'
import DaftarSiswa from './screen/daftar-siswa'
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";


const { Content } = Layout;
const { Title } = Typography;

const menuActive = !!sessionStorage.getItem('menuUjianActive') ? sessionStorage.getItem('menuUjianActive') : 25;


const titleMenu = (params) => {
	const menuKeys = parseInt(params)
	if (menuKeys === 21) {
		return 'Input Soal'
	} else if (menuKeys === 22) {
		return 'Input Nilai'
	} else if (menuKeys === 23) {
		return 'Review Jawaban'
	// } else if (menuKeys === 24) {
	// 	return 'Jadwal Ujian'
	} else if (menuKeys === 25) {
		return 'Daftar Siswa'
	} else {
		return 'Daftar Siswa'
	}
}

const UjianAkademik = (props) => {
	const [menu, setMenu] = useState(100)
	const [menuName, setMenuName] = useState(titleMenu(100))
	const [dataRegister, setDataRegister] = useState(null)
	const [matpelReview, setMatpelReview] = useState(null)
	const [isShowMenuUjian, setShowMenuUjian] = useState(false)
	const [activeDiv, setActiveDiv] = useState(0)
	const ujianRef = useRef()

	const handleClickOut = (e) => {
		if (ujianRef.current && (!ujianRef.current.contains(e.target))) {
			setShowMenuUjian(false)
		} 
	}

	useEffect(() => {
		document.title = "Admin Ujian Akademik | Loyola"
	});
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOut)
		return () => {
			document.addEventListener('mousedown', handleClickOut)
		}
	}, [])

	useEffect(() => {
		console.log('ada user check')
		Auth.securityRole('Admin Ujian Akademik').then(res => {
			console.log('ada user ujian',res)
			changeMenu(menuActive)
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				props.history.replace('/')
			}
		})
	}, [])


	const changeMenu = (params) => {
		sessionStorage.setItem('menuUjianActive', params)
		setMenuName(titleMenu(params))
		setMenu(params)
	}

	const gotToReviewJawaban = (event) => {
		if (menu == 23) {
			setDataRegister(event)
			setMenu(231)
		}
		if (menu == 22) {
			setDataRegister(event)
			setMenu(221)
		}
	}

	const resetData = () => {
		setDataRegister(null)
		setMenu(menu)
	}

	const handleClicked = (e) => {
		console.log('data clicked : ', e)
		changeMenu(e.key)
	}

	const menuMobile = (
		<Menu onClick={handleClicked}>
			<Menu.Item key={25}>Daftar Siswa</Menu.Item>
			<Menu.Item key={21}>Input Soal</Menu.Item>
			<Menu.Item key={22}>Input Nilai</Menu.Item>
			<Menu.Item key={23}>Review Jawaban</Menu.Item>
			{/* <Menu.Item key={24}>Jadwal Ujian</Menu.Item> */}
		</Menu>
	);

	const menuList = [
		{label: 'Daftar Siswa', key: 25},
		{label: 'Input Soal', key: 21},
		{label: 'Input Nilai', key: 22},
		{label: 'Review Jawaban', key: 23},
	]

	return (
		<Content className="contentContainer">
			<Row>
				<Col span={24} style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
					{!props.responsive.grid.md ? (
						<div style={{ justifyContent: 'flex-start', textAlign: 'left' }}>
							<Dropdown overlay={menuMobile}>
								<Button style={{ width: '100%' }}>
									{menuName}<DownOutlined />
								</Button>
							</Dropdown>
						</div>
					) : (
						<Space size={"large"} align="start">
							{menuList.map(menuItem => {
								let isActived = menu == menuItem.key
								if(menu == 221 && menuItem.key == 22){
									isActived = true
								}else if(menu == 231 && menuItem.key == 23){
									isActived = true
								}
								
								return (
									<Col span={24}>
										<Title level={3} style={{marginBottom: '0em', color:  isActived ? '#072a6f' : '#BDBDBD', cursor: 'pointer' }} onClick={() => changeMenu(menuItem.key)}>
											{menuItem.label}
										</Title>
									</Col>
								)
							})}
						</Space>
					)}
				</Col>
			</Row>
			{menu == 21 ? (
				<Pertanyaan responsive={props.responsive} /> 
			) : menu == 22 ? (
				<InputNilai responsive={props.responsive} onChange={gotToReviewJawaban} /> 
			) : menu == 221 ? (
				dataRegister && <InputNilaiDetail responsive={props.responsive} dataRegister={dataRegister} onSubmit={resetData} goToList={() => setMenu(22)} /> 
			) : menu == 23 ? (
				<Review 
				setMatpelReview={res => setMatpelReview(res)}
				matpel={matpelReview}
				responsive={props.responsive} 
				onChange={gotToReviewJawaban} /> 
			) : menu == 231 ? (
				dataRegister && <ReviewJawaban 
				responsive={props.responsive} 
				dataSiswa={dataRegister} 
				onSubmit={resetData} 
				goToList={() => setMenu(23)}/> 
			) : menu == 25 ? (
				<DaftarSiswa responsive={props.responsive} /> 
			) : (<LoadingOutlined />)}
		</Content>
	)
}

export default withRouter(UjianAkademik)