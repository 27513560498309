import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import { Col, Layout, Row, Typography, Button, Space, Form, Spin, Table, Card, Input, InputNumber} from "antd";
import { LeftOutlined} from "@ant-design/icons";
import Dummy from "../../dummy";
import { LoyolaServices } from "../../service";
import { dialog } from "../../functions/alert";
import Downloader from "js-file-downloader";

const { Content } = Layout;
const { Title, Text } = Typography;
const dummyData = Dummy.historyPengubahan;

const RincianBiaya = (props) => {
    const [loading, setLoading] = useState(false);
    const [dataHistory, setDataHistory] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getDataRincian()
        // getDummy()
      }, []) 

    const getDummy = () => {
        form.setFieldsValue(Dummy.dataRincianBiaya.data)
        setDataHistory(Dummy.dataRincianBiaya.data.historyPembayaran)
    }
    
    const setFormData = (props) => {
        let body = {
            ups : props.ups,
            usm : props.usm,
            dpUps : props.dpUps,
            cicil1 :props.cicil1,
            cicil2 : props.cicil2,
            cicil3 : props.cicil3,
            tglPerubahan : props.tglPerubahan,
            tipePembayaran : props.tipePembayaran,
            nominal : props.nominal,
            catatanPerubahan :props.catatanPerubahan
        }
        form.setFieldsValue(body);
    }

    const getDataRincian = () => {
        setLoading(true)
        LoyolaServices.getRincianBiaya(props.match.params.id).then(res => {
            console.log('getDataRincian : ', res)
            if(res.data){
                var data = res.data
                form.setFieldsValue(data.data)
                setDataHistory(data.data.historyPembayaran)
                setLoading(false)
            }
        }).catch(err => {
            if(err){
                dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(()=>getDataRincian())
            }
        })
    }

    const columnsHistoryPengubahan = [
        {
            title: 'Tanggal',
            dataIndex: 'tglPerubahan',
            key: 'tanggal',
        },
        {
            title: 'Tipe',
            dataIndex: 'tipePembayaran',
            key: 'tipe',
        },
        {
            title: 'Nominal',
            dataIndex: 'nominal',
            key: 'nominal',
            render: (data) => {
                return (
                    <Text>Rp. {numberFormat(parseInt(data))}</Text>
                )
            },
        },
        {
            title: 'Catatan Pengubahan',
            dataIndex: 'catatanPerubahan',
            key: 'catatan_pengubahan',
        }
    ];

    const handleBack = () => {
        if(props.match.params.jalur != 'prestasi'){
            props.history.push("/finance-jalur-test-online")
        }else{
            props.history.push("/finance-jalur-prestasi")
        }
    };

    const numberFormat = (amount) => {
        return amount.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&.')
    };

    const handleDownload = () => {
        setLoading(true)
        LoyolaServices.getDownloadRincianBiaya(props.match.params.id).then(res => {
            if(res){
              new Downloader({url: res.data.uri}).then(file => {
              }).catch(err => {console.log(err)})
              dialog({icon: 'success', text: 'Data berhasil di download'}).then(setLoading(false))
            }
        }).catch(err => {
            if(err){
                dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(setLoading(false))
            }
        })
    }

    const onFinish = (values) => {
        setLoading(true)
        // let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
        let body = {
            noRegistrasi: props.match.params.id,
            ups : values.ups || 0,
            usm : values.usm || 0,
            dpUps : values.dpUps || 0,
            cicil1 : values.cicil1 ? values.cicil1 : 0,
            cicil2 : values.cicil2 ? values.cicil2 : 0,
            cicil3 : values.cicil2 ? values.cicil2 : 0,
            catatanPerubahan: values.catatanPerubahan
        }
        console.log('body form status : ',body)
        LoyolaServices.putUpdateRincianBiaya(body).then(res => {
            if(res.data){
                dialog({icon:'success', text: 'Data telah disimpan'}).then(() => {
                    setLoading(false)
                    handleBack()
                })
            }
        }).catch(err => {
            if(err){
                dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(() => setLoading(false))
            }
        })
        console.log('Success:', values);
      };

      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return(
        <Content className="site-layout-content" style={{ marginTop:20, marginRight:140, marginLeft:140}}>
            <Spin spinning={loading}>
            <Form
                name="rincian-biaya"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Row>
                    <Col span={16}>
                        <Space>
                            <Title level={1} className="text-heading" style={{color: '#072a6f'}}>
                                <a onClick={handleBack} className={"back-button-bip"}>
                                    <LeftOutlined/>
                                </a>
                                Rincian Biaya {props.match.params.name}
                            </Title>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[16,24]}>
                    <Col span={9}>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Title level={4} className="text-heading">
                                Uang Pengembangan Sekolah
                            </Title>
                            <Form.Item name="ups"
                            rules={[
                                {
                                required: true,
                                message: 'Harap diisi',
                                },
                            ]}
                            >
                                <Input
                                    addonBefore={'Rp.'}
                                    placeholder="0"
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={9}>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Title level={4}>
                                Uang Sekolah Minimum
                            </Title>
                            <Form.Item name="usm"
                            rules={[
                                {
                                required: true,
                                message: 'Harap diisi',
                                },
                            ]}
                            >
                                <Input
                                    addonBefore={'Rp.'}
                                    placeholder="0"
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Button className="app-btn" type="primary" style={{backgroundColor: '#072A6F'}}
                            onClick={handleDownload}
                        >Download</Button>
                    </Col>
                </Row>
                <Row gutter={[16,24]}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="app-card"
                            style={{padding: "0 10px"}}
                        >
                            <Row gutter={[48,24]}>
                                <Col span={12} style={{marginRight:0}}>
                                    <Title level={3} className="text-heading" style={{color: '#072a6f'}}>
                                        Down Payment
                                    </Title>
                                    <Title level={4}>
                                        Uang pengembangan sekolah minimal sebesar 50% harus sudah dibayarkan, yaitu sebesar:
                                    </Title>
                                    <Form.Item
                                        name="dpUps"
                                        // initialValue={ups/2}
                                        rules={[
                                            { 
                                                pattern: new RegExp('^[0-9]+$'),
                                                required: true, 
                                                message: "Harap diisi dengan angka"},
                                        ]}
                                    >
                                        <Input
                                            type={"number"}
                                            addonBefore="Rp. "
                                            style={{width:"60%"}}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Title level={3} className="text-heading" style={{color: '#072a6f'}}>
                                        Cicilan
                                    </Title>
                                    <Form.Item
                                        label={<Title level={4} style={{color: '#072a6f',marginBottom:0}}>Angsuran ke-1</Title>}
                                        name="cicil1"
                                        style={{marginTop:25}}
                                        rules={[
                                            {
                                            pattern: new RegExp('^[0-9]+$'), 
                                            message: "Harap diisi dengan angka"}
                                        ]}
                                    >
                                        <Input
                                            addonBefore="Rp. "
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={<Title level={4} style={{color: '#072a6f',marginBottom:0}}>Angsuran ke-2</Title>}
                                        name="cicil2"
                                        rules={[
                                            {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
                                        ]}
                                    >
                                        <Input addonBefore="Rp." />
                                    </Form.Item>
                                    <Form.Item
                                        label={<Title level={4} style={{color: '#072a6f',marginBottom:0}}>Angsuran ke-3</Title>}
                                        name="cicil3"
                                        rules={[
                                            {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
                                        ]}
                                    >
                                        <Input addonBefore="Rp." />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Title level={3} className="text-heading" style={{color: '#072a6f'}}>
                                        Catatan Pengubahan
                                    </Title>
                                    <Form.Item
                                        name="catatanPerubahan">
                                        <Input.TextArea rows={4}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={16} style={{textAlign: 'right'}}>
                                    <Space>
                                        <Button
                                            size="large"
                                            className="cancel-button"
                                            onClick={handleBack}
                                        >
                                            Batal
                                        </Button>
                                        <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}
                                            size="large"
                                            className='save-button'
                                        >
                                            Simpan
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Title level={3} className="text-heading" style={{color: '#072a6f'}}>
                            History Pengubahan
                        </Title>
                        <Card>
                            <Table
                                columns={columnsHistoryPengubahan}
                                bordered={false}
                                loading={loading}
                                dataSource={dataHistory}
                                pagination={false}
                            />
                        </Card>
                    </Col>
                </Row>
            </Form>
            </Spin>
        </Content>
    );
};

export default withRouter(RincianBiaya);