import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog, confirmDialog } from "../../../functions/alert";
import {SearchOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";

const { Content } = Layout;
const { Title, Text } = Typography;

const mengundurkanDiriText = {
  icon: 'info', 
  text: 'Apakah anda yakin merubah status menjadi mengundurkan diri?', 
  buttons: {
    confirm: {
      text: "Ya",
      value: true,
      visible: true,
      className: 'app-btn primary'
    },
    cancel: {
      text: "Batal",
      value: false,
      visible: true,
      className: 'app-btn danger'
    }
  }
}

const disetujuiText = {
  ...mengundurkanDiriText,
  text: 'Apakah anda yakin merubah status menjadi setuju?'
}

const AdminFinance = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('last_modified_on,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState([]);
  // const [isShow, setShow] = useState({show: false, record:null});
  const userId = sessionStorage.getItem('siswaId')

  useEffect(()=>{
    getData(pagination,sort,search)
  }, []);

  const getData = (paginationParam, sortParam, searchParam) => {
    setLoading(true)
    const param = {
      search: searchParam,
      sort: sortParam,
      page: paginationParam.current - 1,
      size: paginationParam.pageSize
    }
    LoyolaServices.getDataMasterFinance(param).then(res => {
      if (res.data.Status === 'OK') {
        setDataList(res.data.Data)
        setPagination({
          ...paginationParam,
          total: res.data.totalData
        })
        setLoading(false)
      }
    }).catch(err => {
      if (err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan Pada Server'});
        setDataList([])
        setLoading(false)
      }
    })
  }


  const handleSearch = (e) => {
    let key = e.target.value;
    processSearch(key);
  };

  const processSearch = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const handleTableChange = (paginationParam, filters, sorter, extra) => {
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'last_modified_on,desc'}`
    setSorting(sorting)
    setPagination(paginationParam)
    getData(paginationParam, sorting, search)
  };

  const changeFinanceStatus = (siswaId, status) => {
    const body = {
      persetujuan: status,
      userId: userId
    }
    LoyolaServices.handleChangeStatus(siswaId, body).then(res => {
      const {Status, Message} = res.data;
      if (Status === 'OK') {
        dialog({icon: 'success', text: Message})
        .then(() => { getData(pagination,sort,search) })
      }else {
        dialog({icon: 'error', text: res.data.Message ? res.data.Message : 'Terjadi Kesalahan Pada Server'})
      }
    }).catch(err => {
      if (err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan Pada Server'})
      }
    })
  }

  const columns = [
    {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'b.no_registrasi',
        sorter: true
    },
    {
        title: 'Nama Siswa',
        dataIndex: 'namaSiswa',
        key: 'c.nama_siswa',
        sorter: true,
    },
    {
      title: 'Status Finance',
      key: 'd.status',
      sorter: true,
      render: (record) => {
        return (
          <div>
            {record.statusId === 42 ? 
              <span style={{ color: '#00A345'}}>
                {record.statusFinance}
              </span>
              :
              record.statusId === 47 ? 
              <span style={{ color: '#F2994A'}}>
                {record.statusFinance}
              </span>
              :
              record.statusId === 41 ? 
              <span style={{ color: '#EB5757'}}>
                {record.statusFinance}
              </span>
              :
              record.statusId === 48 ? 
              <span style={{}}>
                {record.statusFinance}
              </span> 
              :
              <span style={{ color: '#072A6F'}}>
                {record.statusFinance}
              </span>
            }
          </div>
        )
      }
  },
  {
      title: 'Pilihan',
      width: '30%',
      render: (record) => {
        return (
          <div>
            {record.statusId === 47 && (
              <Button 
                className="app-btn success" 
                style={{marginRight: '.5em', marginBottom: '.5em'}}
                type='primary' 
                onClick={_ => confirmDialog(disetujuiText).then(_ => changeFinanceStatus(record.komitSiswaId, 42))}
              >
                Ubah Menjadi Setuju
              </Button>
            )}
            {record.statusId !== 41 && record.statusId !== 42 && ( 
              <Button 
                className="app-btn danger" 
                type='primary' 
                onClick={() => confirmDialog(mengundurkanDiriText).then(()=> changeFinanceStatus(record.komitSiswaId, 41))}
              >
                Mengundurkan Diri
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  return (
    <div style={{marginTop:20}} >
      <Row gutter={[20,20]}>
        <Col span={24} lg={6} md={8}>
          <Input
              className="input-search"
              placeholder="No. Registrasi"
              style={{
                width: '100%',
                marginRight: '10px',
                textAlign: 'left'
              }}
              prefix={<SearchOutlined/>}
              onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row style={{marginTop:24}}>
        <Col  span={24}>
          <Card bordered={false} className="app-card">
            <Table
              columns={columns}
              size="middle"
              bordered={false}
              pagination={paginationCus}
              onChange={handleTableChange}
              loading={loading}
              dataSource={dataList}
              rowKey={record => record.komitSiswaId}
              scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : 0}}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(AdminFinance);