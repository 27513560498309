import { Button, Col, InputNumber, Modal, Row, Space, Form, Radio, Spin, Select } from 'antd'
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import MoneyInput from '../../../../component/money-input';
import UploadComponent from '../../../../component/upload-component-image-preview';
import { LoyolaServices } from '../../../../service';
import CONFIG from '../../../../service/config';

const { Option } = Select;

ModalBuktiKesanggupan.propTypes = {
  onCancel: PropTypes.func,
};

ModalBuktiKesanggupan.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

const jenisPembayaran = [
  { label: 'Dibayar Lunas', value: true },
  { label: 'Diangsur', value: false }
];

export default function ModalBuktiKesanggupan(props) {
  const {data, onCancel, onRefresh} = props;
  const [isPembayaran, setPembayaran] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [dataCalonSiswa, setCalonSiswa] = useState(null);
  const [minimumBayar, setMinimumBayar] = useState(0);
  const [form] = Form.useForm();

  const handleSubmit = () => {      
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    form.validateFields()
    .then((values)=>{
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan data bukti kesanggupan',
        title: 'Simpan Kesanggupan?'
      }).then(e => {
        setLoading(true)
        // Process
        let body = {
          komitSiswaId: data,
          totalUps: values.totalUps,
          totalUs: values.totalUs,
          uploadBuktiKesanggupan: values.bukti ? values.bukti.fileName : null,
          userId: userId
        }
        if(!isPembayaran){
          body = {
            ...body,
            dpUps: values.uangmuka ? values.uangmuka : values.totalUps * 0.3,
            angsuran: values.angsuran,
          }
        }
        console.log('body : ',body)

        LoyolaServices.putInputKesanggupanFinanceNonBeasiswaById(body).then(res => {
          dialog({icon: 'success', text: 'data berhasil disimpan'})
          onRefresh();  
        }).catch(err => {
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoading(false)
        })
      }).catch(e => {})
    }).catch(err=>{
    });
  }

  useEffect(() => {
    getData()
  },[]);

  const getData = () => {
    LoyolaServices.getlistFinanceNonBeasiswaKesanggupanById(data).then(res=>{
      if(res.data){
        const {data, page = 0, totalData} = res.data;
        const uangmuka = Math.ceil((data.totalUps * 0.3) / 1000)  * 1000;
        setMinimumBayar(uangmuka)
        form.setFieldsValue(data);
        setCalonSiswa(data)
        setLoading(false)
      }
    }).catch(err => {
      dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      setLoading(false)
      onCancel()
    })
  }

  const handleValueChange = (val) => {
    if ('totalUps' in val) {
      const uangmuka = Math.ceil((val.totalUps * 0.3) / 1000)  * 1000;
      setMinimumBayar(uangmuka)
    }
  }

  const onChangePembayaran = e => {
    setPembayaran(e.target.value)
  };

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
        <Row gutter={[20,20]} align='middle'>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Form Bukti Kesanggupan</Title>
          </Col>
          <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '1em'}}>
            {dataCalonSiswa && dataCalonSiswa.noRegistrasi+' - '+dataCalonSiswa.namaSiswa}
          </Col>
          <Col span={24}>
            <Form form={form} onValuesChange={handleValueChange}>
              <Row>
                <Col span={24}>
                <Text>Sepakat, bersedia dan sanggup : {isPembayaran ? 'lunas' : 'angsur'}</Text>
                  <Form.Item
                    name="sepakat"
                    style={{marginBottom: '1em'}}
                  >
                    <Radio.Group options={jenisPembayaran} onChange={onChangePembayaran} defaultValue={isPembayaran} value={isPembayaran}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>UPS <RedStar /></Text>
                  <Form.Item
                    name="totalUps"
                    rules={[{ required: true, message: 'Harap masukan saldo utang UPS!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <MoneyInput className="app-input" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>US <RedStar /></Text>
                  <Form.Item
                    name="totalUs"
                    rules={[{ required: true, message: 'Harap masukan saldo utang UPS!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <MoneyInput className="app-input" />
                  </Form.Item>
                </Col>
                {
                  !isPembayaran &&
                  <Col span={24}>
                    <Col span={24}>
                    <Text>Angsuran (Maksimal 10 kali angsuran) <RedStar /></Text>
                      <Form.Item
                        name="angsuran"
                        rules={[{ required: true, message: 'Harap masukan angsuran!' }]}
                        style={{marginBottom: '1em'}}
                      >
                        <InputNumber className="app-input" style={{width: '100%'}} max={10} min={1}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text>Uang Muka min 30%<RedStar /></Text>
                      <Form.Item
                        name="uangmuka"
                        rules={[{ required: true, message: 'Harap masukan DP' }]}
                        style={{marginBottom: '1em'}}
                      >
                        <MoneyInput className="app-input" min={minimumBayar}  placeholder={`${minimumBayar}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                      </Form.Item>
                    </Col>
                  </Col>
                }
                {
                  dataCalonSiswa &&
                  <Col span={24} style={{textAlign: 'left'}}>
                    <Text>Bukti Kesanggupan</Text>
                    <Form.Item
                      name="bukti"
                      rules={[{ required: true, message: 'Harap upload bukti kesanggupan!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <UploadComponent 
                        url={CONFIG.BASE_URL_NOAUTH + '/api/upload/buktiKesanggupan?noRegistrasi='+ dataCalonSiswa.noRegistrasi} 
                        accept=".jpg,.jpeg,.pdf,.png"
                        maxSize={5}
                        title="Upload bukti kesanggupan"
                        className="app-input" />
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Col>
          <Col span={24} style={{textAlign: 'left'}}>
            <Space align='start'>
              <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>
              <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
            </Space>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
