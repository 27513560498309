import { Button, Col, Modal, Row, Space, Form, DatePicker, Spin, Select, Table } from 'antd'
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import MoneyInput from '../../../../component/money-input';
import UploadComponent from '../../../../component/upload-component-image-preview';
import { LoyolaServices } from '../../../../service';
import CONFIG from '../../../../service/config';
import moment from 'moment';
import Downloader from "js-file-downloader";
import { DownloadOutlined, HistoryOutlined } from '@ant-design/icons';
import { FinanceService } from '../../finance-service';
import SelectComponent from '../../../../component/select-component';
// import { Redstar } from '../../../component/common';

const { Option } = Select;

ModalPelunasanPembayaran.propTypes = {
  onCancel: PropTypes.func,
};

ModalPelunasanPembayaran.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

export default function ModalPelunasanPembayaran(props) {
  const  {data, onCancel, onRefresh} = props;
  const [isLoading, setLoading] = useState(true);
  const [dataCalonSiswa, setCalonSiswa] = useState({});
  const [lovCicilan, setCicilanLov] = useState([]);
  const [isShowHistory, setShowHistory] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {      
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    form.validateFields()
    .then((values)=>{
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan data pembayaran ?',
        title: 'Simpan keputusan?'
      }).then(e => {
        setLoading(true)
        // Process
        let body = {
          ...values,
          komitSiswaId: data,
          uploadBuktiTrf: values.bukti ? values.bukti.fileName : null,
          tglInput: values.tglInput ? moment(values.tglInput).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          userId: userId,
      }
        console.log('data value adalah : ',body)
        LoyolaServices.postInputPembayaranFinanceNonBeasiswaById(body).then(res => {
          dialog({icon: 'success', text: 'data berhasil disimpan'})
          onRefresh();  
        }).catch(err => {
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoading(false)
        })
      }).catch(e => {})
    }).catch(err=>{
    });
  }

  useEffect(() => {
    getData()
  },[]);

  const getData = async () => {
    await LoyolaServices.getlistFinanceNonBeasiswaById(data).then(async (res) => {
      if(res.data){
        let dataSiswa = res.data.data 

        await FinanceService.getLovKetPembayaran(dataSiswa.noRegistrasi).then(res => {
          if(res.data){
            const {pembayaranBelumLunas, cicilanTerakhir} = res.data;
            dataSiswa.isLast = cicilanTerakhir;
            if (!!pembayaranBelumLunas) {
              setCicilanLov([pembayaranBelumLunas])
              dataSiswa.ketPembayaranId = pembayaranBelumLunas.ketPembayaranId;
            }
            console.log({pembayaranBelumLunas, dataSiswa})
          }
        }).catch(err => {
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          onCancel()
        });
        setCalonSiswa(dataSiswa)
        form.setFieldsValue(dataSiswa);
        console.table(form.getFieldsValue())
      }
    }).catch(err => {
      dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      onCancel()
    })
    setLoading(false)

  }

  const showHistory = () => {
    setShowHistory(!isShowHistory)
  }

  const handleDownload = () => {
    FinanceService.downloadHistoryPembayaran(data).then(res => {
      if(res.data){
        new Downloader({url: res.data.link}).then(file => {
        }).catch(err => {console.log(err)})
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
      }
    })
  }

  const columns = [
    {
      title: 'Nilai',
      dataIndex: 'nilai',
    },
    {
      title: 'Tanggal',
      dataIndex: 'tanggal',
    },
    {
      title: 'Keterangan',
      dataIndex: 'keterangan',
    },{
      title: 'Bukti Pembayaran',
      dataIndex: 'buktiPembayaran',
      render: (record) => {
        return (
          <Button disabled={!record} className="app-btn" onClick={() => downloadBuktiPembayaran(record)}><DownloadOutlined /> Download</Button>
        )
      },
    },
  ]

  const downloadBuktiPembayaran = (link) => {
      new Downloader({url: link}).then(file => {
        // dialog({icon: 'success', text: 'Data berhasil di download'})
      }).catch(err => {console.log(err)})
  }
  
  const minPayment = !!dataCalonSiswa && !!dataCalonSiswa.isLast ? dataCalonSiswa.sisaUps : 0;
  const disabledForm = !dataCalonSiswa || dataCalonSiswa.sisaUps <= 0;

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
        <Row gutter={[20,0]} align='middle'>
          <Col span={24} style={{...borderedBottom}}>
            <Title level={4} className="app-title">Form Pembayaran</Title>
          </Col>
          <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
            {dataCalonSiswa && dataCalonSiswa.noRegistrasi+' - '+dataCalonSiswa.namaSiswa}
          </Col>
          <Col span={24}>
            <Form form={form} onValuesChange={_ => console.table(form.getFieldsValue())}>
              <Row>
                <Col span={24}>
                  <Text>Total UPS <RedStar /></Text>
                  <Form.Item
                    name="totalUps"
                    rules={[{ required: true, message: 'Harap masukan total UPS!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <MoneyInput className="app-input" disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Saldo utang UPS <RedStar /></Text>
                  <Form.Item
                    name="sisaUps"
                    rules={[{ required: true, message: 'Harap masukan saldo utang UPS!' }]}
                    style={{marginBottom: '2em'}}
                  >
                    <MoneyInput className="app-input" disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={24} style={{...borderedBottom}}>
                  <Title level={4} className="app-title">Input Nilai Cicilan / Pelunasan</Title>
                </Col>
                <Col span={24}>
                  <Text>Nilai <RedStar /></Text>
                  <Form.Item
                    name="nilai"
                    rules={[{ required: true, message: 'Harap masukan Nilai!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <MoneyInput className="app-input" max={dataCalonSiswa.sisaUps} min={minPayment} disabled={disabledForm}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Tanggal <RedStar /></Text>
                  <Form.Item
                    name="tglInput"
                    // rules={[{ required: true, message: 'Harap masukan Tanggal!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <DatePicker className="app-input" style={{width: '100%'}} defaultValue={moment()} disabled={disabledForm}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Keterangan <RedStar /></Text>
                  <Form.Item
                    name="ketPembayaranId"
                    rules={[{ required: true, message: 'Harap masukan Keterangan!' }]}
                    style={{marginBottom: '1em'}}
                  >
                  <SelectComponent items={lovCicilan} labelKey="ketPembayaran" valueKey="ketPembayaranId" showSearch={false} disabled={true} />
                  </Form.Item>
                </Col>
                {
                  dataCalonSiswa &&
                  <Col span={24} style={{textAlign: 'left'}}>
                    <Text>Bukti Cicilan/Transfer  <RedStar /></Text>
                    <Form.Item
                      name="bukti"
                      rules={[{ required: true, message: 'Harap masukan bukti cicilan/transfer!' }]}
                      style={{marginBottom: '1em'}}
                    >
                      <UploadComponent 
                        url={CONFIG.BASE_URL_NOAUTH + '/api/upload/buktiCicilan?noRegistrasi='+ dataCalonSiswa.noRegistrasi} 
                        accept=".png,.jpg,.jpeg,.pdf"
                        maxSize={5}
                        title="Upload bukti pembayaran"
                        disabled={disabledForm}
                        className="app-input" />
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Col>
          <Col span={24} style={{textAlign: 'left'}}>
            <Space className="space-between">
              <Button className="app-btn" onClick={showHistory}><HistoryOutlined /> Lihat History Pembayaran</Button>
              <Space className="end" style={{width: '100%'}}>
                {!disabledForm && <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>}
                <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
              </Space> 
            </Space>
          </Col>
        </Row>
      </Spin>

      <Modal
          className="app-modal"
          visible={isShowHistory}
          onOk={showHistory}
          onCancel={showHistory}
          footer={null}
          closable={false}
        >
          <Row gutter={[20,20]} align='middle'>
            <Col span={24} style={{...borderedBottom}}>
              <Title level={4} className="app-title">History Pembayaran</Title>
            </Col>
            <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
              {dataCalonSiswa && dataCalonSiswa.noRegistrasi+' - '+dataCalonSiswa.namaSiswa}
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                size="middle"
                dataSource={dataCalonSiswa && dataCalonSiswa.historiPembayaranList}
                pagination={false}
              />
            </Col>
            <Space className="space-between" style={{width: '100%'}}>
              <Button className="app-btn" onClick={handleDownload}><DownloadOutlined /> Download History</Button>
              <Button className="app-btn primary" onClick={showHistory}>Tutup</Button>
            </Space>
          </Row>
        </Modal>

    </Modal>
  )
}
