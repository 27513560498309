import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import XLSX from 'xlsx';
import { make_cols } from '../component/MakeColumns';
import { SheetJSFT } from '../component/types';
import { LoyolaServices } from '../../../service';
import { confirmDeleteDialog, confirmDialog, dialog } from '../../../functions/alert';
import Dummy from '../../../dummy';
import { Card, Row, Col, Layout, Typography, Table, Button, Input } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import DropdownComponent from '../../../component/dropdown-component';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment'
import { GlobalFunction } from '../../../global/function';
import _debounce from "lodash.debounce";
import Downloader from "js-file-downloader";
import Title from 'antd/lib/typography/Title';
import ModalUbahJadwal from '../component/modal-ubah-jadwal';
// import { UploadOutlined } from '@ant-design/icons';
 
const { Content } = Layout;
const { Text } = Typography;

const nameValue = {
  interviewerJapres: 'Jadwal-Interview-Jalur-Prestasi',
  beepTestJapres: 'Jadwal-Beep-Test-Jalur-Prestasi',
  interviewerJates: 'Jadwal-Interview-Jalur-Tes',
  beepTestJates: 'Jadwal-Beep-Test-Jalur-Tes',
  daftarUlang: 'Jadwal-Daftar-Ulang',
  ujianAkademik: 'Jadwal-Ujian-Akademik',
  hasilUjian: 'Jadwal-Hasil-Ujian-Akademik'
}

class Penjadwalan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      data: [],
      cols: [],
      dataLov: [],
      jenisJadwal: 0,
      jenisJadwalValue: null,
      sort: "no_registrasi,desc",
      pagination: {
          current: 1,
          pageSize: 10,
      },
      search: '',
      isLoading: false,
      isLoadingData: true,
      isLoadingSubmit: false,
      isShow: false,
      showModalUbahJadwal: false,
      downloadUrl: null,
      isFile: false,
      isVisible: false,
      idPenerimaan: null,
      loadingMatpel: false,
      dataListMatpel: [],
      sortMatpel: 'matpel_id,desc',
      paginationMatpel: {
        current: 1,
        pageSize: 10,
      },
      dataSiswa: {
        noRegistrasi: '',
        namaSiswa: ''
      },
      fileName: null
    }
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
 
  componentDidMount(){
    this.getDataLovJenisJadwal()
    // this.getDummyData()
  }


  getDummyData(){
    this.setState({
      dataLov: Dummy.adminJenisPenjadwalanLov,
      jenisJadwal: Dummy.adminJenisPenjadwalanLov[0].key,
      isLoadingData: false
    })
  }

  getDataLovJenisJadwal(){
    LoyolaServices.getDataLovJenisJadwal().then((res) => {
      if(res.data){
        var data = res.data.data ? res.data.data : []
        this.setState({
          dataLov: data,
          jenisJadwal: data[0].key,
          jenisJadwalValue: data[0].value,
        },
        () => 
          this.getDataList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sort,
            data[0].key,
            this.state.search
          )
        )
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error',text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  async getDataList(page, size, sort, jenisJadwal, search, isHitDownload) {
    this.setState({
        isLoadingData:true,
    })
    let listTemp = [];
    let keyword = search ? GlobalFunction.searchEncode(search) : ''
    await LoyolaServices.getDataAdminJadwal(page - 1, size, sort, jenisJadwal, keyword)
    .then(res => {
      listTemp = res && res.data && res.data.data ? res.data.data : [];
      this.setState({
        pagination: {
        ...this.state.pagination,
        total: res && res.data && res.data.total ? res.data.total : 0
        }
      })
    }).catch(err => {
      dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
    })
    if (jenisJadwal === 6) {
      
    }
    this.setState({
      data: listTemp,
      isLoadingData:false
    })
  }

  postUploadJadwal(data){
    
    if(data.data){
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      if(this.state.jenisJadwal == 8){
        LoyolaServices.postUploadJadwalHasilUjian(userId, data.data).then(res => {
          if(res.data.status === 'OK'){
            dialog({icon:'success', text: res.data.message ? res.data.message : 'Upload Berhasil'}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false}, () => this.getDataList(1,10,this.state.sort, this.state.jenisJadwal))
            })
          }else {
            dialog({icon:'error', text: res.data.message}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false})
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false})
            })
          }
        })
      }else{
        LoyolaServices.postUploadJadwal(userId, this.state.jenisJadwal ,data.data).then(res => {
          if(res.data.Status === 'OK'){
            dialog({icon:'success', text: res.data.Message ? res.data.Message : 'Upload Berhasil'}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false}, () => this.getDataList(1,10,this.state.sort, this.state.jenisJadwal))
            })
          }else {
            dialog({icon:'error', text: res.data.Message}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false})
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon:'error', text: err.response ? err.response.data.message : err.message}).then(() => {
              document.getElementById("file").value = ""
              this.setState({isShow: false, isLoadingSubmit: false})
            })
          }
        })
      }

    }
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }
 
  handleFile() {
    let fullPath = document.getElementById("file").value
    let isUpload = false
    const {jenisJadwalValue} = this.state
    if (fullPath) {
      let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      let filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
        let indexNaming = (filename.indexOf('_') >= 0 && filename.indexOf('_'))
        let namingFile = filename.substring(0,indexNaming)
        if (this.state.jenisJadwal === 1 && namingFile === nameValue.interviewerJapres) {
          isUpload = true
        }else if(this.state.jenisJadwal === 2 && namingFile === nameValue.beepTestJapres) {
          isUpload = true
        }else if(this.state.jenisJadwal === 3 && namingFile === nameValue.ujianAkademik) {
          isUpload = true
        }else if(this.state.jenisJadwal === 4 && namingFile === nameValue.interviewerJates) {
          isUpload = true
        }else if(this.state.jenisJadwal === 5 && namingFile === nameValue.beepTestJates) {
          isUpload = true
        }else if(this.state.jenisJadwal === 6 && namingFile === nameValue.daftarUlang) {
          isUpload = true
        }else if(this.state.jenisJadwal === 8 && namingFile === nameValue.hasilUjian) {
          isUpload = true
        }else {
          isUpload = false
        }
      }
      if (isUpload) {
        this.setState({isLoadingSubmit: true})
  
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
    
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws, {raw: false});
        console.log({dataJadwal: data})
        data = data.filter(row => {
          const values = Object.values(row).filter(e => !!e);
          return values.length > 0;
        })
          const temp = data.map(res => {
            if(this.state.jenisJadwal === 8){
              res['tglPemberitahuan'] = res['Tanggal dan Waktu Kirim Pemberitahuan (DD-MM-YYYY hh:mm)']
              res['noReg'] = res['No Registrasi']
              res['namaSiswa'] = res['Nama Siswa']
              delete res['Tanggal dan Waktu Kirim Pemberitahuan (DD-MM-YYYY hh:mm)']
              delete res['No Registrasi']
              delete res['Nama Siswa']
              return res
            }else{
              res['Tanggal'] = res['Tanggal (DD-MM-YYYY)']
              res['Kota Asal SMP'] = res['Kota Asal SMP (Semarang / Non Semarang)']
              res['Waktu'] = res['Waktu (hh:mm / hh:mm - hh:mm )']
              res['Tanggal dan Waktu Kirim Pemberitahuan'] = res['Tanggal dan Waktu Kirim Pemberitahuan (DD-MM-YYYY hh:mm)']
              delete res['Tanggal (DD-MM-YYYY)']
              delete res['Kota Asal SMP (Semarang / Non Semarang)']
              delete res['Waktu (hh:mm / hh:mm - hh:mm )']
              delete res['Tanggal dan Waktu Kirim Pemberitahuan (DD-MM-YYYY hh:mm)']
              return res
            }
          })
          /* Update state */
          this.postUploadJadwal({ data: temp, cols: make_cols(ws['!ref']), isLoadingSubmit: false })
          // this.setState({ data: data, cols: make_cols(ws['!ref']), isLoadingSubmit: false }, () => {
          //   //call get api
          // });
    
        };
        if (rABS) {
          reader.readAsBinaryString(this.state.file);
        } else {
          reader.readAsArrayBuffer(this.state.file);
        }
      }else {
        dialog({icon: 'error', text: `Nama File yang di-upload tidak sesuai untuk Jadwal ${jenisJadwalValue}`})
      }
    }
  }

  handleDelete = () => {
    this.setState({
        isLoadingData:true,
    })
    LoyolaServices.deleteJadwalUjianAkademik().then(res => {
      if(res.data){
        dialog({icon:'success', text: res.data.message ? res.data.message : 'Delete Berhasil'})
        this.handleLOVchange(this.state.jenisJadwal)
      }
    }).catch(err => {
      if(err){
        dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }
  // Table Change
  handleTableChange = (pagination, search, sorter) => {
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    this.setState({
        sort: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'no_registrasi,desc'}`,
        pagination: pagination
    }, () => {
        this.getDataList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sort,
            this.state.jenisJadwal,
            this.state.search
        )
    });
  };

  handleLOVchange = e => {
    let {jenisJadwalValue, dataLov} = this.state
    dataLov.map(res => {
      if (e === res.key) {
        return jenisJadwalValue = res.value
      }
    })
    this.setState({
      jenisJadwal: e,
      search: '',
      jenisJadwalValue: jenisJadwalValue
    }, () => this.getDataList(1,10,this.state.sort, e))
  }

  handleTableChangeMatpel = (pagination, search, sorter) => {
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    this.setState({
        sortMatpel: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'matpel_id,desc'}`,
        paginationMatpel: pagination
    }, () => {
      this.getDataDetail(
          this.state.idPenerimaan,
          this.state.paginationMatpel,
          this.state.sortMatpel,
        )
    });
  };

  // handle download penjadwalan dinamis berdasarkan jadwal id
  handleDownload = async (id) => {
    this.setState({isLoadingData: true});
    if(id == 8){
      await LoyolaServices.donwloadDataPenjadwalanHasilUjian().then(res => {
          new Downloader({url: res.data.data}).then(file => {
          }).catch(err => {console.log(err)})
      })
    }else{
      await LoyolaServices.donwloadDataPenjadwalan(id).then(res => {
        new Downloader({url: res.data.Link}).then(file => {
        }).catch(err => {console.log(err)})
      })
    }
    this.setState({isLoadingData: false});
  }

  handleSearch = (e) => {
    let key = e.target.value;
    this.setState({
      search: key,
      pagination: {
          current: 1,
          pageSize: 10,
      }
    }, () => this.processSearchCalonSiswa(key))
  };

  handleDataPopup = (data) => {
    const page = {
      current: 1,
      pageSize: 10
    }
    this.setState({
      isVisible: true,
      idPenerimaan: data.penerimaanId.penerimaanId,
      paginationMatpel: {
        current: 1,
        pageSize: 10,
      }
    })
    this.getDataDetail(data.penerimaanId.penerimaanId, page, this.state.sortMatpel)
  }

  getDataDetail = (id, page, sort) => {
    this.setState({
      loadingMatpel: true
    })
    LoyolaServices.getDetailMatpel(id, 0, 10, sort).then(res => {
      if(res.data){
        var data = res.data.data
        this.setState({
          loadingMatpel: false,
          dataListMatpel: data.dataUjianAkademikDTO,
          paginationMatpel: {
            ...this.state.paginationMatpel,
            current: page.current,
            total: res.data.total,
          },
          dataSiswa: data
        })
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        this.setState({
          loadingMatpel: false,
          dataListMatpel: [],
          dataSiswa: {
            noRegistrasi: '',
            namaSiswa: ''
          }
        })
      }
    })
  }

  handleExtendJadwal = (record) => {
    console.log(record);
    this.setState({showModalUbahJadwal: record})
  }

  processSearchCalonSiswa = _debounce((key) => {
    this.getDataList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sort,
      this.state.jenisJadwal,
      key,
      true
    );
  }, 500);

  setShow = visible => {
    this.setState({
      isVisible: visible
    })
    if (!visible) {
      this.setState({
        sortMatpel: 'matpel_id,desc',
        paginationMatpel: {
          current: 1,
          pageSize: 10
        }
      })
    }
  }
 
  render() {
    const { 
      pagination,
      paginationMatpel, 
      data, 
      isLoadingData, 
      isLoadingSubmit, 
      isLoading,
      jenisJadwalValue, 
      jenisJadwal, 
      dataLov, 
      isShow, 
      search,
      isVisible,
      loadingMatpel,
      dataListMatpel,
      dataSiswa,
      file
    } = this.state

    let columns = jenisJadwal === 3 ? [
      {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'no_registrasi',
        sorter: true,
    },
    {
        title: 'Lokasi',
        dataIndex: 'lokasi',
        key: 'lokasi',
        sorter: true,
    },
    {
        title: 'Media',
        dataIndex: 'media',
        key: 'media',
        sorter: true,
    },
    {
      title: 'Link',
      dataIndex: 'link_pelaksanaan',
      key: 'link_pelaksanaan',
      sorter: true,
  },
    {
        title: 'Tanggal dan Waktu Kirim Pemberitahuan',
        key: 'tgl_notifikasi',
        sorter: true,
        render: (record) => {
          return (
            <Text>{record.tglNotifikasi+' '+record.waktuNotifikasi}</Text>
          );
        },
    },
    {
        title: 'Link Download',
        dataIndex: 'linkDownload',
        key: 'link_download',
        sorter: true,
    },  
    {
			title: 'Mata Pelajaran',
			render: (record) => {
        return (
          <Button className="app-btn secondary" onClick={() => this.handleDataPopup(record)}>
            Detail
          </Button>
        );
      },
    },
    ] : 
    jenisJadwal === 8 ?
    [
      {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'no_registrasi',
        sorter: true,
      },
      {
        title: 'Tanggal dan Waktu Kirim Pemberitahuan',
        key: 'tgl_notifikasi',
        sorter: true,
        render: (record) => {
          return (
            <Text>{record.tglNotifikasi+' '+record.waktuNotifikasi}</Text>
          );
        },
      }
    ] : [
      {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'no_registrasi',
        sorter: true,
      },
      {
        title: 'Kota SMP',
        dataIndex: 'kotaSmp',
        key: 'kota_smp',
        sorter: true,
      },
      {
        title: 'Tanggal',
        dataIndex: 'tglPelaksanaan',
        key: 'tgl_pelaksanaan',
        sorter: true,
      },
      {
        title: 'Waktu',
        dataIndex: 'waktuPelaksanaan',
        key: 'waktu_pelaksanaan',
        sorter: true,
      },
      {
        title: 'Lokasi',
        dataIndex: 'lokasi',
        key: 'lokasi',
        sorter: true,
      },
      {
        title: 'Media',
        dataIndex: 'media',
        key: 'media',
        sorter: true,
      },
      {
        title: 'Link',
        dataIndex: 'link_pelaksanaan',
        key: 'link_pelaksanaan',
        sorter: true,
      },
      {
        title: 'Tanggal dan Waktu Kirim Pemberitahuan',
        key: 'tgl_notifikasi',
        sorter: true,
        render: (record) => {
          return (
            <Text>{record.tglNotifikasi+' '+record.waktuNotifikasi}</Text>
          );
        },
      },
      {
        title: 'Link Download',
        dataIndex: 'linkDownload',
        key: 'link_download',
        sorter: true,
      },
    ];

    columns = jenisJadwal === 6 ? [
      ...(columns.slice(0, 2)),
      {
        title: 'Tanggal dan Waktu Pelaksanaan',
        dataIndex: 'tglWaktuPelaksanaan',
        key: 'tanggal_waktu_pelaksanaan',
        sorter: true,
      },
      {
        title: 'Batas Tanggal dan Waktu Pelaksanaan',
        dataIndex: 'batasTglWaktuPelaksanaan',
        key: 'batas_tanggal_waktu_pelaksanaan',
        sorter: true,
      },
      ...(columns.slice(4, 7)),
      {
        title: 'Tanggal dan Waktu Kirim Pemberitahuan',
        dataIndex: 'tgl_notifikasi',
        key: 'tanggal_notifikasi_jadwal',
        sorter: true,
      },
      ...(columns.slice(8)),
      {
        title: 'Aksi',
        key: '',
        sorter: false,
        render: (record) => {
          const isLocalLink = [
            'https://ppdb.yayasanloyola.org/#/',
            'https://ppdb.yayasanloyola.org/',
            'https://ppdb.yayasanloyola.org',
            'ppdb.yayasanloyola.org',
          ].includes(record.link_pelaksanaan || '');
          return record.media === 'Online' && isLocalLink ? (
            <Button className="app-btn orange" onClick={_ => this.handleExtendJadwal(record)}>Ubah</Button>
          ) : '';
        },
      },
    ] : columns;
      
    const columnsMatpel = [
      {
        title: 'Mata Pelajaran',
        dataIndex: 'matpel',
        key: 'ms.matpel_sikap',
        sorter: true,
      },
      {
        title: 'Waktu Ujian',
        dataIndex: 'waktuUjian',
        key: 'waktu_ujian',
        sorter: true,
      },
      {
        title: 'Kode Classroom',
        dataIndex: 'kc',
        key: 'kc'
      }
    ]
      
    const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }
    const borderedBottom = {
      borderBottom: '1px solid #d9d9d9',
      marginBottom: '1em'
    }
    return (

      <div style={{marginTop:20}}>
        <Row gutter={[20,20]} align='middle'>
            <Col span={24} lg={6} md={8}>
              <Input
                className="input-search"
                placeholder="Search"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={(e) => this.handleSearch(e)}
                value={search}
              />
            </Col>
            {
              jenisJadwalValue &&
              <Col span={12} lg={6} md={8}>
                <DropdownComponent 
                  defaultValue={jenisJadwalValue} 
                  data={dataLov}
                  style={{width: '100%', marginTop: 4}}
                  onChange={(e) => this.handleLOVchange(e)}
                />
              </Col>
            }
            {
              jenisJadwalValue &&
              <Col span={12} lg={6} md={8} style={{alignSelf: 'center'}}>
                <Button type='link' disabled={isLoadingData} onClick={()=> this.handleDownload(jenisJadwal)}>
                  Download Template Jadwal
                </Button>
              </Col>
            }
          <Col span={24} flex='auto' style={{textAlign: 'right', justifyContent: 'flex-end'}}>
            {
              jenisJadwal == 3 &&
              <Button 
                loading={!jenisJadwalValue || isLoadingData} 
                className="app-btn" 
                style={{backgroundColor: '#ff4d4f', color: '#fff', marginRight: 10}}  
                onClick={()=>confirmDeleteDialog({text: 'Apakah Anda yakin akan menghapus Penjadwalan Ujian Akademik?'}).then(()=> {
                  this.handleDelete()
                })}
              >
                Hapus
              </Button>
            }
            <Button 
              loading={!jenisJadwalValue} 
              className="app-btn" 
              style={{backgroundColor: '#FFE600', color: '#072A6F'}}  
              onClick={()=>this.setState({isShow: true})}
            >
              Upload Jadwal
            </Button>
          </Col>
        </Row>
        <Row style={{marginBottom: 10, marginTop:24}}>
          <Col span={24}>
            <Card
              bordered={false}
              className="app-card"
            >
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={this.handleTableChange}
                loading={isLoadingData}
                rowKey={record => record.jadwalId}
                dataSource={data}
                scroll={{x: this.props.responsive && this.props.responsive.isMobile ? this.props.responsive.width : 0}}
              />
            </Card>
          </Col>
        </Row>
        {console.log(file)}
        <Modal
          visible={isShow}
          onCancel={()=>this.setState({isShow: false, file: null}, () => document.getElementById("file").value = "")}
          title={`Upload Jadwal ${jenisJadwalValue}`}
          footer={[
            <Button key="back" onClick={()=>this.setState({isShow: false, file: null}, () => document.getElementById("file").value = "")}>
              Tutup
            </Button>,
            <Button 
              key="submit" 
              type="primary" 
              loading={isLoadingSubmit} 
              onClick={this.handleFile}
              disabled={file === null}
            >
              Upload
            </Button>,
          ]}
          closeIcon={null}
        >
          <Row gutter={[10,10]}>
            <Col span={24}>
              <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
            </Col>
          </Row>
        </Modal>
        <Modal
          className="app-modal"
          visible={isVisible}
          onCancel={()=>this.setShow(false)}
          footer={null}
          closeIcon={null}
          width="500px"
          closable={false}
        >
          <Row style={{marginTop: 24}}>
            <Col span={24} style={{...borderedBottom}}>
              <Title level={4} className="app-title">Detail Mata Pelajaran</Title>
            </Col>
            <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
              {dataSiswa.noRegistrasi} - {dataSiswa.namaSiswa}
            </Col>
            <Col span={24}>
              <Card bordered={false} className="app-card">
                <Table
                  columns={columnsMatpel}
                  size="middle"
                  bordered={false}
                  pagination={false}
                  onChange={this.handleTableChangeMatpel}
                  loading={loadingMatpel}
                  dataSource={dataListMatpel}
                  scroll={{x: this.props.responsive && this.props.responsive.isMobile ? this.props.responsive.width : null}}
                />
              </Card>
            </Col>
            <Col span={24} style={{width: '100%', marginTop: 20, textAlign: 'center'}}>
              <Button className="app-btn primary padded" onClick={() => this.setShow(false)}>OK</Button>
            </Col>
          </Row>
        </Modal>
        <ModalUbahJadwal
          data={this.state.showModalUbahJadwal}
          onCancel={_ => this.setState({showModalUbahJadwal: null})} 
          onFinish={_ => {
            this.setState({showModalUbahJadwal: null});
            this.getDataList(1,10,this.state.sort, this.state.jenisJadwal);
          }} 
        />

      </div>
    )
  }
}
 
export default withRouter(Penjadwalan);