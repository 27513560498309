import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Button, Table, Card, Input, Select} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog, confirmDeleteDialog } from "../../../functions/alert";
import { PlusOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons";
import FormUser from "../component/form-user";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";

const { Content } = Layout;
const { Option } = Select;

const MasterUser = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('user_full_name,asc');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [dataList, setDataList] = useState(null);
  const [dataLov, setDataLov] = useState([]);
  const [isShow, setShow] = useState({show: false, record:null});

  useEffect(()=>{
    getDataLov()
    getData(pagination,sort,search,filter)
    // eslint-disable-next-line
  }, []);

  const getDataLov = () => {
    setLoading(true)
    LoyolaServices.getDataLovUserAdmin().then(res => {
      if(res.data){
        var data = res.data.data
        setDataLov(data)
        console.log(data)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        // setDataList([])
      }
    })
  }

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearch(key);
  };

  const processSearch = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param,filter)
  }, 500);

  const handleFilter= (param) => {
    setFilter(param)
    getData({current: 1, pageSize: 10}, sort, search, param)
  } 

  const getData = (page, sort, search, filter) => {
    setLoading(true)
    LoyolaServices.getDataUserAdmin(page.current - 1, page.pageSize, sort, search, filter).then(res => {
      if(res.data){
        var data = res.data.data
        console.log(data)
        setDataList(data)
        setPagination({
            ...pagination,
            current: page.current,
            total: res.data.totalData
          })
        setLoading(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
        // setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'user_full_name,asc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search,filter)
  };

  const onRefresh = () => {
    console.log('data onrefresh : ')
      setShow({show: false, record: null})
      getData(pagination,sort,search,filter)
  }

  const deleteData = (record) => {
    setLoading(true)
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    let body = {userId: record.userId}
    LoyolaServices.deleteDataUserAdmin(userId, body).then(res => {
      if(res.data){
        onRefresh();
        dialog({icon: 'success', text: res.data.message});
      }
    }).catch(err => {
      if(err){
        setLoading(false)
        dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
      }
    })
  }

  const columns = [
    {
        width: '15%',
        title: 'Nama Lengkap',
        dataIndex: 'userFullName',
        key: 'user_full_name',
        sorter: true,
    },
    {
        width: '15%',
        title: 'Username',
        dataIndex: 'userName',
        key: 'user_name',
        sorter: true,
    },
    {
        width: '15%',
        title: 'E-mail',
        dataIndex: 'userEmail',
        key: 'user_email',
        sorter: true,
    },
    {
        width: '15%',
        title: 'No. Telepon',
        dataIndex: 'userPhone',
        key: 'user_phone',
        sorter: true,
    },
    {
        width: '15%',
        title: 'Role',
        dataIndex: 'role',
        key: 'r.role_name',
        sorter: true,
    },
    {
        width: '25%',
        title: 'Aksi',
        render: (record) => {
          return (
            <Row gutter={[20,10]}>
              <Col>
                <Button className="app-btn" style={{backgroundColor: '#EF712B', color: '#fff'}} onClick={() => setShow({show: true, record: record})}>
                  Ubah
                </Button>
              </Col>
              <Col>
                <Button className="app-btn" type='primary' danger onClick={() => confirmDeleteDialog({text: 'Apakah Anda yakin akan menghapus data Ini ?'}).then(()=>deleteData(record))}>
                  Hapus
                </Button>
              </Col>
            </Row>
          );
        },
      },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter']}

    return(
      <div style={{marginTop:20}} >
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
              className="input-search"
              placeholder="Nama Lengkap"
              style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
              }}
              prefix={<SearchOutlined/>}
              onChange={(event) => {handleSearch(event)}}
            />
          </Col>
          <Col span={24} lg={6} md={8} >
            <Select
              mode="multiple"
              allowClear
              placeholder="Role"
              bordered={false}
              style={{
                  paddingTop: 5,
                  paddingRight: 5,
                  width: '100%',
                  textAlign: 'left',
                  minHeight: 40,
                  height: '100%',
                  borderRadius: 16,
                  backgroundColor: "#FFF"
              }}
              suffixIcon={<FilterOutlined/>}
              showArrow={true}
              onChange={(value) => {handleFilter(value)}}
            >
              {dataLov.map(res => {
                return (<Option value={res.roleId}>{res.roleName}</Option>)
              })}
            </Select>
          </Col>
          <Col span={24} lg={12} md={16} flex='auto' style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button
              className="app-btn" style={{backgroundColor: '#FFE600', color: '#072A6F'}} 
              onClick={()=>setShow({show: true, record: null})}
            ><PlusOutlined/> Tambah</Button>
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : null}}
              />
            </Card>
          </Col>
        </Row>
        <FormUser
          isShow={isShow.show}
          isEdit={isShow.record}
          onCancel={()=>setShow({show: false, record: null})}
          onRefresh={onRefresh}
          dataLov={dataLov}
          />
      </div>
    );
};

export default withRouter(MasterUser);