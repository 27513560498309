export default [
  {
    key: true,
    value: 'Sesuai'
  },
  {
    key: false,
    value: 'Tidak Sesuai'
  },
]
