import { Button, Col, Modal, Row, Space, Form, Radio, Table } from 'antd'
import React from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import { confirmDialog, dialog } from '../../../../functions/alert';
import { FinanceService } from '../../finance-service';
import Downloader from 'js-file-downloader';
import { DownloadOutlined } from '@ant-design/icons';
// import { Redstar } from '../../../component/common';

ModalHistoryPembayaran.propTypes = {
  onCancel: PropTypes.func,
};

ModalHistoryPembayaran.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
}

export default function ModalHistoryPembayaran(props) {
  const  {data = {}, onCancel} = props;

  const columns = [
    {
      title: 'Nilai',
      dataIndex: 'nilai'
    },
    {
      title: 'Tanggal',
      dataIndex: 'tanggal'
    },
    {
      title: 'Keterangan',
      dataIndex: 'keterangan'
    },{
      title: 'Bukti Pembayaran',
      dataIndex: 'buktiPembayaran',
      render: (record) => {
        return (
          <Button disabled={!record} className="app-btn" onClick={() => downloadBuktiPembayaran(record)}><DownloadOutlined /> Download</Button>
        )
      },
    },
  ]

  const downloadBuktiPembayaran = (link) => {
      new Downloader({url: link}).then(file => {
        // dialog({icon: 'success', text: 'Data berhasil di download'})
      }).catch(err => {console.log(err)})
  }

  const downloadHistory = () => {
    FinanceService.downloadHistoryPembayaran(data.komitSiswaId).then(res => {
      const {link} = res.data;
      new Downloader({url: link}).then(file => {
        // dialog({icon: 'success', text: 'Data berhasil di download'})
      }).catch(err => {console.log(err)})
    }).catch(err => {dialog({icon: 'error', title: 'Gagal mengunduh history', text: err.response ? err.response.data.message : err.message})})
  }

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Row>
        <Col span={24} style={{...borderedBottom}}>
          <Title level={4} className="app-title">History pembayaran</Title>
        </Col>
        <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '1em'}}>
          {data.noRegistrasi} - {data.namaSiswa}
        </Col>
        <Col span={24} style={{marginBottom: '4em'}}>
          <Table dataSource={data.historiPembayaranList || []} columns={columns} pagination={false} />
        </Col>
        <Col span={24}>
          <Space className="space-between" style={{width: '100%'}}>
            <Button className="app-btn" onClick={downloadHistory}><DownloadOutlined /> Download History</Button>
            <Button className="app-btn primary" onClick={onCancel}>Tutup</Button>
          </Space>
        </Col>
      </Row>

    </Modal>
  )
}
