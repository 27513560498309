import React from 'react';
import { Row, Col } from 'antd';
import {version} from '../../../package.json';
import CONFIG from '../service/config';

export function AppFooter(props) {
  return (
    <div className="app-footer">
      <div className="">
        <Row>
          <Col span={24} style={{textAlign: 'center', justifyContent: 'space-between', display: 'flex'}}>
            <span></span>
            <span>&copy; 2020 Kolese Loyola. {CONFIG.isServer !== 'Production' && CONFIG.isServer }</span>
            <span>Build {version}</span>
          </Col>
        </Row>
      </div>
    </div>
  )
}

// export default {RedStar}