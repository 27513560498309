import React from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Layout, Row, Typography, Col, Input, Space, Card, Table, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, QrcodeOutlined, DownOutlined } from "@ant-design/icons";
import { LoyolaServices } from "../../service";
import _debounce from "lodash.debounce"
import { GlobalFunction } from "../../global/function";
import Dummy from '../../dummy';
import ModalQR from "../../component/modal-scan-qr";
import Auth from "../../service/auth";
import { dialog } from "../../functions/alert";

const { Content } = Layout;
const { Text, Title } = Typography;
const menuKey = [
    { key: 'Prestasi', label: 'Jalur Prestasi' },
    { key: 'TesOnline', label: 'Jalur Tes' },
]

class InterviewerJalurPrestasi extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Interviewer | Loyola"

        this.state = {
            data: Dummy.listSiswa,
            isModalShow: false,
            loadingCalonSiswa: true,
            sort: "pn.no_registrasi,desc",
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            responsive: props.responsive,
            jalur: 'Prestasi'
        }
    }

    handleModalQR = () => {
        this.setState({isModalShow : !this.state.isModalShow})
    };

    componentDidMount() {
		Auth.securityRole('Interviewer').then(res => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
		}).catch(err => {
			console.log('ada user catch',err)
			dialog({icon: 'error', text: 'Akun yang anda gunakan salah!'})
			if(err){
				this.props.history.replace('/')
			}
		})
    }

    async getDataCalonSiswa(page, size, sort, search) {
        this.setState({
            loadingCalonSiswa:true,
        })
        let listTemp = [];
        await LoyolaServices.getListInterViewJalurPrestasi(page, size, sort, search)
        .then(res => {
            console.log("Prestasi")
            console.log(res.data)
            listTemp = res && res.data && res.data.data ? res.data.data : [];
            this.setState({
                totalCalonSiswa : res && res.data && res.data.totalDataCalonSiswa ? res.data.totalDataCalonSiswa : 0,
                totalCalonSiswaDiwawancara : res && res.data && res.data.totalCalonSiswaDiwawancarai? res.data.totalCalonSiswaDiwawancarai : 0,
                pagination: {
					...this.state.pagination,
					total: res && res.data && res.data.totalDataCalonSiswa ? res.data.totalDataCalonSiswa : 0,
				}
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                loadingCalonSiswa: false
            })
        })
        
        this.setState({
            listCalonSiswa: listTemp,
            loadingCalonSiswa:false
        })
    }

    handleSearch(e) {
        let key = e.target.value;
        this.processSearchCalonSiswa(key);
    }

    processSearchCalonSiswa = _debounce((key) => {
        this.setState({
            search: GlobalFunction.searchEncode(key),
            pagination: {
                ...this.state.pagination,
                current: 1
            }
        }, () => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
        });
    }, 500)

    handleJalur(e){
        if(e == 'TesOnline'){
            this.props.history.push('/interviewer-jalur-test-online')
        }
    }

    handleTableChange = (pagination, search, sorter) => {
        if (sorter.order === "ascend") {
            sorter.order = "asc";
        } else if (sorter.order === "descend") {
            sorter.order = "desc";
        }

        this.setState({
            sort: `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'nama_siswa,desc'}`,
            pagination: pagination
        }, () => {
            this.getDataCalonSiswa(
                this.state.pagination.current - 1,
                this.state.pagination.pageSize,
                this.state.sort,
                this.state.search
            )
        });
    };

    render() {
        const tagColor = (tag) => {
            tag = tag.toLowerCase();
            switch (tag) {
                case 'cadangan':
                    return '#F2994A';
                case 'lolos':
                case 'diterima':
                    return '#219653';
                case 'ragu2':
                case 'ragu-ragu':
                    return '#F2C94C';
                case 'belum diwawancara':
                    return '#072A6F';
                case 'tidak lolos':
                case 'ditolak':
                case 'tidak diketahui':
                    return '#EB5757';
                default:
                    return '#072A6F';
            }
        };

        const columns = [
            {
                title: 'No. Registrasi',
                dataIndex: 'noRegistrasi',
                key: 'pn.no_registrasi',
                width: '20%',
                sorter: true,
            },
            {
                title: 'Nama',
                dataIndex: 'namaSiswa',
                key: 'nama_siswa',
                width: '20%',
                sorter: true
            },
            {
                title: 'Asal Sekolah',
                dataIndex: 'sekolahAsal',
                key: 'sekolah_asal',
                width: '20%',
                sorter: true
            },
            {
                title: 'Status',
                dataIndex: 'statusRekomendasi',
                key: 's.status',
                sorter: true,
                width: '20%',
                render: tag => {
                    if(!tag){
                        tag = "tidak diketahui"
                    }
                    let color = tagColor(tag);
                    return (
                        <Text color={color} style={{color: color}}>
                            {tag.toUpperCase()}
                        </Text>
                    )
                }
            },
            {
                width: '20%',
                render: (record) => {
                    if(record.statusRekomendasi === null){;
                        record.statusRekomendasi = "tidak diketahui"
                    }
                    if (record.statusRekomendasi.toLowerCase() === "belum diwawancara"){
                        return (
                            <Space>
                                <Link to={"/input-hasil-interview/" + record.noRegistrasi + "/" + record.namaSiswa + "/prestasi/"+ record.flagDataKesungguhanExist}>
                                    <Button className="admin-update-button" type="primary" size="small">
                                        Input Penilaian
                                    </Button>
                                </Link>
                            </Space>
                        )
                    }
                }
            },
        ];

        const { responsive, jalur, loadingCalonSiswa, listCalonSiswa, data, totalCalonSiswa, totalCalonSiswaDiwawancara, pagination } = this.state
        const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }
        const dropdownLabel = menuKey.find(e => e.key === jalur).label;

        return(
            <Content className="contentContainer">                
                {responsive && responsive.grid.md ? (
                    <Row>
                        <Col>
                            <Space size={"large"}>
                                {menuKey.map(({key, label}) => (
                                    <a type="text" onClick={() => {this.handleJalur(key)}} className="text-heading">
                                        <Title level={3} style={{color: jalur === key ? '#072a6f' : '#BDBDBD' }}>
                                            {label}
                                        </Title>
                                    </a>
                                ))}
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <div style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                        <Dropdown 
                            overlay={(
                                <Menu onClick={e => this.handleJalur(e.key)}>
                                    {menuKey.map(({key, label}) => (
                                        <Menu.Item key={key}>{label}</Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <Button style={{width: '100%', marginBottom: '24px'}}> {dropdownLabel}<DownOutlined/> </Button>
                        </Dropdown>
                    </div>
                )}
                <Row align="middle">
                    <Col span={24} lg={6}>
                        <Input
                            className="input-search"
                            placeholder="No. Registrasi"
                            style={{
                                width: '100%',
                                marginRight: '10px',
                                textAlign: 'left'
                            }}
                            prefix={<SearchOutlined/>}
                            onChange={(e) => this.handleSearch(e)}
                        />
                    </Col>
                    <Col span={24} lg={18} style={{ textAlign: responsive.grid.xs && responsive.width < 640 ? 'left' : 'right' }}>
                        <Row justify='end'>
                            <Col>
                                <Button type="text" onClick={this.handleModalQR}>
                                    <QrcodeOutlined style={{fontSize:25, justifyContent:'right', color: '#072A6F'}}/> <Text className='title-scanqr'> Scan QR</Text>
                                </Button>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Text className="title-verifikator" >{totalCalonSiswaDiwawancara} dari {totalCalonSiswa} calon siswa telah diwawancara</Text>
                        </Row>
                    </Col>
                    <ModalQR
                        visible={this.state.isModalShow}
                        buttonCancel={this.handleModalQR}
                        routeRole={"input-hasil-interview"}
                        routeJalur={"prestasi"}
                        type={'interviewer'}
                        isPrestasi={true}
                    />
                </Row>
                <Row style={{marginBottom: 10, marginTop:24}}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="app-card"
                        >
                            <Table
                                columns={columns}
                                size="middle"
                                bordered={false}
                                pagination={paginationCus}
                                onChange={this.handleTableChange}
                                loading={loadingCalonSiswa}
                                dataSource={listCalonSiswa}
                                scroll={{x: responsive && responsive.grid.xs ? responsive.width : 0}}
                            />
                        </Card>
                    </Col>
                </Row>
            </Content>
        );
    }
}

export default withRouter(InterviewerJalurPrestasi)