import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";

const { Text, Title } = Typography
const { Option } = Select;
const FormUser = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [userIDedited, setUserIDedited] = useState(null)
    const [hidden, setHidden] = useState(true)
    const userID = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId

    const handleHiddenForm = () =>{
      setHidden(!hidden)
    }

    useEffect(()=>{
      console.log('record : ',props.isEdit)
      if(props.isEdit){
        setHidden(true)
        form.setFieldsValue(props.isEdit);
        setUserIDedited(props.isEdit.userId);
      }else{
        setHidden(false)
        form.resetFields()
      }

      // eslint-disable-next-line
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let putBody = {
        userId: userIDedited,
        roleId: values.roleId,
        userName: values.userName,
        userFullName: values.userFullName,
        userPassword: values.userPassword,
        userPhone: values.userPhone,
        userEmail: values.userEmail
      }
      let postBody = {
        roleId: values.roleId,
        userName: values.userName,
        userFullName: values.userFullName,
        userPassword: values.userPassword,
        userPhone: values.userPhone,
        userEmail: values.userEmail
      }

      console.log('body form status : ', putBody)
      if(props.isEdit){
        putBody.id = props.isEdit.userId
        console.log('body : ',putBody)
        LoyolaServices.putDataUserAdmin(userID, putBody).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            let text = err.response && err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
            dialog({icon: 'error', text: text})
            setLoading(false)
          }
        })
      }else{
        LoyolaServices.postDataUserAdmin(userID, postBody).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            let text = err.response &&  err.response.data && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
            dialog({icon: 'error', text: text})
            setLoading(false)
          }
        })
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Data User</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form 
                name="status"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item name="userFullName" label="Nama Lengkap"
                  rules={[
                    {
                      required: true,
                      message: 'Nama Lengkap harus diisi',
                    },
                  ]}
                >
                  <Input
                      maxLength={50}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item name="userName" label="Username"
                  rules={[
                    {
                      required: true,
                      message: 'Username harus diisi',
                    },
                  ]}
                >
                  <Input
                      maxLength={20}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Col span={24} style={!props.isEdit ? { display: 'none'} : {color: '#072A6F', textAlign: 'right', justifyContent: 'flex-end', alignItems: 'right'}}>
                  <Text>
                    <a onClick={handleHiddenForm}>
                        Ubah Password
                    </a>
                  </Text>
                </Col>
                <Form.Item name="userPassword" label="Password"
                    style={hidden ? { display: 'none'} : {}}
                    rules={!hidden ? [{ required: true, message: 'Password harus diisi' }] : ""}
                    hasFeedback
                >
                    <Input.Password
                        className='passwordColor'
                        style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                        type="password"
                    />
                </Form.Item>
                <Form.Item name="confirmPassword" label="Confirm Password"
                    style={hidden ? { display: 'none'} : {}}
                    dependencies={['userPassword']}
                    rules={!hidden ? 
                      [
                        { 
                            required: true, message: 'Confirm Password harus diisi' 
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('userPassword') === value) {
                                return Promise.resolve();
                                }
                
                                return Promise.reject('Password yang anda masukkan tidak sama!');
                            },
                        }),
                      ]
                      :
                      ""
                    }
                    hasFeedback
                >
                    <Input.Password
                        className='passwordColor'
                        style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                        type="password"
                    />
                </Form.Item>
                <Form.Item name="userEmail" label="E-mail"
                  rules={[
                    {
                      required: true,
                      message: 'E-mail harus diisi',
                    },
                    {
                      type:'email', 
                      message: 'Harap diisi dengan format e-mail',
                    }
                  ]}
                >
                  <Input
                      maxLength={50}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item name="userPhone" label="No. Telepon"
                  rules={[
                    {
                      required: true,
                      message: 'No. Telepon harus diisi',
                    },
                    {
                        pattern: new RegExp('^[0-9]+$'), 
                        message: "Harap diisi dengan angka"
                    }
                  ]}
                >
                  <Input
                      maxLength={15}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item name="roleId"
                  rules={[
                    {
                      required: false,
                      message: 'Role harus diisi',
                    },
                  ]}>
                  <Select
                    placeholder="Role"
                    allowClear
                  >
                    {
                      props.dataLov.map(res => {
                      return (<Option value={res.roleId}>{res.roleName}</Option>)
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormUser);