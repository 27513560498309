import React from 'react';
import 'antd/dist/antd.css';
import './app/css/global.css';
import './app/css/container.scss';
import './app/service/token-interceptor';
import { Layout } from "antd";
import Routes from "./Routes";


const App = () => (
  <Layout className="app-layout">
    <Routes />
  </Layout>
)


export default App;
