import { Input, Modal, Form, Button, DatePicker } from 'antd'
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { LoyolaServices } from '../../../service';
import { confirmDialog, dialog } from "../../../functions/alert";
import { RedStar } from '../../../component/common';

const keys = [
  {label: 'No. Registrasi', key: 'noRegistrasi'},
  {label: 'Nama', key: 'namaSiswa'},
  {label: 'Kota SMP', key: 'kotaSmp'},
  {label: 'Tanggal & waktu pelaksanaan', key: 'tglWaktuPelaksanaan'},
  {label: 'Batas tanggal & waktu pelaksanaan', key: 'batasTglWaktuPelaksanaan'},
  {label: 'Lokasi', key: 'lokasi'},
  {label: 'Media', key: 'media'},
  {label: 'Link', key: 'link_pelaksanaan'},
  {label: 'Tanggal & waktu kirim pemberitahuan', key: 'tgl_notifikasi'},
];

export default function ModalUbahJadwal(props) {
  const [form] = Form.useForm()
  // const dateBatas = moment()
  const {data, onCancel, onFinish} = props;

  const handleSimpan = () => {
    confirmDialog({
      text: `Apakah Anda yakin ingin memperpanjang waktu pelaksanaan daftar ulang No. Registrasi ${data.noRegistrasi}?`
    }).then(_ => {
      const newValue = moment(form.getFieldValue('batasTglWaktuPelaksanaan')).format('DD-MM-YYYY HH:mm');
      const user = JSON.parse(sessionStorage.getItem('user'));

      const body = {
        userId: user.userId,
        noRegistrasi: data.noRegistrasi,
        batasTglWaktuPelaksanaan: newValue
      }
      LoyolaServices.updateJadwalDaftarUlang(body).then(res => {
        if (res.data.Status !== "BAD_REQUEST") {
          dialog({icon: 'success', text: `Jadwal Daftar Ulang No. Registrasi ${data.noRegistrasi} berhasil diperpanjang` });
          onFinish();
        }  else {
          dialog({title: 'Gagal mengubah jadwal', icon: 'error', text: res.data.Message})
        }
      }).catch(err => {
        dialog({title: 'Gagal mengubah jadwal', icon: 'error', text: err.response && err.response.data && err.response.data.message})
      })
    }).catch(_ => {})
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        batasTglWaktuPelaksanaan: moment(data.timestampBatasPelaksanaan),
        tglWaktuPelaksanaan: moment(data.timestampPelaksanaan),
        'tgl_notifikasi': moment(data.timestampPelaksanaan), 
      });
    }
  }, [data])

  return (
    <div>
      <Modal
        className="app-modal"
        visible={!!data}
        onCancel={onCancel}
        footer={null}
        closeIcon={null}
        width="500px"
        closable={false}
      >
        <div style={{textAlign: 'center', padding: '1em 0px'}}>
          <Title level={4}>Ubah jadwal Daftar Ulang</Title>
        </div>
        <Form form={form}>
          {!!data ? keys.map(({key, label}) => {
            const formRule = key === 'batasTglWaktuPelaksanaan' ? [{ required: true, message: 'Tanggal pelaksanaan harus diisi' }] :  []
            return (
              <>
                <Text>{label}</Text> {key === 'batasTglWaktuPelaksanaan' && <RedStar />}
                <Form.Item rules={formRule} name={key} key={'ubahjadwal-'+key} style={{marginBottom: '1em'}}>
                  {key === 'batasTglWaktuPelaksanaan' || key === 'tglWaktuPelaksanaan' || key === 'tgl_notifikasi' ? 
                  <DatePicker showTime={true} style={{width: '100%'}} disabled={key !== 'batasTglWaktuPelaksanaan'} /> :
                  <Input value={data[key]} disabled={true} />}
                </Form.Item>
              </>
            );
          }) : ''}
          <div style={{display: 'flex', justifyContent: 'right', width: '100%', marginTop: '2em'}}>
            <Button className="app-btn danger" style={{marginLeft: '1em'}} onClick={_ => onCancel()}>Batal</Button>
            <Button className="app-btn success" style={{marginLeft: '1em'}} onClick={_ => {
              form.validateFields().then(_ => {
                handleSimpan()
              }).catch(err => {})
            }}>Simpan</Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
