/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { Layout, Row, Input, Card, Button, Form, Col, Typography, message } from 'antd';
import { LockOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import Logo from '../../../assets/logo-loyola-300x300.svg'
import Auth from '../../service/auth';
import { useForm } from 'antd/lib/form/Form';
import HandleRole from "./handle-role";

const { Content } = Layout;
const { Text, Title } = Typography;

const Login = props => {
  const history = useHistory();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!Auth.isLogin()) {
      const role = JSON.parse(sessionStorage.getItem("user")).authorities[0].authority;
      const loc = HandleRole(role);
      history.push(loc);
    }
  }, []);

  useEffect(() => {
    document.title = props.name + " | Loyola"

  }, [props.name])

  const handleLogin = async () => {
    setIsLoading(true);
    Auth.login(form.getFieldsValue()).then((response) => {
      var res = response.data
      var role = res.authorities[0].authority // get role
      if(sessionStorage.getItem('token')){
        const loc = HandleRole(role);
        console.log(loc)
        history.push(loc);
        props.onLogin();
      }
    }).catch(err => {
      setIsLoading(false);
      if(err.response){
        message.error('Username/Password salah');
      }else{
        message.error('Terjadi Kesalahan Pada Server');
      }
      console.log(err);
    });
  }

  const handleForgotPass = () => {
    props.history.push('/lupa-password');
  }

  return(
    <Layout>
    <Content className="site-layout-login contentContainer">
      <div className="container">
        <Row gutter={[40, 40]} style={{alignContent: "start", margin: 'auto'}}>
          <Col span={24} xl={{span: 18, offset: 3}} lg={24}>
            <img src={Logo} alt="logo" onClick={()=> props.history.push("/")} />
          </Col>
          <Col span={24} xl={{span: 9, offset: 3}} lg={24} style={{marginBottom: '2em'}}>
            <Text className="title-login">
              Panitia PPDB<br />
              SMA Kolese Loyola <br />
              Semarang
            </Text>
          </Col>

          <Col span={24} xl={{span: 9}} lg={24}>
            <Card className="card-site-login">
              <Form form={form} >
                  <Row gutter={[0, 16]}>
                    <Col style={{textAlign: 'center'}} span={24}>
                      <Title style={{color: "#fff", marginBottom: '1em'}}>LOGIN</Title>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{marginBottom:0}}
                        name="username"
                        rules={[
                          { required: true, message: 'Harap masukkan email!' }
                        ]}
                      >
                        <Input
                          className="form-group-login"
                          prefix="@"
                          type="text"
                          placeholder="E-mail"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{marginBottom:0}}
                        name="password"
                        rules={[{ required: true, message: 'Harap masukkan password!' }]}
                      >
                        <Input.Password
                          className="form-group-login"
                          prefix={<LockOutlined />}
                          name="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{textAlign: 'right',marginBottom:20}}>
                      <span 
                        style={{ 
                          fontSize: 16,
                          color: "#ffffff", 
                          cursor: "pointer" 
                        }} 
                        onClick={() => handleForgotPass()}
                      >
                        Lupa password?
                      </span>
                    </Col>
                    <Col span={24}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        className="app-btn lg block primary"
                        disabled={isLoading}
                        onClick={() => {
                          if (isLoading) { return 0; }
                          form.validateFields().then(value => {
                            handleLogin();
                          }).catch(err => {
                            console.log(err);
                          })
                        }}
                      >
                        {isLoading && <LoadingOutlined />}
                        MASUK KE AKUN
                      </Button>
                    </Col>
                  </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        

      </div>
    </Content>
    </Layout>
  )

}

export default withRouter(Login)
