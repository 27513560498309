import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Input, Form, Button, Typography, Col, Row} from "antd";
import Modal from "antd/lib/modal/Modal";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";

const { Title } = Typography
const FormKategoriStatus = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
      console.log('record : ',props.isEdit)
      if(props.isEdit){
        form.setFieldsValue(props.isEdit);
      }else{
        form.resetFields()
      }

      // eslint-disable-next-line
    }, [props.isShow]);
    
    const onFinish = (values) => {
      setLoading(true)
      let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
      let body = {
        kategoriStatus: values.kategoriStatus
      }
      console.log('body form status : ',body)
      if (props.isEdit) {
        body.id = props.isEdit.katStatusId
        console.log('body : ',body)
        LoyolaServices.putKategoriStatus(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      } else {
        LoyolaServices.postKategoriStatus(userId,body).then(res => {
          if(res.data){
            dialog({icon: 'success', text: res.data.message}).then(()=>{
              props.onRefresh()
              setLoading(false)
            })
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Modal 
          visible={props.isShow}
          layout="vertical"
          onCancel={()=>props.onCancel()}
          footer={null}
          closeIcon={null}
          width='400px'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
              {<Title level={4} style={{color: '#072A6F'}}>{ props.isEdit ? 'Ubah' : 'Tambah'} Kategori Status</Title>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Form
                name="status"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item name="kategoriStatus" label="Nama Kategori Status"
                  rules={[
                    {
                      required: true,
                      message: 'Nama Kategori Status harus diisi',
                    },
                  ]}
                >
                  <Input
                      maxLength={100}
                      style={{backgroundColor: "#F2F2F2", borderRadius: 16}}
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[20]}>
                    <Col offset={6} span={6} style={{textAlign: 'right'}}>
                      <Button type='primary' danger onClick={()=>props.onCancel()} style={{borderRadius: 8}}>
                        Batal
                      </Button>
                    </Col>
                    <Col span={6} style={{textAlign: 'left'}}>
                      <Button loading={loading} type="primary" htmlType="submit" style={{borderRadius: 8,backgroundColor: '#27AE60'}}>
                        { props.isEdit ? 'Ubah' : 'Tambah'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
    );
};

export default withRouter(FormKategoriStatus);