import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { Layout } from 'antd';
import PertanyaanUjianPg from "./pertanyaan-ujian-pg";
import PertanyaanUjianEssai from "./pertanyaan-ujian-esai";
import { LoadingOutlined } from "@ant-design/icons";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import Dummy from "../../../dummy";

const { Content } = Layout;

const PertanyaanInput = (props) => {
    const [menu, setMenu] = useState(0)
    const [dataLovMatpel, setDataLovMatpel] = useState(null)

    useEffect(() => {
      // getDataDummy()
      getDataLovMatpel()
    },[])

    const getDataLovMatpel = () => {
      LoyolaServices.getDataLovMatpel().then(res => {
        if(res.data){
          setDataLovMatpel(res.data.data)
        }
      }).catch(err => {
        if(err){
          dialog({icon:'error', text: err.response ? err.response.data.message : err.message})
        }
      })
    }

    const changeMenu = (params) => {
        setMenu(params)
    }

    return(
        <div style={{ marginTop:20}}>
          {dataLovMatpel ?
            (
              menu == 0 ? 
                <PertanyaanUjianPg 
                  responsive={props.responsive} 
                  changeMenu={(event)=>changeMenu(event)} 
                  dataLovMatpel={dataLovMatpel}
                /> 
              :
                <PertanyaanUjianEssai 
                  responsive={props.responsive} 
                  changeMenu={(event)=>changeMenu(event)} 
                  dataLovMatpel={dataLovMatpel}
                />
            ) 
              :
                <LoadingOutlined/>
          }
        </div>
    );
}

export default withRouter(PertanyaanInput)