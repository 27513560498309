import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Button, Table, Card, Input} from "antd";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import {SearchOutlined} from "@ant-design/icons";
import _debounce from "lodash.debounce";
import {GlobalFunction} from "../../../global/function";
import Downloader from "js-file-downloader";

const { Content } = Layout;

const DownloadBerkasKesungguhan = (props) => {

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [sort, setSorting] = useState('pn.no_registrasi,desc');
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState(null);
  const [records, setRecord] = useState([]);

  useEffect(()=>{
    getData(pagination,sort,search)
    // getDataDummy()
  }, []);

  const getData = (page,sort,search) => {
    setLoading(true);
    LoyolaServices.getlistKesungguhan(page.current - 1,page.pageSize,sort,search).then(res => {
      if(res.data){
        var data = res.data.data;
        setDataList(data);
        setPagination({
          ...pagination,
          current: res.data.page + 1,
          total: res.data.totalDataCalonSiswa
        })
        setLoading(false)
      }
    }).catch((err) => {
      if(err){
        let text = (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
        setDataList([])
        setLoading(false)
      }
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra, search);
    if (sorter.order === "ascend") {
        sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    let sorting = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : 'pn.no_registrasi,desc'}`
    setSorting(sorting)
    setPagination(pagination)
    getData(pagination,sorting,search)
  };

  const handleDownload = () => {
    setLoading(true)
    let userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    let params = ''
    let dataRecords = []
    if(records && records.length > 0){
      records.map(res => {
        dataList.map(resList => {
          if(resList.noRegistrasi == res.noRegistrasi){
            dataRecords.push(res)
          }
        })
      })

    console.log('dataRecords = ',dataRecords)

    dataRecords.map(res => {
      params += (','+res.noRegistrasi)
    })

      if(dataRecords.length > 0){
        LoyolaServices.getDownloadDataUploadKejujuran(params).then(res => {
          if(res.data){
            new Downloader({url: res.data.Link}).then(file => {
            }).catch(err => {console.log(err)})
            dialog({icon: 'success', text: 'Pesan Telah Terkirim'})
            onRefresh()
            setLoading(false)
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
            setLoading(false)
          }
        })
      }else{
        dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
        setLoading(false)
      }

    }else {
      dialog({icon: 'error', text: 'Tidak ada data yang dipilih'})
      setLoading(false)
    }
  }

  const onRefresh = () => {
      getData(pagination,sort,search)
  }

  const handleSearch = (e) => {
    let key = e.target.value;
    processSearchCalonSiswa(key);
  };

  const processSearchCalonSiswa = _debounce((key) => {
    const param = GlobalFunction.searchEncode(key);
    setSearch(param)
    getData({current: 1, pageSize: 10},sort,param)
  }, 500);

  const columns = [
    {
        title: 'No. Registrasi',
        dataIndex: 'noRegistrasi',
        key: 'pn.no_registrasi',
        sorter: true,
    },
    {
        title: 'Nama Siswa',
        dataIndex: 'namaSiswa',
        key: 'nama_siswa',
        sorter: true,
        width: '70%'
    },
  ];

  const paginationCus = { ...pagination, showSizeChanger: false, position: ['none', 'bottomCenter'] }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRecord(selectedRows)
    },
  };

    return(
      <div style={{marginTop:20}}>
        <Row gutter={[20,20]}>
          <Col span={24} lg={6} md={8}>
            <Input
                className="input-search"
                placeholder="No. Registrasi"
                style={{
                  width: '100%',
                  marginRight: '10px',
                  textAlign: 'left'
                }}
                prefix={<SearchOutlined/>}
                onChange={handleSearch}
            />
          </Col>
          <Col span={24} lg={18} md={16}  style={{justifyContent: 'flex-end', alignItems: 'right', textAlign: 'end'}}>
            <Button className="app-btn" type="primary" style={{backgroundColor: '#072A6F'}}
              onClick={handleDownload}
            >Download</Button>
          </Col>
        </Row>
        <Row style={{marginTop:24}}>
          <Col span={24}>
            <Card bordered={false} className="app-card">
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                columns={columns}
                size="middle"
                bordered={false}
                pagination={paginationCus}
                onChange={handleTableChange}
                loading={loading}
                dataSource={dataList}
                scroll={{x: props.responsive && props.responsive.isMobile ? props.responsive.width : 0}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default withRouter(DownloadBerkasKesungguhan);