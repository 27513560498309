import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Layout, Row, Button, Divider, Card, Input, Popconfirm, Checkbox, Typography, Form, Spin, Tooltip } from "antd";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import { QuestionCircleOutlined, DeleteOutlined, PlusCircleOutlined, ClearOutlined, SaveOutlined } from "@ant-design/icons";
import moment from 'moment'
import { sortBy } from "lodash";

const dummyBeasiswaUtama = {
  "status": "OK",
  "message": "Proses berhasil",
  "data": {
    "listKomitmenLabel": [
      {
        "komitmenId": 1,
        "komitmen": "Flat Offer"
      },
      {
        "komitmenId": 5,
        "komitmen": "Flat Offer Plus - Silver"
      },
      {
        "komitmenId": 4,
        "komitmen": "Flat Offer Plus - Gold"
      },
      {
        "komitmenId": 3,
        "komitmen": "Flat Offer Plus - Platinum"
      },
      {
        "komitmenId": 6,
        "komitmen": "Negosiasi"
      }
    ],
    "listBeasiswaUtama": [
      {
        "beasiswaId": 1,
        "beasiswa": "Prestasi Rapor",
        "infoBeasiswa": "Prestasi rapor bisa diajukan berdasarkan jumlah nilai 5 mata pelajaran pada kelas 7 dan kelas 8. jika jumlah >= 450 maka potongan UPS yang bisa didapatkan sebanyak 10%. Sedangkan untuk jumlah >=475, potongan UPS yang bisa didapatkan sebanyak 20%",
        "flatOffer": true,
        "flatOfferPlusSilver": true,
        "flatOfferPlusGold": true,
        "flatOfferPlusPlatinum": true,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      },
      {
        "beasiswaId": 2,
        "beasiswa": "Prestasi Seni",
        "infoBeasiswa": "Prestasi seni bisa diajukan oleh siswa yang memiliki sertifikat kejuaraan tingkat provinsi/nasional/internasional dalam bidang seni. Beasiswa yang didapatkan mulai dari 15% - 50% potongan UPS",
        "flatOffer": true,
        "flatOfferPlusSilver": true,
        "flatOfferPlusGold": true,
        "flatOfferPlusPlatinum": true,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      },
      {
        "beasiswaId": 3,
        "beasiswa": "Prestasi Olahraga",
        "infoBeasiswa": "Prestasi olahraga bisa diajukan oleh siswa yang memiliki sertifikat kejuaraan tingkat provinsi/nasional/internasional dalam bidang olahraga. Beasiswa yang didapatkan mulai dari 15% - 50% potongan UPS",
        "flatOffer": true,
        "flatOfferPlusSilver": true,
        "flatOfferPlusGold": true,
        "flatOfferPlusPlatinum": true,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      },
      {
        "beasiswaId": 5,
        "beasiswa": "Keluarga Inti Alumni (KEKL/KKL)",
        "infoBeasiswa": null,
        "flatOffer": true,
        "flatOfferPlusSilver": true,
        "flatOfferPlusGold": true,
        "flatOfferPlusPlatinum": true,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      },
      {
        "beasiswaId": 4,
        "beasiswa": "OfoN",
        "infoBeasiswa": "Beasiswa OfoN dapat diajukan oleh siswa yang pengasilan kedua orang tuanya < Rp. 1.500.000. Beasiswa yang bisa didapatkan mulai dari 65% - 100%",
        "flatOffer": true,
        "flatOfferPlusSilver": false,
        "flatOfferPlusGold": false,
        "flatOfferPlusPlatinum": false,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      }
    ]
  }
}

const dummyBeasiswaTambahan = {
  "status": "OK",
  "message": "Proses berhasil",
  "data": {
    "listKomitmenLabel": [
      {
        "komitmenId": 1,
        "komitmen": "Flat Offer"
      },
      {
        "komitmenId": 5,
        "komitmen": "Flat Offer Plus - Silver"
      },
      {
        "komitmenId": 4,
        "komitmen": "Flat Offer Plus - Gold"
      },
      {
        "komitmenId": 3,
        "komitmen": "Flat Offer Plus - Platinum"
      },
      {
        "komitmenId": 6,
        "komitmen": "Negosiasi"
      }
    ],
    "listBeasiswaTambahan": [
      {
        "beasiswaId": 6,
        "beasiswa": "Prestasi Rapor Tambahan",
        "infoBeasiswa": "Prestasi rapor bisa diajukan berdasarkan jumlah nilai 5 mata pelajaran pada kelas 7 dan kelas 8. jika jumlah >= 450 maka potongan UPS yang bisa didapatkan sebanyak 10%. Sedangkan untuk jumlah >=475, potongan UPS yang bisa didapatkan sebanyak 20%",
        "flatOffer": true,
        "flatOfferPlusSilver": true,
        "flatOfferPlusGold": true,
        "flatOfferPlusPlatinum": true,
        "negosiasi": false,
        "userId": null,
        "deleted": false
      }
    ]
  }
}

const MasterBeasiswa = (props) => {
  const [loadingBeasiswaUtama, setLoadingBeasiswaUtama] = useState(false);
  const [loadingBeasiswaTambahan, setLoadingBeasiswaTambahan] = useState(false);
  const [valueLabelBeasiswaUtama, setValueLabelBeasiswaUtama] = useState([]);
  const [valueLabelBeasiswaTambahan, setValueLabelBeasiswaTambahan] = useState([]);
  const [valueBeasiswaUtama, setValueBeasiswaUtama] = useState([]);
  const [valueBeasiswaTambahan, setValueBeasiswaTambahan] = useState([]);
  const [formBeasiswaUtama] = Form.useForm();
  const [formBeasiswaTambahan] = Form.useForm();
  const userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId;

  useEffect(() => {
    readMasterBeasiswaUtama();
    readMasterBeasiswaTambahan();
  }, []);

  const readMasterBeasiswaUtama = async () => {
    setLoadingBeasiswaUtama(true)
    await LoyolaServices.getBeasiswa(true).then(res => {
      if (res.data) {
        setValueLabelBeasiswaUtama(res.data.data.listKomitmenLabel);
        setValueBeasiswaUtama(res.data.data.listBeasiswa);
        formBeasiswaUtama.setFieldsValue(res.data.data);
      }
      setLoadingBeasiswaUtama(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaUtama(false))
      }
    })
  };

  const readMasterBeasiswaTambahan = async () => {
    setLoadingBeasiswaTambahan(true)
    await LoyolaServices.getBeasiswa(false).then(res => {
      if (res.data) {
        setValueLabelBeasiswaTambahan(res.data.data.listKomitmenLabel);
        setValueBeasiswaTambahan(res.data.data.listBeasiswa);
        formBeasiswaTambahan.setFieldsValue(res.data.data);
      }
      setLoadingBeasiswaTambahan(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaTambahan(false))
      }
    })
  }

  const onFinishBeasiswaUtama = async (values) => {
    await values.listBeasiswa.map(item => {
      if (item) {
        item.infoBeasiswa = item?.infoBeasiswa || '';
        item.flatOffer = item?.flatOffer || false;
        item.flatOfferPlusGold = item?.flatOfferPlusGold || false;
        item.flatOfferPlusPlatinum = item?.flatOfferPlusPlatinum || false;
        item.flatOfferPlusSilver = item?.flatOfferPlusSilver || false;
        item.negosiasi = item?.negosiasi || false;
        item.deleted = item?.deleted || false;
        item.userId = userId;
      }
    })
    setLoadingBeasiswaUtama(true)
    await LoyolaServices.putBeasiswa(values.listBeasiswa, true).then(res => {
      if (res.data) {
        dialog({ icon: 'success', text: 'Data Beasiswa Utama telah disimpan' })
        readMasterBeasiswaUtama()
      }
      setLoadingBeasiswaUtama(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaUtama(false))
      }
    })
  };

  const onFinishBeasiswaTambahan = async (values) => {
    await values.listBeasiswa.map(item => {
      if (item) {
        item.infoBeasiswa = item?.infoBeasiswa || '';
        item.flatOffer = item?.flatOffer || false;
        item.flatOfferPlusGold = item?.flatOfferPlusGold || false;
        item.flatOfferPlusPlatinum = item?.flatOfferPlusPlatinum || false;
        item.flatOfferPlusSilver = item?.flatOfferPlusSilver || false;
        item.negosiasi = item?.negosiasi || false;
        item.deleted = item?.deleted || false;
        item.userId = userId;
      }
    })
    setLoadingBeasiswaTambahan(true)
    await LoyolaServices.putBeasiswa(values.listBeasiswa, false).then(res => {
      if (res.data) {
        dialog({ icon: 'success', text: 'Data Beasiswa Tambahan telah disimpan' })
        readMasterBeasiswaTambahan()
      }
      setLoadingBeasiswaTambahan(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaTambahan(false))
      }
    })
  };

  const deleteRowBeasiswaUtama = async (id) => {
    const beasiswaUtamaTempDelete = formBeasiswaUtama.getFieldValue('listBeasiswa');
    await beasiswaUtamaTempDelete.map(item => {
      item.userId = userId;
      if (id === item.beasiswaId) {
        item.deleted = true;
      }
    })
    setLoadingBeasiswaUtama(true)
    await LoyolaServices.putBeasiswa(beasiswaUtamaTempDelete, true).then(res => {
      if (res.data) {
        dialog({ icon: 'success', text: 'Data Beasiswa Utama telah dihapus' })
        readMasterBeasiswaUtama()
      }
      setLoadingBeasiswaUtama(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaUtama(false))
      }
    })
  }

  const deleteRowBeasiswaTambahan = async (id) => {
    const beasiswaTambahanTempDelete = formBeasiswaTambahan.getFieldValue('listBeasiswa');
    await beasiswaTambahanTempDelete.map(item => {
      item.userId = userId;
      if (id === item.beasiswaId) {
        item.deleted = true;
      }
    })
    setLoadingBeasiswaTambahan(true)
    await LoyolaServices.putBeasiswa(beasiswaTambahanTempDelete, false).then(res => {
      if (res.data) {
        dialog({ icon: 'success', text: 'Data Beasiswa Tambahan telah dihapus' })
        readMasterBeasiswaTambahan()
      }
      setLoadingBeasiswaTambahan(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoadingBeasiswaTambahan(false))
      }
    })
  }

  return (
    <Layout.Content style={{ marginTop: 20 }} className="site-layout-content">
      <Row>
        <Col className="text-heading">
          <Typography.Title level={3} style={{ color: '#072A6F', cursor: 'pointer' }}>
            Beasiswa
          </Typography.Title>
        </Col>
      </Row>
      {/* Utama */}
      <Form
        name="list-beasiswa-utama"
        onFinish={onFinishBeasiswaUtama}
        onFinishFailed={(error) => console.log('Failed : ', error)}
        form={formBeasiswaUtama}
      >
        <Form.List name="listBeasiswa">
          {(fields, { add, remove }) => (
            <>
              <Row>
                <Col span={24}>
                  <Spin spinning={loadingBeasiswaUtama}>
                    <Card bordered={false} className="app-card" style={{ overflow: 'auto' }}>
                      <Row gutter={[20, 24]} style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Col offset={6} span={6}>
                          <Typography.Text style={{ color: '#072A6F', fontWeight: 'bold' }}>Info Beasiswa</Typography.Text>
                        </Col>
                        {valueLabelBeasiswaUtama && valueLabelBeasiswaUtama.map(item => {
                          return (
                            <Col span={2}>
                              <Typography.Text key={item.komitmenId} style={{ color: '#072A6F', fontWeight: 'bold' }}>{item.komitmen}</Typography.Text>
                            </Col>
                          )
                        })}
                      </Row>
                      <Row gutter={[20, 24]} style={{ marginBottom: 15 }}>
                        <Col span={3}>
                          <Typography.Text style={{ color: '#072A6F', borderBottom: '1px solid #072A6F', fontWeight: 'bold' }}>Beasiswa Utama</Typography.Text>
                        </Col>
                      </Row>
                      {fields.map((field, index) => {
                        return (
                          <Row gutter={[20]} style={{ textAlign: 'center' }}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                              <Form.Item
                                name={[field.name, "beasiswa"]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Nama Beasiswa harus diisi',
                                  },
                                ]}>
                                <Input
                                  style={{ borderRadius: 16 }} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={[field.name, "infoBeasiswa"]}>
                                <Input.TextArea
                                  style={{ borderRadius: 16 }}
                                  rows={4} />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOffer"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusSilver"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusGold"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusPlatinum"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "negosiasi"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item>
                                {valueBeasiswaUtama[index] && valueBeasiswaUtama[index].beasiswaId ?
                                  <Popconfirm
                                    title="Apakah anda yakin untuk menghapus data tersebut？"
                                    icon={
                                      <QuestionCircleOutlined style={{ color: 'red' }} />
                                    }
                                    onConfirm={async () => {
                                      if (valueBeasiswaUtama[index] && valueBeasiswaUtama[index].beasiswaId) {
                                        await deleteRowBeasiswaUtama(valueBeasiswaUtama[index].beasiswaId);
                                      }
                                      remove(field.name);
                                    }}>
                                    <Button icon={<DeleteOutlined />} danger type='primary' style={{ borderRadius: 8 }} >Hapus</Button>
                                  </Popconfirm>
                                  :
                                  <Button icon={<DeleteOutlined />} danger type='primary' style={{ borderRadius: 8 }} onClick={() => { remove(field.name); }}>Hapus</Button>
                                }
                              </Form.Item>
                            </Col>
                          </Row>
                        )
                      })}
                    </Card>
                  </Spin>
                </Col>
              </Row>
              <Row gutter={[20]} style={{ marginTop: 30 }}>
                <Col span={6}>
                  <Form.Item>
                    <Button type='primary' icon={<PlusCircleOutlined />} style={{ borderColor: '#4AA447', borderRadius: 8, backgroundColor: '#4AA447' }} onClick={() => add()}>
                      Tambah Beasiswa Utama
                    </Button>
                  </Form.Item>
                </Col>
                <Col offset={12} span={3}>
                  <Form.Item>
                    <Button block type='primary' icon={<ClearOutlined />} style={{ borderColor: '#EF7138', borderRadius: 8, backgroundColor: '#EF7138' }} onClick={() => readMasterBeasiswaUtama()}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button block loading={loadingBeasiswaUtama} type="primary" icon={<SaveOutlined />} htmlType="submit" style={{ borderColor: '#FEE64D', borderRadius: 8, backgroundColor: '#FEE64D', color: '#072A6F' }}>
                      Simpan
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
      <Divider />
      {/* Tambahan */}
      <Form
        name="list-beasiswa-tambahan"
        onFinish={onFinishBeasiswaTambahan}
        onFinishFailed={(error) => console.log('Failed : ', error)}
        form={formBeasiswaTambahan}
      >
        <Form.List name="listBeasiswa">
          {(fields, { add, remove }) => (
            <>
              <Row>
                <Col span={24}>
                  <Spin spinning={loadingBeasiswaTambahan}>
                    <Card bordered={false} className="app-card" style={{ overflow: 'auto' }}>
                      <Row gutter={[20, 24]} style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Col offset={6} span={6}>
                          <Typography.Text style={{ color: '#072A6F', fontWeight: 'bold' }}>Info Beasiswa</Typography.Text>
                        </Col>
                        {valueLabelBeasiswaTambahan && valueLabelBeasiswaTambahan.map(item => {
                          return (
                            <Col span={2}>
                              <Typography.Text key={item.komitmenId} style={{ color: '#072A6F', fontWeight: 'bold' }}>{item.komitmen}</Typography.Text>
                            </Col>
                          )
                        })}
                      </Row>
                      <Row gutter={[20, 24]} style={{ marginBottom: 15 }}>
                        <Col span={3}>
                          <Typography.Text style={{ color: '#072A6F', borderBottom: '1px solid #072A6F', fontWeight: 'bold' }}>Beasiswa Tambahan</Typography.Text>
                        </Col>
                      </Row>
                      {fields.map((field, index) => {
                        return (
                          <Row gutter={[20]} style={{ textAlign: 'center' }}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                              <Form.Item
                                name={[field.name, "beasiswa"]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Nama Beasiswa harus diisi',
                                  },
                                ]}>
                                <Input
                                  style={{ borderRadius: 16 }} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={[field.name, "infoBeasiswa"]}>
                                <Input.TextArea
                                  style={{ borderRadius: 16 }}
                                  rows={4} />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOffer"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusSilver"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusGold"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "flatOfferPlusPlatinum"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                valuePropName="checked"
                                name={[field.name, "negosiasi"]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item>
                                {valueBeasiswaTambahan[index] && valueBeasiswaTambahan[index].beasiswaId ?
                                  <Popconfirm
                                    title="Apakah anda yakin untuk menghapus data tersebut？"
                                    icon={
                                      <QuestionCircleOutlined style={{ color: 'red' }} />
                                    }
                                    onConfirm={async () => {
                                      if (valueBeasiswaTambahan[index] && valueBeasiswaTambahan[index].beasiswaId) {
                                        await deleteRowBeasiswaTambahan(valueBeasiswaTambahan[index].beasiswaId);
                                      }
                                      remove(field.name);
                                    }}>
                                    <Button icon={<DeleteOutlined />} danger type='primary' style={{ borderRadius: 8 }} >Hapus</Button>
                                  </Popconfirm>
                                  :
                                  <Button icon={<DeleteOutlined />} danger type='primary' style={{ borderRadius: 8 }} onClick={() => { remove(field.name); }}>Hapus</Button>
                                }
                              </Form.Item>
                            </Col>
                          </Row>
                        )
                      })}
                    </Card>
                  </Spin>
                </Col>
              </Row>
              <Row gutter={[20]} style={{ marginTop: 30 }}>
                <Col span={6}>
                  <Form.Item>
                    <Button type='primary' icon={<PlusCircleOutlined />} style={{ borderColor: '#4AA447', borderRadius: 8, backgroundColor: '#4AA447' }} onClick={() => add()}>
                      Tambah Beasiswa Tambahan
                    </Button>
                  </Form.Item>
                </Col>
                <Col offset={12} span={3}>
                  <Form.Item>
                    <Button block type='primary' icon={<ClearOutlined />} style={{ borderColor: '#EF7138', borderRadius: 8, backgroundColor: '#EF7138' }} onClick={() => readMasterBeasiswaTambahan()}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button block loading={loadingBeasiswaTambahan} type="primary" icon={<SaveOutlined />} htmlType="submit" style={{ borderColor: '#FEE64D', borderRadius: 8, backgroundColor: '#FEE64D', color: '#072A6F' }}>
                      Simpan
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Layout.Content>
  );
};

export default withRouter(MasterBeasiswa);