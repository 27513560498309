import { Button, Col, Input, Modal, Row, Space, Form, Radio } from 'antd'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../component/common';
import { confirmDialog, dialog } from '../../../functions/alert';
import { FinanceService } from '../finance-service';
// import { Redstar } from '../../../component/common';

EditKeputusan.propTypes = {
  onCancel: PropTypes.func,
};

EditKeputusan.defaultProps  = {
  onCancel: () => {},
}

export default function EditKeputusan(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [])

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        text: 'Apakah anda yakin ingin mengubah keputusan seleksi beasiswa prestasi Seni dan Olahraga',
        title: 'Simpan keputusan?'
      }).then(e => {
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const body = {
          userId: userId,
          beasiswaSiswaId: data.beasiswaSiswaId,
          kemampuanId: data.kemampuanId,
          ...form.getFieldsValue()
        }
        FinanceService.editKeputusanSeleksi(body).then(res => {
          dialog({title: 'Keputusan seleksi telah tersimpan', icon: 'success'}).then(_ => {
            onFinish()
          });
        }).catch(err => {
          dialog({title: 'Gagal mengubah keputusan seleksi', icon: 'error'})
        })
      }).catch(e => {})
    }).catch(err => {

    })
  }

  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Row>
        <Col span={24} style={{borderBottom: '1px solid #d9d9d9', marginBottom: '1em'}}>
          <Title level={4} className="app-title">Keputusan seleksi beasiswa prestasi Seni dan Olahraga</Title>
        </Col>
        <Col span={24} style={{marginBottom: '1em'}}>
          <Form form={form} onValuesChange={e => {console.log(form.getFieldsValue())}}>
            <Text>Status <RedStar /></Text>
            <Form.Item
              name="statusSeleksi"
              rules={[{ required: true, message: 'Status harus dipilih!' }]}
              style={{marginBottom: '1em'}}
            >
              <Radio.Group>
                <Radio value={1}>Lolos</Radio>
                <Radio value={2}>Tidak Lolos</Radio>
              </Radio.Group>
            </Form.Item>
            <Text>Catatan</Text>
            <Form.Item name="catatanSeleksi">
              <Input.TextArea className="app-input" />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Space className="end" style={{width: '100%'}}>
            <Button className="app-btn primary" onClick={handleSubmit}>Simpan</Button>
            <Button className="app-btn secondary" onClick={onCancel}>Batal</Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}
