import { Button, Col, Input, Modal, Row, Space, Form, Radio, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { RedStar } from '../../../../component/common';
import { confirmDialog, dialog } from '../../../../functions/alert';
import { ArrowDownOutlined, DownOutlined } from '@ant-design/icons';
import { FinanceService } from '../../finance-service';
import Downloader from "js-file-downloader";

ModalVerifikasi.propTypes = {
  onCancel: PropTypes.func,
};

ModalVerifikasi.defaultProps  = {
  onCancel: () => {},
}

const borderedBottom = {
  borderBottom: '1px solid #d9d9d9',
  marginBottom: '1em'
};

const listBerkas = [
  {key: 'buktiYatim', label: 'Bukti Yatim'},
  {key: 'buktiRekamMedis', label: 'Bukti Rekam Medis'},
  {key: 'buktiBencana', label: 'Bukti Bencana'},
  {key: 'buktiSktm', label: 'Bukti SKTM'},
  {key: 'kartuKeluarga', label: 'Kartu Keluarga'},
  {key: 'berkasTambahan', label: 'Berkas Tambahan'},
]

export default function ModalVerifikasi(props) {
  const  {data, onCancel, onFinish} = props;
  const [form] = Form.useForm(); 
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState({});
  const [listDiskon, setListDiskon] = useState([]);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setIsLoading(true);
    await FinanceService.getListDiskonOfon().then(res => {
      setListDiskon(res.data.data);
    }).catch(err => {})
    FinanceService.getVerifikasiBeasiswaOfon(data.komitSiswaId).then(res => {
      const verifikasiData = res.data.data;
      const {diskonId, statusPengajuanUtama, statusPengajuanTambahan, catatanVerifikasi} = verifikasiData
      form.setFieldsValue({diskonId, statusPengajuanUtama, statusPengajuanTambahan, catatanVerifikasi});
      setLoadedData(verifikasiData);
    }).catch(err => {
      dialog({icon: 'error', title: 'Gagal memuat data', text: err.response ? err.response.data.message : err.message}).then(_ => {onCancel()});
    })
    setIsLoading(false);
  }

  const handleSubmit = () => {
    form.validateFields().then(val => {
      confirmDialog({
        text: 'Apakah anda yakin ingin menyimpan keputusan verifikasi?',
        title: 'Simpan keputusan verifikasi'
      }).then(e => {
        const userId = JSON.parse(sessionStorage.getItem('user')).userId;
        const body = {
          statusPengajuanTambahan: null,
          ...val,
          komitSiswaId: data.komitSiswaId,
          userId: userId,
        }
        FinanceService.putVerifikasiBeasiswaOfon(body).then(res => {
          dialog({title: 'Data berhasil diverifikasi', icon: 'success'}).then(_ => {
            onFinish();  
          });
        }).catch(err => {
          dialog({icon: 'error', title: 'Gagal menyimpan data', text: err.response ? err.response.data.message : err.message});
        }) 
      }).catch(e => {})
    }).catch(err => {})
  }

  const handleDownload = (fileKey) => {
    console.log(fileKey, loadedData[fileKey])
    new Downloader({url: loadedData[fileKey]}).then(file => {
    }).catch(err => {
      dialog({title: 'Gagal mengunduh file', icon: 'error'})
    })
  }

  // const isFormDisabled = data.statusPengajuanBeasiswa !== 'Belum Diverifikasi';
  const isFormDisabled = loadedData.isVerified ? loadedData.isVerified : false;
  return (
    <Modal
      className="app-modal"
      closable={false}
      visible={true}
      onOk={_ => {}}
      onCancel={onCancel}
      width="500px"
      footer={null}
    >
      <Spin
        spinning={isLoading}
        centered
        size="large">
      <Row>
        <Col span={24} style={{...borderedBottom}}>
          <Title level={4} className="app-title">Form verifikasi</Title>
        </Col>
        <Col span={24} style={{fontWeight: 'bold', fontSize: '1.4em', marginBottom: '.5em'}}>
          {loadedData.noRegistrasi} - {loadedData.namaSiswa}
        </Col>
        <Col span={24} style={{fontSize: '1.2em', paddingBottom: '.5em',  ...borderedBottom}}>
          <Space direction="vertical" size={0}>
            {listBerkas.map(berkas => {
              const isDownloadEnabled = !!loadedData[berkas.key]
              return (
                <Button type="link" style={{padding: '0px'}} disabled={!isDownloadEnabled} onClick={_ => handleDownload(berkas.key)}>
                  <ArrowDownOutlined />Download {berkas.label}
                </Button>
              )
            })}
          </Space>
        </Col>
        <Col span={24}>
          <Form form={form}>
            <Row>
              <Col span={24}>
                <Text>Beasiswa OfoN <RedStar /></Text>
                <Form.Item
                  name="statusPengajuanUtama"
                  rules={[{ required: true, message: 'Diskon OfoN harus diisi!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <Radio.Group disabled={isFormDisabled}>
                    <Radio value={43}>Lolos</Radio>
                    <Radio value={44}>tidak lolos</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>Diskon OfoN <RedStar /></Text>
                <Form.Item
                  name="diskonId"
                  rules={[{ required: true, message: 'Beasiswa OfoN harus diisi!' }]}
                  style={{marginBottom: '1em'}}
                >
                  <Radio.Group disabled={isFormDisabled}>
                    {listDiskon.map(diskon => (<Radio value={diskon.diskonId}>{diskon.diskonOfon}</Radio>))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              {!!data.beasiswaTambahan && (
                <Col span={24}>
                  <Text>Diskon KEKL <RedStar /></Text>
                  <Form.Item
                    name="statusPengajuanTambahan"
                    rules={[{ required: true, message: 'Diskon KEKL harus diisi!' }]}
                    style={{marginBottom: '1em'}}
                  >
                    <Radio.Group disabled={isFormDisabled}>
                      <Radio value={43}>Lolos</Radio>
                      <Radio value={44}>tidak lolos</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Text>Catatan</Text>
                <Form.Item name="catatanVerifikasi">
                  <Input.TextArea className="app-input" disabled={isFormDisabled} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Space className="end" style={{width: '100%'}}>
            {!isFormDisabled && <Button className="app-btn secondary padded" onClick={handleSubmit}>Simpan</Button>}
            <Button className="app-btn primary padded" onClick={onCancel}>Batal</Button>
          </Space>
        </Col>
      </Row>
      </Spin>
    </Modal>
  )
}
