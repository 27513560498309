import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Layout, Row, Button, Table, Card, Input, InputNumber, Select, Typography, Form, Spin } from "antd";
import { LoyolaServices } from "../../../service";
import { dialog } from "../../../functions/alert";
import { FilterOutlined } from "@ant-design/icons";
import moment from 'moment'

const MasterKomitmenUPS = (props) => {
  const [loading, setLoading] = useState(false);
  const [valueKomitmen, setValueKomitmen] = useState([]);
  const [form] = Form.useForm();
  const userId = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId;

  useEffect(() => {
    readKomitmenUPS();
  }, []);

  const readKomitmenUPS = async () => {
    setLoading(true)
    await LoyolaServices.getKomitmenUPS().then(res => {
      if (res.data) {
        setValueKomitmen(res.data.data)
        form.setFieldsValue({ listKomitmen: res.data.data })
      }
      setLoading(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoading(false))
      }
    })
  };

  const onFinish = async (values) => {
    values.listKomitmen.forEach(item => {
      item.lastModifiedBy = userId;
    });
    setLoading(true)
    await LoyolaServices.putKomitmenUPS(values.listKomitmen).then(res => {
      if (res.data) {
        dialog({ icon: 'success', text: 'Data telah disimpan' })
        readKomitmenUPS()
      }
      setLoading(false)
    }).catch(err => {
      if (err) {
        dialog({ icon: 'error', text: err.response ? err.response.data.message : err.message }).then(() => setLoading(false))
      }
    })
  };

  return (
    <Layout.Content style={{ marginTop: 20 }} className="site-layout-content">
      <Row>
        <Col className="text-heading">
          <Typography.Title level={3} style={{ color: '#072A6F', cursor: 'pointer' }}>
            Komitmen UPS
          </Typography.Title>
        </Col>
      </Row>
      <Form
        name="komitmen-ups"
        onFinish={onFinish}
        onFinishFailed={(error) => console.log('Failed : ', error)}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Spin spinning={loading}>
              <Card bordered={false} className="app-card">
                <Row gutter={[20, 24]}>
                  <Col offset={7} span={8}>
                    <Typography.Title level={4} style={{ color: '#072A6F' }}>Deskripsi</Typography.Title>
                  </Col>
                  <Col span={4}>
                    <Typography.Title level={4} style={{ color: '#072A6F' }}>Nominal UPS</Typography.Title>
                  </Col>
                  <Col span={4}>
                    <Typography.Title level={4} style={{ color: '#072A6F' }}>Nominal US</Typography.Title>
                  </Col>
                </Row>
                <Form.List name="listKomitmen">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => (
                        <Row gutter={[20]}>
                          <Col offset={1} span={6}>
                            <Typography.Title level={4} style={{ color: '#072A6F' }}>{valueKomitmen[index].komitmenUps}</Typography.Title>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={[field.name, "infoKomitmen"]}>
                              <Input.TextArea
                                style={{ borderRadius: 16 }}
                                rows={4} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name={[field.name, "biayaUps"]}
                              rules={[
                                {
                                  pattern: new RegExp('^[0-9]+$'),
                                  message: "Harap diisi dengan angka"
                                }
                              ]}
                            >
                              <Input
                                prefix="Rp."
                                style={{ borderRadius: 16 }} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name={[field.name, "biayaUs"]}
                              rules={[
                                {
                                  pattern: new RegExp('^[0-9]+$'),
                                  message: "Harap diisi dengan angka"
                                }
                              ]}
                            >
                              <Input
                                prefix="Rp."
                                style={{ borderRadius: 16 }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Card>
            </Spin>
          </Col>
        </Row>
        <Row gutter={[20]} justify="end" style={{ marginTop: 30 }}>
          <Col span={3}>
            <Form.Item>
              <Button block type='primary' style={{ borderColor: '#EF7138', borderRadius: 8, backgroundColor: '#EF7138' }} onClick={readKomitmenUPS}>
                Reset
              </Button>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item>
              <Button block loading={loading} type="primary" htmlType="submit" style={{ borderColor: '#FEE64D', borderRadius: 8, backgroundColor: '#FEE64D', color: '#072A6F' }}>
                Simpan
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Layout.Content>
  );
};

export default withRouter(MasterKomitmenUPS);