import swal from "sweetalert";

export function confirmDialog (config) {
  return new Promise( async(resolve, reject) => {
    const result = await swal({
      
      icon: 'info',
      text: 'Apakah anda Yakin?',
      confirm: true,
      buttons: {
        confirm: {
          text: "Simpan",
          value: true,
          visible: true,
          className: 'app-btn primary'
        },
        cancel: {
          text: "Batal",
          value: false,
          visible: true,
          className: 'app-btn danger'
        }
      },
      ...config
    });

    
    result ? resolve(result) : reject(result);
  })
  
  // return isConfirmed;
}

export function dialog(config) {
  return new Promise( async(resolve, reject) => {
    const result = await swal({
      icon: 'success',
      buttons: {
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: 'app-btn primary'
        }
      },
      ...config
    });
    resolve(result)
  })
}

export function confirmDeleteDialog (config) {
  return new Promise( async(resolve, reject) => {
    const result = await swal({
      
      icon: 'info',
      text: 'Apakah anda Yakin?',
      confirm: true,
      buttons: {
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: 'app-btn primary'
        },
        cancel: {
          text: "Batal",
          value: false,
          visible: true,
          className: 'app-btn danger'
        }
      },
      ...config
    });

    
    result ? resolve(result) : reject(result);
  })
}