/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row, Typography, Button, Table, Card, Input, Form, Checkbox, InputNumber, Space, Spin} from "antd";
import { LoyolaServices } from "../../../service";
import Dummy from "../../../dummy";
import { dialog, confirmDeleteDialog } from "../../../functions/alert";
import {CloseOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import FormSmp from "../component/form-smp";
import _debounce from "lodash.debounce";
import PercentInput from '../../../component/percent-input'

const { Content } = Layout;
const { Title, Text } = Typography;

const btnStyle = {
  color: 'white',
  background: '#00a345',
  borderColor: 'transparent',
  padding: '.5em 2em',
  borderRadius: '4px',
  height: 'auto'

}

function PotonganBiaya (props) {
  const [value, setValue] = useState({});
  const [startValue, setStartValue] = useState([
    {jenisPotongan: 'Potongan', diskonUPS: 5, diskonUS: 10, isActive: false},
    {jenisPotongan: 'Potongan', diskonUPS: 5, diskonUS: 10, isActive: true},
    {jenisPotongan: 'Potongan', diskonUPS: 5, diskonUS: 10, isActive: false}
  ]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    getData()
    // getDataDummy()
    // form.setFieldsValue({value: startValue});
  }, []);

  const getData = async () => {
    setLoading(true);
    await LoyolaServices.getDataPotonganBiaya().then(res => {
      if(res.data){
        const {data} = res.data;
        const newData = data.map(d => {
          return {
            createdBy: d.createdBy,
            potBiayaId: d.potBiayaId,
            jenisPotongan: d.jenisPotongan,
            isActive: d.isActive,
            diskonUPS: d.diskonUPS * 100,
            diskonUS: d.diskonUS * 100,
            isFlatOffer: d.isFlatOffer, 
            isFlatOfferPlus: d.isFlatOfferPlus, 
          }
        })
        setStartValue(newData)
        form.setFieldsValue({value: newData});
      }
    }).catch((err) => {
      if(err){
        let text = err.response && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
      }
    })
    setLoading(false)
  }

  const handleReset = () => {
    form.setFieldsValue({value: startValue})
  }

  const saveData = async () => {
    setLoading(true)
    const data = form.getFieldsValue().value;
    const body = data.map(d => {
      return {
        ...d,
        diskonUPS: d.diskonUPS / 100,
        diskonUS: d.diskonUS / 100
      }
    });
    console.log(body);
    await LoyolaServices.putDataPotonganBiaya(body).then(res => {
      if(res.data){
        dialog({icon: 'success', text: 'Potongan biaya berhasil tersimpan'});
      }
    }).catch((err) => {
      if(err){
        let text = err.response && err.response.data.message ? err.response.data.message : "Terjadi Kesalahan Pada Server"
        dialog({icon: 'error', text: text});
      }
    })
    setLoading(false)
  }

  const handleChange = () => {
    setValue(form.getFieldsValue())
  }

  return(
    <div style={{padding: '20px 0px'}}>
      <Title level={4}>Potongan Biaya</Title>
      <Card className="appCard">
      <Spin spinning={loading}>
        <Form 
          onValuesChange={handleChange}
          form={form}
        >
          
          <div className="ant-table app-table" style={{marginBottom: '0px'}}>
            <div className="ant-table-container">
              <div className="ant-table-content" style={{overflowY: 'auto'}}>
                <Form.List name="value">
                  {(fields, {add, remove}) => (
                    <>
                    <table style={{fontWeight: "bold", fontSize: "1.2em", marginBottom: '1em', minWidth: '800px'}}>
                      <thead style={{textAlign: "center"}}>
                        <tr>
                          <td style={{width: "40%", textAlign: "left"}} rowSpan={2}>Jenis Diskon</td>
                          <td>Diskon UPS</td>
                          <td>Diskon US</td>
                          <td>Flat Offer</td>
                          <td>Flat Offer Plus</td>
                          <td>Aktif</td>
                        </tr>
                      </thead>
                      <tbody>
                        {(fields.map((field, index) => {
                          const formItemStyle = {
                            marginBottom: '0px'
                          }
                          return (
                            <tr key={`ekskul_row_${index}`}>
                              <td><Form.Item style={formItemStyle} name={[field.name, "jenisPotongan"]} rules={[{required: true, message: 'Jenis diskon Harus diisi'}]}>
                                <Input placeholder="Jenis diskon" />
                              </Form.Item></td>
                              <td><Form.Item style={formItemStyle} className="no-handler" name={[field.name, "diskonUPS"]} rules={[{required: true, message: 'Diskon UPS Harus diisi'}]}>
                                <PercentInput maxLength={2} addonAfter="%" />
                              </Form.Item></td>
                              <td><Form.Item style={formItemStyle} className="no-handler" name={[field.name, "diskonUS"]} rules={[{required: true, message: 'Diskon US Harus diisi'}]}>
                                <PercentInput maxLength={2} addonAfter="%" />
                              </Form.Item></td>
                              <td style={{textAlign: 'center'}}><Form.Item style={formItemStyle} name={[field.name, "isFlatOffer"]} valuePropName="checked">
                                <Checkbox />
                              </Form.Item></td>
                              <td style={{textAlign: 'center'}}><Form.Item style={formItemStyle} name={[field.name, "isFlatOfferPlus"]} valuePropName="checked">
                                <Checkbox />
                              </Form.Item></td>
                              <td style={{textAlign: 'center'}}><Form.Item style={formItemStyle} name={[field.name, "isActive"]} valuePropName="checked">
                                <Checkbox />
                              </Form.Item></td>
                              {/* <td title="Hapus Ekstrakurikuler"><CloseOutlined style={{fontSize: '1em'}} onClick={_ => remove(index)} /></td> */}
                            </tr>
                          )
                        }))}
                      </tbody>
                    </table>
                    <div style={{padding: '0em 1em 1.5em'}}>
                      <Button
                        onClick={() => {
                          const user = JSON.parse(sessionStorage.getItem('user')) || {};
                          add({createdBy: user.userId, isActive: false, diskonUPS: 0, diskonUS: 0, isFlatOffer: false, isFlatOfferPlus: false})
                        }}
                        style={btnStyle}
                      >
                        <PlusOutlined /> Tambah Potongan
                      </Button>
                    </div>
                    <Space size="middle" style={{width: '100%', display: 'flex', justifyContent: 'right', padding: '1.5em 1em 0px', borderTop: '1px solid #d9d9d9'}}>
                      <Button
                        onClick={handleReset}
                        style={{...btnStyle, background: '#ef712b'}}
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={() => {form.validateFields().then(_ => {saveData()}).catch(err => {})}}
                        style={{...btnStyle, background: '#ffe500', color: '#072a6f'}}
                      >
                        Simpan
                      </Button>
                    </Space>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </div>
        </Form>
        </Spin>
        {/* <pre style={{padding: '2em', margin: '2em 0px', background: '#eee'}}>{JSON.stringify(value, '', 2)}</pre> */}
      </Card>
    </div>
  );
};

export default withRouter(PotonganBiaya);